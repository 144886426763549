import { apiAuthQuestion, apiAuthAnswer } from '@/api/auth'
import { required, email } from '@vuelidate/validators'
import { RESET_PASSWORD_TOKEN_LIFETIME } from '@/constants'
import { notCreditCard } from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  data: () => ({
    passwordVisible: false,
    email: '',
    question: null,
    answer: '',
    loading: false,
    errors: {}
  }),
  created() {
    const resetRequest = localStorage.getItem('resetRequest')
    if (resetRequest && Date.now() - parseInt(resetRequest) < RESET_PASSWORD_TOKEN_LIFETIME) {
      this.setModal('ResetPassword')
    }
    if (resetRequest && Date.now() - parseInt(resetRequest) > RESET_PASSWORD_TOKEN_LIFETIME) {
      localStorage.removeItem('resetRequest')
      localStorage.removeItem('resetRequestUsername')
    }
  },
  validations() {
    if (!this.question) {
      return {
        email: { required, email },
      }
    } else {
      return {
        email: { required },
        answer: { required, notCreditCard }
      }
    }
  },
  methods: {
    handleRequest() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      return this.question ? this.handleRequestAnswer() : this.handleRequestQuestion()
    },
    async handleRequestQuestion() {
      try {
        this.loading = true
        const res = await apiAuthQuestion(this.email)
        if (res.question) this.question = res.question
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
      }
      finally {
        this.loading = false
        this.$store.dispatch('app/setErrors')
      }
    },
    async handleRequestAnswer() {
      const data = {
        username: this.email,
        question: this.question.code,
        answer: this.answer
      }
      try {
        this.loading = true
        const res = await apiAuthAnswer(data)
        if (res.status === 'SUCCESS') {
          this.setModal('ResetPassword')
          this.$msg('Password reset request successfully, we will send secret code for change password - please check your email!', 1)
          localStorage.setItem('resetRequest', JSON.stringify(Date.now()))
          localStorage.setItem('resetRequestUsername', this.email)
        }
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
      }
      finally {
        this.loading = false
        this.$store.dispatch('app/setErrors')
      }
    }
  }
}
