import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),

    props: {
      open: Boolean,
      // initial code value
      isEdit: Boolean,
      valueN: Number,
      disabled: {
        type: Boolean,
        default: false
      },
      max: {
        type: Number,
        default: 14
      }
    },
    data: () => ({
      // value: "",
      code: "",
      label: "",
      nights: [
        {
            title: '1 Night',
            code: 1
        },
        {
            title: '2 Nights',
            code: 2
        },
        {
            title: '3 Nights',
            code: 3
        },
        {
            title: '4 Nights',
            code: 4
        },
        {
            title: '5 Nights',
            code: 5
        },
        {
            title: '6 Nights',
            code: 6
        },
        {
            title: '7 Nights',
            code: 7
        },
        {
            title: '8 Nights',
            code: 8
        },
        {
            title: '9 Nights',
            code: 9
        },
        {
            title: '10 Nights',
            code: 10
        },
        {
            title: '11 Nights',
            code: 11
        },
        {
            title: '12 Nights',
            code: 12
        },
        {
            title: '13 Nights',
            code: 13
        },
        {
            title: '14 Nights',
            code: 14
        },
    ]
    }),
    computed: {
      windowWidth() {
        return this.windowSize.width.value;
      },
      computedNights() {
        return this.nights.filter(i => i.code <= this.max)
      },
      selectedOption() {
        return this.nights.find(i => i.code === this.valueN)
      }
    },
    methods: {
      selectNights(night, code) {
        this.code = code;
        // this.value = night;
        this.$emit("getNights", { night: code});
        this.$emit("open-select", "");
      },

      init() {
        // const currentNight = this.nights.find((item)=> item.code === this.valueN )
        // this.value = currentNight.title || '1 Night'
      },
    },
    mounted() {
      this.init();
    },
  };
  