import "core-js/stable";
import "regenerator-runtime/runtime";
import "intersection-observer";
import Vue from "vue";
import VueMq from 'vue-mq'
import VueBus from "vue-bus";
import VueDebounce from "vue-debounce";
import moment from "moment";
moment().format();
import VCalendar from "v-calendar";
import "./style/app.sass";
import "./config/prototypes";
import "./config/events";
import "./config/filters";
import "./lib/ant-design";
import globalMixin from "./mixin/globalMixin";
import store from "./store";
import router from "./lib/router";
import App from "./App.vue";
import Header from "./components/Header/index.vue";
import InitialAlert from "./components/InitialAlert/index.vue";
import Footer from "./components/Footer/index.vue";
import Modal from "./components/Modal/index.vue";
import CancelReservation from "./components/CancelReservation/index.vue";
import CancelReservationItem from "./components/CancelReservationItem/index.vue";
import LodgingItem from "./pages/Profile/ReservationPage/LodgingItem/index.vue";
import ActivityItem from "./pages/Profile/ReservationPage/ActivityItem/index.vue";
import CartItem from "./pages/ReviewPage/CartItem/index.vue";
import CartTourItem from "./pages/ReviewPage/CartTourItem/index.vue";
import CheckAvail from "./components/CheckAvail/index.vue";
import Code from "./components/CheckAvail/Code/index.vue";
import DateInput from "./components/CheckAvail/Date/index.vue";
import Nights from "./components/CheckAvail/Nights/index.vue"
import Guests from "./components/CheckAvail/Guests/index.vue";
import Location from "./components/CheckAvail/Location/index.vue";
import Select from "./components/CheckAvail/Select/index.vue";
import InfoBar from "./components/InfoBar/index.vue";
import StepsBar from "./components/StepsBar/index.vue";
import Promo from "./components/Promo/index.vue";
import HotelCard from "./components/HotelCard/index.vue";
import HotelMap from "./components/HotelMap/index.vue";
import ReservationCard from "./components/ReservationCard/index.vue";
import RoomCard from "./components/RoomCard/index.vue";
import InlineActivityCard from "./components/InlineActivityCard/index.vue";
import TourCard from "./components/TourCard/index.vue";
import FilterSidebar from "./components/FilterSidebar/index.vue";
import AccountMobileSideMenue from "./components/AccountMobileSideMenue/index.vue";
import Spinner from "./components/Spinner/index.vue";
import Debug from "./components/Debug/index.vue";
import ContextHeaderNavigation from "./components/ContextHeaderNavigation/index.vue";
import {VueWindowSizePlugin} from "vue-window-size/plugin";
import { apiAppStatus } from "@/api/property";
import Clipboard from "v-clipboard";
import { gtmInit } from "@/lib/gtm";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'vue-resize/dist/vue-resize.css';
import VueResize from 'vue-resize';

if (
  typeof globalThis !== 'undefined' &&
  import.meta.env.NODE_ENV === 'production'
) {
  globalThis.console.log = () => null;
  globalThis.console.warn = () => null;
  globalThis.console.info = () => null;
  globalThis.console.error = () => null;
}

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
  console.log('errorHandler: err, vm, info', err, info);
}

Vue.config.warnHandler = function (msg, vm, trace) {
  console.log('warnHandler: msg, vm, trace', msg, trace);
}


Vue.use(VueBus);
Vue.use(VueDebounce);
Vue.use(Clipboard);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueResize);
// eslint-disable-next-line
Vue.component("Header", Header);
Vue.component("InitialAlert", InitialAlert);
// eslint-disable-next-line
Vue.component("Footer", Footer);
Vue.component("Modal", Modal);
Vue.component("CancelReservation", CancelReservation);
Vue.component("CancelReservationItem", CancelReservationItem);
Vue.component("LodgingItem", LodgingItem);
Vue.component("ActivityItem", ActivityItem);
Vue.component("CartItem", CartItem);
Vue.component("CartTourItem", CartTourItem);
Vue.component("CheckAvail", CheckAvail);
Vue.component("promo-code", Code);
Vue.component("date", DateInput);
Vue.component('nights', Nights)
Vue.component("guests", Guests);
Vue.component("location", Location);
Vue.component("select-custom", Select);
Vue.component("info-bar", InfoBar);
Vue.component("Promo", Promo);
Vue.component("HotelCard", HotelCard);
Vue.component("HotelMap", HotelMap);
Vue.component("ReservationCard", ReservationCard);
Vue.component("RoomCard", RoomCard);
Vue.component("InlineActivityCard", InlineActivityCard);
Vue.component("TourCard", TourCard);
Vue.component("steps-bar", StepsBar);
Vue.component("spinner", Spinner);
Vue.component("filter-sidebar", FilterSidebar);
Vue.component("AccountMobileSideMenue", AccountMobileSideMenue);
Vue.component("debug", Debug);
Vue.component("ContextHeaderNavigation", ContextHeaderNavigation);

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.use(VueMq, {
  breakpoints: {
    sm: 480,
    md: 640,
    lg: 992,
    xl: 1300,
    xxl: Infinity
  }
})

Vue.mixin(globalMixin);
Vue.prototype.$eventHub = new Vue();
const files = import.meta.glob("./assets/svg-inline/*.svg");

export const app = new Vue({
  el: "#app",
  store,
  router,
  render: (r) => r(App),
  data: () => ({
    intervalId: "",
    innerWidth: window.innerWidth,
  }),
  async created() {
    const ping = await apiAppStatus();
    this.$store.dispatch("auth/setAvailabilityStatusMessage", ping.message);
    this.checkStatus();

    await store.dispatch(
      "auth/setAuthStatus",
      !!localStorage.getItem("access_token")
    );
    await store.dispatch("app/initApp");
    await store.dispatch("property/initCart");
    await gtmInit(Vue, router);
    await store.dispatch("app/ready");
  },
  methods: {
    checkStatus() {
      let self = this;
      this.intervalId = setInterval(async function() {
        let ping = await apiAppStatus();
        await self.$store.dispatch(
          "auth/setAvailabilityStatusMessage",
          ping.message
        );
      }, 300000);
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
});