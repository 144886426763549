import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),
  props: {
    open: Boolean,
    label: String,
    value: String,
    noMobile: Boolean,
    disabled: Boolean,
    close: Function
  },
  data: () => ({
  }),
  computed: {
    windowWidth() {
      return this.windowSize.width.value;
    },
  },
  watch: {
    open() {
      if (this.open) {
        this.$nextTick().then(() => {
          const el = document.getElementById("select-data")
          const { left } = el.getBoundingClientRect()
          if (left < 0 && this.windowWidth >= this.SIZES.md) {
            el.style.transform = `translateX(${Math.abs(left) + 15}px)`
          }
        })
      }
    }
  },
  methods: {
    openSelect() {
      if (!this.disabled) {
        this.$emit('open-select')
      }
    },
    onClose() {
      if (typeof this.close === "function") {
        this.close();
      } else {
        this.$emit('open-select')
      }
    }
  }
}
