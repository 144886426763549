
export default {
  methods: {
    onReturnToCart() {
      this.$router.push({
        name: "ReviewPage"
      })
    }
  },
};
