import { apiAvailabilityRooms } from "@/api/availability";
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    // global available dates
    minDate: Date,
    maxDate: Date,
    maxStayDate: {
      type: Date,
      default: null
    },
    // dynamic available dates
    allowMinDate: Date,
    allowMaxDate: Date,
    // selected date range
    startDate: Date,
    endDate: Date,
    // disabled month days
    disabledDays: {
      type: Array,
      default: null
    },
    customClasses: {
      type: Object,
      default() {
        return {};
      },
    },
    // minimum number of nights to select
    minNights: {
      type: Number,
      default: 1,
    },
    // current date
    date: Date,
    // day click callback
    onDayClick: Function,
    onSelectionPreview: Function,
    // single day type (used for tours)
    singleDay: {
      type: Boolean,
      default: false,
    },
    availableDays: {
      type: Array,
      default: null
    },
    prevMonthLastDateDisabled: {
      type: Boolean,
      default: null
    }
  },
  data: () => ({
    dayTitleFormat: "MMMM D, YYYY",
    //availableDates: [moment(), moment()],
    disabledDaysByMonth: {},
    // current date based on year and month. no day is supported
    currentDate: null,
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    // allowed dates range
    initialMinDate: null,
    initialMaxDate: null,
    selectedStartDate: null,
    selectedEndDate: null,
    selectPreviewDate: null,
  }),
  async created() {
    this.init();
  },
  computed: {
    selectedRange() {
      return {
        start: this.startDate,
        end: this.endDate
      }
    },
    currentMonth() {
      return this.currentDate.getMonth();
    },
    currentMonthLabel() {
      return this.months[this.currentMonth];
    },
    currentYear() {
      return this.currentDate.getFullYear();
    },
    currentMonthKey() {
      return this.$dayjs(this.currentDate).format("YYYY-MM");
    },
    allowPrevMonth() {
      // const minDate = this.$dayjs(this.minDate).toDate();
      // const currentDate = this.$dayjs(this.currentDate).toDate();

      // //console.log("allowPrevMonth", minDate, currentDate);

      // return minDate < currentDate;
      return this.currentDate > this.allowMinDate;
    },
    allowNextMonth() {
      // const maxDate = this.$dayjs(this.computedMaxDate).toDate();
      // const currentDate = this.$dayjs(this.currentDate).toDate();
      // maxDate.setDate(1);
      // currentDate.setDate(1);

      // //console.log("allowNextMonth", this.computedMaxDate, maxDate, currentDate);

      // return maxDate > currentDate;
      console.log('allowNextMonth', this.$dayjs(this.allowMaxDate).date(1).toDate())
      return this.currentDate < this.$dayjs(this.allowMaxDate).date(1).toDate();
    },
    checkOutMode() {
      return this.startDate && !this.endDate;
    },
    allDates() {
      const length = this.$dayjs(this.currentDate).daysInMonth()
      return Array.from({ length })
        .map((_, i) => this.$dayjs(this.currentDate).set('date', i + 1).toDate())
    },
    allDays() {
      return this.allDates.map(i => i.getDate())
    },
    computedAvailableDays() {
      return this.allDays
        .filter(i => (
          // is between computed min and max values
          (this.$dayjs(this.currentDate).date(i).isBetween(this.computedMinDate, this.computedMaxDate, 'day', '[]')) &&
          // is not in disabled days and disabledDays is defined
          (!this.disabledDays || !this.disabledDays.includes(i)) &&
          // is in availabile days if availableDays is defined
          (!this.availableDays || this.availableDays.includes(i))
        ))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
    },
    disabledDaysComputed() {
      return this.allDays
        .filter(i => (
          (!this.disabledDays || this.disabledDays.includes(i)) &&
          (!this.computedAvailableDays || !this.computedAvailableDays.includes(i))
        ))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
      // let days = this.disabledDays ? this.disabledDays : [];

      // days.sort(function(a, b) {
      //   return a - b;
      // });

      // //console.log("days", this.minNights);
      // //console.log("days", days);

      // let filledDays = [];
      // /*if (this.minNights > 1) {
      //   const gap = this.minNights + 1;
      //   const length = days.length;
      //   let prev, next;
      //   for (var i = 0; i < length; i++) {
      //     if (!prev) {
      //       prev = days.shift();
      //       filledDays.push(prev);
      //       continue;
      //     }

      //     next = days.shift();

      //     if (next - prev < gap) {
      //       //console.log('FILL', prev, next)
      //       for (var j = prev + 1; j < next; j++) {
      //         console.log("FILL::2", j);
      //         filledDays.push(j);
      //       }
      //     }

      //     filledDays.push(next);

      //     prev = next;
      //   }
      // } else {
      //   filledDays = days;
      // }*/
      // filledDays = days;

      // //console.log("days", filledDays);

      // return filledDays;
    },
    // calculates min possible date starting from startDate to first disabled day
    minDateFromDisabledDays() {
      if (!this.selectedStartDate || this.selectedEndDate) {
        return null;
      }

      let date = this.$dayjs(this.currentDate).toDate();

      /*for (var i = 0; i <= 12; i++) {
        console.log(this.$dayjs(this.currentDate).add(i, "month"));
      }*/

      let day = null;
      const selectedDate = this.selectedStartDate.getDate();
      const selectedMonth = this.selectedStartDate.getMonth();
      const currentMonth = this.currentMonth;

      if (selectedMonth < currentMonth) {
        return null;
      }

      if (this.disabledDaysComputed) {
        let days = this.disabledDaysComputed;

        if (selectedMonth === currentMonth) {
          days = days.filter((day) => day < selectedDate);
        }

        day = _.max(days);
      }

      if (day) {
        day++;
        date.setDate(day);
      } else {
        date = null;
      }

      //console.log("minDateFromDisabledDays", date);

      return this.$dayjs(date).toDate();
    },
    computedMinDate() {
      console.log('computedMinDate')
      let date = null;
      let dates = [];

      if (this.allowMinDate) {
        dates.push(this.allowMinDate);
      }

      if (this.minDate) {
        dates.push(this.minDate);
      }

      // if (this.minDateFromDisabledDays) {
      //   dates.push(this.minDateFromDisabledDays);
      // }

      //console.log(dates);

      if (dates.length > 0) {
        date = _.max(dates);
      }

      //console.log("computedMinDate", date);

      return this.$dayjs(date).toDate();
    },
    // calculates max possible date starting from startDate to first disabled day
    maxDateFromDisabledDays() {
      if (!this.selectedStartDate || this.selectedEndDate) {
        return null;
      }

      let date = this.$dayjs(this.currentDate).toDate();
      let day = null;
      const selectedDate = this.selectedStartDate.getDate();
      const selectedMonth = this.selectedStartDate.getMonth();
      const currentMonth = this.currentMonth;

      if (selectedMonth > currentMonth) {
        return null;
      }

      if (this.disabledDaysComputed) {
        let days = this.disabledDaysComputed;

        if (selectedMonth === currentMonth) {
          days = days.filter((day) => day > selectedDate);
        }

        day = _.min(days);
      }

      if (day) {
        //day--;
        date.setDate(day);
      } else {
        date = null;
      }

      //console.log("maxDateFromDisabledDays", date);

      return this.$dayjs(date).toDate();
    },
    // calculates max possible date starting from startDate to disabled day plus extra day allowing checkout
    maxDateFromDisabledDaysForCheckout() {
      if (!this.selectedStartDate || this.selectedEndDate) {
        return null;
      }

      let date = this.$dayjs(this.currentDate).toDate();
      let day = null;
      const selectedDate = this.selectedStartDate.getDate();
      const selectedMonth = this.selectedStartDate.getMonth();
      const currentMonth = this.currentMonth;

      if (selectedMonth > currentMonth) {
        return null;
      }

      if (this.disabledDaysComputed) {
        let days = this.disabledDaysComputed;

        if (selectedMonth === currentMonth) {
          days = days.filter((day) => day > selectedDate);
        }

        day = _.min(days);
      }

      if (day) {
        date.setDate(day);
      } else {
        date = null;
      }

      //console.log("maxDateFromDisabledDaysForCheckout", date);

      return this.$dayjs(date).toDate();
    },
    // max stay date takes into account:
    // - maxStayDate
    // - maxDate + 14 days (if there is no maxStayDate)
    computedMaxStayDate() {
      return this.maxStayDate || this.$dayjs(this.maxDate).add(14, 'day').toDate()
    },
    maxCheckoutDate() {
      return this.$dayjs(this.computedMaxStayDate).add(1, 'day').toDate()
    },
    // max date takes into account:
    // - global maxdate
    // - dynamic maxdate (+-14 days, applied from parent)
    // - maxdate from disabled days array
    computedMaxDate() {
      let date = null;
      let dates = [];

      if (this.allowMaxDate) {
        dates.push(this.singleDay ? this.allowMaxDate : this.maxCheckoutDate)
      }

      if (this.maxDate) {
        dates.push(this.singleDay ? this.maxDate : this.maxCheckoutDate)
      }

      // if (!this.singleDay && this.maxDateFromDisabledDays) {
      //   dates.push(this.maxDateFromDisabledDays);
      // }

      //console.log("computedMaxDate", this.allowMaxDate, this.maxDate, this.maxDateFromDisabledDays);
      //console.log(dates);

      if (dates.length > 0) {
        date = _.min(dates);
      }

      //console.log("computedMaxDate", date);

      return this.$dayjs(date).toDate();
    },
    // same as computedMaxDate, but:
    // - allows first disabled day from disabled days array
    /*
    computedMaxDateForCheckout() {
      let date = null;
      let dates = [];

      if (this.allowMaxDate) {
        dates.push(this.allowMaxDate);
      }

      if (this.maxDate) {
        dates.push(this.maxDate);
      }

      if (this.maxDateFromDisabledDaysForCheckout) {
        dates.push(this.maxDateFromDisabledDaysForCheckout);
      }

      //console.log(this.allowMaxDate, this.maxDate, this.maxDateFromDisabledDaysForCheckout);
      //console.log(dates);

      if (dates.length > 0) {
        date = _.min(dates);
      }

      //console.log("computedMaxDateForCheckout", date);

      return this.$dayjs(date).toDate();
    },
    */
    weeks() {
      // NEW
      const getWeekOfMonth = date => {
        const firstDayInMonth = this.$dayjs(date).date(1).day()
        const calendarDayNumber = this.$dayjs(date).date() + firstDayInMonth - 1
        return 0 | calendarDayNumber / 7
      }

      const weeks = _.chain(0)
        .range(getWeekOfMonth(this.allDates.slice(-1)[0]) + 1)
        .map(i => _.map(_.range(0, 7), i => ({
          disabled: true,
          outsideCurrentMonth: true
        })))
        .value()
  
      if (this.singleDay) {
        this.allDates.forEach(date => {
          const dayjsDate = this.$dayjs(date)
          const weekOfMonth = getWeekOfMonth(date)
          const isDisabled = !this.computedAvailableDays.includes(dayjsDate.date())

          weeks[weekOfMonth][dayjsDate.day()] = {
            customClasses: "",
            date: dayjsDate.date(),
            day: dayjsDate.day(),
            dayjs: dayjsDate,
            disabled: isDisabled,
            month: dayjsDate.month(),
            notSelectable: false,
            outsideCurrentMonth: false,
            selected: dayjsDate.isSame(this.startDate),
            year: dayjsDate.year()
          }
        })

        return weeks

      } else {
        this.allDates.forEach(date => {
          const dayjsDate = this.$dayjs(date)

          // Prev Date
          const dayjsPrevDate = dayjsDate.subtract(1, 'day')
          const prevDate = dayjsPrevDate.toDate()
          const prevDateIsDisabled = !!(
            prevDate < this.currentDate ||
            !this.computedAvailableDays.includes(dayjsPrevDate.date())
          )
          const prevDateIsNotAllowed = !!(
            prevDate < this.currentDate ||
            (this.allowMinDate && prevDate < this.allowMinDate) ||
            (this.allowMaxDate && prevDate > this.allowMaxDate)
          )

          const weekOfMonth = getWeekOfMonth(date)
          const isStartDate = dayjsDate.isSame(this.startDate)
          const isEndDate = dayjsDate.isSame(this.endDate)
          const isSelected = (
            isStartDate ||
            isEndDate ||
            this.startDate && this.endDate && dayjsDate.isBetween(this.startDate, this.endDate, 'day', '[]')
          )
          const isHovered = (
            this.selectedStartDate &&
            this.selectPreviewDate &&
            dayjsDate.isBetween(this.selectedStartDate, this.selectPreviewDate, 'day', '[]')
          )
          const isDisabled = (
            !this.computedAvailableDays.includes(dayjsDate.date())
          )
          const isNotAllowed = (
            (this.allowMinDate && date < this.allowMinDate) ||
            (this.allowMaxDate && date > this.allowMaxDate)
          )

          let firstEnabledDay
          let nextAfterLastEnabledDay
          if (dayjsDate.date() === 1) {
            firstEnabledDay = !isDisabled && this.prevMonthLastDateDisabled && !isSelected
            nextAfterLastEnabledDay = isDisabled && (this.prevMonthLastDateDisabled === false) && !isSelected && this.checkOutMode
          } else {
            firstEnabledDay = !isDisabled && prevDateIsDisabled && !isSelected
            nextAfterLastEnabledDay = isDisabled && (prevDateIsDisabled === false && prevDateIsNotAllowed === false) && !isSelected
          }

          weeks[weekOfMonth][dayjsDate.day()] = {
            day: dayjsDate.day(),
            date: dayjsDate.date(),
            month: dayjsDate.month(),
            year: dayjsDate.year(),
            dayjs: dayjsDate,
            outsideCurrentMonth: false,
            startDate: isStartDate,
            selected: isSelected,
            endDate: isEndDate,
            previewSelection: isHovered && !isDisabled && !isNotAllowed,
            disabled: (isDisabled || isNotAllowed) && !(this.checkOutMode && nextAfterLastEnabledDay),
            firstEnabledDay,
            nextAfterLastEnabledDay,
            customClasses: this.customClasses[dayjsDate.format("MM/DD/YYYY")] || null,
            notSelectable: isDisabled && nextAfterLastEnabledDay && !this.checkOutMode,
          }
        })
      }

      return weeks

      // // OLD
      // //console.log("WEEKS", this.disabledDaysComputed);
      // let weeks = [];
      // let currentDateCloned = new Date(this.currentDate.getTime());
      // currentDateCloned.setDate(1);

      // const currentMonth = this.currentMonth;
      // const currentYear = this.currentYear;
      // const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();
      // const firstDay = currentDateCloned.getDay();
      // let started = false;
      // let disabled = false;
      // let firstEnabledDay = false;
      // let nextAfterLastEnabledDay = false;
      // var previewSelection = false;
      // let startDate, endDate;

      // let week = [];
      // for (var i = 0; i < firstDay; i++) {
      //   week.push({
      //     day: i,
      //     date: "",
      //     month: "",
      //     year: "",
      //     //dayjs: this.$dayjs(currentDateCloned),
      //     disabled: true,
      //     outsideCurrentMonth: true,
      //   });
      // }

      // for (var i = 0; i < numberOfDays; i++) {
      //   if (week.length === 7) {
      //     weeks.push(week);
      //     week = [];
      //   }

      //   disabled = false;
      //   firstEnabledDay = false;
      //   nextAfterLastEnabledDay = false;
      //   previewSelection = false;
      //   let day = currentDateCloned.getDate();

      //   if (this.inAllowedRange(currentDateCloned)) {
      //     // current date is inside min/max range

      //     // disable by disabled days
      //     if (this.disabledDaysComputed.indexOf(day) !== -1) {
      //       disabled = true;
      //     }

      //     // selection flags
      //     if (+currentDateCloned === +this.selectedStartDate) {
      //       started = true;
      //     } else if (i == 0 && currentDateCloned > this.selectedStartDate && currentDateCloned < this.selectedEndDate) {
      //       started = true;
      //     }

      //     if (!this.singleDay) {
      //       // look for first check in day
      //       if (!disabled && day > 1 && this.disabledDaysComputed.indexOf(day - 1) !== -1) {
      //         firstEnabledDay = true;
      //       }

      //       // look for last check out day
      //       if (disabled && day > 1 && this.disabledDaysComputed.indexOf(day - 1) === -1) {
      //         nextAfterLastEnabledDay = true;
      //         disabled = false;
      //       } else if (+currentDateCloned === +this.computedMaxDate) {
      //         nextAfterLastEnabledDay = true;
      //         disabled = false;
      //       }
      //     }

      //     // preview selection flag
      //     if (this.selectPreviewDate && this.selectedStartDate) {
      //       let startDateDay = this.selectedStartDate;
      //       let previewDay = this.selectPreviewDate;
      //       let currentDateDay = currentDateCloned;

      //       if (+startDateDay <= +previewDay && +currentDateDay >= +startDateDay && +currentDateDay <= +previewDay) {
      //         previewSelection = true;
      //       } else if (startDateDay > previewDay && +currentDateDay >= +previewDay && +currentDateDay <= +startDateDay) {
      //         previewSelection = true;
      //       }
      //     }
      //   } else {
      //     // disable any date outside
      //     disabled = true;
      //   }

      //   /*if (this.checkOutMode) {
      //     // check out mode
      //     if (
      //       currentDateCloned < this.computedMinDate ||
      //       currentDateCloned > this.computedMaxDateForCheckout
      //     ) {
      //       disabled = true;
      //     }
      //   } else {
      //     // check in mode
      //     if (
      //       currentDateCloned < this.computedMinDate ||
      //       currentDateCloned > this.computedMaxDate
      //     ) {
      //       disabled = true;
      //     }

      //     if (this.disabledDaysComputed.indexOf(day) !== -1) {
      //       disabled = true;
      //     }
      //   }*/

      //   // possible selection classes
      //   if (this.selectedStartDate && !this.selectedEndDate) {
      //   }

      //   if (!this.singleDay) {
      //     // start/end date flags
      //     startDate = false;
      //     endDate = false;
      //     if (+currentDateCloned === +this.selectedStartDate) {
      //       startDate = true;
      //     }
      //     if (+currentDateCloned === +this.selectedEndDate) {
      //       endDate = true;
      //     }
      //   }
      //   week.push({
      //     day: currentDateCloned.getDay(),
      //     date: currentDateCloned.getDate(),
      //     month: currentMonth,
      //     year: currentYear,
      //     disabled: disabled && !endDate,
      //     dayjs: this.$dayjs(currentDateCloned),
      //     customClasses: this.customClasses[this.$dayjs(currentDateCloned).format("MM/DD/YYYY")]
      //       ? this.customClasses[this.$dayjs(currentDateCloned).format("MM/DD/YYYY")]
      //       : "",
      //     startDate: startDate,
      //     endDate: endDate,
      //     selected: started,
      //     outsideCurrentMonth: false,
      //     previewSelection: previewSelection,
      //     firstEnabledDay: firstEnabledDay,
      //     nextAfterLastEnabledDay: nextAfterLastEnabledDay,
      //     notSelectable: disabled || (nextAfterLastEnabledDay && !this.checkOutMode),
      //   });

      //   if (!this.selectedEndDate || +currentDateCloned === +this.selectedEndDate) {
      //     started = false;
      //   }

      //   //console.log(currentDateCloned);

      //   currentDateCloned.setDate(currentDateCloned.getDate() + 1);
      // }

      // if (week.length === 7) {
      //   weeks.push(week);
      //   week = [];
      // } else {
      //   for (var i = week.length; i <= 6; i++) {
      //     week.push({
      //       day: i,
      //       date: "",
      //       month: "",
      //       year: "",
      //       disabled: true,
      //       outsideCurrentMonth: true,
      //     });
      //   }
      //   weeks.push(week);
      // }

      // return weeks;
    },
  },
  methods: {
    init() {
      const date = this.date;
      const minDate = this.minDate;
      const maxDate = this.singleDay ? this.maxDate : this.maxCheckoutDate;
      const startDate = this.startDate;
      const endDate = this.endDate;
      const disabledDays = this.disabledDays;

      //console.log(this.currentDate);
      if (typeof date !== "undefined" && date !== null) {
        this.currentDate = this.$dayjs(date).toDate();
      } else {
        this.currentDate = new Date();
      }

      if (typeof minDate !== "undefined" && minDate !== null) {
        this.initialMinDate = minDate;
      } else {
        this.initialMinDate = new Date();
      }
      this.initialMinDate.setHours(0, 0, 0, 0);

      if (typeof maxDate !== "undefined" && maxDate !== null) {
        this.initialMaxDate = maxDate;
        this.initialMaxDate.setHours(0, 0, 0, 0);
      } else {
        this.initialMaxDate = null;
      }

      if (
        typeof startDate !== "undefined" &&
        startDate !== null &&
        typeof endDate !== "undefined" &&
        endDate !== null &&
        !(startDate > endDate)
      ) {
        this.selectedStartDate = startDate;
        this.selectedEndDate = endDate;

        this.selectedStartDate.setHours(0, 0, 0, 0);
        this.selectedEndDate.setHours(0, 0, 0, 0);
      }

      if (typeof disabledDays !== "undefined" && disabledDays !== null) {
        this.disabledDaysByMonth[this.currentMonthKey] = disabledDays;
      } else {
        this.disabledDaysByMonth[this.currentMonthKey] = [];
      }

      //this.currentDate.setDate(1);
      //this.currentDate.setHours(0, 0, 0, 0);

      this.normalizeCurrentDate();

      this.$emit("onCurrentDateSelect", this.currentDate);

      //console.log(this);
    },
    inAllowedRange(date) {
      let flag = true;

      if (this.computedMinDate) {
        if (date < this.computedMinDate) {
          flag = false;
        }
      }

      if (this.computedMaxDate) {
        if (date > this.computedMaxDate) {
          flag = false;
        }
      }

      return flag;
    },
    normalizeCurrentDate() {
      //console.log("normalizeCurrentDate", this.currentDate, this.initialMinDate, this.initialMaxDate);
      this.currentDate.setDate(1);
      this.currentDate.setHours(0, 0, 0, 0);

      if (this.currentDate < this.minDate) {
        this.currentDate = this.$dayjs(this.minDate).toDate();
      }

      if (this.currentDate > (this.maxStayDate || this.maxDate)) {
        this.currentDate = this.$dayjs(this.maxStayDate || this.maxDate).date(1).toDate();
      }
      //console.log("normalizeCurrentDate", this.currentDate);
    },
    onPreviousMonth() {
      if (!this.allowPrevMonth) {
        return;
      }

      this.currentDate = this.$dayjs(this.currentDate)
        .subtract(1, "month")
        .toDate();

      this.normalizeCurrentDate();

      this.$emit("onCurrentDateSelect", this.currentDate);
    },
    onNextMonth() {
      if (!this.allowNextMonth) {
        return;
      }

      this.currentDate = this.$dayjs(this.currentDate)
        .add(1, "month")
        .toDate();

      this.normalizeCurrentDate();

      this.$emit("onCurrentDateSelect", this.currentDate);
    },
    getCellClass(day) {
      let classes = [];

      //console.log(day)

      if (day.outsideCurrentMonth) {
        classes.push("rangepicker-outside-month-cell");
      }
      if (day.disabled) {
        classes.push("rangepicker-disabled-cell");
      }
      if (day.startDate) {
        classes.push("rangepicker-start-selection-cell");
      }
      if (day.endDate) {
        classes.push("rangepicker-end-selection-cell");
      }
      if (day.selected) {
        classes.push("rangepicker-selected-cell");
      }
      if (day.previewSelection) {
        classes.push("rangepicker-preview-selection-cell");
      }
      if (day.firstEnabledDay) {
        classes.push("rangepicker-first-enabled");
      }
      if (day.nextAfterLastEnabledDay) {
        classes.push("rangepicker-next-after-last-enabled");
      }
      if (day.notSelectable) {
        classes.push("rangepicker-not-selectable");
      }
      if ((!day.disabled || day.nextAfterLastEnabledDay) && day.dayjs && this.customClasses[day.dayjs.format("MM/DD/YYYY")]) {
        classes.push(this.customClasses[day.dayjs.format("MM/DD/YYYY")]);
      }

      return classes.join(" ");
    },
    onMouseOverDay(day) {
      if (day.disabled) {
        return;
      }

      let dates = [];
      let date = day.dayjs.toDate();
      let startDate = this.selectedStartDate;
      let endDate = this.selectedEndDate;

      if (startDate && !endDate) {
        dates.push(date);
        if (date > startDate) {
          //console.log("DATE > START");
          if (this.computedMaxDate) {
            dates.push(this.computedMaxDate);
          }

          this.selectPreviewDate = _.min(dates);
        } else {
          //console.log("DATE <= START");
          if (this.computedMinDate) {
            dates.push(this.computedMinDate);
          }

          this.selectPreviewDate = _.max(dates);
        }
      }

      //console.log("onMouseOverDay", this.selectedStartDate, this.selectPreviewDate, this.computedMinDate, date);

      if (typeof this.onSelectionPreview === "function") {
        this.onSelectionPreview(this.selectPreviewDate);
      }
    },
    onClick(day) {
      if (typeof this.onDayClick === "function") {
        this.onDayClick(day);
      }

      /*if (day.disabled) {
        return;
      }

      let date = day.dayjs.toDate();
      let checkOutDay = day.nextAfterLastEnabledDay;
      let startDate = this.selectedStartDate;
      let endDate = this.selectedEndDate;
      this.selectPreviewDate = null;

      console.log("checkOutDay", checkOutDay, this.minNights, date, startDate);

      if (startDate && endDate) {
        if (checkOutDay) {
          return;
        }
        this.selectedStartDate = date;
        this.selectedEndDate = null;
      } else if (startDate) {
        if (date < startDate) {
          this.selectedStartDate = date;
          this.selectedEndDate = startDate;
        } else if (+date === +startDate) {
          this.selectedEndDate = this.$dayjs(date)
            .add(1, "day")
            .toDate();
        } else {
          this.selectedEndDate = date;
        }
      } else if (endDate) {
        // should not happen
      } else {
        if (checkOutDay) {
          return;
        }
        this.selectedStartDate = date;
      }

      this.$emit(
        "onDateRangeSelect",
        this.selectedStartDate,
        this.selectedEndDate
      );*/
    },
  },
  watch: {
    selectedRange: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedStartDate = this.selectedRange.start
        this.selectedEndDate = this.selectedRange.end
        this.selectPreviewDate = this.selectedRange.end
      }
    }
  },
};
