import _ from 'lodash'
import { optionsComputed } from "@/store/helper";
import { mapGetters } from 'vuex'
import bookingMixin from '@/mixin/booking'
import AddToReservationModal from '@/components/reservations/AddToReservationModal/index.vue'

export default {
  mixins: [
    bookingMixin
  ],
  components: {
    AddToReservationModal
  },
  props: {},
  data: () => ({
    today: new Date().setHours(0, 0, 0, 0),
  //   searchParams: {
  //     dateFrom: "",
  //     destination: "",
  //     adults: 1,
  //     children: 2,
  //  },
    destinationConstants: ["ALL"],
    loading: false,
    sort_by: null,
    sort_desc: false
  }),
  methods: {
    async init() {
      this.loading = true;

      if (!this.validateUrl()) {
        this.invalidationRedirect();
        return;
      }

      if (!this.$route.query.destination) {
        return this.$router.push({ name: "tours", query: { ...this.params, destination: 'ALL' } })

      } else if (this.$route.query.destination !== 'ALL') {
        return this.$router.push({ name: 'tourPage', query: { ...this.params } })
      }

      if (this.urlNeedsNormalization()) {
        console.log("urlNeedsNormalization", this.urlNeedsNormalization());
        return this.normalizeUrl();
      }
  
      /*
      await this.loadAvailability();
      */
      // Always update awailibility
      await this.$store.dispatch("property/fetchAvailableTours", {
        date: this.$dayjs(this.tourDate).format("MM/DD/YYYY"),
        limit: 1
      });

      // this.searchParams.dateFrom = this.$dayjs(this.tourDate).format("MM-DD-YYYY");
      // this.searchParams.destination = this.destination;
      // this.searchParams.adults = this.adults;
      // this.searchParams.children = this.children;

      this.loading = false;
    },
    validateUrl() {
      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.infants ?? 0)) ||
        _.isNaN(+(this.$route.query.animals ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // check tourDate
      if (this.$route.query.date) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let tourDate = this.$dayjs(this.$route.query.date, "MM-DD-YYYY");
        if (!tourDate.isValid()) {
          // invalid date format
          console.log(`Invalid tourDate ${tourDate}`);
          return false;
        }

        tourDate = tourDate.toDate();
        if (today > tourDate) {
          // past date
          console.log(`Invalid today > tourDate ${today > tourDate}`);
          return false;
        }

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          if (minDate > tourDate) {
            // date is greater than mindate
            console.log(`Invalid minDate > tourDate ${minDate > tourDate}`);
            return false;
          }
        }

        let maxDate = this.$dayjs(this.options.maxdate, "MM/DD/YYYY");
        if (maxDate.isValid()) {
          maxDate = maxDate.toDate();
          if (tourDate > maxDate) {
            // date is greater than maxdate
            console.log(`Invalid tourDate > maxDate ${tourDate > maxDate}`);
            return false;
          }
        }
      }

      // // validate adults. integer, >=1
      // if (!Number.isInteger(this.adults)) {
      //   return false;
      // }

      if (this.params.adults < 1 || this.params.adults > this.toursMaxAdults) {
        console.log(`Invalid params.adults ${this.params.adults}`)
        return false;
      }

      if (this.params.children < 0 || this.params.children > this.toursMaxChildren) {
        console.log(`Invalid params.children ${this.params.children}`)
        return false;
      }
      console.log('this.params.infants, this.params.adults', this.params.infants, this.params.adults);
      if (this.params.infants < 0 || this.params.infants > this.params.adults) {
        console.log(`Invalid params.infants ${this.params.infants}`)
        return false;
      }

      if (this.params.animals < 0 || this.params.animals > this.params.adults) {
        console.log(`Invalid params.animals ${this.params.animals}`)
        return false;
      }

      const guests = this.params.adults + this.params.children + this.params.infants + this.params.animals;
      if (guests > this.toursMaxAdults) {
        console.log(`Invalid guests ${guests}`)
        return false;
      }

      // destination
      if (!this.validateDestination()) {
        console.log('Destination is not valid')
        return false;
      }

      return true;
    },
    validateDestination() {
      if (this.$route.query.destination) {
        return this.$route.query.destination === "ALL";
      }

      return true;
    },
    urlNeedsNormalization() {
      return (
        typeof this.$route.query.date === "undefined" ||
        typeof this.$route.query.adults === "undefined" ||
        typeof this.$route.query.children === "undefined" ||
        typeof this.$route.query.destination === "undefined"
      );
    },
    invalidationRedirect() {
      this.allowRouteLeave = true;

      if (!this.cartIsEmpty) {
        this.$router.push({ name: "ReviewPage" });
        return;
      } else if (this.addToReservation) {
        this.$router.push({ name: "ReservationPage", params: { reservation_id: this.addToReservation.uniqueId } });
        return
      } else if (this.modifyReservation) {
        this.$router.push({ name: "ReservationPage", params: { reservation_id: this.modifyReservation.uniqueId } });
        return
      }

      this.$router.push({ name: 'book_activity' });
    },
    async loadAvailability() {
      const dateFrom = this.$dayjs(this.tourDate).format("MM/DD/YYYY");

      console.log("loadAvailability::1", this.$store.state.property, this.$store.state.property.toursAvailability);

      if (!this.$store.state.property.toursAvailability || typeof this.$store.state.property.toursAvailability[dateFrom] === "undefined") {
        await this.$store.dispatch("property/fetchAvailableTours", { date: dateFrom, limit: 1 });
      }
    },
    /*
     * Set params defaults and redirect to normalized URL
     */
    async normalizeUrl() {
      let urlQueryParams = {};

      if (!this.$route.query.dateFrom) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          urlQueryParams["date"] = minDate > today ? this.$dayjs(minDate).format("MM-DD-YYYY") : this.$dayjs(today).format("MM-DD-YYYY");
        } else {
          urlQueryParams["date"] = this.$dayjs(today).format("MM-DD-YYYY");
        }
      } else {
        urlQueryParams["date"] = this.$route.query.dateFrom;
      }

      urlQueryParams["destination"] = this.$route.query.destination ? this.$route.query.destination : "ALL";
      urlQueryParams["adults"] = this.$route.query.adults ? this.$route.query.adults : 1;
      urlQueryParams["children"] = this.$route.query.children ? this.$route.query.children : 0;

      if (this.$route.query.rateCode) {
        urlQueryParams["rateCode"] = this.$route.query.rateCode;
      }

      //console.log("normalizeUrl", urlQueryParams);

      return this.$router.push({
        name: "tours",
        query: urlQueryParams,
      });
    },
  },
  computed: {
    ...optionsComputed,
    ...mapGetters({
      tours: 'property/tours',
      addToReservation: 'app/addToReservation',
      modifyReservation: 'app/modifyReservation',
      isModifyReservationMode: 'app/isModifyReservationMode',
      cartIsEmpty: 'property/cartIsEmpty',
      toursMaxAdults: 'property/toursMaxAdults',
      toursMaxChildren: 'property/toursMaxChildren',
      toursAvailability: 'property/toursAvailability',
    }),
    params() {
      return {
        destination: this.$route.query.destination,
        date: this.$route.query.date,
        adults: +this.$route.query.adults || 0,
        children: +this.$route.query.children || 0,
        infants: +this.$route.query.infants || 0,
        animals: +this.$route.query.animals || 0,
        rateCode: this.$route.query.rateCode
      }
    },
    dayToursAvailability() {
      const dateKey = this.$dayjs(this.params.date, 'MM-DD-YYYY').format("MM/DD/YYYY");
      console.log(dateKey, this.toursAvailability, this.toursAvailability[dateKey]);
      return this.toursAvailability[dateKey] ?? {};
    },
    tourDate() {
      return this.$dayjs(this.$route.query.date, "MM-DD-YYYY").toDate();
    },
    destination() {
      return this.$route.query.destination;
    },
    adults() {
      return parseInt(this.$route.query.adults);
    },
    children() {
      return parseInt(this.$route.query.children);
    },
    maxGuests() {
      return parseInt(this.$store.state.app.options.resformMaxOccupancy);
    },

    sortedTours() {
      let sortedTours = _.filter(this.tours, (i) => (
        this.dayToursAvailability?.[i.code] &&
        ['OPEN', 'SOLDOUT'].includes(this.dayToursAvailability?.[i.code].status)
      ))

      if (this.sort_by === 'price') {
        sortedTours = _.sortBy(sortedTours, [
          i => {
            const maxAvailableOption = _.chain(this.dayToursAvailability?.[i.code].options)
              .sortBy(i => i.available)
              .last()
              .value()

              return !maxAvailableOption.available || (this.params.adults + this.params.children) > maxAvailableOption.available
          },

          i => this.dayToursAvailability?.[i.code].prices.adult.min
        ])

      } else {
        sortedTours = _.sortBy(sortedTours, [
          i => {
            const maxAvailableOption = _.chain(this.dayToursAvailability?.[i.code].options)
              .sortBy(i => i.available)
              .last()
              .value()

            return !maxAvailableOption.available || (this.params.adults + this.params.children) > maxAvailableOption.available
          }
        ])
      }

      return sortedTours
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,

      handler() {
        this.init()
      }
    },
    params: {
      immediate: true,
      deep: true,
      handler() {
        if (this.validateUrl()) {
          this.$store.dispatch('property/setActivitySearchParams', {
            ...this.params,
            type: 'exact'
          })
        }
      }
    }
  },
  // What about to create bookingMixin and use it in all activity and lodges booking pages?
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', from.name, to.name)
    if (this.allowRouteLeave) {
      return next();
    }

    if (!this.isModifyReservationMode && !this.addToReservation) {
      return next();
    }

    if (['ReviewPage', 'hotels', 'tours', 'tourPage', 'hotelPage', 'activitiesPage'].includes(to.name)) {
      return next();
    }

    this.setConfirmationModal({
      content: "Are you sure you want to cancel reservation editing?",
    })
      .then(() => {
        if (this.addToReservation) {
          this.$store.dispatch("app/endAddToReservation")
        }

        if (this.isModifyReservationMode) {
          this.$store.dispatch("app/endModifyReservation")
        }

        next();

      })
      .catch(function() {
        next(false);
      });
  }
};
