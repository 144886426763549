<template>
  <b-overlay :show="isFetching">
    <b-row>
      <b-col
        v-for="todoButton in todoButtons"
        :key="todoButton.action"
        lg="4"
        class="mb-4"
        :class="{
          'opacity-5': !todoButton.enabled,
        }"
        :style="{
          filter: !todoButton.enabled ? 'grayscale(100%)' : null
        }"
      >
        <component
          :is="todoButton.is"
          :to="todoButton.is === 'router-link' ? todoButton.action : undefined"
          :href="todoButton.is === 'a' ? todoButton.action : undefined"
          :target="todoButton.is === 'a' ? '_blank' : undefined"
          @click="todoButton.enabled ? addToReservation(todoButton.action) : null"
        >
          <div
            class="position-relative d-block mb-3"
            :class="{
              'cursor-pointer': !!todoButton.enabled,
              'cursor-not-allowed': !todoButton.enabled
            }"
          >
            <b-img
              class="d-block h-220px w-100 object-fit-cover"
              :blank="!todoButton.image"
              :src="todoButton.image"
              :alt="todoButton.title"
            />

            <span
              v-if="!todoButton.enabled"
              class="position-absolute h-100 w-100 d-flex justify-content-center align-items-center text-white font-size-14"
              :style="{
                top: 0,
                left: 0
              }"
            >
              Coming Soon
            </span>
          </div>

          <b-button
            class="text-uppercase"
            variant="primary"
            size="lg"
            block
            :disabled="!todoButton.enabled || todoButton.loading"
          >
            <b-spinner
              v-if="todoButton.loading"
              class="mr-1"
              small
            />

            {{ todoButton.title }}
          </b-button>
        </component>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { apiReservation } from "@/api/property";
import crudItemMixin from '@/mixin/crud-item'
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'ReservationItemContinueShopping',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    crudItemMixin
  ],

  props: {
    mode: {
      type: String,
      default: 'addToReservation'
    }
  },

  data() {
    return {
      idKey: 'token',
      isLoadingActivity: false
    }
  },

  computed: {
    ...mapGetters({
      options: 'app/options',
      cartIsEmpty: 'property/cartIsEmpty'
    }),

    todoButtons() {
      return _.map(this.options.todoButtons, i => ({
        ...i,
        loading: this.checkIfTodoButtonLoading(i.action),
        is: ['activity', 'stay', 'dining'].includes(i.action) ? 'div' : i.action.startsWith('/') ? 'router-link' : 'a',
      }))
    }
  },

  methods: {
    async addToReservation(action) {
      if (action === 'activity') {
        await this.addActivityToReservation()
      } else if (action === 'stay') {
        this.addStayToReservation()
      } else if (action === 'dining') {
        this.addDinningToReservation()
      }
    },

    async addActivityToReservation(isConfirmedClearCart) {
      if (!this.cartIsEmpty && !isConfirmedClearCart) {
        // @TODO move all options to bootstrap settings
        this.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and start editing your reservation?', {
          title: 'Confirm',
          headerBgVariant: 'secondary',
          headerClass: 'close-button-black',
          titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
          contentClass: 'bg-gamma',
          bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
          footerClass: 'border-0',
          okTitle: 'Yes',
          okVariant: 'primary',
          hideHeaderClose: false,
          cancelVariant: 'outline-primary',
          buttonSize: 'lg',
          centered: true
        })
          .then(async (value) => {
            if (value) {
              this.addActivityToReservation(true)
            }
          })

      } else {
        if (this.mode === 'addToReservation') {
          this.isLoadingActivity = true
          await this.$store.dispatch('app/startAddToReservation', this.item)
          this.isLoadingActivity = false
        }

        const query = {
          date: this.$dayjs(this.item.arrival).format('MM-DD-YYYY'),
          adults: this.item.adults,
          children: this.item.children,
          destination: 'ALL'
        }

        this.$router.push({ name: 'tours', query })
      }
    },

    addStayToReservation() {
      this.$router.push({ name: 'ReservationListPage', query: { tab: 'new', reservation: this.item.uniqueId } })
    },

    addDinningToReservation() {
      console.log('addDinningToReservation', this.item)
    },

    checkIfTodoButtonLoading(action) {
      let loading = false
      
      if (action === 'activity') {
        loading = this.isLoadingActivity
      }

      return loading
    },

    fetchItem() {
      this.isFetching = true

      return apiReservation(this.newItemItemIdentifier)
        .then((data) => {
          this.isFetching = false
          this.newItem = data
        })
        .catch(e => {
          this.isFetching = false
          console.log(e)
        })
    }
  }
}
</script>