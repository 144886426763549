import DateNightsSelect from '@shared/components/DateNightsSelect.vue'
import DateSelect from '@shared/components/DateSelect.vue'
import DateFlexibleSelect from '@shared/components/DateFlexibleSelect.vue'
import FormMixin from '@shared/mixins/form'
import { useWindowSize } from 'vue-window-size';
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({
    windowSize: useWindowSize(),
    v$: useVuelidate(),
  }),

  components: {
    DateNightsSelect,
    DateSelect,
    DateFlexibleSelect
  },

  mixins: [
    FormMixin
  ],

  props: {
    minDate: {
      type: Date,
      required: true
    },

    maxDate: {
      type: Date,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    // Can be grater than maxDate
    maxStayDate: {
      type: Date,
      required: false
    },

    mode: {
      type: String,
      default: 'lodges'
    },

    open: {
      type: Boolean,
      default: false
    },

    dateLabel: {
      type: String,
      default: null
    },

    hiddenModeSwitcher: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      autosave: true,
      lastClickedDay: null
    }
  },

  computed: {
    windowWidth() {
      return this.windowSize.width.value;
    },
    label() {
      if (this.newValue.type === 'flexible') {
        return this.$dayjs(this.newValue.date).format('MMMM YYYY')

      } else if (this.mode === 'tours') {
        return this.$dayjs(this.newValue.date).format('ddd, MMM D, YYYY')
      }
      
      const startDateStr = this.$dayjs(this.newValue.date).format('ddd, MMM D')
      const endDateStr = this.$dayjs(this.newValue.date).add(this.newValue.nights || 0, 'day').format('ddd, MMM D, YYYY')
      const nightsStr = `${this.newValue.nights} ${this.newValue.nights === 1 ? 'Night' : 'Nights'}`
      return `${startDateStr} - ${endDateStr}, ${nightsStr}`
    },

    allowedRange() {
      return {
        start: this.minDate,
        end: this.maxDate
      }
    }
  },

  watch: {
    allowedRange: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.newValue || this.newValue.date < this.allowedRange.start) {
          this.newValue.date = this.allowedRange.start

        } else if (this.newValue > this.allowedRange.end) {
          this.newValue.date = this.allowedRange.end
        }
      }
    }
  }
};
