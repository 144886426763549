import {apiDeleteReservation} from "@/api/property";

export default {
    props: {
        reservation: Object,
        type: String,
        isWarning: Boolean,
        uniqId: Number
    },
    data: () => ({
        form: {
            sections: {},
            total: 0,
            tax: 0,
            subtitles: {},
            arrival: null,
            departure: null,
        },
        deposit: 0,
        tours: {},
        lodgingAmount: null,
    }),
    created() {
        this.tours = this.$store.state.property.tours;
        this.form.sections = this.reservation.sections;
        this.form.tax= this.reservation.tax;
        this.form.total = this.reservation.total;
        this.form.subtitles = this.reservation.subtitles;
        this.form.arrival = this.reservation.arrival;
        this.form.departure = this.reservation.departure;
        this.lodgingAmount = this.$get(this.reservation, 'sections.Lodging.items.length') || null;
        const currentSection = this.reservation.sections[Object.keys(this.reservation.sections)[0]].items;
        this.deposit = currentSection.reduce((acc, val)=> acc + val.deposit,
        0)
    },
    computed: {
        totalItemsAmount : function() {
            return this.$get(this.reservation, 'sections.Lodging.items.length') || 0 + this.$get(this.reservation, 'sections.Tours.items.length') || 0;
        }
    },
    methods: {}
}
