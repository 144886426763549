import { PROPERTY_CODE } from "@/constants";
import { apiAddTourToCart } from "@/api/cart";
import { mapGetters } from 'vuex'

export default {
  props: {},
  data: () => ({
    questions: {},
    adults: 1,
    children: 0,
    infants: 0,
    animals: 0,
    answers: {},
    tourCode: "",
    tourOption: "",
    date: "",
    anyAge: false,
    loading: false,
    errorMessage: "",
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.questions = this.$store.state.app.modalProps.questions ? this.$store.state.app.modalProps.questions : {};
      this.adults = this.$store.state.app.modalProps.adults ? this.$store.state.app.modalProps.adults : 1;
      this.children = this.$store.state.app.modalProps.children ? this.$store.state.app.modalProps.children : 0;
      this.infants = this.$store.state.app.modalProps.infants ? this.$store.state.app.modalProps.infants : 0;
      this.animals = this.$store.state.app.modalProps.animals ? this.$store.state.app.modalProps.animals : 0;
      this.answers = this.$store.state.app.modalProps.answers ? this.$store.state.app.modalProps.answers : {};
      this.tourCode = this.$store.state.app.modalProps.tourCode;
      this.tourOption = this.$store.state.app.modalProps.tourOption;
      this.date = this.$store.state.app.modalProps.date;
      this.anyAge = this.$store.state.app.modalProps.anyAge;
      //console.log("INIT", this.$store.state.app.modalProps, this.adultQuestionnaire, this.childQuestionnaire, this.tourQuestions);
    },
    onAddedToCart() {
      return this.$store.state.app.modalProps.onAddedToCart?.();
    },
    async onSave() {
      if (!this.valid) {
        return;
      }

      const data = {
        property_code: PROPERTY_CODE,
        tour_code: this.tourCode,
        tour_option: this.tourOption,
        date: this.date,
        adults: this.adults,
        children: this.children,
        infants: this.infants,
        animals: this.animals,
        answers: this.answers,
      };

      this.loading = true;

      if (!this.cartToken) {
        await this.$store.dispatch('property/fetchCartToken')
      }

      apiAddTourToCart(this.cartToken, data)
        .then(async (resolve) => {
          await this.$store.dispatch("property/fetchTourCart")
          this.loading = false;
          this.onAddedToCart();
          // this.setModal();
          // const me = this;

          // if (this.showReviewPageDialog) {
          //   await this.setConfirmationModal({
          //     content: "Do you want to add more tours?",
          //     header: "",
          //     decline: "No, Review Reservation",
          //     confirm: "Yes",
          //     confirmOnClose: true,
          //   })
          //     .then(function() {
          //       console.log("THEN");
          //     })
          //     .catch(function() {
          //       console.log("CATCH");
          //       me.$router.push({
          //         name: "ReviewPage",
          //       });
          //     });
          // }
        })
        .catch((err) => {
          console.log('error', err)
          this.errorMessage = err.response.data.detail;
          //console.error(err.response.data);
          this.loading = false;
        });
    },
    /*
    * some android browsers do not trigger update input event so need to do this manually to update button state
    */
    keyup(a) {
      this.$set(this.answers, a.target.dataset.key, a.target.value);
      //this.answers[a.target.dataset.key] = a.target.value;
      //this.$forceUpdate();
    },
    isValid() {
      console.log("isValid", this.answers);
      var i, j;
      var flag = true;

      for (i = 1; i <= this.adults; i++) {
        for (j in this.adultQuestionnaire) {
          flag = this.answers[`adult-${i}-${j}:${this.adultQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i = 1; i <= this.children; i++) {
        for (j in this.childQuestionnaire) {
          flag = this.answers[`child-${i}-${j}:${this.childQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i = 1; i <= this.infants; i++) {
        for (j in this.infantQuestionnaire) {
          flag = this.answers[`infant-${i}-${j}:${this.infantQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i = 1; i <= this.animals; i++) {
        for (j in this.animalQuestionnaire) {
          flag = this.answers[`animal-${i}-${j}:${this.animalQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i in this.tourQuestions) {
        flag = this.answers[`t-${i}:${this.tourQuestions[i].title}`] ? flag : false;
      }

      return flag;
    },
  },
  computed: {
    ...mapGetters({
      cartToken: 'property/cartToken'
    }),

    // showReviewPageDialog() {
    //   return this.$store.state.app.modalProps.showReviewPageDialog
    // },

    adultQuestions() {
      const questionsValues = Object.values(this.questions);

      return questionsValues
        .filter((obj) => obj.type === "Adult")
        .map((obj) => ({
          type: obj.type,
          title: obj.title,
          options: obj.options ? obj.options.split("<br />").map((el) => el.trim()) : obj.options,
        }));
    },
    childQuestions() {
      const questionsValues = Object.values(this.questions);

      return questionsValues
        .filter((obj) => obj.type === "Child")
        .map((obj) => ({
          type: obj.type,
          title: obj.title,
          options: obj.options ? obj.options.split("<br />").map((el) => el.trim()) : obj.options,
        }));
    },
    infantQuestions() {
      const questionsValues = Object.values(this.questions);

      return questionsValues
        .filter((obj) => obj.type === "Infant")
        .map((obj) => ({
          type: obj.type,
          title: obj.title,
          options: obj.options ? obj.options.split("<br />").map((el) => el.trim()) : obj.options,
        }));
    },
    animalQuestions() {
      const questionsValues = Object.values(this.questions);

      return questionsValues
        .filter((obj) => obj.type === "Animal")
        .map((obj) => ({
          type: obj.type,
          title: obj.title,
          options: obj.options ? obj.options.split("<br />").map((el) => el.trim()) : obj.options,
        }));
    },
    guestQuestions() {
      const questionsValues = Object.values(this.questions);

      return questionsValues
        .filter((obj) => obj.type === "Guest")
        .map((obj) => ({
          type: obj.type,
          title: obj.title,
          options: obj.options ? obj.options.split("<br />").map((el) => el.trim()) : obj.options,
        }));
    },
    tourQuestions() {
      const questionsValues = Object.values(this.questions);

      return questionsValues
        .filter((obj) => obj.type === "Tour")
        .map((obj) => ({
          type: obj.type,
          title: obj.title,
          options: obj.options ? obj.options.split("<br />").map((el) => el.trim()) : obj.options,
        }));
    },
    adultQuestionnaire() {
      return this.adultQuestions.concat(this.guestQuestions);
    },
    childQuestionnaire() {
      return this.childQuestions.concat(this.guestQuestions);
    },
    infantQuestionnaire() {
      return this.infantQuestions.concat(this.guestQuestions);
    },
    animalQuestionnaire() {
      return this.animalQuestions.concat(this.guestQuestions);
    },
    valid() {
      //console.log("valid", this.answers);
      var i, j;
      var flag = true;

      for (i = 1; i <= this.adults; i++) {
        for (j in this.adultQuestionnaire) {
          flag = this.answers[`adult-${i}-${j}:${this.adultQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i = 1; i <= this.children; i++) {
        for (j in this.childQuestionnaire) {
          flag = this.answers[`child-${i}-${j}:${this.childQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i = 1; i <= this.infants; i++) {
        for (j in this.infantQuestionnaire) {
          flag = this.answers[`infant-${i}-${j}:${this.infantQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i = 1; i <= this.animals; i++) {
        for (j in this.animalQuestionnaire) {
          flag = this.answers[`animal-${i}-${j}:${this.animalQuestionnaire[j].title}`] ? flag : false;
        }
      }

      for (i in this.tourQuestions) {
        flag = this.answers[`t-${i}:${this.tourQuestions[i].title}`] ? flag : false;
      }

      return flag;
    },
  },
};
