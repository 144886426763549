import { mapGetters } from "vuex";
import { promoCode, notCreditCard } from "@shared/utils/validators";
import { PROPERTY_CODE } from "@/constants";
import CodeSelect from '@shared/components/CodeSelect.vue'
import {useVuelidate} from "@vuelidate/core";
import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    v$: useVuelidate(),
    windowSize: useWindowSize(),
  }),

  props: {
    open: Boolean,
    // pass applied promo and type here
    promoCode: String,
    promoType: String,
    promoGroupEnabled: {
      type: Boolean,
      default: true,
    },
    promoCodes: {
      type: Array,
    },
    ratePlan: {
      type: Object,
      default: null
    },
    nights: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: null
    }
  },
  components: {
    CodeSelect
  },
  data: () => ({
    value: "",
    displayValue: "",
    disabled: false,
    selectedCodeType: "",
    promoCodeSelected: false,
    loading: false,
    checkedCode: "",
    valid: false,
  }),
  validations: () => {
    return {
      value: { promoCode, notCreditCard},
    }
  },
  mounted() {
    // this.init();
  },
  computed: {
    ...mapGetters({
      rateplans: "property/hotelsRatePlans"
    }),
    windowWidth() {
      return this.windowSize.width.value;
    },
    promoTypes() {
      let types = [];

      types.push({
        value: "promo",
        label: "Promo Code",
      });

      if (this.promoGroupEnabled && this.mode !== 'tours') {
        types.push({
          value: "group",
          label: "Group Code",
        });
      }

      return types;
    },
    showCodeTypeSelect() {
      return this.promoTypes.length + this.promoCodes.length > 1;
    },
    showPromoSelect() {
      return this.promoCodes.length >= 1;
    },
    showCodeInput() {
      return !this.promoCodeSelected;
    },
    codeType: {
      set(value) {
        this.selectedCodeType = value;
      },
      get() {
        if (this.promoTypes.length + this.promoCodes.length === 1) {
          this.selectedCodeType = this.promoTypes[0].value;
        }

        return this.selectedCodeType;
      },
    },
    maskedValue() {
      if (this.promoCode) {
        return this.promoCode
          .toUpperCase()
          .split("")
          .map((char, idx) => (idx !== 0 && idx !== this.promoCode.length - 1 ? "*" : char))
          .join("");
      } else {
        return "";
      }
    },
    fieldLabel() {
      if (this.promoTypes.length === 1) {
        return "Promo Code";
      } else {
        return "Promo / Group Code";
      }
    },
    allowInput() {
      return !this.promoCodeSelected && this.codeType;
    },
    isPromo() {
      return (this.codeType || '') === "promo";
    },
    isSpecial() {
      return this.codeType && !this.isPromo
    },
    codeEmpty() {
      return this.value === "";
    },
    allowValidateButton() {
      return !this.isSpecial;
    },
    allowValidate() {
      return this.codeNeedsValidation && !this.codeValidated && !this.v$.value.$error;
    },
    allowApply() {
      return !this.codeNeedsValidation || this.codeValid;
    },
    codeNeedsValidation() {
      return this.isPromo && !this.codeEmpty;
    },
    codeValidated() {
      return !!this.checkedCode && this.checkedCode.toUpperCase() === this.value.toUpperCase();
    },
    codeValid() {
      return (!this.codeNeedsValidation || this.codeValidated && this.valid) && !this.v$.value.$error;
    },
  },
  methods: {
    async init() {
      if (typeof this.promoType !== "undefined" && this.promoType !== null) {
        this.codeType = this.promoType;
      }

      if (typeof this.promoCode !== "undefined" && this.promoCode !== null) {
        this.value = this.promoCode;
        this.displayValue = this.promoCode;

        if (this.codeNeedsValidation) {
          const rateCode = this.value.toUpperCase();
          await this.loadRateplans(rateCode);

          if (typeof this.rateplans[rateCode] === "undefined") {
            this.value = "";
            this.displayValue = "";
          }
        }
      }

      // pass data to parent
      this.$emit("getCode", {
        rateCode: this.value.toUpperCase(),
        rateType: this.codeType,
      });
    },
    async checkCodeValid() {
      const rateCode = this.value.toUpperCase();

      if (this.codeNeedsValidation) {
        this.loading = true;

        await this.loadRateplans(rateCode);
        this.checkedCode = rateCode;  
        this.valid = typeof this.rateplans[rateCode] !== "undefined";
        this.loading = false;
  
        if (!this.valid) {
          return;
        }  
      }

      this.applyCode();
      this.$emit("open-select", "");
    },
    onClose() {
      this.$emit("open-select", "");
    },
    onClear() {
      this.value = "";
      this.displayValue = "";
      this.selectedCodeType = "";
      this.promoCodeSelected = false;

      this.$emit("getCode", {
        rateCode: "",
        rateType: this.codeType,
      });
    },
    applyCode() {
      if (!this.allowApply) {
        return;
      }

      const rateCode = this.value.toUpperCase();
      this.displayValue = rateCode;
      this.$emit("getCode", {
        rateCode: rateCode,
        rateType: this.codeType,
      });
    },
    onDeleteCode(allow) {
      if (allow) {
        this.codeType = "";
        this.value = "";
      }
    },
    onPromoCodeSelect(code) {
      this.value = code;
      this.promoCodeSelected = true;
      this.applyCode();
    },
    onPromoTypeSelect() {
      if (this.promoCodeSelected) {
        this.value = "";
      }
      this.promoCodeSelected = false;
      this.applyCode();
    },
    async loadRateplans(rateCode) {
      await this.$store.dispatch("property/fetchHotelsRatePlans", {
        property_code: PROPERTY_CODE,
        rateplan: rateCode,
      });
    },
  }
};
