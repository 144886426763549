import http from "@/lib/axios";
import { PROPERTY_CODE } from "@/constants";

export const apiAvailabilityRooms = (hotelCode, data) =>
  http.get(`availability/rooms/${PROPERTY_CODE}/${hotelCode}`, {
    params: data,
  });
export const apiAvailabilityTours = (data) =>
  http.get(`availability/tours/${PROPERTY_CODE}`, { params: data });
export const apiAvailabilityHotels = (data) =>
  http.get(`availability/hotels/${PROPERTY_CODE}`, { params: data });
export const apiAvailabilityHotelsMonthly = (hotelCode, data) => {
  return http.get(`availability/hotels/${PROPERTY_CODE}/${hotelCode}`, {
    params: data,
    limit: data.limit ?? 31
  });
};
export const apiAvailabilityRoomsMonthly = (hotelCode, data) => {
  data.limit = 31;
  return http.get(`availability/rooms/${PROPERTY_CODE}/${hotelCode}`, {
    params: data,
  });
};
export const apiAvailabilityRateplan = (data) =>
  http.post("availability/rateplan", { ...data });

export const apiAvailabilityGroupPlan = (data) =>
  http.post("availability/groupcode", { ...data });
