<template>
  <vc-date-picker
    :value="value"
    class="border-0 rounded-0 bg-transparent"
    is-expanded
    color="red"
    locale="en-us"
    transition="none"
    :min-date="minDate"
    :max-date="maxDate"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'DateSelect',

  props: {
    value: {
      type: Date,
      default: () => ({})
    },

    minDate: {
      type: Date,
      required: true
    },

    maxDate: {
      type: Date,
      required: true
    }
  }
}
</script>