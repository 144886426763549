<template>
  <b-row
    v-bind="rowProps"
  >
    <b-col
      v-for="option in options"
      :key="option.value"
      v-bind="colProps"
    >
      <b-button
        class="d-block"
        :disabled="option.disabled"
        v-bind="{
          ...buttonProps,
          variant: isActive(option.value) ? variantActive : buttonProps.variant
        }"
        @click="toggleItem(option.value)"
      >
        <slot
          name="prepend"
          :option="option"
        />

        {{ option.text }}

        <slot
          name="append"
          :option="option"
        />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'VBFormInputButtons',

  props: {
    value: {
      type: [Array, Number, String, Boolean],
      default: null
    },

    options: {
      type: Array,
      default: () => ([])
    },

    multiple: {
      type: Boolean,
      default: false
    },

    rowProps: {
      type: Object,

      default: () => ({
        noGutters: true
      })
    },

    colProps: {
      type: Object,
      default: () => ({})
    },

    buttonProps: {
      type: Object,

      default: () => ({
        variant: 'outline-secondary',
        block: true
      })
    },

    variantActive: {
      type: String,
      default: 'secondary'
    }
  },

  methods: {
    toggleItem(id) {
      let newValue

      if (this.multiple) {
        newValue = _.cloneDeep(this.value) || []

        if (newValue.includes(id)) {
          newValue.splice(newValue.indexOf(id), 1)
        } else {
          newValue.push(id)
        }

      } else {
        newValue = id !== this.value ? id : null
      }

      this.$emit('input', newValue)
    },

    isActive(id) {
      if (this.multiple) {
        return this.value && this.value.includes(id)

      } else {
        return this.value === id
      }
    }
  }
}
</script>