<template>
  <b-form
    class="d-flex flex-column"
    @submit.prevent="findReservation()"
  >
    <div class="overflow-auto h-100">
      <div class="mt-3 text-secondary overflow-hidden">
        <b-form-group
          class="px-3"
          label="Arrival date"
          label-class="font-family-1 font-size-9 pb-1"
          :state="getFieldState(v$.input.date)"
          :invalid-feedback="getFieldInvalidFeedback(v$.input.date)"
        >
          <vc-date-picker
            v-slot="{ inputValue, inputEvents }"
            v-model="input.date"
            color="red"
            is-expanded
            :model-config="{ type: 'string', mask: 'MM/DD/YYYY' }"
            locale="en-us"
          >
            <b-form-input
              :value="inputValue ? $dayjs(inputValue).format('MM/DD/YYYY') : null"
              placeholder="Arrival date"
              readonly
              size="lg"
              :state="getFieldState(v$.input.date)"
              v-on="inputEvents"
            />
          </vc-date-picker>
        </b-form-group>

        <b-form-group
          class="px-3"
          label="Reservation number"
          label-class="font-family-1 font-size-9 pb-1"
          :state="getFieldState(v$.input.reservation_number)"
          :invalid-feedback="getFieldInvalidFeedback(v$.input.reservation_number)"
        >
          <b-form-input
            v-model="input.reservation_number"
            class="hide-arrows"
            placeholder="Reservation number"
            type="number"
            size="lg"
            :state="getFieldState(v$.input.reservation_number)"
          />
        </b-form-group>

        <b-form-group
          class="px-3"
          label="First name"
          label-class="font-family-1 font-size-9 pb-1"
          :state="getFieldState(v$.input.first_name)"
          :invalid-feedback="getFieldInvalidFeedback(v$.input.first_name)"
        >
          <b-form-input
            v-model="input.first_name"
            placeholder="First name"
            size="lg"
            :state="getFieldState(v$.input.first_name)"
          />
        </b-form-group>

        <b-form-group
          class="px-3 pb-0 mb-0"
          label="Last name"
          label-class="font-family-1 font-size-9 pb-1"
          :state="getFieldState(v$.input.last_name)"
          :invalid-feedback="getFieldInvalidFeedback(v$.input.last_name)"
        >
          <b-form-input
            v-model="input.last_name"
            placeholder="Last name"
            size="lg"
            :state="getFieldState(v$.input.last_name)"
          />
        </b-form-group>
      </div>
    </div>

    <b-form-group class="m-0 py-4 px-3">
      <b-button
        class="text-uppercase"
        variant="primary"
        block
        size="lg"
        type="submit"
        :disabled="isSubmitting"
      >
        <b-spinner
          v-if="isSubmitting"
          class="mr-1"
          small
        />

        Find Reservation
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import crudItemMixin from '@/mixin/crud-item'
import { apiReservationToken } from '@/api/property'
import { required, minLength, maxLength, minValue } from '@vuelidate/validators'
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'ReservationItemFind',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    crudItemMixin
  ],

  validations: () => {
    return {
      input: {
        date: { required },
        reservation_number: { required, minValue: minValue(1000000) },
        first_name: { required, minLength: minLength(2), maxLength: maxLength(50) },
        last_name: { required, minLength: minLength(2), maxLength: maxLength(50) },
      }
    }
  },

  methods: {
    getInput() {
      return {
        reservation_number: null,
        first_name: null,
        last_name: null,
        date: null
      }
    },

    findReservation() {
      this.isSubmitting = true

      return apiReservationToken(this.input)
        .then((data) => {
          this.isSubmitting = false
          this.$emit('found', data)
        })
        .catch(e => {
          this.isSubmitting = false

          const reservationNumberInvalid = !!e.response.data.validation_messages?.reservation_number
          const firstNameInvalid = !!e.response.data.validation_messages?.first_name
          const lastNameInvalid = !!e.response.data.validation_messages?.last_name
          const dateInvalid = !!e.response.data.validation_messages?.date

          let messages = []
          if (dateInvalid) {
            messages.push('Date is invalid.')
          }
          if (reservationNumberInvalid) {
            messages.push('Reservation number is invalid.')
          }
          if (firstNameInvalid) {
            messages.push('First name is invalid.')
          }
          if (lastNameInvalid) {
            messages.push('Last name is invalid.')
          }

          messages.forEach(i => {
            this.$bvToast.toast(i, {
              title: 'Error',
              variant: 'danger',
              solid: true
            })
          })

          throw Error(e)
        })
    }
  }
}
</script>