import dayjs from '@/lib/dayjs'
import _ from 'lodash';

export const getRoomEventItem = ({
  item,
  hotels,
  isAgency,
  propertyTitle
}) => {
  const quantity = +item.quantity * +item.nights;
  return {
    item_id: item.hotelCode,
    item_name: hotels[item.hotelCode]?.title,
    affiliation: isAgency ? 'TA' : 'Consumer',
    item_brand: propertyTitle,
    item_category: 'Hotel',
    item_category2: dayjs(item.date, 'MM/DD/YYYY').format('YYYYMMDD'),
    item_category3: +item.nights,
    item_category4: +item.quantity,
    item_category5: +item.adults + +item.children,
    item_category6: +item.adults,
    item_category7: +item.children,
    item_variant: item.roomDetails.title,
    coupon: item.rateCode,
    location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
    price: _.round(item.total / quantity, 2),
    quantity,
  }
}

export const getTourEventItem = ({
  item,
  tours,
  isAgency,
  propertyTitle
}) => {
  const quantity = +item.quantity * (+item.adults + +item.children);
  return {
    item_id: item.tourCode,
    item_name: tours[item.tourCode]?.title,
    affiliation: isAgency ? 'TA' : 'Consumer',
    item_brand: propertyTitle,
    item_category: 'Activity',
    item_category2: dayjs(item.date, 'MM/DD/YYYY').format('YYYYMMDD'),
    item_category5: +item.adults + +item.children,
    item_category6: +item.adults,
    item_category7: +item.children,
    item_variant: _.chain(tours[item.tourCode]?.options)
      .find(o => o.pickup[item.optionCode])
      .get('title')
      .value(),
    coupon: item.rateCode,
    location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
    price: _.round(item.total / quantity, 2),
    quantity,
  }
}