import BookStay from "@/pages/BookStay/index.vue";
import BookActivity from "@/pages/BookActivity/index.vue";
import Profile from "@/pages/Profile/index.vue";
import ProfileInfo from "@/pages/Profile/Info/index.vue";
import ProfileSettings from "@/pages/Profile/Settings/index.vue";
import ProfileReservations from "@/pages/Profile/Reservations/index.vue";
import ReservationPage from "@/pages/Profile/ReservationPage/index.vue";
import SingleReservationPage from "@/pages/Profile/ReservationPage/index.vue";
import ReservationReviewPage from "@/pages/Profile/ReservationPage/index.vue";
import HotelList from "@/pages/HotelList/index.vue";
import HotelListFlex from "@/pages/HotelListFlex/index.vue";
import HotelPage from "@/pages/HotelPage/index.vue";
import ActivitiesPage from "@/pages/ActivitiesPage/index.vue";
import TourListFlexible from "@/pages/TourListFlexible/index.vue";
import TourList from "@/pages/TourList/index.vue";
import TourPage from "@/pages/TourPage/index.vue";
import ReviewPage from "@/pages/ReviewPage/index.vue";
//import NotFound from "@/pages/Error404";
//import Test from "@/components/Test";

export default [
  /*{
    path: "/test",
    name: "test",
    component: Test,
  },*/
  {
    path: "/",
    name: "home",
    redirect: { name: "book_stay" },
  },
  {
    path: "/booking/lodging",
    name: "book_stay",
    component: BookStay,
  },
  {
    path: "/booking/activities",
    name: "book_activity",
    component: BookActivity,
    meta: {
      role: "ToursEnabled",
    },
  },
  {
    path: "/profile",
    name: "profile",
    redirect: "/profile/info",
    component: Profile,
    children: [
      {
        path: "info",
        component: ProfileInfo,
        name: "info",
        meta: {
          role: "UserOrAgency",
        },
      },
      {
        path: "reservations",
        name: "ReservationListPage",
        component: ProfileReservations,
        meta: {
          role: "UserOrAgency",
        },
        children: [
          {
            path: ":reservation_id",
            name: "ReservationPage",
            component: ReservationPage,
            props: true,
            meta: {
              role: "UserOrAgency",
            },
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        component: ProfileSettings,
        meta: {
          role: "User",
        },
      },
    ],
  },
  {
    path: "/reservation",
    name: "reservation",
    redirect: "/reservation/manage",
    component: Profile,
    children: [
      {
        path: "manage",
        name: "singleReservationPage",
        component: SingleReservationPage,
        props: true,
        meta: {
          role: "Reservation",
        },
      },
      {
        path: "review",
        name: "ReservationReview",
        component: ReservationReviewPage,
        props: true,
        meta: {
          role: "AfterReservationPlaced",
        },
      },
    ],
  },
  {
    path: "/booking/lodging-confirmation",
    name: "ConfirmationPage",
    component: ReservationReviewPage,
    props: true,
    meta: {
      /*
      role: "AfterReservationPlaced",
      */
      role: 'UserOrAgency',
    },
  },
  {
    path: "/booking/lodging-search",
    name: "hotels",
    component: HotelList,
  },
  {
    /* Old format of Hotel Page URL */
    path: "/booking/lodging-select/:hotel_id",
    redirect: { name: "hotelPage" },
  },
  {
    path: "/booking/lodging-select",
    name: "hotelPage",
    component: HotelPage,
  },
  {
    path: "/booking/activities-select",
    name: "activitiesPage",
    component: ActivitiesPage,
  },
  {
    path: "/booking/lodging-review",
    name: "ReviewPage",
    component: ReviewPage,
  },
  {
    path: "/booking/lodging-flex-search",
    name: "hotelsFlex",
    component: HotelListFlex,
  },
  {
    path: "/booking/activities-search",
    name: "tours",
    component: TourList,
    meta: {
      role: "ToursEnabled",
    },
  },
  {
    path: "/booking/activities-flex-search",
    name: "toursFlexible",
    component: TourListFlexible,
    meta: {
      role: "ToursEnabled",
    },
  },
  {
    path: "/booking/activity-time",
    name: "tourPage",
    component: TourPage,
    meta: {
      role: "ToursEnabled",
    },
  },
  {
    path: "*",
    redirect: { name: "book_stay" },
  },
  {
    path: "/partners",
    name: "Partners",
    component: BookStay,
  },
];
