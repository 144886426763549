<template>
  <b-form @submit.prevent="checkCode">
    <b-form-group
      v-if="codeTypeOptions.length > 1"
      v-slot="{ ariaDescribedby }"
      class="px-4 text-secondary"
    >
      <b-form-radio-group
        v-model="newValue.code_type"
        class="text-uppercase"
        :options="codeTypeOptions"
        :aria-describedby="ariaDescribedby"
        name="radios-stacked"
        stacked
      />
    </b-form-group>

    <b-form-group
      :state="getVuelidateFieldStatus(v$.newValue.code)"
      :invalid-feedback="getVuelidateFieldInvalidFeedback(v$.newValue.code)"
    >
      <b-input-group>
        <b-form-input
          v-model="newValue.code"
          class="font-size-8"
          :placeholder="placeholder"
          size="lg"
        />

        <b-input-group-append
          class="cursor-pointer"
          @click="clearCode('submit')"
        >
          <b-input-group-text>
            <b-icon
              font-scale="0.7"
              icon="trash"
            />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <template v-if="!selectedRatePlan || (newValue.code !== value.code || newValue.code_type !== value.code_type)">
      <div v-if="errorMessage">
        <b-alert
          show
          class="font-size-8 text-left p-2"
          variant="danger"
        >
          {{ errorMessage }}
        </b-alert>
      </div>

      <div class="text-center mt-2">
        <b-button
          class="text-uppercase mx-1 py-1"
          type="submit"
          variant="primary"
          size="lg"
          :disabled="isLoading || v$.$error"
        >
          Apply
        </b-button>

        <b-button
          class="text-uppercase mx-1 py-1"
          variant="secondary"
          size="lg"
          @click="$emit('cancel')"
        >
          Close
        </b-button>
      </div>
    </template>
 
    <div v-else-if="selectedRatePlan">
      <b-alert
        show
        class="font-size-8 text-left p-2"
        variant="success"
      >
        <template v-if="selectedRatePlan.category === 'GROUP'">
          Special group rate for <span class="font-weight-bold">{{ selectedRatePlan.code }}</span> are available for stays {{ $dayjs(selectedRatePlan.start, 'MM/DD/YYYY').format('MMM D') }} - {{ $dayjs(selectedRatePlan.end, 'MM/DD/YYYY').format('MMM D, YYYY') }}. When you check availability, the dates will be presented to reflect this window.
        </template>

        <template v-else>
          Promo code <span class="font-weight-bold">{{ selectedRatePlan.code }}</span> is applied.
        </template>
      </b-alert>

      <b-row
        v-if="selectedRatePlan.category === 'GROUP'"
        class="mb-4"
        no-gutters
      >
        <b-col
          cols="5"
        >
          <NightsSelectDropdown
            class="h-50px mr-2"
            v-model="newValue.nights"
            :property="property"
            :max="maxNights"
            :parent-id="parentDropdownId"
          />
        </b-col>

        <b-col
          cols="7"
        >
          <b-button
            class="border-0 text-uppercase font-size-8 text-white text-truncate max-w-100 h-50px"
            :disabled="!newValue.nights"
            variant="primary"
            block
            size="lg"
            @click="$emit('group-search', newValue)"
          >
            Check Availability
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-form>
</template>

<script>
import formMixin from '@shared/mixins/form'
import { promoCode, notCreditCard } from "@shared/utils/validators"
import axios from '../lib/axios'
import { getRatePlanFromResponse } from '@shared/utils/rateplan'
import NightsSelectDropdown from './NightsSelectDropdown.vue'
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'CodeSelect',
  setup: () => ({v$: useVuelidate()}),
  mixins: [
    formMixin
  ],

  components: {
    NightsSelectDropdown
  },

  props: {
    property: {
      type: Object,
      required: true
    },

    ratePlan: {
      type: Object,
      default: null
    },

    mode: {
      type: String,
      default: null
    },
    
    parentDropdownId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isLoading: false,
      errorMessage: null,
      fetchedRatePlan: null
    }
  },

  computed: {
    codeTypeOptions() {
      const codeTypeOptions = [
        { value: 'promo', text: 'Promo Code' }
      ]

      if (this.property.resformPromoGroup && this.mode === 'lodges') {
        codeTypeOptions.push({ value: 'group', text: 'Group Code' })
      }

      return codeTypeOptions
    },

    placeholder() {
      const codeType = this.codeTypeOptions.find(i => i.value === this.newValue.code_type)
      return codeType ? `Enter ${codeType.text}` : 'Promo Code or Group Code'
    },

    selectedRatePlan() {
      if (
        this.fetchedRatePlan?.code === this.value.code &&
        (this.fetchedRatePlan?.category === 'GROUP' ? 'group' : 'promo') === this.value.code_type
      ) {
        return this.fetchedRatePlan
      }

      if (
        this.ratePlan?.code ===this.value.code &&
        (this.ratePlan?.category === 'GROUP' ? 'group' : 'promo') === this.value.code_type
      ) {
        return this.ratePlan
      }

      return null
    },

    newSelectedRatePlan() {
      if (this.fetchedRatePlan?.code === this.newValue.code) {
        return this.fetchedRatePlan
      }

      if (this.ratePlan?.code ===this.newValue.code ) {
        return this.ratePlan
      }

      return null
    },

    maxNights() {
      if (this.selectedRatePlan?.start && this.selectedRatePlan?.end) {
        return this.$dayjs(this.selectedRatePlan.end).diff(this.selectedRatePlan.start, 'day')
      }

      return 14
    }
  },

  validations: () => {
    return {
      newValue: {
        code: {
          promoCode,
          notCreditCard
        },
      },
    }
  },

  watch: {
    'newValue.code_type': {
      immediate: true,
      handler() {
        // Set default to promocode
        if (!this.newValue.code_type) {
          this.newValue.code_type = 'promo'
        }

        // check if code type allowed
        if (!this.codeTypeOptions.find(i => this.newValue.code_type === i.value)) {
          this.newValue.code_type = 'promo'
        }
      }
    }
  },

  methods: {
    async checkCode() {
      if (!this.newValue.code) {
        this.clearCode('submit')
        return
      }

      // Upper case code
      this.newValue.code = this.newValue.code.toUpperCase()

      this.v$.$touch()

      if (this.v$.$error) {
        return
      }

      if (this.newValue.code_type === 'promo') {
        await this.fetchRatePlan(this.newValue.code)

      } else if (this.newValue.code_type === 'group') {
        await this.fetchGroupCode(this.newValue.code)
      }

      if (this.newSelectedRatePlan) {
        this.$emit('update:rate-plan', this.newSelectedRatePlan)
        this.submit('input')
      }
    },

    clearCode(eventType='input') {
      this.newValue.code = null
      this.newValue.code_type = null
      this.errorMessage = null
      this.submit(eventType)
    },

    async fetchRatePlan(ratePlan) {
      const input = {
        property_code: this.property.code,
        rateplan: ratePlan
      }

      this.isLoading = true

      return axios.post('availability/rateplan', input)
        .then(({ data }) => {
          this.errorMessage = null
          this.fetchedRatePlan = getRatePlanFromResponse(data.details, 'promo')
        })
        .catch((error) => {
          this.errorMessage = `${error.response.data.detail}`
          throw error;
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async fetchGroupCode(groupCode) {
      const input = {
        property_code: this.property.code,
        groupcode: groupCode
      }

      this.isLoading = true

      return axios.post('availability/groupcode', input)
        .then(({ data }) => {
          this.errorMessage = null
          this.fetchedRatePlan = getRatePlanFromResponse(data.details, 'group')
        })
        .catch((error) => {
          this.errorMessage = `${error.response.data.detail}`
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>