import { mapGetters } from "vuex";
import { gtmTrack } from "@/lib/gtm";
import { optionsComputed } from "@/store/helper";
import { PROPERTY_CODE } from "@/constants";
import bookingMixin from '@/mixin/booking'
import _ from 'lodash'
import { getPrices } from '@/utils/taxes-and-fees'

export default {
  mixins: [
    bookingMixin
  ],

  data: () => ({
    today: new Date().setHours(0, 0, 0, 0),
    hotelsItems: [],
    priceRange: [0, 0],
    filters: [],
    order: "asc",
    mapView: false,
    loading: false,
    urlQueryParams: {},
    destinationConstants: ["ALL", "ALL-HOTELS", "ALL-CAMPGROUNDS"],
    min: 0,
    max: 0,
  }),
  async created() {
    await this.init();
  },
  methods: {
    validateUrl() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      // check dateFrom
      if (!!this.$route.query.dateFrom) {
        let dateFrom = this.$dayjs(this.$route.query.dateFrom, "MM-DD-YYYY", true);
        if (!dateFrom.isValid()) {
          // invalid date format
          return false;
        }

        dateFrom = dateFrom.toDate();
        if (today > dateFrom) {
          // past date
          return false;
        }

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          if (minDate > dateFrom) {
            // date is greater than mindate
            return false;
          }
        }

        let nights = 1;
        if (!!this.$route.query.nights && Number.isInteger(+this.$route.query.nights)) {
          nights = parseInt(this.$route.query.nights);
        }

        let maxDate = this.$dayjs(this.options.maxdate, "MM/DD/YYYY");
        if (maxDate.isValid()) {
          maxDate = maxDate.toDate();
          if (dateFrom > maxDate) {
            // date is greater than maxdate
            return false;
          }
        }
      }

      // validate adults. integer, >=1
      if (!!this.$route.query.adults) {
        if (!Number.isInteger(+this.$route.query.adults)) {
          return false;
        }

        if (parseInt(this.$route.query.adults) < 1) {
          return false;
        }
      }

      // validate children. integer, >=0
      if (!!this.$route.query.children) {
        if (!Number.isInteger(+this.$route.query.children)) {
          return false;
        }

        if (parseInt(this.$route.query.children) < 0) {
          return false;
        }
      }

      const guests = parseInt(this.$route.query.adults) + parseInt(this.$route.query.children);
      if (guests > this.maxGuests) {
        // guests exceeded
        return false;
      }

      // validate nights. integer, >=1 && <=14
      if (!!this.$route.query.nights) {
        if (!Number.isInteger(+this.$route.query.nights)) {
          return false;
        }

        const nights = parseInt(this.$route.query.nights);
        if (nights < 1 || nights > 14) {
          return false;
        }
      }

      return true;
    },
    /*
     * Destination is valid if not present or exists
     */
    validateDestination() {
      let hotelCodes = Object.keys(this.$store.state.property.hotels);
      return (
        !this.$route.query.destination ||
        !!hotelCodes.concat(this.destinationConstants).find((item) => item === this.$route.query.destination)
      );
    },
    validateHotel() {
      let hotelCodes = Object.keys(this.$store.state.property.hotels);
      return hotelCodes.find((item) => item === this.$route.query.destination);
    },
    urlNeedsNormalization() {
      return (
        typeof this.$route.query.dateFrom === "undefined" ||
        typeof this.$route.query.adults === "undefined" ||
        typeof this.$route.query.children === "undefined" ||
        typeof this.$route.query.nights === "undefined" ||
        typeof this.$route.query.destination === "undefined"
      );
    },
    /*
     * Set params defaults and redirect to normalized URL
     */
    async normalizeUrl() {
      this.urlQueryParams["adults"] = !!this.$route.query.adults ? this.$route.query.adults : 1;
      this.urlQueryParams["children"] = !!this.$route.query.children ? this.$route.query.children : 0;
      this.urlQueryParams["destination"] = !!this.$route.query.destination ? this.$route.query.destination : "ALL";

      if (!this.$route.query.dateFrom) {
        this.urlQueryParams["dateFrom"] = this.$dayjs(this.allowedRange.min).format('MM-DD-YYYY');
        this.urlQueryParams["nights"] = this.$route.query.nights;
        this.urlQueryParams["rateCode"] = this.searchParams.rateCode;
      } else {
        this.urlQueryParams["dateFrom"] = this.$route.query.dateFrom;
        this.urlQueryParams["nights"] = this.$route.query.nights;
        if (!!this.$route.query.rateCode) {
          this.urlQueryParams["rateCode"] = this.$route.query.rateCode;
        }
      }

      if (!this.$route.query.nights) {
        this.urlQueryParams["nights"] = this.ratePlan?.minstay || 1;
      }

      return this.$router.push({
        name: "hotels",
        query: this.urlQueryParams,
      });
    },
    async loadRateplans() {
      await this.$store.dispatch("property/fetchHotelsRatePlans", {
        property_code: PROPERTY_CODE,
        rateplan: this.rateCodeCorrectValue,
      });
    },
    ratePlansValid() {
      return typeof this.ratePlans[this.rateCodeCorrectValue] !== "undefined";
    },
    async loadCart() {
      await this.$store.dispatch("property/fetchCart");
    },
    async loadAvailability() {
      await this.$store
        .dispatch("property/fetchAvailableHotels", {
          date: this.$dayjs(this.searchParams.dateFrom, "MM-DD-YYYY").format("MM/DD/YYYY"),
          limit: 1,
          rate_code: this.searchParams.rateCode,
          is_group: this.searchParams.rateType === 'group',
          nights: this.searchParams.nights,
        })
        .catch((error) => {
          console.log(error);
        });

      // const avail = this.$store.state.property.availableHotels;
      // this.hotelsAvailability = avail[Object.keys(avail)[0]];

      // for (var code in this.hotelsAvailability) {
      //   let hotel = this.hotelsAvailability[code];

      //   // apply guests to min price
      //   // use min_by_guest for ordering and displaying
      //   // use availability_by_guest for availability
      //   if (
      //     typeof hotel["perGuests"][this.guests] !== "undefined" &&
      //     hotel["perGuests"][this.guests]["r"] === this.rateCode &&
      //     hotel["perGuests"][this.guests]["a"] > 0
      //   ) {
      //     // rooms are available for requested guests and rate
      //     hotel["min_composed"] = hotel["perGuests"][this.guests]["m"];
      //     hotel["available_amount_composed"] = hotel["perGuests"][this.guests]["a"];
      //     hotel["rate_composed"] = hotel["perGuests"][this.guests]["r"];
      //     hotel["available_composed"] = true;
      //     hotel["occupancy_exceed"] = false;
      //   } else if (typeof hotel["perGuests"][this.guests] !== "undefined" && hotel["perGuests"][this.guests]["a"] > 0) {
      //     // rooms are available for requested guests, but another rate
      //     hotel["min_composed"] = 0;
      //     hotel["available_amount_composed"] = 0;
      //     hotel["rate_composed"] = 0;
      //     hotel["available_composed"] = false;
      //     hotel["occupancy_exceed"] = false;
      //   } else if (hotel["perGuests"][1]["a"] > 0) {
      //     // rooms are present, but not not available for requested guests
      //     hotel["min_composed"] = parseInt(hotel["perGuests"][1]["m"]);
      //     hotel["available_amount_composed"] = hotel["perGuests"][1]["a"];
      //     hotel["rate_composed"] = hotel["perGuests"][1]["r"];
      //     hotel["available_composed"] = false;
      //     hotel["occupancy_exceed"] = true;
      //   } else {
      //     // no rooms are available
      //     hotel["min_composed"] = 0;
      //     hotel["available_amount_composed"] = 0;
      //     hotel["rate_composed"] = hotel["perGuests"][1]["r"];
      //     hotel["available_composed"] = false;
      //     hotel["occupancy_exceed"] = false;
      //   }

      //   if (this.priceRange[0] === 0 && hotel["min_composed"] > 0) {
      //     this.priceRange[0] = hotel["min_composed"];
      //     this.priceRange[1] = hotel["min_composed"];
      //   } else {
      //     if (hotel["min_composed"] > this.priceRange[1]) {
      //       this.priceRange[1] = hotel["min_composed"];
      //     }
      //     if (hotel["min_composed"] > 0 && hotel["min_composed"] < this.priceRange[0]) {
      //       this.priceRange[0] = hotel["min_composed"];
      //     }
      //   }
      // }
    },
    // async loadHotels() {
    //   const res = await apiHotelList();
    //   this.hotels = Object.values(res);
    // },
    async init() {
      this.loading = true;
      // VALIDATE SEARCH PARAMS
      if (!this.searchParamsIsValid) {
        this.$router.push({
          name: "book_stay",
          params: { skipCartCheck: true }
        })
        return
      }

      // VALIDATE DESTINATION
      await this.$store.dispatch('property/fetchHotelsList')
      if (this.hotels[this.searchParams.destinations]) {
        this.$router.push({
          name: 'hotelPage',
          query: {
            ...this.searchParams
          }
        })
        return
      }

      // VALIDATE RATE
      if (this.searchParams.rateCode) {
        // Fetch rate plan
        try {
          await this.$store.dispatch('property/fetchHotelsRatePlan', {
            rateCode: this.searchParams.rateCode,
            rateType: this.searchParams.rateType
          })
        } catch (e) {
          console.log(`Rate code (${this.searchParams.rateType}) is invalid ${e}`)
        }

        // If not rate plan
        if (!this.ratePlan) {
          this.$bvModal.msgBoxConfirm('Sorry, but the rate code you used is not a valid discount code.', {
            title: 'Error',
            headerBgVariant: 'secondary',
            headerClass: 'close-button-black',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma ok-only',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Ok',
            okVariant: 'primary',
            hideHeaderClose: false,
            cancelVariant: 'outline-primary',
            buttonSize: 'lg',
            centered: true
          })
            .then(() => {
              this.$router.push({
                name: 'hotels',
                query: {
                  ...this.$route.query,
                  rateCode: undefined,
                  rateType: undefined
                }
              })
            })
          return
        }

        // Redirect to flex mode for group code
        if (this.ratePlan.category === 'GROUP') {
          this.$router.push({
            name: "hotelsFlex",
            query: {
              ...this.$route.query,
              dateFrom: this.$dayjs(this.allowedRange.start).format('MM-DD-YYYY'),
            }
          });
          return;
        }
      }

      /*
      if (!this.validateUrl()) {
        return this.$router.push({ name: "book_stay", params: { skipCartCheck: true }});
      } else if (!this.validateDestination()) {
        let query = {};
        for (const queryKey in this.$route.query) {
          query[queryKey] = this.$route.query[queryKey];
        }
        query["destination"] = "ALL";

        return this.$router.push({
          name: "hotels",
          query: query,
        });
      } else if (this.validateHotel()) {
        return this.$router.push({
          name: "hotelPage",
          query: this.$route.query,
        });
      }
      */

      /*
      // load rates. in rate code invalid - go home
      await this.loadRateplans();

      if (!this.ratePlansValid()) {
        const that = this;

        // redirect to INTERNET rate
        this.setConfirmationModal({
          content: "Sorry, but the promo code you used is not a valid discount code.",
          header: "Error",
          confirm: "Ok",
          disableDecline: true,
          confirmOnClose: true
        })
        .then(function() {
          that.$route.query.rateCode = "";
          return that.normalizeUrl();
        })
      }
      */
      /*
      if (this.urlNeedsNormalization()) {
        return this.normalizeUrl();
      }

      localStorage.removeItem("step2");
      localStorage.setItem("step2", this.$route.fullPath);
      localStorage.removeItem("step2_type");
      localStorage.setItem("step2_type", "exact");
      */

      if (this.toursInlineEnabled) {
        let step4_fullPath = "/booking/activities-select" +
            `?dateFrom=${this.$route.query.dateFrom}&nights=${
                this.$route.query.nights
            }&destination=${
                this.$route.query.destination
            }&adults=${
                this.$route.query.adults
            }&children=${
                this.$route.query.children
            }${!!this.$route.query.rateCode
                ? '&rateCode=' + this.$route.query.rateCode
                : ""}`;
        localStorage.removeItem("step4");
        localStorage.setItem("step4", step4_fullPath);
      }
      await this.loadCart();
      await this.loadAvailability();
      // await this.loadHotels();

      this.$store.dispatch("app/setHotelMinPrice", Math.ceil(this.priceRange[0]));
      this.$store.dispatch("app/setHotelMaxPrice", Math.ceil(this.priceRange[1]));
      /*
      if (!Object.keys(this.$store.state.property.hotelsRatePlans).length) {
        await this.$store
          .dispatch("property/fetchHotelsRatePlans", {
            property_code: PROPERTY_CODE,
            rateplan: this.$route.query.rateCode || "INTERNET",
          })
          .catch((error) => {
            console.error(error.response.data.detail);
          });
      }
      */

      this.$eventHub.$on("hotel-map-toggle", this.onHotelMapToggle);
      this.$eventHub.$emit("toggle-hotel-stay-type-filter", this.$route.query.destination);

      this.trackData();
      this.loading = false;
    },
    trackData() {
      // track search
      const data = {
        ecommerce: {
          currencyCode: "USD",
          detail: [
            {
              actionField: { list: "Search" },
            },
            {
              products: [
                {
                  name: this.destination,
                },
              ],
            },
          ],
        },
        page: {
          type: "category",
          environment: "production",
        },
      };

      gtmTrack(data);
    },
    onHotelMapToggle(status) {
      this.mapView = status;

      if (this.mapView) {
        this.$eventHub.$emit("hotel-map-show");
      } else {
        this.$eventHub.$emit("hotel-map-hide");
      }
    },
    onSkipLodging() {
      return this.$router.push({
        name: "activitiesPage",
        query: this.$route.query
      })
    },
    getAvailabilityStatus(hotelAvailability) {
      const guests = this.guests;
      const availabilityPerGuests = hotelAvailability?.perGuests[guests];
      const availabilityPerOneAdult = hotelAvailability?.perGuests[1];
      if (hotelAvailability?.status === 'CLOSED') {
        return 'closed';
      } else if (availabilityPerGuests?.a) {
        return 'available';
      } else if (!availabilityPerGuests?.a && availabilityPerOneAdult?.a) {
        return 'occupancyExceeded';
      } else if (hotelAvailability) {
        return 'soldOut';
      }
    },
    getHotelPrices(hotelAvailability) {
      const nights = this.searchParams.nights;
      const guests = this.guests;
      const availabilityPerGuests = hotelAvailability?.perGuests[guests];
      const base = availabilityPerGuests?.m.p ?? 0;
      const fees = availabilityPerGuests?.m.f ?? 0;
      const taxes = availabilityPerGuests?.m.t ?? 0;

      return getPrices({
        base,
        fees,
        taxes,
        nights,
      })
    },
    getHotelOldPrices(hotelAvailability) {
      const nights = this.searchParams.nights;
      const guests = this.guests;
      const availabilityPerGuests = hotelAvailability?.perGuests[guests];
      const base = availabilityPerGuests?.b.p ?? 0;
      const fees = availabilityPerGuests?.b.f ?? 0;
      const taxes = availabilityPerGuests?.b.t ?? 0;

      return getPrices({
        base,
        fees,
        taxes,
        nights,
      })
    },
    getHotelPricesPerOneAdult(hotelAvailability) {
      const nights = this.searchParams.nights;
      const availabilityPerGuests = hotelAvailability?.perGuests[1];
      const base = availabilityPerGuests?.m.p ?? 0;
      const fees = availabilityPerGuests?.m.f ?? 0;
      const taxes = availabilityPerGuests?.m.t ?? 0;

      return getPrices({
        base,
        fees,
        taxes,
        nights,
      })
    },
  },
  computed: {
    ...optionsComputed,
    ...mapGetters({
      hotels: 'property/hotels',
      ratePlans: "property/hotelsRatePlans",
      addToReservation: 'app/addToReservation',
      isModifyReservationMode: 'app/isModifyReservationMode',
      hotelsAvailability: 'property/hotelsAvailability',
    }),
    searchParams() {
      return {
        destination: this.$route.query.destination,
        dateFrom: this.$route.query.dateFrom,
        nights: +this.$route.query.nights || 0,
        adults: +this.$route.query.adults || 0,
        children: +this.$route.query.children || 0,
        rateCode: this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : null,
        rateType: this.$route.query.rateType
      };
    },

    allowedRange() {
      let start = this.bookingDatesWindow.min
      let end = this.bookingDatesWindow.max

      if (this.ratePlan?.category === 'GROUP') {
        if (this.ratePlan.start) {
          start = this.$dayjs.max([
            start,
            this.$dayjs(this.ratePlan.start)
          ]).format('MM/DD/YYYY')
        }

        if (this.ratePlan.end) {
          end = this.$dayjs.min([
            end,
            this.$dayjs(this.ratePlan.end).subtract(1, 'day')
          ]).format('MM/DD/YYYY')
        }
      }

      return { start, end }
    },

    searchParamsIsValid() {
      // numeric params
      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.nights ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // date
      const dateFrom = this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY');
      if (
        !dateFrom.isValid() ||
        this.searchParams.dateFrom !== dateFrom.format('MM-DD-YYYY')
      ) {
        console.log(`Param dateFrom (${this.searchParams.dateFrom}) invalid`);
        return false;
      }

      if (!this.$dayjs(dateFrom).isBetween(this.allowedRange.start, this.allowedRange.end, 'day', '[]')) {
        console.log(`Param dateFrom (${this.searchParams.dateFrom}) is not within allowedRange`)
        return false
      }

      // nights
      if (this.searchParams.nights < 1 || this.searchParams.nights > 14) {
        console.log(`Param nights (${this.searchParams.nights}) invalid`)
        return false
      }

      // guests
      if (this.searchParams.adults < 1) {
        console.log(`Param adults (${this.searchParams.adults}) invalid`)
        return false
      }

      if (this.searchParams.children < 0) {
        console.log(`Param children (${this.searchParams.children}) invalid`)
        return false
      }

      if (this.searchParams.adults + this.searchParams.children > parseInt(this.options.resformMaxOccupancy)) {
        console.log(`Params adults + children (${this.searchParams.adults} + ${this.searchParams.children}) invalid`)
        return false
      }

      // destination
      if (!this.validateDestination()) {
        console.log('Destination is not valid')
        return false;
      }

      return true
    },
    destination() {
      return this.$route.query.destination;
    },

    dayHotelsAvailability() {
      const dateFromKey = this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').format('MM/DD/YYYY');
      return this.hotelsAvailability?.[dateFromKey];
    },

    // hotelsFiltered() {
    //   if (_.isEmpty(this.hotelsAvailability)) {
    //     return []
    //   }

    //   const filters = this.filters;

    //   let arr = []
    //   let helpedArr = []

    //   this.hotelsItems.forEach((hotel)=> {
    //     if (!!!this.hotelsAvailability[hotel.code].available_amount_composed) {
    //       arr.push(hotel)
    //       helpedArr.push(hotel)
    //     }
    //   })

    //   let hotels = this.hotelsItems.filter((hotel) => {
    //     let result = true;
    //     filters.forEach((filter) => {
    //       switch (filter.field) {
    //         case "exclude_type_campground":
    //           if (hotel.type === "campground") {
    //             arr.forEach((i, idx) => {
    //               if (i.type === hotel.type) {
    //                 arr.splice(idx, 1)
    //               }
    //             })
    //             result = false;
    //           }
    //           break;
    //         case "exclude_type_hotel":
    //           if (hotel.type === "hotel") {
    //             arr.forEach((i, idx) => {
    //               if (i.type === hotel.type) {
    //                 arr.splice(idx, 1)
    //               }
    //             })
    //             result = false;
    //           }
    //           break;
    //         case "price_range":
    //           const price = this.hotelsAvailability[hotel.code].min_composed;
    //           let filteredHelpedArr = helpedArr.map(item => item.code)
    //           if (price >= filter.value[0] && price <= filter.value[1] && this.hotelsAvailability[hotel.code].min_composed) {
    //             // result = false;
    //             arr.push(hotel)
    //           }
    //           else {
    //             if (this.max !== filter.value[1] && this.min !== filter.value[0] && !this.hotelsAvailability[hotel.code].min_composed) {
    //                 arr = arr.filter(item => !filteredHelpedArr.includes(item.code))
    //             }
    //             if (this.min !== filter.value[0]) {
    //               if (!!arr.find(i => this.hotelsAvailability[i.code].min_composed) && !this.hotelsAvailability[hotel.code].min_composed) {
    //                 arr = arr.filter(item => !filteredHelpedArr.includes(item.code))
    //               }
    //             }
    //             if (this.max !== filter.value[1] || (this.max !== filter.value[1] && this.min !== filter.value[0])) {
    //               if (!this.hotelsAvailability[hotel.code].min_composed && !!arr.find(i => this.hotelsAvailability[i.code].min_composed)) {
    //                 arr = arr.filter(item => !filteredHelpedArr.includes(item.code))
    //               }
    //             } 
    //           }
    //           break;
    //       }
    //     });
    //     return result;
    //   });

    //   this.min = 0
    //   this.max = 0
    //   hotels.forEach((hotel)=> {
    //     if (this.hotelsAvailability[hotel.code].available_amount_composed > 0) {
    //       let price = this.hotelsAvailability[hotel.code].min_composed
    //       if (this.min === 0 || price < this.min && price > 0) {
    //       this.min = price
    //       }
    //       if (price > this.max) {
    //         this.max = price
    //       }
    //     }
    //   })

    //   this.$store.dispatch("app/setHotelMinPrice", this.min);
    //   this.$store.dispatch("app/setHotelMaxPrice", this.max);

    //   // sort
    //   hotels = _.orderBy(arr, [
    //     (i) => !!this.hotelsAvailability[i.code]?.perGuests?.[this.guests]?.a ?? false,  // is available
    //     (i) => !!this.hotelsAvailability[i.code]?.perGuests?.[1]?.m,  // has price per a guest
    //     (i) => this.hotelsAvailability[i.code]?.perGuests?.[this.guests]?.m,  // price
    //   ], ['desc', 'desc', this.order]);

    //   // hotels = arr.sort((a, b) => {
    //   //   const rateA = this.hotelsAvailability[a.code].rates[Object.keys(this.hotelsAvailability[a.code].rates)[0]]
    //   //   const rateB = this.hotelsAvailability[b.code].rates[Object.keys(this.hotelsAvailability[b.code].rates)[0]]
    //   //   const priceA = this.hotelsAvailability[a.code].min_composed;
    //   //   const priceB = this.hotelsAvailability[b.code].min_composed;
    //   //   const availableA = this.hotelsAvailability[a.code].available_composed;
    //   //   const availableB = this.hotelsAvailability[b.code].available_composed;
    //   //   const occupancyExceedA = this.hotelsAvailability[a.code].occupancy_exceed;
    //   //   const occupancyExceedB = this.hotelsAvailability[b.code].occupancy_exceed;

    //   //   let orderFactorA;
    //   //   let orderFactorB;
    //   //   if (this.order === "asc") {
    //   //     orderFactorA = 0;
    //   //     orderFactorB = 0;

    //   //     if (availableA) {
    //   //       orderFactorA += priceA;
    //   //     } else if (occupancyExceedA) {
    //   //       orderFactorA += 10000;
    //   //       orderFactorA += priceA;
    //   //     } else if (rateA && rateA.min) {
    //   //       orderFactorA += 10000;
    //   //       orderFactorA += priceA;
    //   //     } else {
    //   //       orderFactorA += 100000;
    //   //     }

    //   //     if (availableB) {
    //   //       orderFactorB += priceB;
    //   //     } else if (occupancyExceedB) {
    //   //       orderFactorB += 10000;
    //   //       orderFactorB += priceB;
    //   //     } else if (rateB && rateB.min) {
    //   //       orderFactorB += 10000;
    //   //       orderFactorB += priceB;
    //   //     } else {
    //   //       orderFactorB += 100000;
    //   //     }
    //   //   } else {
    //   //     orderFactorA = 100000;
    //   //     orderFactorB = 100000;

    //   //     if (availableA) {
    //   //       orderFactorA -= 10000;
    //   //       orderFactorA -= priceA;
    //   //     } else if (occupancyExceedA) {
    //   //       orderFactorA -= priceA;
    //   //     } else if (rateA && rateA.min) {
    //   //       orderFactorA -= 10000;
    //   //       orderFactorA -= priceA;
    //   //     }

    //   //     if (availableB) {
    //   //       orderFactorB -= 10000;
    //   //       orderFactorB -= priceB;
    //   //     } else if (occupancyExceedB) {
    //   //       orderFactorB -= priceB;
    //   //     } else if (rateB && rateB.min) {
    //   //       orderFactorB -= 10000;
    //   //       orderFactorB -= priceB;
    //   //     }
    //   //   }

    //   //   return orderFactorA - orderFactorB;
    //   // });

    //   return hotels;
    // },

    hotelsWithAvailability() {
      return _.map(this.hotels, (hotel) => {
        const availability = this.dayHotelsAvailability?.[hotel.code];
        const prices = this.getHotelPrices(availability);
        const pricesPerOneAdult = this.getHotelPricesPerOneAdult(availability);
        const oldPrices = this.getHotelOldPrices(availability);
        const availabilityStatus = this.getAvailabilityStatus(availability);
        const available = availability?.perGuests[this.guests]?.a ?? 0;
        return {
          hotel,
          availability,
          available,
          availabilityStatus,
          prices,
          pricesPerOneAdult,
          oldPrices
        }
      }).filter((i) => i.availability);
    },

    hotelsPriceRange() {
      const hotelsWithPrices = this.hotelsWithAvailability.filter((i) => i.prices?.avgPrice?.subTotal);
      const cheapestHotel = _.minBy(hotelsWithPrices, (i) => i.prices.avgPrice.subTotal);
      const mostExpensiveHotel = _.maxBy(hotelsWithPrices, (i) => i.prices.avgPrice.subTotal);
      const minPrice = cheapestHotel?.prices.avgPrice.subTotal || cheapestHotel?.pricesPerOneAdult.avgPrice.subTotal || 0;
      const maxPrice = mostExpensiveHotel?.prices.avgPrice.subTotal || mostExpensiveHotel?.pricesPerOneAdult.avgPrice.subTotal || 0;
      return [
        _.floor(minPrice),
        _.ceil(maxPrice),
      ];
    },

    filteredHotelsWithAvailability() {
      const includeHotels = !this.filters.find(i => i.field === 'exclude_type_hotel')?.value;
      const includeCampgrounds = !this.filters.find(i => i.field === 'exclude_type_campground')?.value;
      const [minPrice, maxPrice] = this.filters.find(i => i.field === 'price_range')?.value || [0, 0];

      return this.hotelsWithAvailability.filter((item) => {
        if (item.hotel.type === 'hotel' && !includeHotels) {
          return false;
        } else if (item.hotel.type === 'campground' && !includeCampgrounds) {
          return false;
        }

        const price = item.prices.avgPrice.subTotal || item.pricesPerOneAdult.avgPrice.subTotal || 0;

        if (minPrice && minPrice !== this.hotelsPriceRange[0] && price < minPrice) {
          return false;
        }

        if (maxPrice && maxPrice !== this.hotelsPriceRange[1] && price > maxPrice) {
          return false;
        }
  
        return true;
      });
    },

    sortedHotelsWithAvailability() {
      return _.orderBy(this.filteredHotelsWithAvailability, [
        (item) => item.prices.avgPrice.subTotal ? 1 : 0,  // is available
        (item) => item.pricesPerOneAdult.avgPrice.subTotal ? 1 : 0,  // has price per one guest
        (item) => item.prices.avgPrice.subTotal,  // price
      ], ['desc', 'desc', this.order]);
    },

    maxGuests() {
      return parseInt(this.$store.state.app.options.resformMaxOccupancy);
    },
    noRateMessage() {
      const rateCode = this.searchParams.rateCode ?? 'INTERNET';
      return (
        // current rate is not available
        !this.filteredHotelsWithAvailability.find((item) => (
          item.availability.rates[rateCode]?.available[this.guests] > 0
        )) &&
        // another rate is available
        !!this.filteredHotelsWithAvailability.find((item) => (
          item.availability.perGuests[this.guests]?.a > 0
        )) &&
        `We're sorry. The rate "${rateCode}" is not available on your selected dates. However, other rates are available at the hotels listed below. Click "View Calendar" to see other rates and availability at each hotel.`
      )
      // const hotels = this.$store.state.property.hotels;
      // let filteredHotels;
      // let noRatesCounter = 0,
      //   hotelsAmount = 0;

      // let rateCode = !!this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : "INTERNET";

      // if (this.$route.query.destination === "ALL-CAMPGROUNDS") {
      //   filteredHotels = Object.values(this.hotelsAvailability).filter((hotel) => hotels[hotel.hotelCode].type === "campground");
      // } else if (this.$route.query.destination === "ALL-HOTELS") {
      //   filteredHotels = Object.values(this.hotelsAvailability).filter((hotel) => hotels[hotel.hotelCode].type === "hotel");
      // } else filteredHotels = Object.values(this.hotelsAvailability);

      // hotelsAmount = filteredHotels.length;

      // filteredHotels = filteredHotels.filter((hotel) => {
      //   if (!Object.keys(hotel.rates).length) {
      //     noRatesCounter++;
      //     return false;
      //   } else {
      //     return Object.keys(hotel.rates).find((rate) => {
      //       if (!hotel.rates[rate].available[1]) {
      //         noRatesCounter++;
      //       }
      //       return rate === rateCode && !!hotel.available_composed;
      //     });
      //   }
      // });

      // return (
      //   !!Object.keys(this.hotelsAvailability).length &&
      //   (noRatesCounter === hotelsAmount
      //     ? false
      //     : !filteredHotels.length
      //     ? `We're sorry. The rate "${rateCode}" is not available on your selected dates. However, other rates are available at the hotels listed below. Click "View Calendar" to see other rates and availability at each hotel.`
      //     : false)
      // );
    },
    guests() {
      return parseInt(this.searchParams.adults + this.searchParams.children);
    },
    nights() {
      return parseInt(this.searchParams.nights);
    },
    isLoading() {
      return !this.filteredHotelsWithAvailability.length && this.loading;
    },
    isNoResults() {
      return !this.filteredHotelsWithAvailability.length && !this.loading;
    },
    // for using when parsing perGuests availability which uses ALL instead of INTERNET
    rateCode() {
      return this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : "INTERNET";
    },
    // @TODOL migrate from rateCodeCorrectValue to rateCode as availability uses INTERNET rather than ALL
    rateCodeCorrectValue() {
      return this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : "INTERNET";
    },
    ratePlan() {
      if (this.searchParams.rateCode) {
        return this.ratePlans[this.searchParams.rateCode]
      }
      return null
    },
    /*
    minDateFromRate() {
      const minDate = Object.values(this.ratePlan).reduce((min, currentHotel) => {
        return Math.min(new Date(min), new Date(currentHotel.start));
      }, this.ratePlan[Object.keys(this.ratePlan)[0]].start);

      const minDateOption = this.$dayjs(this.options.mindate).toDate();

      return minDateOption > minDate ? this.$dayjs(this.options.mindate).format("MM-DD-YYYY") : this.$dayjs(minDate).format("MM-DD-YYYY");
    },
    */
     /*
    nightsFromRate() {
      return Object.values(this.ratePlan)[0].minstay;
    },
    */
    /*
    rateCodeFromRate() {
      return typeof this.ratePlans[this.rateCodeCorrectValue] !== "undefined" ? this.rateCodeCorrectValue : "INTERNET";
    },
    */
    canSkipLodging() {
      return this.toursInlineEnabled;
    }
  },
  watch: {
    "$store.state.app.hotelFilter": function() {
      this.filters = this.$store.state.app.hotelFilter;
    },
    "$store.state.app.hotelOrder": function() {
      this.order = this.$store.state.app.hotelOrder;
    },
    "$route.fullPath": function(value, oldValue) {
      if (value === oldValue) {
        return;
      }
      this.init();
    },
    hotels: {
      immediate: true,
      deep: true,
      handler() {
        if (this.hotels) {
          this.hotelsItems = _.cloneDeep(Object.values(this.hotels))
        }
      }
    },
    searchParams: {
      immediate: true,
      deep: true,
      handler() {
        if (this.searchParamsIsValid) {
          this.$store.dispatch('property/setLodgeSearchParams', {
            ...this.searchParams,
            type: 'exect'
          })
        }
      }
    },
    hotelsPriceRange: {
      immediate: true,
      handler() {
        this.priceRange = this.hotelsPriceRange;
      }
    }
  },
  // What about to create bookingMixin and use it in all activity and lodges booking pages?
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', from.name, to.name)
    if (this.allowRouteLeave) {
      return next();
    }

    if (!this.isModifyReservationMode && !this.addToReservation) {
      return next();
    }

    if (['ReviewPage', 'hotels', 'tours', 'tourPage', 'hotelPage', 'activitiesPage'].includes(to.name)) {
      return next();
    }

    this.setConfirmationModal({
      content: "Are you sure you want to cancel reservation editing?",
    })
      .then(() => {
        if (this.addToReservation) {
          this.$store.dispatch("app/endAddToReservation")
        }

        if (this.modifyReservation) {
          this.$store.dispatch("app/endModifyReservation")
        }

        next();
      })
      .catch(function() {
        next(false);
      });
  }
};
