export default {
  props: {
    alerts: Array,
  },
  data: () => ({}),
  methods: {
    onClose(id) {},
    trySetHeight() {
      if (this.$refs.alerts && this.$refs.alerts.offsetHeight) {
        this.$eventHub.$emit("alerts-rendered", this.$refs.alerts.offsetHeight);
      }
    },
    resetHeight() {
      this.$eventHub.$emit("alerts-destroyed");
    },
  },
  updated() {
    this.trySetHeight();
  },
  mounted() {
    window.addEventListener("resize", this.trySetHeight);
    this.trySetHeight();
  },
  beforeDestroy() {
    this.resetHeight();
  },
};
