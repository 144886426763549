<template>
  <a-modal
    v-if="modalIsShown"
    visible
    :title="modalTitle"
    :footer="false"
    width="380px"
    :mask-closable="false"
    :closable="!!isUser"
    :wrap-class-name="!isUser ? 'hide-close-button': null"
    transition-name=""
    mask-transition-name=""
    @cancel="$store.dispatch('app/setAddToReservationSkipped', true)"
  >
    <a-spin
      :spinning="isFetching"
      :style="{
        padding: '20px',
        minHeight: '400px'
      }"
    >
      <div
        v-if="!isUser"
        :style="{
          maxWidth: '220px',
          margin: '0 auto',
          fontFamily: 'Roboto, Arial'
        }"
      >
        <div
          :style="{
            fontSize: '18px',
            textAlign: 'center',
            marginBottom: '30px'
          }"
        >
          Do you have an existing lodging or tour reservation?
        </div>

        <div
          :style="{
            marginBottom: '30px',
            textAlign: 'center'
          }"
        >
          <b-button
            class="text-uppercase mx-1"
            size="lg"
            variant="outline-primary"
            @click="setModal('Login')"
          >
            Yes
          </b-button>

          <b-button
            class="text-uppercase mx-1"
            size="lg"
            variant="outline-primary"
            @click="$store.dispatch('app/setAddToReservationSkipped', true)"
          >
            No
          </b-button>
        </div>

        <div
          :style="{
            fontSize: '18px',
            textAlign: 'center',
            marginBottom: '20px'
          }"
        >
          Adding an activity to existing reservation for the same stay will allow us to provide better guest service when you arrive.
        </div>
      </div>

      <template v-else>
        <ReservationSelect
          :value="reservationId"
          :filters="reservationFilters"
          submit-title="Add to reservation"
          @cancel="$store.dispatch('app/setAddToReservationSkipped', true)"
          @input="setAddToReservationById($event)"
          @empty="$store.dispatch('app/setAddToReservationSkipped', true)"
        />

        <b-button
          block
          variant="link"
          size="lg"
          @click="$store.dispatch('app/setAddToReservationSkipped', true)"
        >
          Create new reservation
        </b-button>
      </template>
    </a-spin>
  </a-modal>
</template>

<script>
import _ from 'lodash'
import { apiReservation } from '@/api/property'
import { mapGetters } from 'vuex'
import ReservationSelect from '@/components/reservations/ReservationSelect/index.vue'

export default {
  name: 'AddToReservationModal',

  components: {
    ReservationSelect
  },

  props: {
    unskip: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      reservationId: undefined,
      reservation: null,
      isFetching: false
    }
  },

  mounted() {
    if (this.unskip && this.addToREservationSkipped) {
      this.$store.dispatch('app/setAddToReservationSkipped', false)
    }
  },

  computed: {
    ...mapGetters({
      shownModal: 'app/shownModal',
      isUser: 'auth/isUser',
      authStatus: 'auth/status',
      addToReservation: 'app/addToReservation',
      addToREservationSkipped: 'app/addToReservationSkipped',
      cartIsEmpty: 'property/cartIsEmpty',
      reservationList: 'property/reservationList'
    }),

    modalIsVisible() {
      return (
        !this.addToReservation &&
        !this.addToREservationSkipped &&
        !this.shownModal &&
        this.cartIsEmpty
      )
    },

    modalIsShown() {
      return (
        this.modalIsVisible &&
        (!this.authStatus || !_.isEmpty(this.upcomingReservations))
      )
    },

    modalTitle() {
      return this.authStatus ? 'Your reservations' : 'Book an activity'
    },

    upcomingReservations() {
      return _.filter(this.reservationList, this.reservationFilters)
    }
  },

  watch: {
    modalIsVisible: {
      immediate: true,

      async handler() {
        if (this.modalIsVisible && _.isEmpty(this.upcomingReservations) && this.isUser) {
          await this.$store.dispatch('property/fetchReservationsList')

          if (_.isEmpty(this.upcomingReservations)) {
            this.$store.dispatch('app/setAddToReservationSkipped', true)
          }
        }
      }
    }
  },

  methods: {
    setAddToReservationById(uniqueId) {
      const reservation = _.find(this.reservationList, { uniqueId })

      if (reservation) {
        this.isFetching = true

        return apiReservation(reservation.token)
          .then(async data => {
            await this.$store.dispatch('app/startAddToReservation', data)

            const query = {
              date: this.$dayjs(reservation.arrival).format('MM-DD-YYYY'),
              adults: reservation.adults,
              children: reservation.children,
              destination: 'ALL'
            }

            this.$router.push({ name: 'tours', query })
          })
          .catch(error => {
            console.log('error', error)
            this.reservationId = null
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    },

    reservationFilters(i) {
      return i.status !== 'CANCELLED' && this.$dayjs(this.$dayjs().format('MM/DD/YYYY')) < this.$dayjs(i.arrival) 
    }
  }
}
</script>