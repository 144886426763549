<template>
  <div id="app" :class="loading ? 'data-loading data-loding-fixed' : ''">
    <b-modal
      :visible="!!msg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      content-class="rounded-lg shadow"
      body-class="py-4 px-5"
      size="lg"
    >
      <div
        v-html="msg"
        class="text-center text typography"
      />
    </b-modal>

    <div v-if="!appInit" class="preloader" />

    <component :is="layout" v-else>
      <router-view />
    </component>

    <debug />
  </div>
</template>

<script>
import Default from '@/layouts/Default/index.vue'
const components = { Default };

export default {
  components,
  data() {
    return {
      dropdownSelectOpenedIds: [],
    }
  },

  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (!this.dropdownSelectOpenedIds.includes(bvEvent.componentId)) {
        this.dropdownSelectOpenedIds.push(bvEvent.componentId);
      }
    })

    this.$root.$on('bv::dropdown::hide', bvEvent => {
      const openedNestedId = this.dropdownSelectOpenedIds.find((i) => (
        i.includes(`nested-for--${(bvEvent.componentId)}`)
      ));
      if (openedNestedId) {
        bvEvent.preventDefault();
        return;
      }

      if (this.dropdownSelectOpenedIds.includes(bvEvent.componentId)) {
        this.dropdownSelectOpenedIds = this.dropdownSelectOpenedIds.filter(i => i !== bvEvent.componentId);
      }
    })
  },
  methods: {
  },
  computed: {
    layout() {
      const key = this.$get(this.$route, 'meta.layout');
      return components[key] ? key : 'Default'
    },
    appInit() {
      document.documentElement.style.setProperty('--color-alpha', `#${this.$store.state.app.options.color1}`);
      document.documentElement.style.setProperty('--color-beta', `#${this.$store.state.app.options.color2}`);
      document.documentElement.style.setProperty('--color-gamma', `#${this.$store.state.app.options.color3}`);
      document.documentElement.style.setProperty('--color-psi', `#${this.$store.state.app.options.color4}`);
      return this.$store.state.app.init;
    },
    msg() {
      return this.$store.state.auth.availabilityStatusMessage;
    },
    loading() {
      return this.$store.state.app.loading;
    }
  }
}
</script>

<style lang="sass">
@import "@/style/app"
</style>
