import _ from 'lodash'
import { optionsComputed, propertyComputed, propertyMethods } from "@/store/helper";
import { mapGetters } from 'vuex'
import bookingMixin from '@/mixin/booking'
import { useWindowSize } from 'vue-window-size';
import { gtmTrack } from "@/lib/gtm";
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),
  mixins: [
    bookingMixin
  ],
  data: () => ({
    checkedButton: "",
    initialTransformValue: 0,
    transformValue: 0,
    tabTransformWidth: 0,
    minTourPrices: {},
    activityTime: "",
    adultQty: 1,
    childrenQty: 0,
    tourDate: "",
    loading: false,
    calendar_loading: false,
    noToursOnModifySearch: false,
    /*
     * use selectedDate to pre-select date on activity item tab in edit mode
     */
    selectedDate: false,
    /*
     * use selectedOption to pre-select option on activity item tab in edit mode
     */
    selectedOption: false,
    /*
     * number of weeks to display
     */
    displayWeeks: 3,
    /*
     * calendar start date. might be in the past
     */
    calendarStartDate: null,
    /*
     * remember last availability call date
     */
    lastCallDate: null,
    lastCallFutureDate: null,
    lastCallPastDate: null,
    destinationConstants: ["ALL", "ALL-HOTELS", "ALL-CAMPGROUNDS"],
    sort_by: null,
    sort_desc: false
  }),
  async created() {
    this.init();
  },
  methods: {
    ...propertyMethods,

    validateUrl() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      // check dateFrom
      if (!!this.$route.query.dateFrom) {
        let dateFrom = this.$dayjs(this.$route.query.dateFrom, "MM-DD-YYYY", true);
        if (!dateFrom.isValid()) {
          // invalid date format
          return false;
        }

        dateFrom = dateFrom.toDate();
        if (today > dateFrom) {
          // past date
          return false;
        }

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          if (minDate > dateFrom) {
            // date is greater than mindate
            return false;
          }
        }

        let maxDate = this.$dayjs(this.bookingDatesWindow.max, "MM/DD/YYYY")
        if (maxDate.isValid()) {
          maxDate = maxDate.toDate();
          if (dateFrom > maxDate) {
            // date is greater than maxdate
            return false;
          }
        }
      }

      // validate adults. integer, >=1
      if (!!this.$route.query.adults) {
        if (!Number.isInteger(+this.$route.query.adults)) {
          return false;
        }

        if (parseInt(this.$route.query.adults) < 1) {
          return false;
        }
      }

      // validate children. integer, >=0
      if (!!this.$route.query.children) {
        if (!Number.isInteger(+this.$route.query.children)) {
          return false;
        }

        if (parseInt(this.$route.query.children) < 0) {
          return false;
        }
      }

      const guests = parseInt(this.$route.query.adults) + parseInt(this.$route.query.children);
      if (guests > this.maxGuests) {
        // guests exceeded
        return false;
      }

      // validate nights. integer, >=1 && <=14
      if (!!this.$route.query.nights) {
        if (!Number.isInteger(+this.$route.query.nights)) {
          return false;
        }

        const nights = parseInt(this.$route.query.nights);
        if (nights < 1 || nights > 14) {
          return false;
        }
      }

      return true;
    },
    validateDestination() {
      let hotelCodes = Object.keys(this.$store.state.property.hotels);
      return (
        !this.$route.query.destination ||
        !!hotelCodes.concat(this.destinationConstants).find((item) => item === this.$route.query.destination)
      );
    },
    urlNeedsNormalization() {
      return (
        typeof this.$route.query.dateFrom === "undefined" ||
        typeof this.$route.query.nights === "undefined" ||
        typeof this.$route.query.adults === "undefined" ||
        typeof this.$route.query.children === "undefined"
      );
    },
    dateNeedsFix() {
      //console.log("dateNeedsFix", this.$route.params.skipDateCheck);
      // check date parameter check for direct URLs
      if (!this.$route.params.skipDateCheck) {
        const dateFromKey = this.$dayjs(this.$route.query.dateFrom, "MM-DD-YYYY").format("YYYY-MM-DD");

        const arriveDateKey = this.$dayjs(this.arriveDate).format("YYYY-MM-DD");

        if (arriveDateKey !== dateFromKey) {
          return true;
        }
      }
    },
    fixDate() {
      let urlQueryParams = this.$_.cloneDeep(this.$route.query);

      // need to clone query to prevent Avoided redundant navigation to current location error
      urlQueryParams["dateFrom"] = this.$dayjs(this.arriveDate).format("MM-DD-YYYY");

      return this.$router.replace({
        name: "activitiesPage",
        query: urlQueryParams,
      });
    },
    /*
     * Set params defaults and redirect to normalized URL
     */
    normalizeUrl() {
      let urlQueryParams = {};

      if (!this.$route.query.dateFrom) {
        const minDate = this.$dayjs(this.bookingDatesWindow.min, "MM/DD/YYYY").format("MM-DD-YYYY");
        urlQueryParams["dateFrom"] = minDate;
      } else {
        urlQueryParams["dateFrom"] = this.$route.query.dateFrom;
      }

      if (!this.$route.query.nights) {
        urlQueryParams["nights"] = 1;
      } else {
        urlQueryParams["nights"] = this.$route.query.nights;
      }

      if (!this.$route.query.adults) {
        urlQueryParams["adults"] = 1;
      } else {
        urlQueryParams["adults"] = this.$route.query.adults;
      }

      if (!this.$route.query.children) {
        urlQueryParams["children"] = 0;
      } else {
        urlQueryParams["children"] = this.$route.query.children;
      }

      if (!this.$route.query.destination) {
        urlQueryParams["destination"] = "ALL";
      } else {
        urlQueryParams["destination"] = this.$route.query.destination;
      }

      //console.log("normalizUrl::2", urlQueryParams);

      return this.$router.push({
        name: "activitiesPage",
        query: urlQueryParams,
      });
    },
    /*
     * check if activity page could be processed
     */
    couldDo() {
      // check if there are rooms in the cart
      return !this.$_.isEmpty(this.pCart);
    },
    doStupidStuff() {
      //@TODO: discuss destination parameter
      let hotelCodes = Object.keys(this.$store.state.property.hotels);
      localStorage.removeItem("step2");
      localStorage.removeItem("step4");
      localStorage.setItem("step4", this.$route.fullPath);

      let step2_fullPath = "";

      if (localStorage.getItem("step2_type") === "flex") {
        step2_fullPath =
          "/booking/lodging-flex-search" +
          `?dateFrom=${this.$route.query.dateFrom}&nights=31
            &destination=ALL&adults=${this.$route.query.adults}&children=${this.$route.query.children}${
            !!this.$route.query.rateCode ? "&rateCode=" + this.$route.query.rateCode : ""
          }`;
      } else {
        step2_fullPath =
          "/booking/lodging-search" +
          `?dateFrom=${this.$route.query.dateFrom}&nights=${this.$route.query.nights}&destination=ALL&adults=${
            this.$route.query.adults
          }&children=${this.$route.query.children}${!!this.$route.query.rateCode ? "&rateCode=" + this.$route.query.rateCode : ""}`;
      }
      localStorage.setItem("step2", step2_fullPath);

      if (!!hotelCodes.find((code) => code === this.$route.query.destination)) {
        let step3_fullPath = `/booking/lodging-select/${this.$route.query.destination}?dateFrom=${this.$route.query.dateFrom}&nights=${
          this.$route.query.nights
        }&destination=${this.$route.query.destination}&adults=${this.$route.query.adults}&children=${this.$route.query.children}${
          !!this.$route.query.rateCode ? "&rateCode=" + this.$route.query.rateCode : ""
        }`;

        localStorage.removeItem("step3");
        localStorage.setItem("step3", step3_fullPath);
      }
    },
    async loadAvailability() {
      //console.log("loadAvailability", this.needLoadAvailability);

      if (!this.needLoadAvailability) {
        return;
      }

      if (!this.lastCallDate) {
        this.lastCallDate = this.actualStartDate;
        this.lastCallFutureDate = this.actualStartDate;
        this.lastCallPastDate = this.actualStartDate;
      }

      let date;
      if (this.lastCallDate < this.actualStartDate) {
        // loading future dates
        date = this.$dayjs(this.lastCallFutureDate)
          .add(30, "day")
          .toDate();

        this.lastCallFutureDate = date;
      } else if (this.lastCallDate > this.actualStartDate) {
        // loading past dates
        date = this.$dayjs(this.lastCallPastDate)
          .subtract(30, "day")
          .toDate();
        const mindate = this.$dayjs(this.bookingDatesWindow.min).toDate();

        date = date < mindate ? mindate : date;

        this.lastCallPastDate = date;
      } else {
        date = this.actualStartDate;
      }

      this.lastCallDate = date;

      const availabilityData = {
        date: this.$dayjs(date).format("MM/DD/YYYY"),
        limit: 30,
      };

      this.calendar_loading = true;
      await this.$store.dispatch("property/fetchAvailableTours", availabilityData);
      this.calendar_loading = false;
    },
    async loadCart() {
      await this.$store.dispatch("property/fetchTourCart");
    },
    countLength() {
      this.tabTransformWidth = (this.windowWidth - 40) * 0.84 * 0.2 + 8;
      let totalLength = (this.windowWidth - 40) * 0.84 * 0.2 * Object.keys(this.tours).length + 8 * Object.keys(this.tours).length - 1;
      this.initialTransformValue = Object.keys(this.tours).length > 4 ? totalLength - (this.windowWidth - 40) * 0.84 : 0;
    },
    async init() {
      if (!this.toursInlineEnabled) {
        return this.$router.push({ name: "book_stay", params: { skipCartCheck: true } });
      }
      //console.log("INIT", this.$route, this.$route.params.edit);
      if (!this.validateUrl()) {
        return this.$router.push({ name: "book_stay", params: { skipCartCheck: true } });
      } else if (!this.validateDestination()) {
        let query = {};
        for (const queryKey in this.$route.query) {
          query[queryKey] = this.$route.query[queryKey];
        }
        query["destination"] = "ALL";

        return this.$router.push({
          name: "activitiesPage",
          query: query,
        });
      }

      this.loading = true;
      this.checkedButton = _.keys(this.sortedTours)[0]
      await this.$store.dispatch('property/fetchToursList')

      await this.loadCart();

      //if (!this.couldDo()) {
      //return this.$router.push({ name: "book_stay" });
      //}

      if (this.urlNeedsNormalization()) {
        return this.normalizeUrl();
      }

      /*
      // do not fix date for a now
      if (this.dateNeedsFix()) {
        return this.fixDate();
      }*/

      //console.log("INIT", this.tours.length);


      if (this.$_.isEmpty(this.tours)) {
        this.$msg("There are no tours in the property");
      }

      if (typeof this.$route.params !== "undefined" && typeof this.$route.params.edit !== "undefined") {
        this.checkedButton = this.$route.params.edit.tourCode;
        this.selectedDate = this.$route.params.edit.date;
        this.selectedOption = this.$route.params.edit.optionCode;
      }
      //console.log("INIT::2", this.checkedButton, this.selectedDate, this.selectedOption);

      this.doStupidStuff();

      this.tourDate = this.dateFrom;
      this.adultQty = this.adults;
      this.childrenQty = this.children;

      await this.loadAvailability();

      this.checkedButton = _.keys(this.sortedTours)[0]

      this.loading = false;

      // this.setToursAvailabilityRange();

      /*
        40 - page left-padding + right-padding
        0.84 - tab scroll-bar width in percents
        0.2 - tab width in percents
        8 - margin-left of each tab except the 1st
      */
      this.countLength();
      this.trackData();
    },
    handleButtonClick(code) {
      this.selectedDate = false;
      this.checkedButton = code;
      this.trackData();
    },
    handleSliderButtonClick(type) {
      if (type === "left" && this.transformValue < 0)
        if (this.transformValue + this.tabTransformWidth <= 0) this.transformValue += this.tabTransformWidth;
        else this.transformValue += -this.transformValue;
      if (type === "right" && -this.transformValue < this.initialTransformValue)
        if (this.initialTransformValue - -this.transformValue >= this.tabTransformWidth) this.transformValue -= this.tabTransformWidth;
        else this.transformValue -= this.initialTransformValue - -this.transformValue;
    },
    /*
    setToursAvailabilityRange() {
      const weekStart = this.startDate;
      const calendarLength = 21;
      const mindate = this.$dayjs(this.bookingDatesWindow.min, "MM/DD/YYYY").toDate();
      const maxdate = this.$dayjs(this.bookingDatesWindow.max, "MM/DD/YYYY").toDate();

      //console.log("setToursAvailabilityRange", weekStart, totalDays);
      this.toursAvailability = {};

      const avail = this.$store.state.property.toursAvailability;

      for (let i = 0; i < calendarLength; i++) {
        const date = this.$dayjs(weekStart)
          .add(i, "d")
          .toDate();

        const dateKey = this.$dayjs(date).format("MM/DD/YYYY");
        
        if (date < mindate || date > maxdate || typeof avail[dateKey] === "undefined") {
          this.$set(this.toursAvailability, dateKey, false);
        } else if (!this.checkDateInBookingWindow(dateKey)) {
          this.$set(this.toursAvailability, dateKey, false);
        } else {
          this.$set(this.toursAvailability, dateKey, avail[dateKey]);
        }
      }

      //console.log("setToursAvailabilityRange", avail, this.toursAvailability);
    },
    */
    setQty(type, key) {
      if (type === "plus") {
        if (key === "adult" && this.adultQty < 5) this.adultQty += 1;
        if (key === "children" && this.childrenQty < 5) this.childrenQty += 1;
      }
      if (type === "minus") {
        if (key === "adult" && this.adultQty > 1) this.adultQty -= 1;
        if (key === "children" && this.childrenQty > 0) this.childrenQty -= 1;
      }
    },
    onSkipActivities() {
      this.$router.push({
        name: "ReviewPage",
      });
    },
    async onPreviousWeek() {
      if (!this.allowPreviousWeek) {
        return;
      }

      this.calendarStartDate = this.$dayjs(this.calendarStartDate)
        .subtract(7, "day")
        .toDate();
      await this.loadAvailability();

      // this.setToursAvailabilityRange();
    },
    async onNextWeek() {
      if (!this.allowNextWeek) {
        return;
      }

      this.calendarStartDate = this.$dayjs(this.calendarStartDate)
        .add(7, "day")
        .toDate();
      await this.loadAvailability();

      // this.setToursAvailabilityRange();
    },
    trackData() {
      const data = {
        'content-name': '/booking/activities-select',
        'content-view-name': 'Choose Activity Time',
        event: 'view_item',
        ecommerce: {
          currency: 'USD',
          // value: , // @TODO: sum of all items
          items: [{
            item_id: this.tour.code,
            item_name: this.tour.title,
            affiliation: this.isAgency ? 'TA' : 'Consumer',
            index: 0,
            item_brand: this.options.title,
            item_category: 'Activity',
            item_category2: this.$dayjs(this.params.dateFrom, 'MM/DD/YYYY').format('YYYYMMDD'),
            item_category5: this.params.adults + this.params.children,
            item_category6: this.params.adults,
            item_category7: this.params.children,
            // item_variant: '', // @TODO: room type
            coupon: this.params.rateCode,
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            // price: null, // @TODO: price
            // quantity: null // @TODO: number of rooms
          }]
        }
      }

      gtmTrack({ ecommerce: null });
      gtmTrack(data);
    },
  },
  computed: {
    ...optionsComputed,
    ...propertyComputed,

    ...mapGetters({
      tours: 'property/tours',
      hotelCart: 'property/hotelCart',
      tourCart: 'property/tourCart',
      toursAvailability: 'property/toursAvailability',
      isAgency: 'auth/isAgency',
      options: 'app/options',
    }),
    windowWidth() {
      return this.windowSize.width.value;
    },
    params() {
      return {
        destination: this.$route.query.destination,
        dateFrom: this.$route.query.dateFrom,
        nights: parseInt(this.$route.query.nights),
        adults: parseInt(this.$route.query.adults) || 0,
        children: parseInt(this.$route.query.children) || 0,
        rateCode: this.$route.query.rateCode
      }
    },

    calendarRange() {
      const calendarLength = 21

      return _.map(_.range(0, calendarLength), i => {
        return this.$dayjs(this.calendarStartDate).add(i, 'day').format('MM/DD/YYYY')
      })
    },

    calendarToursAvailability() {
      const calendarToursAvailability = {}

      for (let i = 0; i < this.calendarRange.length; i++) {
        const day = this.calendarRange[i]
        calendarToursAvailability[day] = _.get(this.toursAvailability, day)
      }

      return calendarToursAvailability
    },

    dateFrom() {
      return this.$dayjs(this.$route.query.dateFrom, "MM-DD-YYYY").toDate();
    },
    adults() {
      return parseInt(this.$route.query.adults);
    },
    children() {
      return parseInt(this.$route.query.children);
    },
    nights() {
      return parseInt(this.$route.query.nights);
    },
    /*
    sortedTours(){
      const dayTourAvailability = this.toursAvailability?.[this.$dayjs(this.dateFrom).format('MM/DD/YYYY')]
      const sortedTourCodes = _.keys(dayTourAvailability || this.tours)
      const sortedTours = {}

      for (let i = 0; i < sortedTourCodes.length; i++) {
        const tourCode = sortedTourCodes[i]
        sortedTours[tourCode] = this.tours[tourCode]
      }

      return sortedTours
    },
    */

    /*
    sortedTours() {
      const dayTourAvailability = this.toursAvailability?.[this.$dayjs(this.dateFrom).format('MM/DD/YYYY')]

      if (!dayTourAvailability) {
        return this.tours
      }

      let sortedTours = _.filter(this.tours, i => dayTourAvailability?.[i.code])

      if (this.sort_by === 'price') {
        sortedTours = _.sortBy(sortedTours, [
          i => !dayTourAvailability[i.code].available,
          i => dayTourAvailability[i.code].prices.adult.min
        ])

      } else {
        sortedTours = _.sortBy(sortedTours, [
          i => !dayTourAvailability[i.code].available
        ])
      }

      return _.keyBy(sortedTours, 'code')
    },
    */

    tour() {
      return this.tours?.[this.checkedButton];
    },
    /*
    prices() {
      let prices = {};

      for (var dateKey in this.toursAvailability) {
        const daylyData = this.toursAvailability[dateKey];

        if (!daylyData) {
          continue;
        }

        for (var tourCode in daylyData) {
          const tourData = daylyData[tourCode];
          if (+tourData["available"] === 0) {
            continue;
          }

          if (typeof prices[tourCode] === "undefined") {
            prices[tourCode] = {
              adults: {
                min: 0,
                max: 0,
              },
              children: {
                min: 0,
                max: 0,
              },
            };
          }

          if (prices[tourCode]["adults"]["min"] === 0 || prices[tourCode]["adults"]["min"] > tourData["prices"]["adult"]["min"]) {
            prices[tourCode]["adults"]["min"] = parseFloat(tourData["prices"]["adult"]["min"]);
          }

          if (prices[tourCode]["adults"]["max"] === 0 || prices[tourCode]["adults"]["max"] < tourData["prices"]["adult"]["max"]) {
            prices[tourCode]["adults"]["max"] = parseFloat(tourData["prices"]["adult"]["max"]);
          }

          if (prices[tourCode]["children"]["min"] === 0 || prices[tourCode]["children"]["min"] > tourData["prices"]["child"]["min"]) {
            prices[tourCode]["children"]["min"] = parseFloat(tourData["prices"]["child"]["min"]);
          }

          if (prices[tourCode]["children"]["max"] === 0 || prices[tourCode]["children"]["max"] < tourData["prices"]["child"]["max"]) {
            prices[tourCode]["children"]["max"] = parseFloat(tourData["prices"]["child"]["max"]);
          }
        }

        //console.log("prices", prices, this.toursAvailability);
      }

      return prices;
    },
    */
    /*
    tourStats() {
      const tourStats = {}

      for (const tourCode in this.tours) {
        const isOpened = !!_.chain(this.toursAvailability)
          .find(date => (
              !!_.find(date?.[tourCode]?.options, option => (
                ['OPEN', 'SOLDOUT'].includes(option.status)
              )
            )
          ))
          .value()

        tourStats[tourCode] = {
          status: isOpened ? 'OPEN' : 'CLOSED'
        }
      }

      return tourStats
    },
    */

    availabilityDataByTours() {
      const tourStats = {}

      for (const code in this.tours) {
        const tourDays = {}

        for (const date in this.toursAvailability) {
          if (date in this.calendarToursAvailability) {
            tourDays[date] = this.toursAvailability[date][code]
          }
        }

        if (tourDays) {
          tourStats[code] = tourDays
        }
      }

      return tourStats
    },

    tourStats() {
      return _.chain(this.tours)
        .map((tour, code) => {
          const isOpened = !!_.find(this.availabilityDataByTours[tour.code], tourDate => (
            tourDate && !!_.find(tourDate.options, option => ['OPEN', 'SOLDOUT'].includes(option.status))
          ))

          const cheapestAvailableDay = _.chain(this.availabilityDataByTours[tour.code])
            .map((tourDate, dateKey) => ({...tourDate, dateKey}))
            .filter(tourDate => tourDate && tourDate.available)
            .sortBy(tourDate => tourDate.prices.adult)
            .first()
            .value()

          const isAvailable = !!cheapestAvailableDay;
          const status = isAvailable ? 'AVAILABLE' : isOpened ? 'SOLD OUT' : 'CLOSED';

          let adultPrices;
          if (isAvailable) {
            const base = cheapestAvailableDay?.prices?.adult?.min;
            const fees = cheapestAvailableDay?.prices?.adult?.fee;
            const taxes = cheapestAvailableDay?.prices?.adult?.tax;
            adultPrices = getPrice({
              base,
              fees,
              taxes
            })
          }

          let childPrices;
          if (isAvailable) {
            const base = cheapestAvailableDay?.prices?.child?.min;
            const fees = cheapestAvailableDay?.prices?.child?.fee;
            const taxes = cheapestAvailableDay?.prices?.child?.tax;
            childPrices = getPrice({
              base,
              fees,
              taxes
            })
          }

          const prices = isAvailable ? {
            adult: adultPrices,
            child: childPrices,
          } : undefined

          return { code, status, isAvailable, prices }
        })
        .keyBy('code')
        .value()
    },

    sortedTours() {
      let sortedTours = _.filter(this.tours, i => this.tourStats?.[i.code])

      if (this.sort_by === 'price') {
        sortedTours = _.sortBy(sortedTours, [
          i => !this.tourStats[i.code].isAvailable,
          i => this.tourStats[i.code].prices?.adult.total
        ])

      } else {
        sortedTours = _.sortBy(sortedTours, [
          i => !this.tourStats[i.code].isAvailable
        ])
      }

      return _.keyBy(sortedTours, 'code')
    },

    /*
    defaultActivityTime: {
      get: function() {
        return !!this.activityTime ? this.activityTime : Object.keys(this.tour.options)[0];
      },
      set: function(newValue) {
        this.activityTime = newValue;
      },
    },
    */
    isSliderBtnDisabledLeft() {
      this.countLength()
      return !(this.transformValue < 0);
    },
    isSliderBtnDisabledRight() {
      this.countLength()
      return !(-this.transformValue < this.initialTransformValue);
    },
    scrollableClasses() {
      let classes = [];

      if (this.isSliderBtnDisabledLeft) {
        classes.push("scrolled-left");
      }

      if (this.isSliderBtnDisabledRight) {
        classes.push("scrolled-right");
      }

      return classes.join(" ");
    },
    arriveDate() {
      let arriveDate = false;

      for (let i in this.pCart) {
        let date = this.$dayjs(this.pCart[i].date, "MM/DD/YYYY").toDate();

        if (arriveDate === false || date < arriveDate) {
          arriveDate = date;
        }
      }

      if (arriveDate === false) {
        return this.dateFrom;
      }

      return arriveDate;
    },
    departDate() {
      let departDate = false;

      for (let i in this.pCart) {
        let date = this.$dayjs(this.pCart[i].date, "MM/DD/YYYY")
          .add(this.pCart[i].nights, "day")
          .toDate();

        if (departDate === false || date > departDate) {
          departDate = date;
        }
      }

      if (departDate === false) {
        return this.$dayjs(this.dateFrom)
          .add(1, "day")
          .toDate();
      }

      return departDate;
    },
    needLoadAvailability() {
      return Object.keys(this.threeWeeksAvailability).length < this.displayWeeks * 7 - this.numberOfDaysInThePast;
    },
    threeWeeksAvailability() {
      let availability = {};
      let clonedDate = this.$dayjs(this.calendarStartDate).toDate();
      const avail = this.$store.state.property.toursAvailability;

      for (let i = 0; i < this.displayWeeks * 7; i++) {
        const dateKey = this.$dayjs(clonedDate).format("MM/DD/YYYY");

        if (typeof avail[dateKey] !== "undefined") {
          availability[dateKey] = avail[dateKey];
        }

        clonedDate = this.$dayjs(clonedDate).add(1, "day");
      }

      return availability;
    },
    numberOfDaysInThePast() {
      return this.$dayjs(this.actualStartDate).diff(this.$dayjs(this.calendarStartDate), "day");
    },
    actualStartDate() {
      const minDate = this.$dayjs(this.bookingDatesWindow.min)

      return this.calendarStartDate < minDate ? minDate : this.calendarStartDate
    },
    allowPreviousWeek() {
      const mindate = this.$dayjs(this.bookingDatesWindow.min)

      return this.calendarStartDate > mindate;
    },
    allowNextWeek() {
      const maxdate = this.$dayjs(this.bookingDatesWindow.max, "MM/DD/YYYY").toDate();
      const date = this.$dayjs(this.calendarStartDate)
        .add(this.displayWeeks * 7 - 1, "day")
        .toDate();

      return date < maxdate;
    },
    maxGuests() {
      return this.options.resformMaxOccupancy;
    },
    canSkipActivities() {
      return this.cartNotEmpty;
    }
  },

  watch: {
    "$route.fullPath": function(value, oldValue) {
      if (value === oldValue) {
        return;
      }

      this.init();
    },

    params: {
      immediate: true,
      deep: true,

      handler() {
        this.calendarStartDate = this.$dayjs(this.params.dateFrom, 'MM-DD-YYYY')
          .startOf("week")
          .toDate()
      }
    },
  }
}
