<template>
  <section class="profile__section">
    <h2 class="profile__section-title">CHANGE SECRET QUESTION</h2>
    <form>
      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="question"> Question </label>
          <a-select
            id="question"
            placeholder="Please select a Secret Question"
            class="input__select input--auth"
            dropdownClassName="input__select-options"
            @change="handleOptionSelect"
          >
            <a-select-option v-for="(question, key) in questions" :key="key" :value="key">
              {{ unescapeString(question) }}
            </a-select-option>
          </a-select>
          <div
            class="form__item-error"
            v-if="v$.question.required?.$invalid && v$.question.$error"
          >
            Field is required
          </div>
          <div class="form__item-error" v-if="errors.question">
            <span v-for="err in errors.question" :key="err">
              {{ err }}
            </span>
          </div>
        </div>
      </div>

      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-answer"> Answer </label>
          <a-input
            placeholder="Answer"
            id="profile-answer"
            class="input input--auth"
            :class="{ 'input--error': v$.answer.$error }"
            v-model.trim="v$.answer.$model"
          />
          <div
            class="form__item-error"
            v-if="v$.answer.required?.$invalid && v$.answer.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.answer.general?.$invalid && v$.answer.$error"
          >
            Letters, digits and '- &:#;?,-.|_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.answer.notCreditCard?.$invalid && v$.answer.$error">
            Value can't be credit card number
          </div>
          <div class="form__item-error" v-if="errors.username">
            <span v-for="err in errors.username" :key="err">
              {{ err }}
            </span>
          </div>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-password"> Password </label>
          <a-input
            placeholder="Password"
            id="profile-password"
            class="input input--auth"
            :type="isPasswordVisible"
            :class="{ 'input--error': v$.password.$error }"
            v-model.trim="v$.password.$model"
            @focus="resetFormFieldErrors"
            name="password"
          />
          <a-checkbox
            :checked="passwordVisible"
            @change="passwordVisible = !passwordVisible"
          >
            Show password
          </a-checkbox>
          <div
            class="form__item-error"
            v-if="v$.password.required?.$invalid && v$.password.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.password.password?.$invalid && v$.password.$error"
          >
            Password must be 8-60 chars length, contain at least one uppercase, one lowercase, one special character and one digit
          </div>
          <ul class="form__item-error" v-if="errors.password">
            <li v-for="err in errors._password" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
      </div>
    </form>
    <div class="profile__buttons">
      <div></div>
      <a-button
        class="button button-primary"
        @click="handleQuestionChange"
        :disabled="loading"
      >
        Save changes
      </a-button>
    </div>
  </section>
</template>

<script>
import {required } from '@vuelidate/validators'
import { apiUpdateProfile } from '@/api/profile'
import { unescapeString } from '@/utils/index'
import { general, password, notCreditCard } from "@shared/utils/validators"
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    password: '',
    question: '',
    answer: '',
    passwordVisible: false,
    loading: false,
    errors: {}
  }),
  validations: () => {
    return {
      password: {
        required, password
      },
      answer: {
        required,
        general,
        notCreditCard,
      },
      question: {
        required
      }
    }
  },
  computed: {
    isPasswordVisible() {
      return this.passwordVisible ? 'text' : 'password'
    },
    questions() {
      return this.$store.state.app.options.accountQuestions
    }
  },
  methods: {
    unescapeString,
    handleOptionSelect(value) {
      this.question = value
    },
    clearForm() {
      this.answer = ''
      this.password = ''
      this.v$.$reset()
    },
    async handleQuestionChange() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.loading = true
      try {
        const res = await apiUpdateProfile['question']({ password: this.password, answer: this.answer, question: this.question })
        if (res && res.status === 'SUCCESS') {
          this.$msg('Secret question successfully changed!', 1)
          this.clearForm()
        }
      } catch (error) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
        this.$store.dispatch('app/setErrors')
      }
      finally {
        this.loading = false
      }
    }
  }
}

</script>
