export default {
  name: 'TruncatedText',

  props: {
    text: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isTruncated: true,
      parentClientHeight: 0, 
      bodyClientHeight: 0,
      // scrollHeight: 0,
      // clientHeight: 0,
      // offsetHeight: 0,
    }
  },

  computed: {
    showTruncatedButton() {
      return !this.isTruncated || this.bodyClientHeight > this.parentClientHeight;
    }
  },

  watch: {
    $mq: {
      immediate: true,
      handler() {
        this.isTruncated = true
      }
    }
  },

  mounted() {
    this.initTruncated();
  },

  methods: {
    initTruncated() {
      this.setHeight();
      window.addEventListener('resize', this.setHeight);
    },

    setHeight() {
      if (this.$refs.body) {
        this.parentClientHeight = this.$el.clientHeight;
        // this.scrollHeight = this.$refs.body.scrollHeight;
        this.bodyClientHeight = this.$refs.body.clientHeight;
        // this.offsetHeight = this.$refs.body.offsetHeight;
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setHeight);
  }
}