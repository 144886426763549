import { mapGetters } from "vuex";
import { apiAvailabilityHotels } from "@/api/availability";
import { optionsComputed } from "@/store/helper";
import { gtmTrack } from "@/lib/gtm";
import bookingMixin from '@/mixin/booking'
import MonthCarousel from '@/components/MonthCarousel/index.vue'
import _ from 'lodash'
import mapValues from 'lodash/mapValues'
import {getPrices} from '@/utils/taxes-and-fees'

export default {
  mixins: [
    bookingMixin
  ],

  components: {
    MonthCarousel,
  },

  data() {
    return {
      mode: "top",
      today: new Date().setHours(0, 0, 0, 0),
      // nights: 1,
      maxNights: 0,
      iterator: 0,
      finalPrice: 0,
      visible: false,
      priceCalendar: {},
      groupAvailability: null,
      hotelsAvailability: {},
      // calendar scroll
      shift: 0,
      maxShift: 0,
      hotelType: "",
      destinationConstants: ["ALL", "ALL-HOTELS", "ALL-CAMPGROUNDS"],
      monthsAmount: 13,
      maxScroll: false,
      hotelAvail: {},
      prevFinalPrice: 0,
      isLoadingAvailability: false,
      isLoadingRatePlan: false,
      // moved to computed
      //rateCode: "",
      //guests: 0,
      //minStay: 1,
    }
  },
  async created() {
    await this.init();
  },
  methods: {
    // validateUrl() {
    //   this.nights = this.$route.query.nights
    //   let today = new Date();
    //   today.setHours(0, 0, 0, 0);

    //   // check dateFrom
    //   if (!!this.$route.query.dateFrom) {
    //     let dateFrom = this.$dayjs(this.$route.query.dateFrom, "MM-DD-YYYY", true);
    //     if (!dateFrom.isValid()) {
    //       // invalid date format
    //       return false;
    //     }

    //     dateFrom = dateFrom.toDate();
    //     if (today > dateFrom) {
    //       // past date
    //       return false;
    //     }

    //     let minDate = this.$dayjs(this.allowedRange.start, "MM/DD/YYYY");
    //     if (minDate.isValid()) {
    //       minDate = minDate.toDate();
    //       if (minDate > dateFrom) {
    //         // date is greater than mindate
    //         return false;
    //       }
    //     }

    //     let maxDate = this.$dayjs(this.allowedRange.end, "MM/DD/YYYY")
    //     if (maxDate.isValid()) {
    //       maxDate = maxDate.toDate().setDate(1);
    //       if (dateFrom > maxDate) {
    //         // date is greater than maxdate
    //         return false;
    //       }
    //     }
    //   }

    //   // validate adults. integer, >=1
    //   if (!!this.$route.query.adults) {
    //     if (!Number.isInteger(+this.$route.query.adults)) {
    //       return false;
    //     }

    //     if (parseInt(this.$route.query.adults) < 1) {
    //       return false;
    //     }
    //   }

    //   // validate children. integer, >=0
    //   if (!!this.$route.query.children) {
    //     if (!Number.isInteger(+this.$route.query.children)) {
    //       return false;
    //     }

    //     if (parseInt(this.$route.query.children) < 0) {
    //       return false;
    //     }
    //   }

    //   if (this.guests > this.maxGuests) {
    //     // guests exceeded
    //     return false;
    //   }

    //   // validate nights. integer, >=1 && <=14
    //   if (!!this.$route.query.nights) {
    //     if (!Number.isInteger(+this.$route.query.nights)) {
    //       return false;
    //     }

    //     const nights = parseInt(this.$route.query.nights);
    //     if (nights < 1 || nights > 14) {
    //       return false;
    //     }
    //   }

    //   return true;
    // },
    /*
     * Destination is valid if not present or exists
     */
    validateDestination() {
      let hotelCodes = Object.keys(this.$store.state.property.hotels);
      
      return (
        !this.$route.query.destination ||
        !!hotelCodes.concat(this.destinationConstants).find((item) => item === this.$route.query.destination)
      );
    },
    urlNeedsNormalization() {
      return (
        typeof this.$route.query.dateFrom === "undefined" ||
        typeof this.$route.query.adults === "undefined" ||
        typeof this.$route.query.children === "undefined" ||
        typeof this.$route.query.nights === "undefined" ||
        typeof this.$route.query.destination === "undefined"
      );
    },
    /*
     * Set params defaults and redirect to normalized URL
     */
    async normalizeUrl() {
      let query = {};

      // get min of date from rate or options
      if (!this.$route.query.dateFrom) {
        const minDate = this.$dayjs(this.allowedRange.start, "MM/DD/YYYY");

        query["dateFrom"] =
          this.ratePlan?.start > this.allowedRange.end
            ? this.$dayjs(this.ratePlan?.start, 'MM/DD/YYYY').format("MM-DD-YYYY")
            : this.$dayjs(minDate).format("MM-DD-YYYY");

        query["nights"] = !!this.$route.query.nights ? this.$route.query.nights : 1;
      } else {
        query["dateFrom"] = this.$route.query.dateFrom;
      }

      if (!this.$route.query.nights) {
        query["nights"] = this.ratePlan?.minstay || 1;
      }

      query["adults"] = !!this.$route.query.adults ? this.$route.query.adults : 1;
      query["children"] = !!this.$route.query.children ? this.$route.query.children : 0;
      query["destination"] = !!this.$route.query.destination ? this.$route.query.destination : "ALL";
      if (!!this.searchParams.rateCode) query["rateCode"] = this.searchParams.rateCode;

      return this.$router.push({
        name: "hotelsFlex",
        query: query,
      });
    },
    /*
    async loadRateplans() {
      await this.$store.dispatch("property/fetchHotelsRatePlans", {
        property_code: PROPERTY_CODE,
        rateplan: this.rateCode,
      });
    },
    */
    /*
    ratePlansValid() {
      return typeof this.rateplans[this.rateCode] !== "undefined";
    },
    */
    async init() {
      // VALIDATE SEARCH PARAMS
      if (!this.searchParamsIsValid) {
        this.$router.push({
          name: "book_stay",
          params: { skipCartCheck: true }
        })
        return
      }

      // VALIDATE RATE
      if (this.searchParams.rateCode) {
        // Fetch rate plan
        this.isLoadingRatePlan = true;
        try {
          await this.$store.dispatch('property/fetchHotelsRatePlan', {
            rateCode: this.searchParams.rateCode,
            rateType: this.searchParams.rateType
          })
        } catch (e) {
          console.log(`Rate code (${this.searchParams.rateType}) is invalid ${e}`)
        }
        this.isLoadingRatePlan = false;

        // If not rate plan
        if (!this.ratePlan) {
          this.$bvModal.msgBoxConfirm('Sorry, but the rate code you used is not a valid discount code.', {
            title: 'Error',
            headerBgVariant: 'secondary',
            headerClass: 'close-button-black',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma ok-only',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Ok',
            okVariant: 'primary',
            hideHeaderClose: false,
            cancelVariant: 'outline-primary',
            buttonSize: 'lg',
            centered: true
          })
            .then(() => {
              this.$router.push({
                name: 'hotelsFlex',
                query: {
                  ...this.$route.query,
                  rateCode: undefined,
                  rateType: undefined
                }
              })
            })
          return
        }
      }

      /*
      if (!this.validateUrl()) {
        return this.$router.push({ name: "book_stay", params: { skipCartCheck: true }});

      } else if (!this.validateDestination()) {

        let query = {};
        for (const queryKey in this.$route.query) {
          query[queryKey] = this.$route.query[queryKey];
        }
        query["destination"] = "ALL";

        return this.$router.push({
          name: "hotelsFlex",
          query: query,
        });
      }
      */

      /*
      // load rates. in rate code invalid - go home
      await this.loadRateplans();
      if (!this.ratePlansValid()) {
        const that = this;

        // redirect to INTERNET rate
        this.setConfirmationModal({
          content: "Sorry, but the promo code you used is not a valid discount code.",
          header: "Error",
          confirm: "Ok",
          disableDecline: true,
          confirmOnClose: true,
        }).then(function() {
          that.$route.query.rateCode = "";
          return that.normalizeUrl();
        });
      }

      if (this.urlNeedsNormalization()) {

        return this.normalizeUrl();
      }

      const maxdate = this.bookingDatesWindow.max;

      if (maxdate) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        this.monthsAmount = this.$dayjs(maxdate).diff(today, "month") + 1;
      }
      
      localStorage.removeItem("step2");
      localStorage.setItem("step2", this.$route.fullPath);
      localStorage.removeItem("step2_type");
      localStorage.setItem("step2_type", "flex");
      */
     /*
      if (this.toursInlineEnabled) {
        let step4_fullPath = "/booking/activities-select" +
            `?dateFrom=${this.$route.query.dateFrom}&nights=1&destination=${
                this.$route.query.destination
            }&adults=${
                this.$route.query.adults
            }&children=${
                this.$route.query.children
            }${!!this.searchParams.rateCode
                ? '&rateCode=' + this.$route.query.rateCode
                : ""}`;
        localStorage.removeItem("step4");
        localStorage.setItem("step4", step4_fullPath);
      }
      */

      this.hotelType =
        this.$route.query.destination === "ALL-CAMPGROUNDS" ? "campground" : this.$route.query.destination === "ALL-HOTELS" ? "hotel" : "";

      const date = this.$dayjs().format("YYYY/MM") === this.activeMonth ? new Date(this.allowedRange.start).getDate() : 1;

      // this.hotels = await apiHotelList();
      this.isLoadingAvailability = true;
      console.log('this.isLoadingAvailability', this.isLoadingAvailability);
      if (this.groupMode) {
        await this.fetchAvailableHotelsForGroupCode();
      } else {
        await this.fetchAvailableHotels(this.activeMonth, date);
      }
      this.isLoadingAvailability = false;
      console.log('this.isLoadingAvailability', this.isLoadingAvailability);

      if (!this.$store.state.property.availableHotels) {
        const dateFrom = this.$dayjs(this.$route.query.dateFrom, 'MM-DD-YYYY').format("MM/DD/YYYY");
        const { date, limit } = this.groupModeDates ?? { date: dateFrom, limit: 31 };

        await this.$store.dispatch("property/fetchAvailableHotels", {
          date,
          limit,
          rate_code: this.searchParams.rateCode,
          is_group: this.searchParams.rateType === 'group'
        })
      }

      window.addEventListener("resize", this.onWindowResize);
      this.initScrollable();
      this.scrollToSelectedDestination();

      this.trackData();
    },
    trackData() {
      // track search
      const data = {
        ecommerce: {
          currencyCode: "USD",
          detail: [
            {
              actionField: { list: "Search" },
            },
            {
              products: [
                {
                  name: this.destination,
                },
              ],
            },
          ],
        },
        page: {
          type: "category",
          environment: "production",
        },
      };

      gtmTrack(data);
    },
    onScroll(event) {
      if (!event.target._prevClass.includes('scroll-top')) {
        let scrollTop = document.querySelector('#scrollTopContainer');
        let scrollBottom = document.querySelector('#scrollContainer');
        scrollTop.scrollTo(scrollBottom.scrollLeft,0);
        if (scrollBottom.scrollLeft === this.maxShift) {
          this.maxScroll = true
        } else if (scrollBottom.scrollLeft === 0) {
          this.maxScroll = false
        } else {
          this.maxScroll = 'enabled'
        }
      } else {
        let scrollTop = document.querySelector('#scrollTopContainer');
        let scrollBottom = document.querySelector('#scrollContainer');
        scrollBottom.scrollTo(scrollTop.scrollLeft,0);
        if (scrollTop.scrollLeft === this.maxShift) {
          this.maxScroll = true
        } else if (scrollTop.scrollLeft === 0) {
          this.maxScroll = false
        } else {
          this.maxScroll = 'enabled'
        }
      }
      // if (this.$refs.scrollableContainer || this.$refs.scrollTop) {
      //   if (!event.target._prevClass.includes('scroll-top')) {
      //     this.$refs.scrollTop.scrollLeft = this.$refs.scrollableContainer.scrollLeft
      //     this.shift = this.$refs.scrollTop.scrollLeft
      //   } else {
      //     this.$refs.scrollableContainer.scrollLeft = this.$refs.scrollTop.scrollLeft
      //     this.shift = this.$refs.scrollableContainer.scrollLeft
      //   }
      // }
    },
    onWindowResize() {
      this.initScrollable();
    },
    initScrollable() {
      this.maxShift =
        this.$refs.scrollableContainer && this.$refs.scrollableArea
          ? this.$refs.scrollableArea.offsetWidth - this.$refs.scrollableContainer.offsetWidth
          : 0;
    },
    async fetchAvailableHotels(newMonth, date = 1) {
      return new Promise((resolve) => {
        //console.log("fetchAvailableHotels::1", this.minStay);
        const avail = apiAvailabilityHotels({
          date: this.$dayjs(newMonth).date(date).format("MM/DD/YYYY"),
          limit: 31,
          rate_code: this.searchParams.rateCode,
          is_group: this.searchParams.rateType === 'group',
          nights: this.searchParams.nights,
          children: this.searchParams.children
        });
        resolve(avail);
      })
        .then((avail) => {
          // console.log("fetchAvailableHotels::2", avail, this.monthIndex);
          this.$set(this.priceCalendar, newMonth, {});
          for (const dateKey in avail.availability) {
            // limit by current month only and booking dates window
            if (
              this.$dayjs(dateKey).format('YYYY/MM') === this.activeMonth &&
              this.$dayjs(dateKey).isBetween(this.allowedRange.start, this.allowedRange.end, 'day', '[]')
            ) {
              this.$set(this.priceCalendar[newMonth], dateKey, {});
              for (const hotelCode in avail.availability[dateKey]) {
                // filter by type
                let isFiltered = !this.hotelType || this.hotels[hotelCode].type === this.hotelType;
                if (isFiltered) {
                  const cancelBeforeDate = this.getCancelBeforeDateForDate(dateKey);
                  const cancelationAvailable = cancelBeforeDate > new Date();
                  const isGroupExtended = this.getIsGroupExtended(hotelCode, dateKey);
                  this.$set(this.priceCalendar[newMonth][dateKey], hotelCode, avail.availability[dateKey][hotelCode]);
                  this.$set(this.priceCalendar[newMonth][dateKey][hotelCode], "visible", false);
                  this.$set(this.priceCalendar[newMonth][dateKey][hotelCode], "available", this.available(dateKey, hotelCode));
                  this.$set(this.priceCalendar[newMonth][dateKey][hotelCode], "cancelBeforeDate", cancelBeforeDate);
                  this.$set(this.priceCalendar[newMonth][dateKey][hotelCode], "cancelationAvailable", cancelationAvailable);
                  this.$set(this.priceCalendar[newMonth][dateKey][hotelCode], "isGroupExtended", isGroupExtended);
                }
                //console.log("fetchAvailableHotels::3", this.priceCalendar);
              }
            }
          }
        })

        .catch((e) => {
          console.error(e);
        });
    },
    async fetchAvailableHotelsForGroupCode() {
      const { date, limit } = this.groupModeDates;

      const resp = await apiAvailabilityHotels({
        date,
        limit,
        rate_code: this.searchParams.rateCode,
        is_group: this.searchParams.rateType === 'group',
        nights: this.searchParams.nights,
        children: this.searchParams.children,
      });

      this.groupAvailability = {};
      const startDate = this.$dayjs(date, 'MM/DD/YYYY').toDate();
      const endDate = this.$dayjs(startDate).add(limit, 'day').toDate();

      for (const dateKey in resp.availability) {
        const isGroupDate = this.$dayjs(dateKey, 'MM/DD/YYYY').isBetween(startDate, endDate, 'day', '[)');

        if (isGroupDate) {
          this.$set(this.groupAvailability, dateKey, {});
          for (const hotelCode in resp.availability[dateKey]) {
            const isFiltered = (
              // is filtered
              (!this.hotelType || this.hotels[hotelCode].type === this.hotelType) &&
              // in group plan
              hotelCode in this.ratePlan.hotels
            );
            if (isFiltered) {
              const cancelBeforeDate = this.getCancelBeforeDateForDate(dateKey);
              const cancelationAvailable = cancelBeforeDate > new Date();
              const isGroupExtended = this.getIsGroupExtended(hotelCode, dateKey);
  
              // filter out all rates except for group ratePlan
              const dateHotelAvailability = {
                ...resp.availability[dateKey][hotelCode],
                rates: {
                  [this.ratePlan?.code]: resp.availability[dateKey][hotelCode].rates[this.ratePlan?.code]
                },
                rates2: {
                  [this.ratePlan?.code]: resp.availability[dateKey][hotelCode].rates2[this.ratePlan?.code]
                },
              }
  
              this.$set(this.groupAvailability[dateKey], hotelCode, dateHotelAvailability);
              this.$set(this.groupAvailability[dateKey][hotelCode], 'visible', false);
              this.$set(this.groupAvailability[dateKey][hotelCode], 'available', this.available(dateKey, hotelCode));
              this.$set(this.groupAvailability[dateKey][hotelCode], 'cancelBeforeDate', cancelBeforeDate);
              this.$set(this.groupAvailability[dateKey][hotelCode], 'cancelationAvailable', cancelationAvailable);
              this.$set(this.groupAvailability[dateKey][hotelCode], 'isGroupExtended', isGroupExtended);
            }
          }
        }
      }
    },
    //callback(val) {
    //console.log(val);
    //},
    /*
    beforeCarouselChange(from, to) {
      this.carouselIndex = to;
      this.monthIndex = this.$dayjs(new Date().setMonth(to + new Date(this.today).getMonth(), 1)).format("YYYY/MM");
      //console.log(to, new Date(this.today).getMonth(), this.monthIndex, this.carouselIndex);

      const minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
      const date = this.$dayjs().format("YYYY/MM") === this.monthIndex ? new Date(this.today).getDate() : 1;
      const dateForUrl = this.$dayjs(new Date().setMonth(to + new Date(this.today).getMonth(), date)).format("MM-DD-YYYY")

      const dateForUrlFormat = this.$dayjs(dateForUrl, "MM-DD-YYYY", true);
      if (!this.priceCalendar[this.monthIndex]) this.fetchAvailableHotels(this.monthIndex, date);

      const query = {};

      query["dateFrom"] = dateForUrlFormat.toDate() < minDate.toDate() ? this.$dayjs(minDate.toDate()).format("MM-DD-YYYY") : dateForUrl
      query["adults"] = !!this.$route.query.adults ? this.$route.query.adults : 1;
      query["children"] = !!this.$route.query.children ? this.$route.query.children : 0;
      query["nights"] = this.nights
      query["destination"] = !!this.$route.query.destination ? this.$route.query.destination : "ALL";
      if (!!this.$route.query.rateCode) query["rateCode"] = this.$route.query.rateCode;
      console.log('QUERRRYYY', query, dateForUrl, minDate);
      this.$router.push({
        name: "hotelsFlex",
        query: query
      });

      //console.log(this.monthIndex)
    },
    */
    // setQty(type, hotelCode, date) {
    //   if (type === "plus") {
    //     if (this.nights < 14) {
    //       this.nights += 1;
    //     }
    //   }
    //   if (type === "minus") {
    //     if (this.nights > 1) {
    //       this.nights -= 1;
    //     }
    //   }

    //   // let pricePrevNights = 0
    //   // for (let i = 1;i <= this.nights; i++) {
    //   //   pricePrevNights += this.hotelAvail.currentHotel[Object.keys(this.hotelAvail.currentHotel)[0]].perNight[i].mins[this.$route.query.adults]
    //   // }

    //   this.finalPrice = this.hotelAvail.currentHotel[Object.keys(this.hotelAvail.currentHotel)[0]].perNight[this.nights].mins[this.$route.query.adults] * this.nights
    //   this.prevFinalPrice = this.finalPrice


    //   // this.finalPrice =
    //   //   this.currentMonth[
    //   //     this.$dayjs(date)
    //   //       .add(this.nights - 1, "d")
    //   //       .format("MM/DD/YYYY")
    //   //   ][hotelCode].rates[this.rateCodeFromRate].mins[this.$route.query.adults] * this.nights;
    // },
    hidePopover(hotelCode, date) {
      // const ratePlan = this.ratePlans[rateCode]

      this.$router.push({
        name: "hotelPage",
        query: {
          dateFrom: this.$dayjs(date).format("MM-DD-YYYY"),
          nights: this.searchParams.nights,
          destination: hotelCode,
          adults: this.searchParams.adults,
          children: this.searchParams.children,
          rateCode: this.searchParams?.rateCode,
          rateType: this.searchParams?.rateType,
        },
      });
      this.visible = false;
    },
    // async visibleChange(hotelCode, date) {
    //   //console.log("visibleChange", date)
    //   this.nights = parseInt(this.$route.query.nights);
    //   this.loading = true
    //   await new Promise((resolve) => {
    //     //console.log("fetchAvailableHotels::1", this.minStay);
    //     const avail = apiAvailabilityHotelsMonthly(hotelCode,{
    //       date: this.$dayjs(date).add(this.nights - 1, "d").format("MM/DD/YYYY"),
    //       limit: 31,
    //       rate_code: this.$route.query.rateCode,
    //       nights: this.nights,
    //     });
    //     resolve(avail);
    //   })
    //   .then((avail) => {
    //     const dateCurrent = this.$dayjs(date).add(this.nights - 1, "d").format("MM/DD/YYYY")
    //     console.log('ddddddddddddddddddddddd', avail);
    //     this.$set(this.hotelAvail, 'currentHotel', avail.availability[dateCurrent][Object.keys(avail.availability[dateCurrent])[0]].rates)
    //     this.loading = false
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //     this.loading = false
    //   });
    //   console.log('this.hotelAvail', this.hotelAvail.currentHotel , this.nights, this.$route.query.adults);
    //   // console.log('HOTE< AVAAAIL', this.hotelAvail.currentHotel[Object.keys(this.hotelAvail.currentHotel)[0]]);
    //   this.isAvail = this.hotelAvail.currentHotel[Object.keys(this.hotelAvail.currentHotel)[0]].perNight[this.nights].available.length || this.hotelAvail.currentHotel[Object.keys(this.hotelAvail.currentHotel)[0]].perNight[this.nights].available[this.$route.query.adults] ? true: false
    //   this.finalPrice = this.hotelAvail.currentHotel[Object.keys(this.hotelAvail.currentHotel)[0]].perNight[this.nights].mins[this.$route.query.adults] * this.nights
    //   this.prevFinalPrice = this.finalPrice


    //   console.log('this.$route.query.rateCode',this.rateCodeFromRate);

    //   // this.finalPrice = this.currentMonth[date][hotelCode].rates[this.rateCodeFromRate].mins[this.$route.query.adults] * this.nights;
    //   this.maxNights = 0;
    //   let maxNights = 0;
    //   for (
    //     let i = 0, currentDate = date;
    //     this.$get(this.currentMonth, `${currentDate}.${hotelCode}.min`) > 0 && i < 14;
    //     i++,
    //       currentDate = this.$dayjs(date)
    //         .add(i, "d")
    //         .format("MM/DD/YYYY")
    //   ) {
    //     maxNights++;
    //   }
    //   this.maxNights = maxNights;
    // },
    available(date, hotelCode) {
      //console.log("available", date, hotelCode, this.currentMonth, this.rateCode, this.rateCodeFromRate);
      if (
        !Object.keys(this.currentPage[date][hotelCode].rates).length ||
        typeof this.currentPage[date][hotelCode].rates[this.currentPage[date][hotelCode].perGuests[1].r] === "undefined" ||
        !this.currentPage[date][hotelCode].rates[this.currentPage[date][hotelCode].perGuests[1].r].available
        // this.currentMonth[date][hotelCode].rates[this.currentMonth[date][hotelCode].perGuests[1].r].available[1]
      ) {
        return 0;
      }
      if (
        this.currentPage[date][hotelCode].perGuests[1]?.m.p &&
        !this.currentPage[date][hotelCode].perGuests[this.guests]


        // !Object.keys(this.currentMonth[date][hotelCode].rates).length ||
        // typeof this.currentMonth[date][hotelCode].rates[this.currentMonth[date][hotelCode].perGuests[1].r] === "undefined" ||
        // !this.currentMonth[date][hotelCode].rates[this.currentMonth[date][hotelCode].perGuests[1].r].available
      ) {
        return "MAX OCCUPANCY EXCEEDED";
        // return 0;
      }

      if (
        Object.keys(this.currentPage[date][hotelCode].rates[this.currentPage[date][hotelCode].perGuests[1].r].available).length &&
        !!this.currentPage[date][hotelCode].rates[this.currentPage[date][hotelCode].perGuests[1].r].available[this.guests] &&
        Object.keys(this.currentPage[date][hotelCode].perGuests[this.guests].m.p) &&
        !!this.currentPage[date][hotelCode].perGuests[this.guests].a
      ) {
        return this.currentPage[date][hotelCode].perGuests[this.guests].a;
      }

      // return "MAX OCCUPANCY EXCEEDED";
    },

    getCancelBeforeDateForDate(dateKey) {
      const settings = this.options.cancelBeforeHotels?.find(i => (
        this.$dayjs(dateKey, 'MM/DD/YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY').subtract(1, 'day'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(dateKey, 'MM/DD/YYYY')
        .subtract(value, 'day')
        .toDate();

      return cancelBeforeDate;
    },

    getIsGroupExtended(hotelCode, dateKey) {
      if (this.groupMode) {
        const block = _.chain(this.ratePlan.hotels[hotelCode])
          .find(i => (
            this.$dayjs(dateKey, 'MM/DD/YYYY').isBetween(
              this.$dayjs(i.start, 'MM/DD/YYYY'),
              this.$dayjs(i.end, 'MM/DD/YYYY').subtract(1, 'day'),
              'day',
              '[]'
            )
          ))
          .value()

        if (block) {
          const isGroupExtended = !this.$dayjs(dateKey, 'MM/DD/YYYY').isBetween(
            this.$dayjs(block.startDate, 'MM/DD/YYYY'),
            this.$dayjs(block.endDate, 'MM/DD/YYYY').subtract(1, 'day'),
            'day',
            '[]'
          );

          return isGroupExtended;
        }
      }

      return false;
    },

    scrollToSelectedDestination() {
      if (this.scrollToSelectedDestination) {
        const destinationElement = this.$el.querySelector('.active-hotel');
        setTimeout(() => {
          destinationElement?.scrollIntoView({
            inline: 'center',
            block: 'center',
          });
        });
      }
    },
  },
  computed: {
    ...optionsComputed,
    ...mapGetters({
      ratePlans: "property/hotelsRatePlans",
      hotels: 'property/hotels',
      options: 'app/options',
    }),
    searchParams: {
      get() {
        return {
          destination: this.$route.query.destination,
          dateFrom: this.$route.query.dateFrom,
          nights: parseInt(this.$route.query.nights) || 1,
          adults: parseInt(this.$route.query.adults) || 0,
          children: parseInt(this.$route.query.children) || 0,
          rateCode: this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : null,
          rateType: this.$route.query.rateType
        }
      },

      set(value) {
        this.$router.push({ name: "hotelsFlex", query: value})
      }
    },

    allowedRange() {
      let start = this.bookingDatesWindow.min
      let end = this.bookingDatesWindow.max

      if (this.ratePlan?.category === 'GROUP') {
        if (this.ratePlan.start) {
          start = this.$dayjs.max([
            start,
            this.$dayjs(this.ratePlan.start)
          ]).format('MM/DD/YYYY')
        }

        if (this.ratePlan.end) {
          end = this.$dayjs.min([
            end,
            this.$dayjs(this.ratePlan.end).subtract(1, 'day')
          ]).format('MM/DD/YYYY')
        }
      }

      return { start, end }
    },

    searchParamsIsValid() {
      // numeric params
      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.nights ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // date
      const dateFrom = this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY')

      if (
        !dateFrom.isValid() ||
        this.searchParams.dateFrom !== dateFrom.format('MM-DD-YYYY')
      ) {
        console.log(`Param dateFrom (${this.searchParams.dateFrom}) invalid`)
        return false
      }

      if (!this.$dayjs(dateFrom).isBetween(this.allowedRange.start, this.allowedRange.end, 'day', '[]')) {
        console.log(`Param dateFrom (${this.searchParams.dateFrom}) is not within allowedRange`)
        return false
      }

      // nights
      if (this.searchParams.nights < 0 || this.searchParams.nights > 14) {
        console.log(`Param nights (${this.searchParams.nights}) invalid`)
        return false
      }

      // guests
      if (this.searchParams.adults < 1) {
        console.log(`Param adults (${this.searchParams.adults}) invalid`)
        return false
      }

      if (this.searchParams.children < 0) {
        console.log(`Param children (${this.searchParams.children}) invalid`)
        return false
      }

      if (this.searchParams.adults + this.searchParams.children > parseInt(this.options.resformMaxOccupancy)) {
        console.log(`Params adults + children (${this.searchParams.adults} + ${this.searchParams.children}) invalid`)
        return false
      }

      // destination
      if (!this.validateDestination()) {
        console.log('Destination is not valid')
        return false;
      }

      return true
    },

    activeMonth: {
      get() {
        return this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').format("YYYY/MM")
      },

      set(value) {
        const dateFrom = this.$dayjs.max(
          this.$dayjs(value, 'YYYY/MM'),
          this.$dayjs(this.allowedRange.start)
        ).format('MM-DD-YYYY')

        this.searchParams = { ...this.searchParams, dateFrom }
      }
    },

    guests() {
      return this.searchParams.adults + this.searchParams.children;
      // return parseInt(+this.$route.query.adults + +this.$route.query.children);
    },
    maxGuests() {
      return parseInt(this.$store.state.app.options.resformMaxOccupancy);
    },
    /*
    minStay() {
      //console.log("minStay", this.rateplans, Object.values(this.rateplans), Object.values(this.rateplans)[0]);
      if (this.ratePlans) {
        return Object.values(this.ratePlans)[0].minstay;
      } else {
        return 1;
      }
    },
    */
    monthTitles() {
      let titles = [];
      for (let i = 0; i < this.monthsAmount; i++) {
        titles.push(
          this.$dayjs(this.today)
            .add(i, "M")
            .format("MMMM YYYY")
        );
      }
      return titles;
    },
    destination() {
      return this.$route.query.destination;
    },
    hotelsTitle() {
      let hotels = _.map(this.hotels);

      // filter by type
      const type = this.destination === "ALL-CAMPGROUNDS" ? "campground" : this.destination === "ALL-HOTELS" ? "hotel" : "";
      if (type) {
        hotels = hotels.filter(i => i.type === type);
      }

      if (this.groupMode) {
        hotels = hotels.filter(i => i.code in this.ratePlan.hotels)
      }

      return hotels;
    },
    currentPage() {
      if (this.groupMode) {
        return this.groupAvailability;
      }
      return this.priceCalendar && this.priceCalendar[this.activeMonth];
    },
    noRateMessage() {
      const rateCode = this.searchParams.rateCode || "INTERNET";
      let noRate = false;

      if (this.selectedHotel) {
        noRate = (
          // current rate is not available for all dates and selected hotel
          !_.find(this.currentPage, (hotelsAvailability) => (
            hotelsAvailability[this.selectedHotel.code].rates[rateCode]?.available[this.guests] > 0
          )) &&
          // another rate is available
          !!_.find(this.currentPage, (hotelsAvailability) => (
            hotelsAvailability[this.selectedHotel.code].perGuests[this.guests]?.a > 0
          ))
        );
      } else {
        noRate = (
          // current rate is not available for all dates and all hotels
          !_.find(this.currentPage, (hotelsAvailability) => (
            !!_.find(hotelsAvailability, (hotelAvailability) => (
              hotelAvailability.rates[rateCode]?.available[this.guests] > 0
            ))
          )) &&
          // another rate is available
          !!_.find(this.currentPage, (hotelsAvailability) => (
            !!_.find(hotelsAvailability, (hotelAvailability) => (
              hotelAvailability.perGuests[this.guests]?.a > 0
            ))
          ))
        );
      }

      return noRate && "We're sorry. This rate is not available on your selected dates. However, other rates are available at the following hotels.";

      //console.log(this.currentMonth)
      // let filteredDates = !!this.currentPage
      //   ? Object.values(this.currentPage).filter((date) => {
      //       return !!Object.values(date).filter((hotel) => {
      //         return hotel.perGuests[this.guests] && (hotel.perGuests[this.guests].r === rateCode || rateCode === 'INTERNET') && hotel.available !== 0 && hotel.available !== "MAX OCCUPANCY EXCEEDED"
      //         // return Object.keys(hotel.rates).find(
      //         //   (rate) => rate === rateCode && hotel.available !== 0 && hotel.available !== "MAX OCCUPANCY EXCEEDED"
      //         // );
      //       }).length;
      //     })
      //   : [];
      // //console.log(filteredDates);
      // //console.log(this.currentPage);

      // return !!this.currentPage && !filteredDates.length
      //   ? "We're sorry. This rate is not available on your selected dates. However, other rates are available at the following hotels."
      //   : false;
    },
    calendarMinWidth() {
      return this.hotelsTitle ? Object.keys(this.hotelsTitle).length * (155 + 20) + 90 : 0;
    },
    calendarMinWidthStyle() {
      return `min-width: ${this.calendarMinWidth}px`;
    },
    scrollEnabled() {
      return this.maxShift > 0;
    },
    scrollableClasses() {
      let classes = [];

      if (this.scrollEnabled) {
        classes.push("enabled");
      }

      if (this.maxScroll === false) {
        classes.push("scrolled-left");
      }

      if (this.maxScroll === true) {
        classes.push("scrolled-right");
      }

      return classes.join(" ");
    },
    selectedHotel() {
      return this.hotels[this.searchParams.destination] ?? null;
    },
    ratePlan() {
      if (this.searchParams.rateCode) {
        return this.ratePlans[this.searchParams.rateCode]
      }
      return null
    },
    ratePlanHotelDetails() {
      return this.ratePlan && this.selectedHotel && this.ratePlan.details[this.selectedHotel.code];
    },
    ratePlanTitle() {
      if (this.ratePlanHotelDetails) {
        return this.groupMode ? this.ratePlanHotelDetails[0]?.title : this.ratePlanHotelDetails.title;
      }
      return null;
    },
    ratePlanDescription() {
      if (this.ratePlanHotelDetails) {
        return this.groupMode ? this.ratePlanHotelDetails[0]?.description : this.ratePlanHotelDetails.description;
      }
      return null;
    },
    groupMode() {
      return this.ratePlan?.category === 'GROUP';
    },
    groupModeDates() {
      if (this.groupMode) {
        const date = this.ratePlan.start;

        const limit = this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY')
          .diff(this.$dayjs(date, 'MM/DD/YYYY')
          .toDate(), 'day');
        
        return { date, limit };
      }
    },
    multiNightAlert() {
      return 'Please note: Availability results displayed show possible arrival dates with the same room type based upon the # of nights requested, reflecting the average nightly rate. Available rooms and rates may vary for different lengths of stay.';
    },
    /*
    minDateFromRate() {
      const minDate = Object.values(this.ratePlan).reduce((min, currentHotel) => {
        return Math.min(new Date(min), new Date(currentHotel.start));
      }, this.ratePlan[Object.keys(this.ratePlan)[0]].start);

      return minDate;
    },
    */
    /*
    nightsFromRate() {
      return Object.values(this.ratePlan)[0].minstay;
    },
    */
    /*
    rateCodeFromRate() {
      return typeof this.ratePlans[this.searchParams.rateCode] !== "undefined" ? this.searchParams.rateCode : "INTERNET";
    },
    */
    currentPagePrices() {
      return mapValues(this.currentPage, (day) => (
        mapValues(day, (hotelDayAvailability) => {
          const nights = this.searchParams.nights
          const adults = this.searchParams.adults;
          const hotelDayAvailabilityPerGuests = hotelDayAvailability?.perGuests[adults];

          const prices = getPrices({
            base: hotelDayAvailabilityPerGuests?.m.p,
            fees: hotelDayAvailabilityPerGuests?.m.f,
            taxes: hotelDayAvailabilityPerGuests?.m.t,
            nights,
          });

          return prices;
        })
      ));
    },

    currentPageOldPrices() {
      return mapValues(this.currentPage, (day) => (
        mapValues(day, (hotelDayAvailability) => {
          const nights = this.searchParams.nights
          const adults = this.searchParams.adults;
          const hotelDayAvailabilityPerGuests = hotelDayAvailability?.perGuests[adults];

          const prices = getPrices({
            base: hotelDayAvailabilityPerGuests?.b.p,
            fees: hotelDayAvailabilityPerGuests?.b.f,
            taxes: hotelDayAvailabilityPerGuests?.b.t,
            nights,
          });

          return prices;
        })
      ));
    },

    currentPageAvailabilityStatuses() {
      return mapValues(this.currentPage, (day) => (
        mapValues(day, (hotelDayAvailability) => {
          const adults = this.searchParams.adults;
          const hotelDayAvailabilityPerGuests = hotelDayAvailability?.perGuests[adults];
          const hotelDayAvailabilityPerOneGuest = hotelDayAvailability?.perGuests[1];

          if (hotelDayAvailabilityPerOneGuest?.s === 'closed') {
            return 'closed'
          } else if (hotelDayAvailabilityPerGuests?.a) {
            return 'available';
          } else if (hotelDayAvailabilityPerOneGuest?.a) {
            return 'occupancyExceeded';
          } else {
            return 'soldOut';
          }
        })
      ));
    },
  },
  watch: {
    "$route.fullPath": async function(value, oldValue) {
      if (value === oldValue) {
        return;
      }
      await this.init();
    },

    searchParams: {
      immediate: true,
      deep: true,
      handler() {
        if (this.searchParamsIsValid) {
          this.$store.dispatch('property/setLodgeSearchParams', {
            ...this.searchParams,
            type: 'flexible'
          })
        }
      }
    }
  },
};
