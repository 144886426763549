import _ from 'lodash'
import { mapGetters } from "vuex";
import { required, email, requiredIf } from "@vuelidate/validators";
import { apiBookingRequest } from "@/api/cart";
import {
  authComputed,
  appMethodsComputed,
  optionsComputed,
} from "@/store/helper";
import { apiSignIn, apiSignUp } from "@/api/auth";
import {
  password,
  creditcard,
  notCreditCard,
  zip,
  phone,
  general,
  name,
  address
} from "@shared/utils/validators";
import { unescapeString } from "@/utils/index";
import {useVuelidate} from "@vuelidate/core";

const terms = [
  {
    type: 'lodging',
    anchor: 'View Lodging Terms',
  },
  {
    type: 'group',
    anchor: 'View Group Terms',
  },
  {
    type: 'tour',
    anchor: 'View Tour Terms',
  }
]

export default {
  setup: () => ({v$: useVuelidate()}),
  props: {
    allowed: true,
    invalidCartMessage: ""
  },
  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      phone: "",
      city: "",
      country: 'US',
      state: undefined,
      zip: "",
      address1: "",
      address2: "",
      email: "",
      password: "",
      question: undefined,
      answer: "",
      cardNumber: "",
      expiresMonth: undefined,
      expiresYear: undefined,
      howDidYouHear: undefined,
      terms: false,
    },
    gift_card: false,
    form_submitted: false,
    loading: false,
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    errors: {},
  }),
  validations:  () => {
    return {
      form: {
        firstName: { required, name, notCreditCard }, // 1,30
        lastName: { required, name, notCreditCard }, // 1,30
        phone: { required, phone, notCreditCard }, // 1,20
        city: { required, general, notCreditCard }, // 1,40
        country: { required, notCreditCard },
        state: { required, general, notCreditCard }, // 1,40
        zip: { required, zip, notCreditCard }, // 1,15
        address1: { required, address, notCreditCard }, // 1,40
        address2: { address, notCreditCard }, // 1,40
        email: { required, email },
        password: {
          required: requiredIf(function () {
            return !this.$store.state.auth.status;
          }),
          password,
        }, // 8,60
        question: {
          required: requiredIf(function () {
            return !this.$store.state.auth.status;
          }),
          notCreditCard
        },
        answer: {
          required: requiredIf(function () {
            return !this.$store.state.auth.status;
          }),
          general,
          notCreditCard
        },
        cardNumber: { required, creditcard },
        expiresMonth: { required },
        expiresYear: { required },
        howDidYouHear: { required },
        terms: {
          checked(val) {
            return val;
          },
        },
      },
    }
  },
  computed: {
    ...optionsComputed,
    ...authComputed,
    ...mapGetters({
      options: 'app/options',
      cartToken: 'property/cartToken',
      userInfo: "auth/userInfo",
      isUser: "auth/isUser",
      isAgency: 'auth/isAgency',
      addToReservation: 'app/addToReservation',
      tourCartIsEmpty: 'property/tourCartIsEmpty',
      groupCartIsEmpty: 'property/groupCartIsEmpty',
      nonGroupCartIsEmpty: 'property/nonGroupCartIsEmpty',
      cartIsEmpty: 'property/cartIsEmpty'
    }),

    countryList() {
      return this.$store.state.app.options.countries;
    },
    stateList() {
      return this.$store.state.app.options.states[this.form.country];
    },
    howDidYouHearOptions() {
      return this.$store.state.app.options.owsSources;
    },
    currentMonth() {
      var date = new Date();
      return date.getMonth();
    },
    currentYear() {
      var date = new Date();
      return parseInt(date.getFullYear());
    },
    lastYear() {
      return this.currentYear + 10;
    },
    expirationMonthOptions() {
      var date = new Date();
      let options = [];

      if (this.form.expiresYear === this.currentYear) {
        for (let i = this.currentMonth; i < 12; i++) {
          date.setMonth(i);
          options.push({
            label: this.monthNames[i],
            value: i + 1,
          });
        }
      } else if (this.form.expiresYear === this.lastYear) {
        for (let i = 0; i < this.currentMonth; i++) {
          date.setMonth(i);
          options.push({
            label: this.monthNames[i],
            value: i + 1,
          });
        }
      } else {
        for (let i = 0; i < 12; i++) {
          date.setMonth(i);
          options.push({
            label: this.monthNames[i],
            value: i + 1,
          });
        }
      }

      return options;
    },
    expirationYearOptions() {
      let options = [];

      for (let i = 0; i < 11; i++) {
        options.push({
          value: this.currentYear + i,
          label: this.currentYear + i,
        });
      }

      return options;
    },
    questions() {
      return this.$store.state.app.options.accountQuestions;
    },
    allowPlaceOrder() {
      return !this.loading && this.allowed;
    },
    activeTermTypes() {
      let terms = [];
      if (!this.groupCartIsEmpty) {
        terms.push('group');
      }
      if (!this.nonGroupCartIsEmpty) {
        terms.push('lodging');
      }
      if (!this.tourCartIsEmpty) {
        terms.push('tour');
      }
      return terms;
    },
    activeTerms() {
      return terms.filter((i) => this.activeTermTypes.includes(i.type));
    },
  },
  watch: {
    "$store.state.auth.user"() {
      this.setFormValues();
    },
    "form.expiresYear"() {
      const month = this.expirationMonthOptions.filter(
        (month) => month.value === this.form.expiresMonth
      );

      if (month.length < 1) {
        this.form.expiresMonth = undefined;
      }
    },
    stateList: {
      immediate: true,
      handler() {
        if (this.stateList && this.form.state && !this.stateList[this.form.state]) {
          this.form.state = _.findKey(
            this.stateList,
            (k, v) => k === this.form.state || v === this.form.state,
          )
        }
      }
    }
  },
  methods: {
    ...appMethodsComputed,
    unescapeString,
    logout() {
      this.$auth.silentLogout();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCountryOptionSelect(value) {
      this.form.country = value;
      this.state = null;
    },
    handleStateOptionSelect(value) {
      this.form.state = value;
    },
    setFormValues() {
      if (this.isUser) {
        this.form.email = this.form.email || this.userInfo.email
        this.form.address1 = this.form.address1 || this.userInfo.address1
        this.form.address2 = this.form.address2 || this.userInfo.address2
        this.form.city = this.form.city || this.userInfo.city
        this.form.state = this.form.state || this.userInfo.state
        this.form.country = this.form.country || this.userInfo.country
        this.form.zip = this.form.zip || this.userInfo.zip
        this.form.phone = this.form.phone || this.userInfo.phone
        this.form.firstName = this.form.firstName || this.userInfo.firstName
        this.form.lastName = this.form.lastName || this.userInfo.lastName

      } else if (this.isAgency && this.addToReservation) {
        this.form.email = this.form.email || this.addToReservation.email
        this.form.address1 = this.form.address1 || this.addToReservation.address.address1
        this.form.address2 = this.form.address2 || this.addToReservation.address.address2
        this.form.city = this.form.city || this.addToReservation.address.city
        this.form.state = this.form.state || this.addToReservation.address.state
        this.form.country = this.form.country || this.addToReservation.address.country
        this.form.zip = this.form.zip || this.addToReservation.address.zip
        this.form.phone = this.form.phone || this.addToReservation.phone
        this.form.firstName = this.form.firstName || this.addToReservation.address.firstName
        this.form.lastName = this.form.lastName || this.addToReservation.address.lastName
      }
    },
    async onSubmit() {
      if (this.addToReservation) {
        this.form.reservation_token = this.addToReservation.token;
      }

      this.errors = {};
      this.v$.$touch();

      if (this.v$.$invalid) return;

      // remember how did you hear about us answer
      this.putToStorage({ how_did_you_hear: this.form.howDidYouHear });

      if (!this.isLoggedIn) {
        // try to log in
        await this.login();

        if (!this.isLoggedIn && this.addToReservation) {
          const message = `Invalid username or password. You have to login with your credentials to add new items to booking #${this.addToReservation.uniqueId}`
          this.$bvToast.toast(message, {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
          this.setModal('Login')
          return
        }
      }

      if (!this.isLoggedIn) {
        // try to sign up
        await this.signup();
      }

      if (this.isLoggedIn) {
        // trying to place an order
        await this.placeOrder();
      }
    },
    async placeOrder() {
      try {
        this.startPlaceOrder();
        if (!this.cartToken) {
          await this.$store.dispatch("property/fetchCartToken");
        }

        const res = await apiBookingRequest(this.cartToken, this.form)

        if (res.reservation_token) {
          this.$store.dispatch("app/endAddToReservation")
          // this.$store.dispatch("property/clearReservationsList")
          this.$store.dispatch("property/clearCartLocally")
          this.$store.dispatch('property/setLastReservationKeys', {
            uniqueId: res.reservation_unique_id,
            token: res.reservation_token
          })

          if (this.isUser) {
            await this.$store.dispatch("auth/updateUserInfo", { type: "address", payload: this.form })

            await this.$store.dispatch("auth/updateUserInfo", {
              type: "communication",
              payload: {
                phone: this.form.phone,
                email: this.form.email,
              },
            })
          }

          this.$router.push({
            name: "ConfirmationPage",
            query: {
              reservation: res.reservation_unique_id,
              legs: res.legs.join("."),
            }
          })
        }

      } catch (error) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
        this.$store.dispatch('app/setErrors')
        const cardErrorMessage = _.get(error, 'response.data.validation_messages.cardNumber.invalidNumber')
        const reservationTokenMessage = _.get(error, 'response.data.validation_messages.reservation_token.access_denied')
        const cartTokenEmptyMessage = _.get(error, 'response.data.validation_messages.cart_token.empty')
        const detailMessage = _.get(error, 'response.data.detail')
        // const cartItemsExpired = detailMessage === 'Sorry but some items in your cart have expired or have become unavailable. Please review your cart.'
        const cartItemsExpired = ['EXPIRED', 'REVIEW'].includes(error.response.data.result)
        console.log('cartTokenEmptyMessage', cartTokenEmptyMessage)
        console.log('detailMessage', detailMessage)

        const messages = [cardErrorMessage, reservationTokenMessage, cartTokenEmptyMessage]

        // Cart token empty
        if (cartTokenEmptyMessage) {
          await this.refreshCart()
        }

        // Cart Items Expired
        if (cartItemsExpired) {
          // @TODO move all options to bootstrap settings
          this.$bvModal.msgBoxConfirm(error.response.data.detail, {
            title: 'Error',
            headerBgVariant: 'secondary',
            headerClass: 'close-button-black',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma ok-only',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Ok',
            okVariant: 'primary',
            hideHeaderClose: false,
            cancelVariant: 'outline-primary',
            buttonSize: 'lg',
            centered: true
          })
            .then(async () => {
              await this.refreshCart()
            })
        }

        _.each(messages, i => {
          if (i) {
            this.$bvToast.toast(i, {
              title: 'Error',
              variant: 'danger',
              solid: true
            })
          }
        })
      } finally {
        this.endPlaceOrder();
      }
    },
    async login() {
      try {
        this.startPlaceOrder();

        const res = await apiSignIn(this.form.email, this.form.password, {
          silent: true,
        });
        this.$auth.login(
          res.access_token,
          res.refresh_token,
          res.expires_in,
          res.token_type
        );
      } catch (error) {
        // Catch error
      } finally {
        this.endPlaceOrder();
      }
    },
    async signup() {
      try {
        this.startPlaceOrder();

        const data = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          password: this.form.password,
          question: this.form.question,
          answer: this.form.answer,
        };

        const res = await apiSignUp(data);
        this.$auth.login(
          res.access_token,
          res.refresh_token,
          res.expires_in,
          res.token_type
        );
      } catch (error) {
        if (
          error.response.data.detail.indexOf("username already exists") !== -1
        ) {
          this.errors = {
            ...this.errors,
            ...this.$store.state.app.errors,
            email: [error.response.data.detail],
          };
        } else {
          this.errors = { ...this.errors, ...this.$store.state.app.errors };
        }
      } finally {
        this.endPlaceOrder();
      }
    },
    onLogin() {
      this.setFormValues();
    },
    onTnCView(termType) {
      this.setModal('TermsAndConditions', { termType })
    },
    startPlaceOrder() {
      this.$emit("onSeLoading", true);
    },
    endPlaceOrder() {
      this.$emit("onSeLoading", false);
    },

    async refreshCart() {
      await this.$store.dispatch("property/fetchCart")

      if (this.options.toursEnabled) {
        await this.$store.dispatch("property/fetchTourCart")
      }

      if (this.cartIsEmpty) {
        this.$router.push({ name: "home" })
      }
    }
  },
  created() {
    this.setFormValues();

    // populate how did you hear about us answer
    this.form.howDidYouHear =
      this.storage && this.storage.how_did_you_hear
        ? this.storage.how_did_you_hear
        : undefined;

    this.$eventHub.$on("login", this.onLogin);
  },
};
