export function generateAddressString(params) {
  const addressItems = []
  // Name
  const nameItems = []
  if (params.firstName) {
    nameItems.push(params.firstName)
  }
  if (params.lastName) {
    nameItems.push(params.lastName)
  }
  const name = nameItems.join(' ')
  if (name) {
    addressItems.push(name)
  }
  // Address
  if (params.address1) {
    addressItems.push(params.address1)
  }
  if (params.address2) {
    addressItems.push(params.address2)
  }
  // City
  if (params.city) {
    addressItems.push(params.city)
  }
  // State
  if (params.state) {
    addressItems.push(params.state)
  }
  // ZIP
  if (params.zip) {
    addressItems.push(params.zip)
  }
  // Country
  if (params.country) {
    addressItems.push(params.country)
  }
  return addressItems.join(', ')
}