import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),

  props: {
    open: Boolean,
    type: String,
    max: Number,
    adults: Number,
    children: Number,
    infants: Number,
    animals: Number,
    parentComponent: String,
    minAgeChild: Number,
    minAgeAdult: Number,
    minAgeAdultsLabel: {
      type: String,
      default: ""
    },
    minAgeChildrenLabel: {
      type: String,
      default: ""
    },
    infantsAllowed: {
      type: Boolean,
      default: false,
    },
    animalsAllowed: {
      type: Boolean,
      default: false,
    },
    infantsText: {
      type: String,
      default: ''
    },
    animalsText: {
      type: String,
      default: ''
    },
    maxGuests: {
      type: Number,
      default: null
    },
    maxAdults: {
      type: Number,
      default: null
    },
    maxChildren: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    // defaults
    adultQty: 1,
    childrenQty: 0,
    infantQty: 0,
    animalQty: 0,
  }),
  methods: {
    setQty(type, key) {
      if (type === 'plus') {
        if (key === "adult" && this.adultQty < this.dynamicMaxAdults) {
          this.adultQty += 1;
        }
        if (key === "children" && this.childrenQty < this.dynamicMaxChildren) {
          this.childrenQty += 1;
        }
        if (key === "infants" && this.infantQty < this.dynamicMaxInfants) {
          this.infantQty += 1;
        }
        if (key === "animals" && this.animalQty < this.dynamicMaxAnimals) {
          this.animalQty += 1;
        }
      }

      if (type === "minus") {
        if (key === "adult" && this.adultQty > 1) this.adultQty -= 1;
        if (key === "children" && this.childrenQty > 0) this.childrenQty -= 1;
        if (key === "infants" && this.infantQty > 0) this.infantQty -= 1;
        if (key === "animals" && this.animalQty > 0) this.animalQty -= 1;
      }

      this.$emit("getAdults", this.adultQty);
      this.$emit("getChildren", this.childrenQty);
      this.$emit("getInfants", this.infantQty);
      this.$emit("getAnimals", this.animalQty);
    },
  },
  computed: {
    windowWidth() {
      return this.windowSize.width.value;
    },
    guestQty() {
      return this.adultQty + this.childrenQty + this.infantQty + this.animalQty;
    },
    dynamicMaxAdults() {
      if (this.maxGuests) {
        return this.maxGuests - this.childrenQty;
      } else if (this.maxAdults) {
        return this.maxAdults - this.guestQty + this.adultQty;
      }
      return 0;
    },
    dynamicMaxChildren() {
      if (this.maxGuests) {
        return this.maxGuests - this.adultQty;
      } else if (this.maxChildren) {
        return Math.min(this.maxAdults - this.guestQty + this.childrenQty, this.maxChildren);
      }
      return 0;
    },
    dynamicMaxInfants() {
      if (this.maxGuests) {
        return 0;
      }
      return Math.min(this.maxAdults - this.guestQty + this.infantQty, this.adultQty);
    },
    dynamicMaxAnimals() {
      if (this.maxGuests) {
        return 0;
      }
      return Math.min(this.maxAdults - this.guestQty + this.animalQty, this.adultQty);
    },

    maxQuantity() {
      return this.max;
    },
    guests() {
      return {
        adults: this.adults,
        children: this.children,
        infants: this.infants,
        animals: this.animals,
      }
    },
    value() {
      let adult = "";
      let children = "";
      let infant = "";
      let animal = "";
      if (this.adultQty === 0) adult += "";
      if (this.adultQty === 1) adult += `${this.adultQty} Adult`;
      if (this.adultQty > 1) adult += `${this.adultQty} Adults`;
      if (this.childrenQty === 0) children += "";
      if (this.childrenQty === 1) children += `${this.childrenQty} Child`;
      if (this.childrenQty > 1) children += `${this.childrenQty} Children`;
      if (this.infantsAllowed &&this.infantQty === 0) infant += "";
      if (this.infantsAllowed &&this.infantQty === 1) infant += `${this.infantQty} Infant`;
      if (this.infantsAllowed && this.infantQty > 1) infant += `${this.infantQty} Infants`;
      if (this.animalsAllowed && this.animalQty === 0) animal += "";
      if (this.animalsAllowed && this.animalQty === 1) animal += `${this.animalQty} Animal`;
      if (this.animalsAllowed && this.animalQty > 1) animal += `${this.animalQty} Animals`;
      // return !!this.parentComponent
      //   ? !!this.guests && `${adult}${adult && children ? ", " : ""}${children}`
      //   : `${adult}${adult && children ? ", " : ""}${children}`;
      return [adult, children, infant, animal].filter(i => i).join(', ');
    },
    label() {
      return this.type === "hotels" ? "Number of Guests Per Room" : "Number of Guests";
    }
  },
  watch: {
    // adults: {
    //   handler(value) {
    //     if (typeof value !== "undefined" && value !== null && !isNaN(value)) {
    //       this.adultQty = value;
    //       if (this.adultQty + this.childrenQty > this.maxQuantity) {
    //         this.adultQty = 1;
    //         this.childrenQty = 0;
    //       }
    //     }

    //     this.$emit("getAdults", this.adultQty);
    //   },
    //   immediate: true,
    // },
    // children: {
    //   handler(value) {
    //     console.log("watch::children", value);
    //     if (typeof value !== "undefined" && value !== null && !isNaN(value)) {
    //       this.childrenQty = value;
    //       if (this.adultQty + this.childrenQty > this.maxQuantity) {
    //         this.adultQty = 1;
    //         this.childrenQty = 0;
    //       }
    //     }

    //     this.$emit("getChildren", this.childrenQty);
    //   },
    //   immediate: true,
    // },
    guests: {
      immediate: true,
      deep: true,
      handler() {
        this.adultQty = this.guests.adults || 0;
        this.childrenQty = this.guests.children || 0;
        this.infantQty = this.guests.infants || 0;
        this.animalQty = this.guests.animals || 0;
      }
    },

    adultQty: {
      immediate: true,
      handler() {
        if (this.infantQty > this.adultQty) {
          this.infantQty = this.adultQty;
          this.$emit("getInfants", this.infantQty);
        }

        if (this.animalQty > this.adultQty) {
          this.animalQty = this.adultQty;
          this.$emit("getAnimals", this.animalQty);
        }
      }
    }
  },
};
