import _ from 'lodash'
import { apiDeleteReservation, apiReservation } from "@/api/property";
import ContinueShopping from "@/components/ContinueShopping/index.vue";
import { gtmTrack } from "@/lib/gtm";
import { authComputed, optionsComputed } from "@/store/helper";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import ReservationItemContinueShoppingButton from '@/components/reservations/buttons/ReservationItemContinueShoppingButton.vue'
import { mapGetters } from 'vuex'
import ReservationItemContinueShopping from '@/components/reservations/ReservationItemContinueShopping.vue'
import ReservationItemCancelButton from '@/components/reservations/buttons/ReservationItemCancelButton.vue'
import { generateAddressString } from '@/lib/address'
import {getRoomEventItem, getTourEventItem} from '../../../utils/gtm';

export default {
  components: {
    ContinueShopping,
    ReservationItemContinueShoppingButton,
    ReservationItemCancelButton,
    ReservationItemContinueShopping
  },
  data: () => ({
    reservation: null,
    cancelItemModal: false,
    cancelReservationModal: false,
    //modifyModal: false,
    confirmLoading: false,
    token: "",
    reason: "not_selected",
    cancelName: "",
    cancelItemIndex: 0,
    errorModal: false,
    cancelFormClicked: false,
    printLoading: false,
    isWarning: false
  }),
  /*
  async beforeCreate() {
    if (!this.isSingleReservationMode) {
      if (!this.$store.state.property.reservationList) {
        this.$store
          .dispatch("property/fetchReservationsList")
          .then(async () => {
            await this.fetchReservation();
          });
      } else {
        await this.fetchReservation();
      }
    } else {
      await this.fetchReservation();
    }
    //console.log("beforeCreate::2", this.reservation);

    if (this.isConfirmationPage) {
      this.trackData();
    }

    let owsCancels = await this.$store.state.app.options.owsCancels;
    this.owsCancels["not_selected"] = "Please select cancelation reason";

    for (const i in owsCancels) {
      this.owsCancels[i] = owsCancels[i];
    }
  },
  */
  computed: {
    ...optionsComputed,
    ...authComputed,
    ...mapGetters({
      isUser: 'auth/isUser',
      hotels: 'property/hotels',
      tours: 'property/tours',
      options: 'app/options',
      isAgency: 'auth/isAgency',
      reservationList: 'property/reservationList',
      lastReservationKeys: 'property/lastReservationKeys'
    }),

    reservationAddress() {
      const address = this.reservation?.address
      if (address) {
        return generateAddressString({
          firstName: address.firstName,
          lastName: address.lastName,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          state: address.state,
          zip: address.zip,
          country: address.country
        })
      }
      return null
    },

    isConfirmationPage() {
      return this.$route.name === "ConfirmationPage";
    },

    isReservationPage() {
      return this.$route.name === "ReservationPage";
    },

    reservationId() {
      let reservationId

      if (this.isConfirmationPage) {
        reservationId = parseInt(this.$route.query.reservation) || null

      } else if (this.isReservationPage) {
        reservationId = parseInt(this.$route.params.reservation_id) || null
      }

      return reservationId
    },

    reservationToken() {
      let reservationToken

      if (this.isConfirmationPage) {
        const lastReservationId = parseInt(_.get(this.lastReservationKeys, 'uniqueId') || null)
        const lastReservationToken = _.get(this.lastReservationKeys, 'token')

        if (lastReservationId === this.reservationId) {
          reservationToken = lastReservationToken
        } else {
          reservationToken = _.get(this.reservationList, `${this.reservationId}.token`)
        }

      } else if (this.isReservationPage) {
        reservationToken = _.get(this.reservationList, `${this.reservationId}.token`)
      }

      return reservationToken
    },

    owsCancels() {
      return {
        not_selected: 'Please select cancelation reason',
        ...this.options.owsCancels
      }
    },

    lodgings() {
      return _.get(this.reservation, 'sections.Lodging', {})
    },

    activities() {
      return _.get(this.reservation, 'sections.Tours', {})
    },

    hotel() {
      const hotelCode = _.get(this.lodgings, 'items.0.hotelCode')
      return _.get(this.hotels, hotelCode)
    },

    edgeDetected() {
      let userAgentString = navigator.userAgent;
      return userAgentString.indexOf("Edg") > -1;
    },
    select_reason_error() {
      return this.cancelFormClicked && this.reason === "not_selected";
    },
    isSingleReservationMode() {
      return !this.reservationId
    },
    isHistory() {
      let today = new Date().setHours(12, 0, 0, 0)
      return this.$dayjs(today).diff(this.$dayjs(this.reservation.arrival), 'day', true) >= 1
    },
    /*
    * Separate flag for items cancel button
    */ 
    couldCancelItem() {
      return !this.isConfirmationPage;
    },
    couldAddToReservation() {
      return this.reservation && this.reservation.modifyAllowed;
    },
    /*
    isReservationReview() {
      return !!this.$store.getters['property/lastReservationKeys']
    },
    */
    wholeReservationCanceled() {
      if (this.cancelName === "Reservation") {
        return true;
      }

      const lodgings = this.lodgings.items ? this.lodgings.items.length : 0;
      const activities = this.activities.items
        ? this.activities.items.length
        : 0;

      return lodgings + activities <= 1;
    },
    /*
    reservation_token() {
      if (this.isSingleReservationMode) {
        return window.localStorage.getItem("reservation_token")
          ? window.localStorage.getItem("reservation_token")
          : window.localStorage.getItem("last_reservation_token");
      } else {
        return this.$store.state.property.reservationList[this.reservationId]
          .token;
      }
    },
    */
    confirmationLabel() {
      return "Your Reservation is Confirmed";
      //return this.reservation.status === "CHANGED" ? "Your Reservation's Change is Confirmed" : "Your Reservation is Confirmed";
    },
    toursByDate() {
      return _.reduce(this.activities.items, (result, value, key) => {
        result[value.date] = result[value.date] ?? {};
        result[value.date][key] = value;
        return result;
      }, {})
    },
    tourCountByDates() {
      return _.chain(this.toursByDate)
        .map((item, date) => ({
          date,
          count: _.size(item),
        }))
        .filter(i => i.count > 1)
        .keyBy('date')
        .value()
    },
  },
  watch: {
    reservationId: {
      immediate: true,

      async handler() {
        // Fetch reservation
        if (this.reservationId && !this.reservationToken && this.isUser) {
          await this.$store.dispatch('property/fetchReservationsList')
        }

        if (this.reservationToken) {
          try {
            await this.fetchReservation()
          } catch(e) {
            console.log(e)
          }

          if (this.isConfirmationPage && this.reservation) {
            this.trackData()
          }
        }

        if (!this.reservation) {
          this.$router.push({ name: 'ReservationListPage' })
        }
      }
    }
  },
  methods: {
    /*
    async fetchReservations() {
      await this.$store.dispatch("property/fetchReservationsList");
    },
    */
   async fetchReservation() {
    const response = await apiReservation(this.reservationToken)
    this.reservation = response

    // Update agyncy's reservationList
    if (this.isAgency) {
      if (this.reservation) {
        const images = []
        // Hotel image
        const firstHotelCode = this.reservation.sections.Lodging?.items[0]?.hotelCode
        if (firstHotelCode) {
          const firstHotelImage = this.hotels[firstHotelCode]?.images[0]
          if (firstHotelImage) {
            images.push(firstHotelImage)
          }
        }
        // Activity image
        const firstTourCode = this.reservation.sections.Tours?.items[0]?.tourCode
        if (firstTourCode) {
          const firstTourImage = this.tours[firstTourCode]?.images[0]
          if (firstTourImage) {
            images.push(firstTourImage)
          }
        }

        this.$store.dispatch('property/populateReservationList', { ...this.reservation, images })
      }
    }
   },
   /*
    async fetchReservation() {
      try {
        if (this.reservationToken) {
          const res = await apiReservation(this.reservationToken);
          this.reservation = res;
          this.lodgings =
            res.sections.Lodging !== undefined ? res.sections.Lodging : {};
          this.activities =
            res.sections.Tours !== undefined ? res.sections.Tours : {};
          this.hotel = 
            res.sections.Lodging === undefined && res.sections.Tours !== undefined 
            ? this.$store.state.app.options : this.$store.state.property.hotels[this.reservation.sections.Lodging.items[0].hotelCode];
        }
      } catch (err) {
        console.log(err)
        if (!this.isSingleReservationMode || this.isReservationReview) {
          await this.$emit("fetchRservationList");
          this.$router.push({ name: "reservations" });
        } else {
          await this.$auth.logoutFromReservation();
        }
      }
    },
    */
    openCancelReservationModal(data) {
      if (!this.reservation.modifyAllowed) {
         this.isWarning = true
      }
        this.cancelName = data.type;
        this.token = data.token;
        this.cancelItemIndex = data.itemIndex;
        this.cancelFormClicked = false;
        data.type === "Reservation"
          ? (this.cancelReservationModal = true)
          : (this.cancelItemModal = true);
    },
    async onAddToReservation(reservation) {
      if (this.isConfirmationPage) {
        await this.$router.push({
          name: "ReservationPage",
          params: { reservation_id: this.reservation.uniqueId },
          hash: '#bookStay'
        });
      } else {
        this.$eventHub.$emit("add-to-reservation", reservation);
      }
    },
    trackCancelRoomRefund(token) {
      const roomItem = this.reservation.sections.Lodging?.items.find((i) => i.itemToken === token);
      const data = {
        'content-name': '/profile/reservations',
        'content-view-name': 'Reservations',
        event: 'refund',
        ecommerce: {
          currency: 'USD',
          transaction_id: this.reservation.uniqueId,
          tax: _.round(roomItem.tax, 2),
          value: _.round(roomItem.total, 2),
          items: [roomItem].map((item, index) => ({
            ...getRoomEventItem({
              item,
              hotels: this.hotels,
              isAgency: this.isAgency,
              propertyTitle: this.options.title,
            }),
            index,
          })),
        },
      }
      gtmTrack({ ecommerce: null });
      console.log('data', data);
      gtmTrack(data)
    },

    trackCancelActivityRefund(token) {
      const tourItem = this.reservation.sections.Tours?.items.find((i) => i.itemToken === token);
      const data = {
        'content-name': '/profile/reservations',
        'content-view-name': 'Cancel',
        event: 'refund',
        ecommerce: {
          currency: 'USD',
          transaction_id: this.reservation.uniqueId,
          tax: _.round(tourItem.tax, 2),
          value: _.round(tourItem.total, 2),
          items: [tourItem].map((item, index) => ({
            ...getTourEventItem({
              item,
              tours: this.tours,
              isAgency: this.isAgency,
              propertyTitle: this.options.title,
            }),
            index,
          })),
        },
      }
      gtmTrack({ ecommerce: null });
      console.log('data', data);
      gtmTrack(data)
    },

    trackCancelRefund(token, type) {
      try {
        if (type === 'Room') {
          this.trackCancelRoomRefund(token)
        } else if (type === 'Activity') {
          this.trackCancelActivityRefund(token)
        }
      } catch (error) {
        console.error('trackCancelRefund error', error)
      }
    },
    async handleCancel() {
      this.cancelFormClicked = true;
      // if (this.isWarning) {
      //   this.isSecondWarning = true
      //   this.isWarning = false
      // }
      if (this.reason === "not_selected" && !this.isWarning) {
        return;
      }

      this.confirmLoading = true;

      try {
        await apiDeleteReservation(this.token, this.reason);
        this.trackCancelRefund(this.token, this.cancelName);

        if (this.wholeReservationCanceled) {
          this.confirmLoading = false;
          this.cancelReservationModal = false;

          if (!this.isSingleReservationMode) {
            const reservation = this.reservationList[this.reservation.uniqueId]
            await this.$store.dispatch('property/populateReservationList', {
              ...reservation,
              status: 'CANCELLED',
              modifyAllowed: false
            })
            await this.$emit("fetchRservationList");
            this.$router.push({ name: "ReservationListPage" });
          } else {
            await this.$auth.logoutFromReservation();
          }
        } else {
          await this.fetchReservation()
        }
      } catch (err) {
        console.error(err);
      }

      this.confirmLoading = false;
      this.cancelItemModal = false;
    },
    openModifyReservationModal(data) {
      if (!data.item.modifyAllowed) {
        this.$error({
          title:
            "This booking can't be modified online. Please call XXX for assistance.",
        });
      } else {
        //this.token = data.token;
        //this.modifyModal = true;

        data["reservation"] = this.reservation;
        data["modalContainerClass"] = "modify-stay";
  
        console.log('data',data);
        this.setModal("ModifyRoom", data);
        }
    },
    print(action) {
      const content = document.getElementById("printable").innerHTML;

      let styles = "";
      for (const node of [
        ...document.querySelectorAll(
            'link[rel="stylesheet"], link[rel="preload"], style'
        ),
      ]) {
        styles += node.outerHTML;
      }

      styles +=
          '<style type="text/css" media="print">@page { size: landscape; }</style>';

      if (action === 'print') {
        // Open the print window
        const win = window.open(
            "",
            "",
            "left=0,top=0,width=1500,height=1000,toolbar=0,scrollbars=0,status=0"
        );

        win.document.write(
            `<!DOCTYPE html><html><head>${styles}</head><body>${content}</body></html>`
        );

        win.document.close();
        win.focus();
        win.print();
      } else if (action === 'save') {
        this.printLoading = true;
        let w = document.getElementById("printable").offsetWidth;
        let h = document.getElementById("printable").offsetHeight;
        let xScroll = window.scrollX
        let yScroll = window.scrollY

        console.log(yScroll)
        html2canvas(document.getElementById("printable"), {
          dpi: 300, // Set to 300 DPI
          scale: 3, // Adjusts your resolution
          useCORS: true,
          allowTaint: true,
          scrollX: -xScroll,
          scrollY: -yScroll,
        }).then(canvas => {
          let img = canvas.toDataURL("image/jpeg", 1);
          let doc = new jsPDF('L', 'px', [w+10, h+20]);
          doc.addImage(img, 'JPEG', 5, 10, w, h);
          doc.save(`Reservation #${this.reservation.uniqueId}`);
          this.printLoading = false;
        });
      }
    },

    trackData() {
      const legNumbers = this.$route.query.legs?.split('.').filter((i) => i);
      const mode = this.$route.query.mode;

      const lodgingItems = this.reservation.sections.Lodging?.items
        ?.filter((i) => !legNumbers?.length || legNumbers.includes(i.legNumber)) ?? []

      const activityItems = mode !== 'modify' ? (
        this.reservation.sections.Tours?.items
        ?.filter((i) => !legNumbers?.length || legNumbers.includes(i.legNumber)) ?? []
      ) : []

      const products = [
        ...lodgingItems.map((item) => (
          getRoomEventItem({
            item,
            hotels: this.hotels,
            isAgency: this.isAgency,
            propertyTitle: this.options.title
          })
        )),
        ...activityItems.map((item) => (
          getTourEventItem({
            item,
            tours: this.tours,
            isAgency: this.isAgency,
            propertyTitle: this.options.title
          })
        ))
      ];

      const tax = (_.sumBy(lodgingItems, 'tax') ?? 0) + (_.sumBy(activityItems, 'tax') ?? 0);
      const value = (_.sumBy(lodgingItems, 'total') ?? 0) + (_.sumBy(activityItems, 'total') ?? 0);

      const data = {
        'content-name': '/booking/lodging-confirmation',
        'content-view-name': 'Confirmation',
        event: 'purchase',
        ecommerce: {
          currency: 'USD',
          transaction_id: this.reservation.uniqueId,
          tax: _.round(tax, 2),
          value: _.round(value, 2),
          items: [...products.map((item, index) => ({ ...item, index }))]
        }
      }

      gtmTrack({ ecommerce: null });
      console.log('data', data);
      gtmTrack(data)
    },

    goToAddNewActivity(reservation) {
      // this.$store.dispatch("app/startAddToReservation", reservation)
      if (['full', 'standalone'].includes(this.options.toursMode)) {
        this.$router.push({ name: 'book_activity' })

      } else if (this.options.toursMode === 'inline') {
        this.$router.push({
          name: 'activitiesPage',

          query: {
            dateFrom: this.$dayjs(reservation.arrival).format('MM-DD-YYYY'),
            adults: reservation.adults,
            children: reservation.children,
            destination: 'ALL'
          }
        })
      }
    },
    async onCanceledReservation(reservation) {
      await this.$store.dispatch('property/populateReservationList', {
        ...reservation,
        status: 'CANCELLED',
        modifyAllowed: false
      })
      this.$router.push({ name: 'ReservationListPage' })
    }
  },
};
