<template>
  <component
    :is="tag"
    v-bind="tagProps"
    :disabled="isFetching"
    @click="openPopup"
  >
    <b-spinner
      v-if="isFetching"
      class="mr-1"
      small
    />
    
    <slot />

    <b-modal
      v-model="popupIsShown"
      dialog-class="w-400px modal-down-sm-fullscreen"
      header-bg-variant="secondary"
      header-class="rounded-0 pl-4 pr-2 border-0 close-button-black"
      content-class="rounded-0 border-0 bg-gamma"
      centered
      title-class="text-white text-uppercase font-family-2 line-height-12"
      body-class="p-0 bg-gamma"
      hide-footer
      scrollable
    >
      <template #modal-title>
        {{ title }}
      </template>

      <ReservationItemCancel
        ref="item"
        class="h-100 overflow-hidden"
        :item.sync="newItem"
        :prefetch="false"
        @update:item="$emit('update:item', $event)"
        @submitted="$emit('submitted', $event); popupIsShown = false"
        @cancel="$emit('cancel'); popupIsShown = false"
      />
    </b-modal>
  </component>
</template>

<script>
import { apiReservation } from '@/api/property'
import ReservationItemCancel from '../ReservationItemCancel.vue'
import crudItemMixin from '@/mixin/crud-item';
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'ReservationItemCancelButton',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    crudItemMixin
  ],

  components: {
    ReservationItemCancel
  },

  props: {
    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      idKey: 'token',
      popupIsOpened: false
    }
  },

  computed: {
    title() {
      return this.isFetched ? `Cancel #${this.newItem.uniqueId}` : `Cancel reservation`
    },

    popupIsShown: {
      get() {
        return this.isFetched && this.popupIsOpened
      },

      set(value) {
        this.popupIsOpened = value
      }
    }
  },

  validations: () => ({}),

  methods: {
    openPopup() {
      this.popupIsShown = true
      console.log('openPopup', this.item)

      if (!this.isFetched) {
        this.refresh()

      } else {
        this.$nextTick(() => {
          this.$refs.item.refresh()
        })
      }
    },

    fetchItem() {
      this.isFetching = true

      return apiReservation(this.newItemItemIdentifier)
        .then((data) => {
          this.isFetching = false
          this.newItem = data
        })
        .catch(e => {
          this.isFetching = false
          console.log(e)
        })
    }
  }
}
</script>

