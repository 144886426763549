export default {
  methods: {
    setModal(type = "", modalProps = {}) {
      this.$store.dispatch("app/setModal", type);
      this.$store.dispatch("app/setModalProps", modalProps);
    },
    setConfirmationModal(modalProps = {}) {
      return new Promise((resolve, reject) => {
        modalProps["resolve"] = resolve;
        modalProps["reject"] = reject;
        this.$store.dispatch("app/setModal", 'ConfirmationDialog');
        this.$store.dispatch("app/setModalProps", modalProps);  
      });
    },
    resetFormFieldErrors(e) {
      console.log('e', e);
      if (this.errors[e.target.name]) {
        this.errors[e.target.name] = Array.isArray(this.errors[e.target.name])
          ? []
          : {};
      }
      if (this.errors.detail) {
        this.errors.detail = null;
      }
    },
    /*
     * dateString: string
     * supported formats:
     * dd-mm-yyyy
     * mm/dd/yyyy
     */
    dateString2Date(dateString) {
      let dt = dateString.split(/\-|\s/);


      // support "/" separated format
      if (dt.length !== 3) {
        dt = dateString.split("/");

        return new Date(dt[2], dt[0] - 1, dt[1]);
      }

      /*return new Date(
        dt
          .slice(0, 3)
          .reverse()
          .join("-")
      );*/

      return new Date(dt[2], dt[0] - 1, dt[1]);
    },
    /*
     * url: String
     * convert assets URL to API CDN
     */
    getCdnResourceUrl(url) {
      if (import.meta.env.VITE_API_ASSET_URL && import.meta.env.VITE_CDN_API_URL) {
        url = url.replace(import.meta.env.VITE_API_ASSET_URL, import.meta.env.VITE_CDN_API_URL + "/")
        // make pretty url
        url = url.replace(/([^:]\/)\/+/g, "$1")

        return url
      } else {
        return url
      }
    },
    /*
     * url: String
     * convert assets URL to APP CDN
     */
    getAppCdnResourceUrl(url) {
      if (import.meta.env.VITE_CDN_URL) {
        url = import.meta.env.VITE_CDN_URL + "/" + url
        // make pretty url
        url = url.replace(/([^:]\/)\/+/g, "$1")

        return url
      } else {
        return url
      }
    },
  },
};
