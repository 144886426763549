<template>
  <div class="container">
    <div
      v-for="(response, responseIndex) in debugResponses"
      :key="responseIndex"
      class="py-3"
    >
      <b-row class="mb-2">
        <b-col cols="6">
          <div class="font-weight-bold text-truncent overflow-hidden text-nowrap">
            {{ response.url }}
          </div>

          <div class="font-size-8">
            Time: {{ response.duration }}
          </div>
        </b-col>

        <b-col
          v-if="response.ows && response.ows.length"
          cols="6"
          class="text-right"
        >
          <div>
            {{ response.ows[0].server }}
          </div>

          <div class="font-size-8">
            {{ $dayjs(response.timestamp * 1000) }}
          </div>
        </b-col>
      </b-row>

      <b-alert
        :show="!response.ows || !response.ows.length"
      >
        There are no ows.
      </b-alert>

      <b-card
        v-for="(log, logIndex) in response.ows"
        :key="logIndex"
        class="mb-2"
        no-body
      >
        <b-card-header
          v-b-toggle="`accordion-${responseIndex}-${logIndex}`"
          class="cursor-pointer text-truncent"
          :class="{
            'text-success': log.status === 'SUCCESS',
            'text-danger': log.status !== 'SUCCESS'
          }"
        >
          {{ log.name }} ({{ log.status }}) - Time: {{ log.duration }} (cache: {{ log.cache }})
        </b-card-header>

        <b-collapse
          :id="`accordion-${responseIndex}-${logIndex}`"
          :accordion="`accordion-${responseIndex}`"
        >
          <b-card-body>
            <b-row>
              <b-col sm="6">
                <div class="mb-2 font-weight-bold text-success">Request</div>

                <pre class="overflow-scroll" :style="{ height: '300px' }">{{ jsbHtml(log.request) }}</pre>
              </b-col>

              <b-col sm="6">
                <div class="mb-2 font-weight-bold text-info">Response</div>

                <pre class="overflow-scroll" :style="{ height: '300px' }">{{ jsbHtml(log.response) }}</pre>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
  <!--
  <section class="debug">
    <div class="debug__server">{{ debug.server }}</div>
    <div v-for="(log, idx) in debug.log" :key="idx" class="debug__log">
      <div
        class="debug__log-title"
        :class="
          log.status === 'SUCCESS'
            ? 'debug__log-title-success'
            : 'debug__log-title-failed'
        "
        @click="setCurrentOpened(idx)"
      >
        {{ log.name }} ({{ log.status }}) - Time: {{ log.duration }} (cache:
        {{ log.cache }})
      </div>
      <div class="debug__data" v-if="idx === currentOpened">
        <div class="debug__data-item" v-if="log.request">
          <div class="debug__data-title debug__data-title-req">Request</div>
          <span v-html="log.request"></span>
        </div>
        <div class="debug__data-item" v-if="log.response">
          <div class="debug__data-title debug__data-title-res">Response</div>
          <span v-html="log.response"></span>
        </div>
      </div>
    </div>
  </section>
  -->
</template>

<script>
import { mapGetters } from 'vuex'
import jsb from 'js-beautify'

export default {
  name: 'Debug',

  computed: {
    ...mapGetters({
      debugResponses: 'app/debugResponses'
    })
  },

  methods: {
    jsbHtml: jsb.html
  }

  /*
  data: () => ({
    currentOpened: null,
  }),
  computed: {
    debug() { return this.$store.state.app.debug }
  },
  methods: {
    setCurrentOpened(value) {
      this.currentOpened = this.currentOpened === value ? null : value
    }
  }
  */
}
</script>

<style lang="sass">
@import "style"
</style>
