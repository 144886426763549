import { appValuesComputed, appMethodsComputed } from "@/store/helper";
import { mapGetters } from 'vuex'

export default {
  data: () => ({
  }),
  computed: {
    ...appValuesComputed,
    ...mapGetters({
      authStatus: 'auth/status',
      addToReservation: 'app/addToReservation'
    }),
    modifyReservationId() {
      return this.modifyReservation.reservation.uniqueId;
    },
    modifyReservationDates() {
      let startDate = this.dateString2Date(this.modifyReservation.item.date);
      let nights = this.modifyReservation.item.nights;
      let endDate = this.$dayjs(startDate).add(nights, 'day').toDate();
      startDate = this.$dayjs(startDate).format("ddd, MMM D")
      endDate = this.$dayjs(endDate).format("ddd, MMM D, YYYY")
      nights = nights > 1 ? `${nights} Nights` : `${nights} Night`;

      return `${startDate} - ${endDate}, ${nights}`;
    },
    modifyReservationGuests() {
      let guests = parseInt(this.modifyReservation.item.adults) + parseInt(this.modifyReservation.item.children);

      return guests > 1 ? `${guests} Guests` : `${guests} Guest`;
    },
    modifyReservationRoom() {
      return this.modifyReservation.item.roomDetails.title;
    },
    modifyReservationRate() {
      return this.modifyReservation.item.rateTitle;
    },
    modifyReservationPrice() {
      let price = parseFloat(this.modifyReservation.item.total) + parseFloat(this.modifyReservation.item.tax);

      price = this.$numeral(price).format("0,0.00");

      return `$${price} USD`;
    },
  },
  methods: {
    ...appMethodsComputed,
    async onReturnToReservation() {
      const reservation_id = this.addToReservation.uniqueId
      await this.$store.dispatch("app/endAddToReservation")
      this.$router.push({ name: "ReservationPage", params: { reservation_id } })
    },
    async onReturnModifyReservation() {
      const reservation_id = this.modifyReservationId;
      //await this.endModifyReservation();
      this.$router.push({ name: "ReservationPage", params: { reservation_id } })
    },
  },
};
