import { mapState, mapActions } from "vuex";
import { isJSON } from "@/utils/index";

export const authComputed = {
  ...mapState("auth", {
    isLoggedIn: (state) => (state.status ? true : false),
    user: (state) => (state.user ? state.user : {}),
    isUser: (state) => state.status && state.login_type !== "a" && state.login_type !== "r",
    isAgency: (state) => state.status && state.login_type === "a",
    isUserOrAgency: (state) => state.status && state.login_type !== "r",
    isReservation: (state) => state.status && state.login_type === "r",
    isUserOrReservation: (state) => state.status && state.login_type !== "a",
  }),
};

export const propertyMethods = {
  ...mapActions("property", [
    "getHotelsAvailabilityMonthly",
    "clearHotelsAvailabilityMonthlyCache",
    "getAvailabilityRoomsMonthly",
    "clearAvailabilityRoomsMonthlyCache",
  ]),
};

export const propertyComputed = {
  ...mapState("property", {
    pHotels: (state) => {
      return state.hotels;
    },
    pTours: (state) => {
      return state.tours;
    },
    pTourCart: (state) => {
      return state.addedTours;
    },
    cartNotEmpty: (state) => {
      return state.cart && Object.keys(state.cart).length || state.tourCart && Object.keys(state.tourCart).length;
    },
    pCart: (state) => {
      return state.cart ? state.cart : {};
    },
  }),
};

export const optionsComputed = {
  ...mapState("app", {
    promoGroupEnabled: (state) => !!state.options.resformPromoGroup,
    toursEnabled: (state) => !!state.options.toursEnabled,
    toursInlineEnabled: (state) => ((state.options.toursMode === 'inline' || state.options.toursMode === 'full') && !!state.options.toursEnabled),
    toursStandaloneEnabled: (state) => ((state.options.toursMode === 'standalone' || state.options.toursMode === 'full') && !!state.options.toursEnabled),
    options: (state) => state.options,
    storage: (state) => state.storage,
    appErrors : (state) => state.errors,
    // treat correct JSON object as a modify mode
    isModifyReservationMode: (state) => {
      return isJSON(state.mReservation);
    },
    modifyReservation: (state) => {
      return isJSON(state.mReservation) ? JSON.parse(state.mReservation) : {};
    },
    oPropertyAdultsYears: (state) => parseInt(state.options.resformAgeAdult) + "+",
    oPropertyChildrenYears: (state) => parseInt(state.options.resformAgeChild) + "-" + (parseInt(state.options.resformAgeAdult) - 1),
  }),
};

export const appValuesComputed = optionsComputed;

export const appMethodsComputed = {
  ...mapActions("app", ["putToStorage", "startModifyReservation", "endModifyReservation", "putToStorage"]),
};
