import Vue from 'vue'
import Antd, { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

// import {
//   Button,
//   Checkbox,
//   Col,
//   Dropdown,
//   Input,
//   message,
//   Radio,
//   Row,
//   Select,
//   Switch,
//   Table,
//   Layout
// } from 'ant-design-vue'

// import 'ant-design-vue/lib/style/index.css'
// import 'ant-design-vue/lib/button/style/index.css'
// import 'ant-design-vue/lib/checkbox/style/index.css'
// import 'ant-design-vue/lib/dropdown/style/index.css'
// import 'ant-design-vue/lib/grid/style/index.css'
// import 'ant-design-vue/lib/input/style/index.css'
// import 'ant-design-vue/lib/message/style/index.css'
// import 'ant-design-vue/lib/radio/style/index.css'
// import 'ant-design-vue/lib/select/style/index.css'
// import 'ant-design-vue/lib/switch/style/index.css'
// import 'ant-design-vue/lib/table/style/index.css'

// Vue.use(Button)
// Vue.use(Checkbox)
// Vue.use(Col)
// Vue.use(Dropdown)
// Vue.use(Input)
// Vue.use(Radio)
// Vue.use(Row)
// Vue.use(Select)
// Vue.use(Switch)
// Vue.use(Table)
// Vue.use(Layout)

Vue.use(Antd)
Vue.prototype.$message = {
  error: message.error,
  success: message.success
}
