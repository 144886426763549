import http from '@/lib/axios'

export const apiGetProfile = () => http.get('user/profile/me')

export const apiGetAgencyProfile = () => http.get('user/profile/agency/me')

export const apiUpdateProfile = {
  address: data => http.post('user/profile/address', data),
  communication: data => http.post('user/profile/communication', data),
  password: data => http.post('user/profile/password', data),
  question: data => http.post('user/profile/question', data),
}
