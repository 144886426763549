import { required } from '@vuelidate/validators'
import PasswordForm from './Password/index.vue'
import QuestionForm from './Question/index.vue'
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  meta: {
    title: 'Home Page'
  },
  components: {
    PasswordForm,
    QuestionForm
  },
  data: () => ({
    password: '',
    newPassword: '',
    passwordQuestion: '',
    question: '',
    answer: '',
    passwordVisible: false,
    newPasswordVisible: false
  }),
  validations: () => {
    return {
      password: {
        required,
      },
      newPassword: {
        required
      }
    }
  },
  computed: {
    isPasswordVisible() {
      return this.passwordVisible ? 'text' : 'password'
    },
    isNewPasswordVisible() {
      return this.newPasswordVisible ? 'text' : 'password'
    },
    errors() {
      return this.$store.state.app.errors
    }
  },
  methods: {
    handlePasswordChange() {
      this.v$.password.$touch()
      this.v$.newPassword.$touch()
      if (this.v$.password?.$invalid || this.v$.newPassword?.$invalid) return
      this.$store.dispatch('auth/changePassword', { password: this.password, new_password: this.newPassword })
    },
    handleQuestionChange() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.$store.dispatch('auth/changeQuestion', { password: this.passwordQuestion, question: this.question, answer: this.answer })
    }
  },

  created() { }
}
