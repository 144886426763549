<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true; $emit('opened')"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      dialog-class="modal-down-sm-fullscreen"
      content-class="rounded-0 border-0"
      centered
      header-bg-variant="secondary"
      header-class="rounded-0 p-0 border-0 height-50px close-button-secondary"
      title-class="text-white text-uppercase pl-4 pr-0 font-size-18 font-family-2"
      body-class="p-0"
      hide-footer
      scrollable
      size="xl"
    >
      <template #modal-title>
        {{ item.title }}
      </template>
      
      <HotelItemImages
        ref="gallery"
        class="h-100 overflow-hidden bg-psi"
        :item="item"
        :initial-slide="initialSlide"
        :images-key="imagesKey"
        image-class="object-fit-contain object-fit-sm-cover"
        thumb-image-class="object-fit-contain object-fit-sm-cover"
        :show-open-button="false"
      />
    </b-modal>
  </component>
</template>

<script>

export default {
  name: 'HotelItemImagesButton',

  components: {
    HotelItemImages: () => import('../HotelItemImages.vue')
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    initialSlide: {
      type: Number,
      default: 0
    },

    imagesKey: {
      type: String,
      default: 'images'
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false,
      galleryIsShown: false
    }
  },

  computed: {
    title() {
      return 'Hotel images'
    }
  },

  watch: {
    popupIsShown: {
      immediate: true,

      handler() {
        if (this.popupIsShown) {
          this.$nextTick(() => {
            this.$refs.gallery.swiperTop.slideTo(this.initialSlide)
          })
        }
      }
    }
  }
}
</script>

