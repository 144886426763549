import Login from '@/components/Login/index.vue'
import LoginAgency from '@/components/LoginAgency/index.vue'
import LoginReservation from '@/components/LoginReservation/index.vue'
import SignUp from '@/components/SignUp/index.vue'
import ForgotPassword from '@/components/ForgotPassword/index.vue'
import ForgotUsername from '@/components/ForgotUsername/index.vue'
import ResetPassword from '@/components/ResetPassword/index.vue'
import ActivityQuestions from '@/components/ActivityQuestions/index.vue'
import CancelReservation from '@/components/CancelReservation/index.vue'
import HotelRateCalendar from '@/components/HotelRateCalendar/index.vue'
import RoomRateCalendar from '@/components/RoomRateCalendar/index.vue'
import TourRateCalendar from '@/components/TourRateCalendar/index.vue'
import AddRoom from '@/components/AddRoom/index.vue'
import TermsAndConditions from '@/components/TermsAndConditions/index.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog/index.vue'
import ModifyRoom from '@/components/ModifyRoom/index.vue'
import { setBodyPosition } from '@/utils'

const components = {
  Login, SignUp, ForgotPassword, ResetPassword, ActivityQuestions, CancelReservation, HotelRateCalendar, RoomRateCalendar, TourRateCalendar, AddRoom, ForgotUsername, LoginAgency, LoginReservation, ConfirmationDialog, TermsAndConditions, ModifyRoom
};

export default {
  components,
  data: () => ({
    offsetTop: null,
    unwatch: null
  }),
  /*
  created() {
    this.unwatch = this.$store.watch(
      (state) => state.app.modal,
      () => {
        this.$store.state.app.modal
          ? setBodyPosition.fixed(value => this.offsetTop = value)
          : setBodyPosition.unfixed(this.offsetTop)
      }
    )
  },
  */
  computed: {
    modalComponent() {
      return components[this.$store.state.app.modal] ? this.$store.state.app.modal : ""
    },
    isOpen() {
      return this.$store.state.app.modal
    },
    containerClass() {
      return this.$store.state.app.modalContainerClass ? this.$store.state.app.modalContainerClass : (this.$store.state.app.modalProps.modalContainerClass ? this.$store.state.app.modalProps.modalContainerClass : "")
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler() {
        if (this.isOpen) {
          document.body.classList.add('custom-modal-shown')
        } else {
          document.body.classList.remove('custom-modal-shown')
        }
      }
    }
  },
  beforeDestroy() {
    typeof this.unwatch === 'function' && this.unwatch()
  }
}
