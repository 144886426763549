import { apiAgencySignIn } from "@/api/auth";
import { required } from "@vuelidate/validators";

import { notCreditCard } from "@shared/utils/validators";
import md5 from "crypto-js/md5";
import _ from "lodash";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    username: "simpleta@xanterra.net",
    iata: "",
    loading: false,
    errors: {},
  }),
  validations: () => {
    return {
      iata: {
        required,
        notCreditCard
      },
    }
  },
  computed: {
    password() {
      return md5(this.username).toString();
    },
    isPartnersPageForm() {
      return this.$store.state.app.modalProps.partnersPage;
    },
  },
  methods: {
    async handleSignIn() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      try {
        this.loading = true;
        const res = await apiAgencySignIn(
          this.username,
          this.iata,
          this.password
        );

        this.$auth.login(
          res.access_token,
          res.refresh_token,
          res.expires_in,
          res.token_type,
          // agency login flag
          "a"
        );

        if (this.$store.state.app.modalProps.callback) {
          await this.$store.state.app.modalProps.callback(
            this.$store.state.app.modalProps.params
          );
        }

        this.setModal();
      } catch (err) {
        this.errors = {
          ...this.errors,
          ...this.$store.state.app.errors,
          detail: err.response.data.detail,
        };
      } finally {
        this.loading = false;
        this.$store.dispatch("app/setErrors");
      }
    },
    onNewToGrandCanyon() {
      window.open("https://www.xanterra.com/travel-professionals/", "_blank");
    },
    onRegisterYourAgency() {
      window.open(
        "https://www.xanterra.com/travel-professionals/travel-professional-sign-up/",
        "_blank"
      );
    },
    onCancel() {
      this.setModal();

      this.$router.push({
        name: "book_stay",
      });
    },
  },
};
