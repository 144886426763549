import { setBodyPosition } from "@/utils";
import { CartIcon } from "@/components/Icons";
import {
  authComputed,
  optionsComputed,
  propertyComputed,
  propertyMethods
} from "@/store/helper";
import logo from "@/assets/logo-xanterra.png";
import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),
  data: () => ({
    isOpen: false,
    offsetTop: null,
    modal: false,
    cartIcon: CartIcon(),
    openAlert: false,
    newAlerts: [],
    headerHeight: 50,
    alertsHeight: 0,
    openSideMenue: false
  }),
  created() {
    this.openAlert = !!this.alerts;
    this.$eventHub.$on("alerts-rendered", this.onAlertsRendered);
    this.$eventHub.$on("alerts-destroyed", this.onAlertsDestroyed);
    this.$eventHub.$on("close-filter-section", this.onCloseFilterSection);
  },
  computed: {
    ...authComputed,
    ...optionsComputed,
    ...propertyComputed,
    windowWidth() {
      return this.windowSize.width.value;
    },
    hideMainNavigation() {
      const names = [
        "profile",
        "info",
        "ReservationListPage",
        "settings",
        "reservation",
        "singleReservationPage",
        "ReservationReview",
        "ReviewPage"
      ];

      //console.log(this.$route, names.indexOf(this.$route.name));

      return this.cartNotEmpty && names.indexOf(this.$route.name) !== -1;
    },
    cartIconBlackBackground() {
      const names = [
        "ReviewPage"
      ];

      return names.indexOf(this.$route.name) !== -1;
    },
    links() {
      if (this.toursStandaloneEnabled) {
        return [
          { to: "/booking/lodging", title: "Book a stay" },
          { to: "/booking/activities", title: "Book an activity" },
          { to: "/profile/reservations", title: "Modify & Cancel" },
        ];
      } else {
        return [
          { to: "/booking/lodging", title: "Book a stay" },
          { to: "/profile/reservations", title: "Modify & Cancel" },
        ];
      }
    },
    linksMobile() {
      if (this.toursStandaloneEnabled) {
        return [
          { to: "/booking/lodging", title: "Book a stay" },
          { to: "/booking/activities", title: "Book an activity" },
          { to: "/profile/reservations", title: "Modify & Cancel" },
          { to: "#", title: "Your Cart" },
        ];
      } else {
        return [
          { to: "/booking/lodging", title: "Book a stay" },
          { to: "/profile/reservations", title: "Modify & Cancel" },
          { to: "#", title: "Your Cart" },
        ];
      }
    },
    alerts() {
      let tempAlerts = this.$store.state.app.options.alerts;
      if (!!localStorage.getItem('alerts')) {
        tempAlerts = this.$store.state.app.options.alerts.filter(item => {
          const storageAlerts = JSON.parse(localStorage.getItem('alerts'));
          return !!!storageAlerts.find(storageEl => (item.id === storageEl.id));
        })
      }
      this.newAlerts = tempAlerts;
      return !!this.newAlerts.length && !!this.$store.state.app.options.alerts.length && tempAlerts
    },
    fixedHeaderHeight() {
      return this.headerHeight + this.alertsHeight;
    },
    mobileHeaderHeightStyle() {
      return this.isOpen ? `top: ${this.fixedHeaderHeight}px; height: calc(100% - ${this.fixedHeaderHeight}px)` : `top: ${this.fixedHeaderHeight}px;`
    },
    getLogoUrl() {
      return this.getCdnResourceUrl(this.options.logo)
    },
    getMobileLogoUrl() {
      return logo
      // return this.getAppCdnResourceUrl(logo)
    }
  },
  methods: {
    ...propertyMethods,
    updateAlerts(id) {
      const indx = this.newAlerts.findIndex(v => v.id === id);
      let storageAlerts = []
      if (!!localStorage.getItem('alerts')) {
        storageAlerts = JSON.parse(localStorage.getItem('alerts'));
        localStorage.removeItem('alerts');
      }
      storageAlerts.push(this.newAlerts[indx]);
      localStorage.setItem("alerts", JSON.stringify(storageAlerts));

      this.newAlerts.splice(indx, indx >= 0 ? 1 : 0);
    },
    navbarToggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen)
        setBodyPosition.fixed((value) => (this.offsetTop = value));
      else setBodyPosition.unfixed(this.offsetTop);
    },
    openLoginModal() {
      this.setModal("Login");
    },
    handleLogout() {
      this.$auth.logout();
    },
    onCartClick() {
      if (this.$route.name !== "ReviewPage") {
        this.$router.push({ name: "ReviewPage" });
      }
    },
    async clearCart() {
      await this.$store
        .dispatch("property/startOver")
        .then((r) => {
          this.setModal();
          this.$router.push({ path: "/" });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    toSearch() {
      if (!!this.cart) {
        this.setModal("ConfirmationDialog", {
          content:
            "Are you sure you want to empty your cart and choose another lodging date?",
          onConfirm: this.clearCart,
        });
      } else this.$router.push({ path: "/" });
    },
    onAlertsRendered(height) {
      this.alertsHeight = height;
    },
    onAlertsDestroyed() {
      this.alertsHeight = 0;
    },
    onCloseFilterSection() {
      this.openSideMenue = false;
    },
  }
};
