import { propertyComputed } from "@/store/helper";
import _ from 'lodash';
import { mapGetters } from "vuex";
import TruncatedText from '@/components/TruncatedText/index.vue';
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  components: {
    TruncatedText,
  },

  props: {
    tour: Object,
    searchParams: Object,
    availabilityData: Object,
  },

  data: () => ({
    shouldViewMore: false,
    isViewingMore: false
  }),

  methods: {
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore = this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    onViewCalendar() {
      const data = {
        tour_data: this.tour,
        tour_availability: this.availabilityData,
        date: this.searchParams.date,
        adults: this.searchParams.adults,
        children: this.searchParams.children,
        infants: this.searchParams.infants,
        animals: this.searchParams.animals,
      }
      // @click="setModal('TourRateCalendar', { tour_data: data, tour_availability: availabilityData })"
      this.setModal('TourRateCalendar', data);
    }
  },
  computed: {
    ...propertyComputed,
    ...mapGetters({
      tourCart: 'property/tourCart',
      options: 'app/options',
      addToReservation: 'app/addToReservation',
    }),

    itemInCart() {
      const date = this.$dayjs(this.searchParams.date, "MM-DD-YYYY").format("MM/DD/YYYY")

      return !!_.find(this.tourCart, i => (
        i.date === date &&
        i.tourCode === this.tour.code
      ))
    },

    itemIsAlreadyBooked() {
      const date = this.$dayjs(this.searchParams.date, "MM-DD-YYYY").format("MM/DD/YYYY")

      return !!_.find(this.addToReservation?.sections?.Tours?.items, i => (
        i.date === date &&
        i.tourCode === this.tour.code
      ))
    },

    adultPrice() {
      return this.availabilityData.prices && this.availabilityData.prices.adult ? this.availabilityData.prices.adult.min * this.searchParams.adults : 0;
    },
    childrenPrice() {
      return this.availabilityData.prices && this.availabilityData.prices.child ? this.availabilityData.prices.child.min * this.searchParams.children : 0;
    },
    imageUrl() {
      return _.get(this.tour, 'images.0.image')
    },
    imageTitle() {
      return _.get(this.tour, 'images.0.title')
    },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    },
    cancelBeforeDate() {
      const settings = this.options.cancelBeforeActivities?.find(i => (
        this.$dayjs(this.searchParams.date, 'MM-DD-YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(this.searchParams.date, 'MM-DD-YYYY')
        .subtract(value, 'day')
        .toDate();
      
      return cancelBeforeDate;
    },
    cancelationAvailable() {
      const today = new Date();
      return this.cancelBeforeDate && this.cancelBeforeDate > today;
    },
    cancelationMessage() {
      return this.cancelationAvailable ? (
        `free cancelation before ${this.$dayjs(this.cancelBeforeDate).format('ddd, MMM D')}`
      ) : 'cancelation fee applies';
    },

    availabilityStatusOptions() {
      return {
        closed: 'Closed',
        soldOut: 'Sold out',
        notEnoughTime: 'Not Available',
        childrenNotAllowed: 'Children not allowed',
        guestsExceededAvailability: 'Qty exceeded availability'
      }
    },

    availabilityStatus() {
      const isOpened = !!_.values(this.availabilityData.options).find(i => ['OPEN', 'SOLDOUT'].includes(i.status))
      const isAvailable = !!this.availabilityData.available
      const enoughTime = this.$dayjs(this.tour.mindate, 'MM/DD/YYYY').toDate() <= this.$dayjs(this.searchParams.date, 'MM-DD-YYYY').toDate()

      const maxAvailableOption = _.chain(this.availabilityData.options)
        .sortBy(i => i.available)
        .last()
        .value()
      
      if (this.searchParams.children && !this.availabilityData.children) {
        return 'childrenNotAllowed'
      } else if (!isOpened) {
        return 'closed'
      } else if (!enoughTime) {
        return 'notEnoughTime'
      } else if (!isAvailable) {
        return 'soldOut'
      } else if (maxAvailableOption.available < (this.searchParams.adults + this.searchParams.children + this.searchParams.infants + this.searchParams.animals)) {
        return 'guestsExceededAvailability'
      }

      return 'available'
    },

    price() {
      const adults = this.searchParams.adults;
      const children = this.searchParams.children;
      const tourAvailability = this.availabilityData;

      const base = (
        adults * tourAvailability.prices.adult.min +
        children * tourAvailability.prices.child.min
      )

      const fees = (
        adults * tourAvailability.prices.adult.fee +
        children * tourAvailability.prices.child.fee
      )

      const taxes = (
        adults * tourAvailability.prices.adult.tax +
        children * tourAvailability.prices.child.tax
      )

      const price = getPrice({
        base,
        fees,
        taxes,
      })

      return price;
    },

    minPrice() {
      const adults = this.searchParams.adults;
      const tourAvailability = this.availabilityData;

      const base = adults * tourAvailability.prices.adult.min;
      const fees = adults * tourAvailability.prices.adult.fee;
      const taxes = adults * tourAvailability.prices.adult.tax;

      const price = getPrice({
        base,
        fees,
        taxes,
      })

      return price;
    },
  },

  mounted() {
    window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      this.onResize();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
