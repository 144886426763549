import RangePicker from "@/components/RangePicker/index.vue";
import { propertyMethods, optionsComputed, propertyComputed } from "@/store/helper";
import moment from "moment";
import bookingMixin from '@/mixin/booking'
import { mapGetters } from 'vuex'
import {getPrices} from '@/utils/taxes-and-fees'

export default {
  mixins: [
    bookingMixin
  ],
  components: { RangePicker },
  data: () => ({
    room: {},
    // availabilityData: {},
    // allowed global date range
    /*
    minDate: null,
    maxDate: null,
    */
    // current displayed date (month)
    currentDate: null,
    // selected date range
    startDate: null,
    endDate: null,
    // data should be initialized
    rate_code: "",
    room_code: "",
    hotelCode: "",
    guests: 1,
    // loading flag
    loading: false,
    rate: {},
    // start date room data
    startDateRoomData: {},
    selectPreviewDate: null,
    shouldViewMore: false,
    isViewingMore: false
  }),
  async created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      cartToken: 'property/cartToken',
      availableRoomsMonthly: 'property/availableRoomsMonthly'
    }),
    ...optionsComputed,
    ...propertyComputed,
    // dynamic min date based on selected check in date
    availability() {
      if (this.currentDate) {
        const monthString = this.$dayjs(this.currentDate).format('MM/YYYY')
        const key = `${monthString}-${this.room_code}-${this.rate_code}`
        return this.availableRoomsMonthly?.[key] || {}
      }
      return {}
    },
    availabilityData() {
      return this.availability?.availability ?? {};
    },
    ratePlan() {
      return this.availability?.rates?.[this.rate_code];
    },
    prevMonthLastDate() {
      if (this.currentDate) {
        return this.$dayjs(this.currentDate).date(1).subtract(1, 'day').toDate()
      }
      return null
    },
    prevMonthLastDateAvailability() {
      if (!this.prevMonthLastDate) {
        return null
      }
      const monthString = this.$dayjs(this.prevMonthLastDate).format('MM/YYYY')
      const dateString = this.$dayjs(this.prevMonthLastDate).format('MM/DD/YYYY')
      const key = `${monthString}-${this.room_code}-${this.rate_code}`
      const dateAvailabilityData = this.availabilityData?.[dateString]
      return dateAvailabilityData
    },
    prevMonthLastDateDisabled() {
      if (this.checkOutMode) {
        return this.startDate >= this.currentDate ? true : false
      }

      if (!this.prevMonthLastDateAvailability) {
        return null
      }

      return !+this.prevMonthLastDateAvailability?.rooms?.[0]?.available
    },
    minDate() {
      let minDate = this.$dayjs(this.bookingDatesWindow.min).toDate()

      if (this.rate?.start) {
        minDate = this.$dayjs.max(
          this.$dayjs(minDate),
          this.$dayjs(this.rate.start, 'MM/DD/YYYY')
        ).toDate()
      }

      return minDate
    },
    maxDate() {
      let maxDate = this.$dayjs(this.bookingDatesWindow.max).toDate()

      if (this.rate?.end) {
        maxDate = this.$dayjs.min(
          this.$dayjs(maxDate),
          this.$dayjs(this.rate.end, 'MM/DD/YYYY')
        ).toDate()
      }

      return maxDate
    },
    maxStayDate() {
      let maxStayDate = this.$dayjs(this.bookingDatesWindow.maxStay).toDate()

      if (this.rate?.end) {
        maxStayDate = this.$dayjs.min(
          this.$dayjs(maxStayDate),
          this.$dayjs(this.rate.end, 'MM/DD/YYYY').subtract(1, 'day')
        ).toDate()
      }

      return maxStayDate
    },
    allDates() {
      const length = this.$dayjs(this.currentDate).daysInMonth()
      return Array.from({ length })
        .map((_, i) => this.$dayjs(this.currentDate).set('date', i + 1).toDate())
    },
    checkOutMode() {
      return this.startDate && !this.endDate
    },
    computedEndDate() {
      return this.endDate || this.selectPreviewDate
    },
    computedSelectedNights() {
      if (this.computedEndDate) {
        return this.$dayjs(this.computedEndDate).diff(this.$dayjs(this.startDate), 'day')
      }
      return null
    },
    startDateAvailability() {
      if (!this.startDate) {
        return null
      }
      const monthString = this.$dayjs(this.startDate).format('MM/YYYY')
      const startDateString = this.$dayjs(this.startDate).format('MM/DD/YYYY')
      const key = `${monthString}-${this.room_code}-${this.rate_code}`
      const startDateAvailability = this.availabilityData?.[startDateString]
      return startDateAvailability
    },
    checkOutAvailableDates() {
      if (this.checkOutMode) {
        const availabilityPerNight = this.startDateAvailability?.rooms?.[0]?.perNight
        const availabilityPerNightEntries = Object.entries(availabilityPerNight || {})
        return availabilityPerNightEntries
          .filter(([k, v]) => +v.available > 0)
          .map(([k, v]) => this.$dayjs(this.startDate).add(+k - 1, 'day').toDate())
          .sort()
      }
    },
    availableDays() {
      if (this.checkOutMode) {
        return this.allDates
          .filter(date => {
            return this.checkOutAvailableDates.find(i => this.$dayjs(date).isSame(i))
          })
          .map(date => this.$dayjs(date).date())
      }

      return this.allDates
        .filter(date => {
          const dateString = this.$dayjs(date).format("MM/DD/YYYY")
          const roomAvailability = this.availabilityData[dateString]
          const availabilityPerNight = roomAvailability?.rooms?.[0]?.perNight
          const isAvailableArrive = !!availabilityPerNight?.[this.ratePlan.minstay]?.available
          return isAvailableArrive
        })
        .map(date => this.$dayjs(date).date())
    },
    allowMinDate() {
      if (this.startDate && !this.endDate) {
        return this.$dayjs(this.startDate).toDate();
      } else {
        return this.minDate;
      }
    },
    // dynamic max date based on selected check in date
    allowMaxDate() {
      if (this.startDate && !this.endDate) {
        const days = this.maxNightsFromStart;
        return this.$dayjs(this.startDate)
          .add(days, "day")
          .toDate();
      } else {
        return this.maxDate;
      }
    },
    // available amount of nights starting from start date
    maxNightsFromStart() {
      if (!this.startDate) {
        return 1;
      }

      const room = this.startDateRoomData;

      if (room === false) {
        return 1;
      }

      if (typeof room.perNight === "undefined") {
        return 1;
      }

      for (var i = this.minstay; i < 14; i++) {
        if (
          typeof room.perNight[i] === "undefined" ||
          typeof room.perNight[i]["available"] === "undefined" ||
          typeof room.perNight[i]["available"] <= 0
        ) {
          return i - 1;
        }
      }

      return 14;
    },
    prices() {
      let prices = {};
      let minstayFactor = 0;
      let lastPrice = 0;

      if (!this.currentDate) {
        return prices;
      }

      const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();

      let currentDateCloned = this.$dayjs(this.currentDate).toDate();
      currentDateCloned.setDate(1);

      for (var i = 1; i <= numberOfDays; i++) {
        currentDateCloned.setDate(i);
        const dateKey = this.$dayjs(currentDateCloned).format("MM/DD/YYYY");
        prices[dateKey] = 0;

        const room = this.getRoomAvailabilityData(currentDateCloned);
        if (room === false) {
          continue;
        }

        const perNight = room.perNight;

        if (typeof perNight === "undefined") {
          continue;
        }

        if (typeof perNight[this.minstay] === "undefined") {
          continue;
        }

        let price = 0;
        if (perNight[this.minstay]["available"] > 0) {
          price = perNight[this.minstay]["price"];
          minstayFactor = 1;
          lastPrice = price;
        } else if (this.minstay > 1 && minstayFactor < this.minstay && lastPrice > 0) {
          // make available following days for rates with minstay > 1
          minstayFactor++;
          price = lastPrice;
        } else if (minstayFactor >= this.minstay) {
          lastPrice = 0;
        }

        prices[dateKey] = price;
      }

      //console.log("prices", prices);

      return prices;
    },
    disabledDays() {
      let dates = [];
      const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();

      let currentDateCloned = this.$dayjs(this.currentDate).toDate();
      currentDateCloned.setDate(1);

      for (var i = 1; i <= numberOfDays; i++) {
        currentDateCloned.setDate(i);
        //const date = this.$dayjs(currentDateCloned).format("MM/DD/YYYY");

        if (!this.getIsAvailable(currentDateCloned)) {
          dates.push(currentDateCloned.getDate());
        }
      }

      //console.log("disabledDays", dates);

      return dates;
    },
    allowAction() {
      return true;
    },
    cancelButtonLabel() {
      if (this.startDate) {
        return "Cancel";
      } else {
        return "Close";
      }
    },
    cancelButtonTitle() {
      if (this.startDate) {
        return "Cancel Date Selection";
      } else {
        return "Close Calendar";
      }
    },
    checkInOutLabel() {
      if (this.startDate && this.endDate) {
        return "Check In - Check Out";
      } else if (this.startDate) {
        return "Check In";
      } else {
        return "Please select check in date";
      }
    },
    startEndDatesLabel() {
      if (this.startDate && this.endDate) {
        return (
          this.$dayjs(this.startDate).format("ddd, MMM D") +
          " - " +
          this.$dayjs(this.endDate).format("ddd, MMM D, YYYY") +
          ", " +
          this.nightsLabel
        );
      } else if (this.startDate) {
        return this.$dayjs(this.startDate).format("ddd, MMM D, YYYY");
      } else {
        return "";
      }
    },
    nights() {
      if (this.startDate && this.endDate) {
        return this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), "day");
      } else {
        return 0;
      }
    },
    nightsLabel() {
      return this.nights + (this.nights > 1 ? " nights" : " night");
    },
    minStayValid() {
      return this.nights >= this.minstay;
    },
    minStayMessage() {
      return `Please select at least ${this.minstay} ${this.minstay > 1 ? " nights" : " night"}  to proceed`;
    },
    selectionPrices() {
      const adults = +this.$route.query.adults;
      if (this.nights) {
        const startDateRateAvailability = this.startDateAvailability?.rooms
          .find(i => i.rateCode === this.rate_code && i.roomCode === this.room_code);
        const startDateRateAvailabilityPerNight = startDateRateAvailability?.perNight?.[this.nights];
        const startDateRateAvailabilityPerNightPerGuests = startDateRateAvailabilityPerNight?.perGuest?.[adults];
        const nightsIsAvailable = (
          !!startDateRateAvailabilityPerNight?.available &&
          !!startDateRateAvailabilityPerNightPerGuests?.p
        );
        if (nightsIsAvailable) {
          return getPrices({
            base: startDateRateAvailabilityPerNightPerGuests.p ?? 0,
            fees: startDateRateAvailabilityPerNightPerGuests.f ?? 0,
            taxes: startDateRateAvailabilityPerNightPerGuests.t ?? 0,
            nights: this.nights,
          })
        }
      }
    },
    dateRangeChanged() {
      return this.$route.query.dateFrom !== this.$dayjs(this.startDate).format("MM-DD-YYYY") || parseInt(this.$route.query.nights) !== this.nights;
    },
    getImageUrl() {
      if (typeof this.room.images !== "undefined" && this.room.images.length > 0) {
        return this.getCdnResourceUrl(this.room.images[0].image);
      }

      return "";
    },
    getImageTitle() {
      if (typeof this.room.images !== "undefined" && this.room.images.length > 0) {
        return this.room.images[0].title;
      }

      return "";
    },
    minstay() {
      return parseInt(this.rate?.minstay) || 1
    },
    customClasses() {
      //console.log("customClasses", this.rate, this.minstay, this.currentDate);
      let classes = {};

      if (this.minstay === 1 || !this.currentDate) {
        return classes;
      }

      //return classes;

      // apply classes for check out mode only
      if (this.startDate && !this.endDate) {
        let startDateCloned = this.$dayjs(this.startDate).toDate();
        for (var i = 1; i < this.minstay; i++) {
          startDateCloned.setDate(startDateCloned.getDate() + 1);
          const dateKey = this.$dayjs(startDateCloned).format("MM/DD/YYYY");

          classes[dateKey] = "disallow-for-checkout";
        }
      } else if (!this.startDate || (this.startDate && this.endDate)) {
        let currentDateCloned = new Date(this.currentDate.getTime());
        currentDateCloned.setDate(1);
        const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();

        //console.log("customClasses", this.availabilityData, this.hotelCode, this.rate_code, this.minstay);

        for (let i = 0; i < numberOfDays; i++) {
          const dateKey = this.$dayjs(currentDateCloned).format("MM/DD/YYYY");

          // validate
          const room = this.getRoomAvailabilityData(currentDateCloned);

          if (room == false) {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof room.perNight === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof room.perNight[this.minstay] === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (room.perNight[this.minstay]["available"] <= 0) {
            classes[dateKey] = "disallow-for-checkin";
          }

          currentDateCloned.setDate(currentDateCloned.getDate() + 1);
        }
      }

      //console.log("customClasses", classes);

      return classes;
    },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    }
  },
  methods: {
    moment,
    ...propertyMethods,
    async init() {
      //console.log("INIT::", this.options, this.options.mindate, this.options.maxdate);
      this.room = this.$store.state.app.modalProps.roomData;

      this.rate_code = this.$store.state.app.modalProps.rateCode;
      this.rate = this.$store.state.app.modalProps.rate;
      this.hotelCode = this.$route.query.destination;
      this.room_code = this.room.code;
      this.guests = +this.$route.query.adults + +this.$route.query.children;
      /*
      const maxDateOption = this.$dayjs(this.$store.state.app.options.maxdate).toDate()

      if (this.addToReservationArrival) {
        const arrival = this.dateString2Date(this.addToReservationArrival);

        let minDate = new Date();
        let today = new Date();
        minDate.setTime(arrival.getTime() - 14 * 24 * 3600 * 1000);
        minDate.setHours(0, 0, 0, 0);

        this.minDate = minDate >= today ? minDate : today;
      } else if (this.isModifyReservationMode) {
        const arrival = this.dateString2Date(this.modifyReservation.item.date);

        let minDate = new Date();
        let today = new Date();
        minDate.setTime(arrival.getTime() - 14 * 24 * 3600 * 1000);
        minDate.setHours(0, 0, 0, 0);

        this.minDate = minDate >= today ? minDate : today;
      } else if (this.options.mindate) {
        this.minDate = this.dateString2Date(this.options.mindate);
      } else {
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        this.minDate = date;
      }

      if (this.addToReservationDeparture) {
        const departure = this.dateString2Date(this.addToReservationDeparture);

        let maxDate = new Date();
        maxDate.setTime(departure.getTime() + 14 * 24 * 3600 * 1000);
        if (maxDate > maxDateOption) maxDate = maxDateOption
        maxDate.setHours(0, 0, 0, 0);

        this.maxDate = maxDate;
      } else if (this.isModifyReservationMode) {
        const arrival = this.dateString2Date(this.modifyReservation.item.date);
        const nights = this.modifyReservation.item.nights;
        const departure = this.$dayjs(arrival).add(nights, 'day').toDate();

        let maxDate = new Date();
        
        maxDate.setTime(departure.getTime() + 14 * 24 * 3600 * 1000);
        if (maxDate > maxDateOption) maxDate = maxDateOption
        maxDate.setHours(0, 0, 0, 0);

        this.maxDate = maxDate;
      } else if (this.options.maxdate) {
        this.maxDate = this.dateString2Date(this.options.maxdate);
      } else {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        date = this.$dayjs(date)
          .add(12, "month")
          .toDate();

        this.maxDate = date;
      }
      */
      if (this.$route.query.dateFrom) {
        this.date = this.dateString2Date(this.$route.query.dateFrom);
      }

      //console.log(this)
    },
    onDateRangeSelect(start, end) {
      //console.log("onDateRangeSelect", start, end);
      if (start && !end) {
        this.allowMinDate = this.$dayjs(start)
          .subtract(14, "day")
          .toDate();
        this.allowMaxDate = this.$dayjs(start)
          .add(14, "day")
          .toDate();
      } else {
        this.allowMinDate = null;
        this.allowMaxDate = null;
      }

      this.startDate = start;
      this.endDate = end;

      // try to make the whole calendar visible
      this.$nextTick(function() {
        if (this.$refs.action.offsetHeight) {
          this.$refs.content.style["padding-bottom"] = this.$refs.action.offsetHeight + 10 + "px";
          this.$refs.contentWrapper.scrollTop = 1000;
        }
      });
    },
    onCurrentDateSelect(date) {
      this.currentDate = date;
      this.loadData();
    },
    async loadData() {
      let key = this.$dayjs(this.currentDate).format("MM/YYYY") + "-" + this.room_code;
      if (this.rate_code) {
        key = key + "-" + this.rate_code;
      }

      let data = {
        key: key,
        room_code: this.room_code,
        date: this.$dayjs(this.currentDate).format("MM/DD/YYYY"),
        rate_code: this.rate.code,
        is_group: this.rate?.category === 'GROUP',
        hotelCode: this.hotelCode,
      };

      if (this.isModifyReservationMode) {
        console.log(this.cartToken, this.cartToken)
        data["cart_token"] = this.cartToken
      }

      this.loading = true;
      await this.getAvailabilityRoomsMonthly(data);
      this.loading = false;
    },
    getPriceValue(date) {
      let dateKey = this.$dayjs(date).format("MM/DD/YYYY");
      let price = 0;

      if (typeof this.prices[dateKey] !== "undefined") {
        price = this.prices[dateKey];
      }

      return price;
    },

    getPricesForDate(date) {
      const adults = +this.$route.query.adults;
      const dateKey = this.$dayjs(date).format("MM/DD/YYYY");

      const isSelected = (
        this.startDate &&
        this.computedEndDate &&
        this.$dayjs(date).isBetween(this.startDate, this.computedEndDate, 'day', '[)')
      )

      if (isSelected && this.computedSelectedNights) {
        const startDateRateAvailability = this.startDateAvailability?.rooms
          .find(i => i.rateCode === this.rate_code && i.roomCode === this.room_code);
        const startDateRateAvailabilityPerNight = startDateRateAvailability?.perNight?.[this.computedSelectedNights];
        const startDateRateAvailabilityPerNightPerGuests = startDateRateAvailabilityPerNight?.perGuest?.[adults];
        const nightsIsAvailable = (
          !!startDateRateAvailabilityPerNight?.available &&
          !!startDateRateAvailabilityPerNightPerGuests?.p
        );
        if (nightsIsAvailable) {
          return getPrices({
            base: startDateRateAvailabilityPerNightPerGuests?.p ?? 0,
            fees: startDateRateAvailabilityPerNightPerGuests?.f ?? 0,
            taxes: startDateRateAvailabilityPerNightPerGuests?.t ?? 0,
            nights: this.computedSelectedNights,
          })
        }
      } else {
        const rateDateAvailability = this.availabilityData[dateKey]?.rooms
          .find(i => i.rateCode === this.rate_code && i.roomCode === this.room_code);
        const rateDateAvailabilityPerNight = rateDateAvailability?.perNight?.[1];
        const rateIsAvailable = (
          !!rateDateAvailability?.available2 &&
          !!rateDateAvailabilityPerNight?.perGuest?.[adults]?.p
        );
        if (rateIsAvailable) {
          return getPrices({
            base: rateDateAvailabilityPerNight.perGuest?.[adults]?.p ?? 0,
            fees: rateDateAvailabilityPerNight.perGuest?.[adults]?.f ?? 0,
            taxes: rateDateAvailabilityPerNight.perGuest?.[adults]?.t ?? 0,
            nights: 1,
          })
        }
      }
    },

    getPriceLabel(date) {
      const prices = this.getPricesForDate(date);
      if (prices) {
        return this.$formatCurrency(Math.ceil(prices.avgPrice.subTotal));
      }
    },

    onSelectionPreview(date) {
      this.selectPreviewDate = date;
    },
    onCancel() {
      if (this.startDate) {
        this.startDate = null;
        this.endDate = null;
      } else {
        this.setModal();
      }
    },
    getSelectedDatesPriceValue() {
      //console.log("getSelectedDatesPriceValue", this.startDateRoomData);

      if (
        this.startDateRoomData &&
        typeof this.startDateRoomData["perNight"] !== "undefined" &&
        typeof this.startDateRoomData["perNight"][this.nights] !== "undefined"
      ) {
        let price = this.startDateRoomData["perNight"][this.nights]["price"];
        if (this.startDateRoomData.base < this.guests) {
          price += (this.guests - this.startDateRoomData.base) * this.startDateRoomData.perNight[this.nights].additional
        }
        return Math.ceil(price);
      } else {
        return 0;
      }
    },
    getIsAvailable(date) {
      return this.getPriceValue(date) > 0;
    },
    getRoomAvailabilityData(date) {
      let dateKey = this.$dayjs(date).format("MM/DD/YYYY");
      let rooms = [];
      let room = false;

      //console.log(this.availabilityData)

      if (typeof this.availabilityData[dateKey] !== "undefined") {
        if (typeof this.availabilityData[dateKey]["rooms"] !== "undefined") {
          if (typeof this.availabilityData[dateKey]["rooms"] !== "undefined") {
            rooms = this.availabilityData[dateKey]["rooms"];
          }
        }
      }

      const roomsFiltered = rooms.filter((item) => item.rateCode === this.rate_code && item.roomCode === this.room_code);

      if (roomsFiltered.length === 1) {
        room = roomsFiltered.shift();
      }

      //console.log(room);

      return room;
    },
    tryOpenHotelPage() {
      if (!this.minStayValid) {
        return;
      }

      this.setModal();

      if (this.cartNotEmpty && this.dateRangeChanged) {
        this.setModal("ConfirmationDialog", {
          content: "Are you sure you want to empty your cart and choose another lodging date?",
          onConfirm: this.clearCartAndOpenHotelPage,
        });
      } else {
        this.openHotelPage();
      }
    },
    openHotelPage() {
      this.$router.push({
        name: "hotelPage",
        params: { savePosition: true },
        query: {
          dateFrom: this.$dayjs(this.startDate).format("MM-DD-YYYY"),
          nights: this.nights,
          destination: this.hotelCode,
          adults: this.$route.query.adults,
          children: this.$route.query.children,
          rateCode: this.rate?.code,
          rateType: this.rate?.category === 'GROUP' ? 'group' : 'promo'
        },
        hash: "#" + this.room_code,
      });
    },
    async clearCartAndOpenHotelPage() {
      await this.$store
        .dispatch("property/startOver")
        .then((r) => {
          this.setModal();
          this.openHotelPage();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    onDayClick(day) {
      if (day.disabled) {
        return;
      }

      if (day.customClasses === "disallow-for-checkout") {
        return;
      }

      if (day.customClasses === "disallow-for-checkin") {
        return;
      }

      let date = day.dayjs.toDate();
      let checkOutDay = day.nextAfterLastEnabledDay;
      let startDate = this.startDate;
      let endDate = this.endDate;
      this.selectPreviewDate = null;

      //console.log("checkOutDay", checkOutDay, this.minNights, date, startDate);

      if (startDate && endDate) {
        if (checkOutDay) {
          return;
        }
        this.startDate = date;
        this.endDate = null;

        // remember per night data of selected start date in order to calculate available nights from start date
        this.startDateRoomData = this.getRoomAvailabilityData(date);
      } else if (startDate) {
        if (date < startDate) {
        } else if (+date === +startDate) {
          this.startDate = null;
          this.endDate = null;
        } else {
          this.endDate = date;
        }
      } else if (endDate) {
        // should not happen
      } else {
        if (checkOutDay) {
          return;
        }
        this.startDate = date;

        // remember per night data of selected start date in order to calculate available nights from start date
        this.startDateRoomData = this.getRoomAvailabilityData(date);
      }
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore =  this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
    getDayAvailabilityStatus(dateString) {
      if (
        this.ratePlan?.category === 'GROUP' &&
        !this.$dayjs(dateString, 'MM/DD/YYYY').isBetween(
          this.$dayjs(this.ratePlan.start, 'MM/DD/YYYY'),
          this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY'),
          'day',
          '[]'
        )
      ) {
        return {
          value: 'notInGroupDates',
          text: 'Outside of Group Dates',
        }
      }
      // @TODO implelent other statues
      return {
        value: 'available',
        text: 'Available',
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      this.onResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  updated() {
    // try to make the whole calendar visible
    this.$nextTick(function() {
      if (this.$refs.action.offsetHeight) {
        this.$refs.content.style["padding-bottom"] = this.$refs.action.offsetHeight + 10 + "px";
        //this.$refs.contentWrapper.scrollTop = 1000;
      }
    });
  },
};
