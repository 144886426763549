import _ from 'lodash'
import { isJSON } from "@/utils/index"

export function getStateFromLocalStorage(path) {
  const dataString = localStorage.getItem('vuex')
  const data = isJSON(dataString) ? JSON.parse(dataString) : {}
  return _.get(data, path)
}

export function setStateToLocalStorage(path, value) {
  const dataString = localStorage.getItem('vuex')
  const data = isJSON(dataString) ? JSON.parse(dataString) : {}
  _.set(data, path, value)
  localStorage.setItem('vuex', JSON.stringify(data))
}