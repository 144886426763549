import _ from 'lodash'
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),

  props: {
    value: {
      type: [Object, Array, Number, String],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newValue: null,
      changedAt: null,
      savedAt: null
    }
  },

  computed: {
    isDirty() {
      return !_.isEqual(this.value, this.newValue)
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,

      handler() {
        this.setNewValue()
      }
    },

    newValue: {
      immediate: true,
      deep: true,

      handler() {
        this.changedAt = +new Date()

        if (this.autosave) {
          if (this.debounce) {
            this.$nextTick(() => {
              if (this.isDirty) {
                this.debounceSubmit('autosave')
              }
            })

          } else {
            this.submit('autosave')
          }
        }
      }
    }
  },

  created() {
    const debounce = _.isNumber(this.debounce) ? this.debounce : 2000

    this.debounceSubmit = _.debounce(async function(submitEventName) {
      if (this.isDirty) {
        this.savedAt = +new Date()
        await this.submit(submitEventName)
      }
    }, debounce)
  },

  validations: {},

  methods: {
    setNewValue() {
      const newValue = this.getNewValue()

      if (!_.isEqual(newValue, this.newValue)) {
        this.newValue = newValue
      }
    },

    getNewValue() {
      return _.cloneDeep(this.value)
    },

    submit(submitEventName) {
      if (submitEventName !== 'autosave') {
        this.v$.$touch()

        if (this.v$.$error) {
          return
        }
      }
      
      this.$emit('input', _.cloneDeep(this.newValue))

      // Emit submit event
      if (submitEventName && submitEventName !== 'input') {
        this.$emit(submitEventName,  _.cloneDeep(this.newValue))
      }
    },

    getAFieldValidateStatus(vuelidateParam) {
      let validateStatus
      const state = this.getVuelidateFieldStatus(vuelidateParam)

      if (state === false) {
        validateStatus = 'error'

      } else if (status) {
        validateStatus = 'success'
      }

      return validateStatus
    },

    getAFieldHelp(vuelidateParam) {
      let validateStatus
      const state = this.getVuelidateFieldStatus(vuelidateParam)

      if (state === false) {
        validateStatus = this.getVuelidateFieldInvalidFeedback(vuelidateParam)
      }

      return validateStatus
    },

    getVuelidateFieldStatus(vuelidateParam) {
      return vuelidateParam && vuelidateParam.$dirty ? !vuelidateParam?.$invalid : null
    },

    getVuelidateFieldInvalidFeedback(vuelidateParam) {
      if (!vuelidateParam || !vuelidateParam.$dirty || !vuelidateParam?.$invalid) {
        return null
      }

      let invalidFeedback = 'This field is invalid'

      if (vuelidateParam) {
        if (vuelidateParam.required?.$invalid === true) {
          invalidFeedback = 'This value is required.'

        } else if (vuelidateParam.minLength?.$invalid === true) {
          invalidFeedback = `Minimum ${vuelidateParam.minLength.$params.min} length.`

        } else if (vuelidateParam.maxLength?.$invalid === true) {
          invalidFeedback = `Maximum ${vuelidateParam.maxLength.$params.max} length.`

        } else if (vuelidateParam.between?.$invalid === true) {
          if (vuelidateParam.between.$params.min === vuelidateParam.between.$params.max) {
            invalidFeedback = `Value must be equal to ${vuelidateParam.between.$params.min}.`
          } else {
            invalidFeedback = `Value max be between ${vuelidateParam.between.$params.min} and ${vuelidateParam.between.$params.max}.`
          }

        } else if (vuelidateParam.email?.$invalid === true) {
          invalidFeedback = `Invalid email address.`

        } else if (vuelidateParam.sameAs?.$invalid === true) {
          invalidFeedback = `Two inputs don't match.`
        }
      }

      return invalidFeedback
    }
  }
}