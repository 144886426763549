import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import * as middleware from "./middleware";
import store from "../../store";
import { gtmPageView } from "@/lib/gtm";
import { optionsComputed } from "@/store/helper";

let appOptions = { ...optionsComputed };

Vue.use(Router);

const checkAccess = (to) => {
  const meta = Object.assign({}, ...(to.matched.map((item) => item.meta) || {}));

  const role = meta.role || "";

  if (role && !middleware[role]()) return false;

  document.title = meta.title || "Xanterra";
  return true;
};

const parseRoutes = (array) =>
  array.map((item) => {
    const route = { ...item };
    if (!route.meta) {
      route.meta = {};
    }
    if (route.children) {
      route.children = parseRoutes(route.children);
    }
    if (route.component) {
      route.meta = { ...route.meta, ...(route.component.meta || {}) };
    }
    return route;
  });

const router = new Router({
  base: import.meta.env.BASE_URL,
  linkActiveClass: "",
  linkExactActiveClass: "active",
  mode: "history",
  routes: parseRoutes(routes),
  scrollBehavior(to, from, savedPosition) {
    const fromHistory = Boolean(savedPosition);

    if (to && to.params && to.params.savePosition) {
      return {};
    }

    if (fromHistory && store.state.app.routerHistory.length > 0) {
      store.state.app.routerHistory.splice(-1, 1);
    } else {
      store.state.app.routerHistory.push(from);
    }

    return savedPosition || { x: 0, y: 0 };
  },
});

async function clearCart() {
  await store
    .dispatch("property/startOver")
    .then((r) => {
      store.dispatch("property/clearCartLocally");
      store.dispatch("app/setModal");
      router.push({ name: "book_stay" });
    })
    .catch((e) => {
      console.error(e);
    });
}

router.beforeEach((to, from, next) => {
  // Show dialog before go to confirmation
  if (
    (to.name === 'book_stay' || to.name === 'book_activity') &&
    !store.getters['property/cartIsEmpty'] &&
    !to.params.skipCartCheck
  ) {
    if (router.app.$bvModal) {
      // @TODO move all options to bootstrap settings
      router.app.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and choose another dates?', {
        title: 'Confirm',
        headerBgVariant: 'secondary',
        titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
        contentClass: 'bg-gamma',
        bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
        footerClass: 'border-0',
        okTitle: 'Yes',
        okVariant: 'primary',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        cancelVariant: 'outline-primary',
        cancelTitle: 'Cancel',
        buttonSize: 'lg',
        centered: true
      })
        .then((value) => {
          if (value) {
            store.dispatch('property/clearCart')
            next()
          }
        })
      
      return

    } else {
      return next({ name: 'ReviewPage' })
    }
  }
  // clear cart confirmation
  /*
  if (to.name === "book_stay") {
    if (!to.params.skipCartCheck && store.state.property.cart && Object.keys(store.state.property.cart).length) {
      store.dispatch("app/setModal", "ConfirmationDialog");
      store.dispatch("app/setModalProps", {
        content: "Are you sure you want to empty your cart and choose another lodging date?",
        onConfirm: clearCart,
      });

      return;
    }
  }
  */

  if (to.name === "ReservationListPage") {
    if (!store.state.auth.status) {
      store.dispatch("app/setModal", "Login");
      store.dispatch("app/setRedirectRoute", "ReservationListPage");
      next('/');
      return;
    }
  }

  /*
  if (from.name === "ConfirmationPage") {
    store.dispatch('property/setLastReservation', null)
  }
  */

  // keep modify reservation mode for specific routes only
  if (store.getters["app/isModifyReservationMode"] && to.name !== "ReviewPage" && to.name !== "hotelPage") {
    store.dispatch("app/endModifyReservation");
  }

  window.scrollTo({ top: 0, behavior: "smooth" });
  if (checkAccess(to)) next();
  else next("/");
});

router.afterEach((to, from) => {
  //console.log("afterEach", to, from);
  Vue.nextTick(function() {
    gtmPageView();
  });
});

export default router;
