import { apiSignIn } from '@/api/auth'
import { required, email } from '@vuelidate/validators'
import {useVuelidate} from "@vuelidate/core"

export default {
  setup: () => ({v$: useVuelidate()}),

  data: () => ({
    passwordVisible: false,
    email: '',
    password: '',
    loading: false,
    errors: {}
  }),

  computed: {
    isPasswordVisible() {
      return this.passwordVisible ? 'text' : 'password'
    },

    redirectTo() {
      return this.$store.getters["app/redirectTo"];
    }
  },

  validations () {
    return {
      email: {required, email},
      password: {required},
    }
  },

  methods: {
    async handleSignIn() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      try {
        this.loading = true
        const res = await apiSignIn(this.email, this.password, {
          silent: true,
        })
        await this.$auth.login(res.access_token, res.refresh_token, res.expires_in, res.token_type, {silent: true})
        this.$eventHub.$emit('login')

        // redirect after login feature
        if (this.redirectTo) {
          this.$router.push({name: this.redirectTo});
        }

        this.handleClose();
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors, detail: err.detail }
      }
      finally {
        this.loading = false
        this.$store.dispatch('app/setErrors')
      }
    },

    handleClose() {
      this.setModal();
      this.$store.dispatch('app/forgetRedirectRoute');
    }
  }
}
