import Vue from 'vue'

Vue.filter('money', (v) => {
  v = (v || 0)
    .toString()
    .replace(/[^-.0-9]/gim, '')
    .trim()
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(v)
})

Vue.filter('capitalize', (v) => {
  if (!v) return ''
  v = v.toString()
  return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()
})
