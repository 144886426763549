import { apiAppInfo, apiHotelList, apiTourList } from "@/api/property";
import { isJSON } from "@/utils/index";
import dayjs from "dayjs";
import { getStateFromLocalStorage, setStateToLocalStorage } from '@/store/utils'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    routerHistory: [],
    options: {},
    modal: "",
    modalProps: {},
    modalContainerClass: "",
    width: "",
    errors: {},
    init: false,
    initError: false,
    debug: {
      server: null,
      log: [],
    },
    debugResponses: [],
    error429RetryAfter: null,
    hotelMinPrice: 0,
    hotelMaxPrice: 0,
    hotelFilter: [],
    hotelOrder: "",
    roomMinPrice: 0,
    roomMaxPrice: 0,
    roomFilter: [],
    roomOrder: "",
    addToReservation: getStateFromLocalStorage('app.addToReservation'),
    addToReservationSkipped: getStateFromLocalStorage('app.addToReservationSkipped'),
    // Relace usage following by addToReservation
    /*
    addToReservationToken: localStorage.getItem("addToReservationToken") ? localStorage.getItem("addToReservationToken") : null,
    addToReservationId: localStorage.getItem("addToReservationId") ? localStorage.getItem("addToReservationId") : null,
    addToReservationArrival: localStorage.getItem("addToReservationArrival") ? localStorage.getItem("addToReservationArrival") : null,
    addToReservationDeparture: localStorage.getItem("addToReservationDeparture") ? localStorage.getItem("addToReservationDeparture") : null,
    */

    loading: false,
    setRedirectRoute: "",
    storage: localStorage.getItem('storage') && isJSON(localStorage.getItem('storage')) ? JSON.parse(localStorage.getItem('storage')) : {},
    mReservation: localStorage.getItem("mReservation") ? localStorage.getItem("mReservation") : null,
  },
  mutations: {
    setOptions(state, value) {
      state.options = value;
    },
    setModal(state, value) {
      state.modal = value;
    },
    setModalProps(state, value) {
      state.modalProps = value;
    },
    setModalContainerClass(state, value) {
      state.modalContainerClass = value;
    },
    setErrors(state, value = {}) {
      state.errors = value;
    },
    setDebugServer(state, value = null) {
      state.debug.server = value;
    },
    setDebugLog(state, value = []) {
      state.debug.log = value;
    },
    setInit(state, value = true) {
      state.init = value;
    },
    setInitError(state, value = true) {
      state.initError = value;
    },
    setHotelMinPrice(state, value = 0) {
      state.hotelMinPrice = value;
    },
    setHotelMaxPrice(state, value = 0) {
      state.hotelMaxPrice = value;
    },
    setHotelFilter(state, value = []) {
      state.hotelFilter = value;
    },
    setHotelOrder(state, value = true) {
      state.hotelOrder = value;
    },
    setRoomMinPrice(state, value = 0) {
      state.roomMinPrice = value;
    },
    setRoomMaxPrice(state, value = 0) {
      state.roomMaxPrice = value;
    },
    setRoomFilter(state, value = []) {
      state.roomFilter = value;
    },
    setRoomOrder(state, value = true) {
      state.roomOrder = value;
    },
    setAddToReservation(state, value) {
      state.addToReservation = value
      setStateToLocalStorage('app.addToReservation', state.addToReservation)
    },
    setAddToReservationSkipped(state, value) {
      state.addToReservationSkipped = value
      setStateToLocalStorage('app.addToReservationSkipped', state.addToReservationSkipped)
    },
    /*
    setAddToReservationToken(state, value = true) {
      state.addToReservationToken = value;
    },
    setAddToReservationId(state, value = true) {
      state.addToReservationId = value;
    },
    setAddToReservationArrival(state, value = true) {
      state.addToReservationArrival = value;
    },
    setAddToReservationDeparture(state, value = true) {
      state.addToReservationDeparture = value;
    },
    */
    setLoading(state, value = true) {
      state.loading = value;
    },
    setRedirectRoute(state, value = true) {
      state.setRedirectRoute = value;
    },
    setStorageValue(state, value) {
      for (var i in value) {
        state.storage[i] = value[i];
      }

      localStorage.setItem('storage', JSON.stringify(state.storage));
    },
    setMReservation(state, value = null) {
      state.mReservation = value;
    },
    setDebugResponses(state, value) {
      state.debugResponses = value
    },
    setError429RetryAfter(state, value) {
      state.error429RetryAfter = value
    },
  },
  actions: {
    async initApp({ commit }) {
      
      try {
        let [options, hotels, tours] = await Promise.all([apiAppInfo(), apiHotelList(), apiTourList()]);
        /*
        let parts = []
        let splittedDate = options.maxdate.split('/')
        parts[0] = splittedDate[2]
        parts[1] = splittedDate[0]
        parts[2] = splittedDate[1]

        var dt = new Date(
          parseInt(parts[0], 10),      // year
          parseInt(parts[1], 10) - 1,  // month (starts with 0)
          parseInt(parts[2], 10)       // date
        );
        dt.setTime(dt.getTime() + 1 * 86400000);
        parts[0] = "" + dt.getFullYear();
        parts[1] = "" + (dt.getMonth() + 1);
        if (parts[1].length < 2) {
            parts[1] = "0" + parts[1];
        }
        parts[2] = "" + dt.getDate();
        if (parts[2].length < 2) {
            parts[2] = "0" + parts[2];
        }
        let maxDateNew =[]

        maxDateNew[0] = parts[1]
        maxDateNew[1] = parts[2]
        maxDateNew[2] = parts[0]

        options.toursMaxdate = options.maxdate
        options.maxdate = maxDateNew.join("/")
        */

        commit("setOptions", options);
        commit("property/setHotels", hotels, { root: true });
        commit("property/setTours", tours, { root: true });
      } catch (err) {
        commit("setInitError");
        console.error("Error occurred while init app");
        console.error(err);
      }
    },
    async ready({ commit }) {
      commit("setInit");
    },
    setDebug({ commit, state }, value) {
      const server = Array.isArray(value.ows) ? value.ows[0] && value.ows[0].server : null;
      !state.debug.server && commit("setDebugServer", server);
      commit("setDebugLog", [
        // ...state.debug.log,
        ...(Array.isArray(value.ows) ? value.ows : []),
      ]);
    },
    setModal({ commit }, value) {
      commit("setModal", value);
    },
    setModalProps({ commit }, value) {
      commit("setModalProps", value);
    },
    setModalContainerClass({ commit }, value) {
      commit("setModalContainerClass", value);
    },
    setErrors({ commit }, value = {}) {
      commit("setErrors", value);
    },
    setHotelMinPrice({ commit }, value = []) {
      commit("setHotelMinPrice", value);
    },
    setHotelMaxPrice({ commit }, value = []) {
      commit("setHotelMaxPrice", value);
    },
    setHotelFilter({ commit }, value = []) {
      commit("setHotelFilter", value);
    },
    setHotelOrder({ commit }, value = {}) {
      commit("setHotelOrder", value);
    },
    setRoomMinPrice({ commit }, value = []) {
      commit("setRoomMinPrice", value);
    },
    setRoomMaxPrice({ commit }, value = []) {
      commit("setRoomMaxPrice", value);
    },
    setRoomFilter({ commit }, value = []) {
      commit("setRoomFilter", value);
    },
    setRoomOrder({ commit }, value = {}) {
      commit("setRoomOrder", value);
    },
    async startAddToReservation({ commit }, value = {}) {
      if (value) {
        // Clear cart if cart is not empty
        if (!this.getters['property/cartIsEmpty']) {
          await this.dispatch('property/clearCart')
        }

        commit('setAddToReservation', value)
        // Should be deprecated later?
        /*
        commit("setAddToReservationToken", value.token);
        commit("setAddToReservationId", value.uniqueId);
        commit("setAddToReservationArrival", value.arrival);
        commit("setAddToReservationDeparture", value.departure);
        localStorage.setItem("addToReservationToken", value.token);
        localStorage.setItem("addToReservationId", value.uniqueId);
        localStorage.setItem("addToReservationArrival", value.arrival);
        localStorage.setItem("addToReservationDeparture", value.departure);
        */
      }
    },
    async endAddToReservation({ commit }) {
      commit('setAddToReservation', null)
      // Should be deprecated later?
      /*
      commit("setAddToReservationToken", null);
      commit("setAddToReservationId", null);
      commit("setAddToReservationArrival", null);
      commit("setAddToReservationDeparture", null);
      localStorage.removeItem("addToReservationToken");
      localStorage.removeItem("addToReservationId");
      localStorage.removeItem("addToReservationArrival");
      localStorage.removeItem("addToReservationDeparture");
      */
    },
    setAddToReservationSkipped({ commit }, value) {
      commit('setAddToReservationSkipped', value)
    },
    setLoading({ commit }, value = []) {
      commit("setLoading", value);
    },
    setRedirectRoute({ commit }, value = "") {
      commit("setRedirectRoute", value);
    },
    forgetRedirectRoute({ commit }) {
      commit("setRedirectRoute", '');
    },
    putToStorage({ commit }, value) {
      commit("setStorageValue", value);
    },
    async startModifyReservation({ commit, dispatch }, value = {}) {
      const reservation_token = value.item.itemToken;

      value = JSON.stringify(value);
      commit("setMReservation", value);
      localStorage.setItem("mReservation", value);

      await dispatch("property/fetchModifyCartToken", reservation_token, { root: true });
    },
    endModifyReservation({ commit, dispatch }) {
      commit("setMReservation", null);
      localStorage.removeItem("mReservation");
      localStorage.removeItem("availableRoomsAmount");

      dispatch("property/clearCartLocally", {}, { root: true });
      dispatch("property/setCartToken", null, { root: true });      
    },
    setDebugResponse({ commit, state }, value) {
      const newDebugResponse = { ...value, timestamp: dayjs().unix() }
      const newDebugResponses = [newDebugResponse, ...state.debugResponses].slice(0, 5)
      commit('setDebugResponses', newDebugResponses)
    },
    setError429RetryAfter({ commit }, value) {
      commit('setError429RetryAfter', value)
    },
  },
  getters: {
    errors: (state) => state.errors,
    previousRoute: (state) => {
      const historyLen = state.routerHistory.length;
      if (historyLen == 0) return null;
      return state.routerHistory[historyLen - 1];
    },
    redirectTo: (state) => {
      return state.setRedirectRoute;
    },
    modifyReservation: (state) => {
      return isJSON(state.mReservation) ? JSON.parse(state.mReservation) : null
    },
    isModifyReservationMode: (state) => {
      return isJSON(state.mReservation);
    },
    addToReservation: (state) => state.addToReservation,
    addToReservationSkipped: (state) => state.addToReservationSkipped,
    addToReservationToursByDate: state => _.reduce(state.addToReservation?.sections?.Tours?.items, (result, value, key) => {
      result[value.date] = result[value.date] ?? {};
      result[value.date][key] = value;
      return result;
    }, {}),
    options: state => state.options,
    groupPropertyActivities: state => ['yellowstonenationalparklodges'].includes(state.options.code),
    shownModal: state => !!state.modal,
    resformMaxOccupancy: state => parseInt(state.options.resformMaxOccupancy),
    debugResponses: state => state.debugResponses,
    error429RetryAfter: state => state.error429RetryAfter
  },
};
