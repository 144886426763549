<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="openPopup"
  >    
    <slot />

    <b-modal
      v-model="popupIsOpened"
      dialog-class="w-400px modal-down-sm-fullscreen"
      header-bg-variant="secondary"
      header-class="rounded-0 pl-4 pr-2 border-0 close-button-black"
      content-class="rounded-0 border-0 bg-gamma"
      centered
      title-class="text-white text-uppercase font-family-2 line-height-12"
      body-class="p-0 bg-gamma"
      hide-footer
      scrollable
    >
      <template #modal-title>
        {{ title }}
      </template>

      <ReservationItemFind
        ref="item"
        class="h-100 overflow-hidden"
        @found="$emit('found', $event); popupIsOpened = false"
        @cancel="$emit('cancel'); popupIsOpened = false"
      />
    </b-modal>
  </component>
</template>

<script>
import ReservationItemFind from '../ReservationItemFind.vue'
import crudItemMixin from '@/mixin/crud-item'
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'ReservationItemFindButton',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    crudItemMixin
  ],

  components: {
    ReservationItemFind
  },

  props: {
    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      idKey: 'token',
      popupIsOpened: false
    }
  },

  computed: {
    title() {
      return `Find reservation`
    }
  },

  methods: {
    openPopup() {
      this.popupIsOpened = true
    }
  }
}
</script>

