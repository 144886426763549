<template>
  <b-row no-gutters>
    <b-col
      v-for="(date, dateIndex) in dates"
      :key="dateIndex"
      cols="6"
      class="p-1"
    >
      <b-button
        class="p-1 text-uppercase font-size-9"
        :class="{
          'text-white': value.getTime() === date.getTime(),
        }"
        block
        :variant="value.getTime() === date.getTime() ? 'primary' : 'outline-primary'"
        :style="{
          border: '1px solid #CCC',
          color: '#666'
        }"
        @click="$emit('input', date)"
      >
        {{ $dayjs(date).format("MMMM YYYY") }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DateFlexibleSelect',

  props: {
    value: {
      type: Date,
      default: null
    },

    minDate: {
      type: Date,
      required: true
    },

    maxDate: {
      type: Date,
      required: true
    }
  },

  computed: {
    monthsAmount() {
      const firstMonth = this.$dayjs(this.minDate).date(1)
      const lastMonth = this.$dayjs(this.maxDate).date(1)
      return lastMonth.diff(firstMonth, 'month') + 1
    },

    dates() {
      return _.chain(_.range(0, this.monthsAmount))
        .map(i => {
          let date = this.$dayjs(this.minDate).date(1).add(i, 'M').toDate()
          return this.$dayjs.max(this.$dayjs(date), this.$dayjs(this.minDate)).toDate()
        })
        .value()
    }
  }
}
</script>