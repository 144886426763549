import { propertyMethods } from "@/store/helper";
import TruncatedText from '@/components/TruncatedText/index.vue'
import { mapGetters } from "vuex";
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'

export default {
  components: {
    TruncatedText,
    HotelItemImages,
  },
  props: {
    data: Object,
    availabilityData: Object,
    prices: Object,
    oldPrices: Object,
    pricesPerOneGuest: Object,
    rate: Object,
    minAgeAdult: Number,
    minAgeChild: Number
  },
  data: () => ({
    shouldViewMore: true,
    isViewingMore: false,
  }),
  created() {
    if(this.$route.hash) {
      let codeRoom = this.$route.hash.slice(1)
      if(codeRoom === this.availabilityData.roomCode) {
        this.onAddRoomModal()
      }
    }
    //console.log('data: ', this.data);
  },
  computed: {
    ...mapGetters({
      options: 'app/options',
    }),
    nights() {
      return +this.$route.query.nights;
    },
    adults() {
      return +this.$route.query.adults;
    },
    children() {
      return +this.$route.query.children;
    },
    guests() {
      return this.adults + this.children;
    },
    selected() {
      return (
        this.$store.state.property.addedRooms &&
        this.$store.state.property.addedRooms[this.availabilityData.hotelCode] &&
        this.$store.state.property.addedRooms[this.availabilityData.hotelCode][this.availabilityData.rateCode] &&
        this.$store.state.property.addedRooms[this.availabilityData.hotelCode][this.availabilityData.rateCode][
          this.availabilityData.roomCode
        ]
      );
    },
    isSameDate() {
      return (
        this.$dayjs(this.$store.state.property.addedRooms[this.availabilityData.hotelCode][this.availabilityData.rateCode][
          this.availabilityData.roomCode
        ].date).format("MM/DD/YYYY") === this.$dayjs(this.$route.query.dateFrom).format("MM/DD/YYYY")
      )
    },
    // priceWithAdds(price, adults, additional, base, nights) {
    //   if (price && adults && additional && base) {
    //     if (nights) {
    //       return price * nights + ((+adults <= base ? 0 : +adults - base) * additional)
    //     } else {
    //       return price + ((+adults <= base ? 0 : +adults - base) * additional)
    //     }
    //   }
    // },
    quantity() {
      return this.$store.state.property.addedRooms[this.availabilityData.hotelCode][this.availabilityData.rateCode][
        this.availabilityData.roomCode
      ].quantity;
    },
    availMessage() {
      const name = this.isRvOrTent ? 'SITE' : 'ROOM'

      if (this.availabilityData.available === 0) {
        return +this.availabilityData.max >= +this.$route.query.adults + +this.$route.query.children ? 'SOLD OUT' : 'MAX GUESTS EXCEEDED'

      } else if (this.availabilityData.max < +this.$route.query.adults + +this.$route.query.children) {
        return 'MAX GUESTS EXCEEDED'

      }

      return `ONLY ${this.availabilityData.available} ${this.availabilityData.available > 1 ? name + "S" : name} LEFT`

      /*
      let name;
      if (this.isRvOrTent) {
        name = "SITE";
      } else {
        name = "ROOM";
      }


      return this.availabilityData.available === 0
        ? +this.availabilityData.max >= +this.$route.query.adults + +this.$route.query.children
          ? "SOLD OUT"
          : "MAX GUESTS EXCEEDED"
        : +this.availabilityData.max >= +this.$route.query.adults + +this.$route.query.children
        ? `ONLY ${this.availabilityData.available} ${this.availabilityData.available > 1 ? name + "S" : name} LEFT`
        : "MAX GUESTS EXCEEDED";
      */
    },
    dateFrom() {
      return this.$route.query.dateFrom ? this.dateString2Date(this.$route.query.dateFrom) : new Date();
    },
    cancelBeforeDate() {
      const settings = this.options.cancelBeforeHotels?.find(i => (
        this.$dayjs(this.$route.query.dateFrom, 'MM-DD-YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(this.$route.query.dateFrom, 'MM-DD-YYYY')
        .subtract(value, 'day')
        .toDate();
      
      return cancelBeforeDate;
    },
    cancelationAvailable() {
      const today = new Date();
      return this.cancelBeforeDate && this.cancelBeforeDate > today;
    },
    cancelationMessage() {
      return this.cancelationAvailable ? (
        `free cancelation before ${this.$dayjs(this.cancelBeforeDate).format('ddd, MMM D')}`
      ) : 'cancelation fee applies';
    }, 
    getImageUrl() {
      return !!this.data && !!this.data.images.length && this.getCdnResourceUrl(this.data.images[0].image);
    },
    getImageTitle() {
      return !!this.data && !!this.data.images.length && this.data.images[0].title;
    },
    isRvOrTent() {
      return (
        !!this.data &&
        (this.data.type === "rv" || this.data.type === "tent" || this.data.type === "aba_rv" || this.data.type === "aba_tent")
      );
    },
    roomTitle() {
      return !!this.data && this.data.title.replace("amp;", "");
    },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    },
    price() {
      let pricePerNight = this.availabilityData.price;
      if (this.availabilityData.base < this.guests) {
        pricePerNight += (this.guests - this.availabilityData.base) * this.availabilityData.additional;
      }
      return pricePerNight * this.nights;
    }
  },
  methods: {
    ...propertyMethods,
    onViewCalendar(data) {
      this.clearAvailabilityRoomsMonthlyCache();
      this.setModal("RoomRateCalendar", data);
    },
    onAddRoomModal() {
      if (this.availabilityData.available < 1) {
        // do not open modal if no availability
        return;
      }
      const data = {
        minAgeChild: this.minAgeChild,
        minAgeAdult: this.minAgeAdult,
        roomAvailability: this.availabilityData,
        roomData: this.data,
        rate: this.rate,
        modalContainerClass: "add-room-modal-container",
      };

      console.log(data);
      //modalContainerClass
      this.setModal("AddRoom", data);
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore =  this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
  },
  mounted() {
    // window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      // this.onResize();
    });
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
  },
};
