<template>
  <section class="profile__section">
    <h2 class="profile__section-title">
      CHANGE PASSWORD
    </h2>
    <form>
      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-email"> Old Password </label>
          <a-input
            placeholder="Old password"
            id="profile-email"
            class="input input--auth"
            :type="isPasswordVisible"
            :class="{ 'input--error': v$.password.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.password.$model"
            name="password"
            :maxLength="60"
          />
          <a-checkbox
            :checked="passwordVisible"
            @change="passwordVisible = !passwordVisible"
          >
            Show password
          </a-checkbox>
          <div
            class="form__item-error"
            v-if="v$.password.required?.$invalid && v$.password.$error"
          >
            Field is required
          </div>
          <div class="form__item-error" v-if="v$.password.notCreditCard?.$invalid && v$.password.$error">
            Value can't be credit card number
          </div>
          <div class="form__item-error" v-if="errors.username">
            <span v-for="err in errors.username" :key="err">
              {{ err }}
            </span>
          </div>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-phone"> New Password </label>
          <a-input
            placeholder="New password"
            id="profile-phone"
            :type="isNewPasswordVisible"
            class="input input--auth"
            :class="{ 'input--error': v$.newPassword.$error }"
            v-model.trim="v$.newPassword.$model"
            @focus="resetFormFieldErrors"
            name="new_password"
            :maxLength="60"
          />
          <a-checkbox
            :checked="newPasswordVisible"
            @change="newPasswordVisible = !newPasswordVisible"
          >
            Show password
          </a-checkbox>
          <div
              class="form__item-error"
              v-if="v$.newPassword.password?.$invalid && v$.newPassword.$error"
          >
            Password must be 8-60 chars length, contain at least one
            uppercase, one lowercase, one special character and one digit
          </div>
          <div
            class="form__item-error"
            v-if="v$.newPassword.required?.$invalid && v$.newPassword.$error"
          >
            Field is required
          </div>
          <ul class="form__item-error" v-if="errors.new_password">
            <li v-for="err in errors.new_password" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
      </div>
    </form>
    <div class="profile__buttons js-end">
      <a-button
        class="button button-primary"
        @click="handlePasswordChange"
        :disabled="loading"
      >
        Save changes
      </a-button>
    </div>
  </section>
</template>

<script>
import { required } from '@vuelidate/validators'
import { apiUpdateProfile } from '@/api/profile'
import { password, notCreditCard } from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    password: '',
    newPassword: '',
    passwordVisible: false,
    newPasswordVisible: false,
    loading: false,
    errors: {}
  }),
  validations: () => {
    return {
      password: {
        required,
        notCreditCard,
      },
      newPassword: {
        required,
        password
      }, // 8,60
    }
  },
  computed: {
    isPasswordVisible() {
      return this.passwordVisible ? 'text' : 'password'
    },
    isNewPasswordVisible() {
      return this.newPasswordVisible ? 'text' : 'password'
    }
  },
  methods: {
    async handlePasswordChange() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      try {
        this.loading = true
        const res = await apiUpdateProfile['password']({ password: this.password, new_password: this.newPassword })
        if (res && res.status === 'SUCCESS') {
          this.$auth.logout()
          this.$msg('Password changed, log in with new credentials', 1)
          this.setModal('Login')
        }
      } catch (error) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
        this.$store.dispatch('app/setErrors')
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>
