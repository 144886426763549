import { mapGetters } from "vuex";
import { apiHotel, apiHotelAlerts, apiHotelRooms } from "@/api/property";
import { apiAvailabilityRooms } from "@/api/availability";
import { CalendarIcon } from "@/components/Icons";
import { optionsComputed, propertyMethods, appValuesComputed } from "@/store/helper";
import { gtmTrack } from "@/lib/gtm";
import bookingMixin from '@/mixin/booking'
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'
import TruncatedText from '@/components/TruncatedText/index.vue'
import _ from 'lodash';
import { useWindowSize } from 'vue-window-size';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import {getPrices} from '@/utils/taxes-and-fees'

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),

  mixins: [
    bookingMixin
  ],

  components: {
    HotelItemImages,
    TruncatedText
  },

  data: () => ({
    calendarIcon: CalendarIcon(),
    today: new Date().setHours(0, 0, 0, 0),
    hotel: null,
    rooms: null,
    checkedButton: null,
    initialTransformValue: 0,
    transformValue: 0,
    tabTransformWidth: 0,
    availabilityData: { date: new Date(), nights: 1, rate_code: "ALL" },
    // alertMessages: {},
    hotelAlerts: null,
    roomsAvailability: [],
    roomsAvailabilityResponse: {},
    rates: {},
    activeRates: {},
    minRatePrices: {},
    isLoadingRooms: false,
    special: `<div
                  class="special"
                  v-if="rate.category === 'PROMOREQ'"
              >
                <span>Special</span>
              </div>`,
    minPrice: 0,
    maxPrice: 0,
    shouldViewMore: false,
    isViewingMore: false,
    ada_info: false,
    priceFilterReady: false,
    urlQueryParams: {},
    allowRouteLeave: false,
    noRoomsOnModifySearch: false,
    minRooms: {},
    code: ''
  }),
  methods: {
    ...propertyMethods,
    async init() {
      // reset filter
      this.$store.dispatch("app/setRoomFilter", []);

      // VALIDATE SEARCH PARAMS
      if (!this.searchParamsIsValid) {
        this.$router.push({
          name: "book_stay",
          params: { skipCartCheck: true }
        })
        return
      }

      // VALIDATE RATE
      if (this.searchParams.rateCode) {
        // Fetch rate plan
        try {
          await this.$store.dispatch('property/fetchHotelsRatePlan', {
            rateCode: this.searchParams.rateCode,
            rateType: this.searchParams.rateType
          })
        } catch (e) {
          console.log(`Rate code (${this.searchParams.rateType}) is invalid ${e}`)
        }

        // If not rate plan
        if (!this.ratePlan) {
          this.$bvModal.msgBoxConfirm('Sorry, but the rate code you used is not a valid discount code.', {
            title: 'Error',
            headerBgVariant: 'secondary',
            headerClass: 'close-button-black',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma ok-only',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Ok',
            okVariant: 'primary',
            hideHeaderClose: false,
            cancelVariant: 'outline-primary',
            buttonSize: 'lg',
            centered: true
          })
            .then(() => {
              this.$router.push({
                name: 'hotelPage',
                query: {
                  ...this.$route.query,
                  rateCode: undefined,
                  rateType: undefined
                }
              })
            })
          return
        }
      }

      // AGAIN VALIDATE SEARCH PARAMS - if rate plan updated min and max dates
      if (!this.searchParamsIsValid) {
        this.$router.push({
          name: "book_stay",
          params: { skipCartCheck: true }
        })
        return
      }

      /*
      //@TODO: refactor stupid steps logic
      this.doStupidStuff();

      if (!this.validateUrl()) {
        this.allowRouteLeave = true;
        return this.$router.push({ name: "book_stay", params: { skipCartCheck: true }});
      }

      // load rates. in rate code invalid - go home
      await this.loadRateplans();

      if (!this.ratePlansValid()) {
        const that = this;

        // redirect to INTERNET rate
        this.setConfirmationModal({
          content: "Sorry, but the promo code you used is not a valid discount code.",
          header: "Error",
          confirm: "Ok",
          disableDecline: true,
          confirmOnClose: true
        })
        .then(function() {
          that.$route.query.rateCode = "";
          return that.normalizeUrl();
        })
      }

      if (this.urlNeedsNormalization()) {
        return this.normalizeUrl();
      }

      this.roomsAvailability = [];
      this.code = ''
      */

      await this.loadHotel();
      await this.loadHotelAvailability();
      await this.loadRooms();
      await this.loadRoomsAvailability();
      await this.loadHotelAlerts();
      await this.loadCart();

      /*
      40 - page left-padding + right-padding
      0.84 - tab scroll-bar width in percents
      0.2 - tab width in percents
      8 - margin-left of each tab except the 1st
      */
      this.tabTransformWidth = (this.windowWidth - 40) * 0.84 * 0.2 + 8;
      let totalLength = (this.windowWidth - 40) * 0.84 * 0.2 * Object.keys(this.activeRates).length + 8 * Object.keys(this.activeRates).length - 1;
      this.initialTransformValue = Object.keys(this.activeRates).length > 4 ? totalLength - (this.windowWidth - 40) * 0.84 : 0;

      // force ADA-only checkbox
      this.ada_only = this.enableAdaOnLoad;

      this.trackData();
      this.scheduleScrollToCurrentRoom(300);
    },
    doStupidStuff() {
      localStorage.removeItem("step3");
      localStorage.setItem("step3", this.$route.fullPath);
      // localStorage.removeItem('queryForActivities')
      // localStorage.setItem('queryForActivities', JSON.stringify(this.$route.query))
      let step2_fullPath = "";

      if (localStorage.getItem("step2_type") === "flex") {
        step2_fullPath =
          "/booking/lodging-flex-search" +
          `?dateFrom=${this.$route.query.dateFrom}&nights=${this.$route.query.nights}&destination=ALL&adults=${this.$route.query.adults}&children=${this.$route.query.children}&rateCode=${this.$route.query
            .rateCode || " "}`;
      } else {
        step2_fullPath =
          "/booking/lodging-search" +
          `?dateFrom=${this.$route.query.dateFrom}&nights=${this.$route.query.nights}&destination=ALL&adults=${
            this.$route.query.adults
          }&children=${this.$route.query.children}&rateCode=${this.$route.query.rateCode || " "}`;
      }
      localStorage.removeItem("step2");
      localStorage.setItem("step2", step2_fullPath);

      if (this.toursInlineEnabled) {
        let step4_fullPath = "/booking/activities-select" +
            `?dateFrom=${this.$route.query.dateFrom}&nights=${
                this.$route.query.nights
            }&destination=${
                this.$route.query.destination
            }&adults=${
                this.$route.query.adults
            }&children=${
                this.$route.query.children
            }${!!this.$route.query.rateCode
                ? '&rateCode=' + this.$route.query.rateCode
                : ""}`;
        localStorage.removeItem("step4");
        localStorage.setItem("step4", step4_fullPath);
      }
    },
    validateUrl() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      // check dateFrom
      if (!!this.$route.query.dateFrom) {
        let dateFrom = this.$dayjs(this.$route.query.dateFrom, "MM-DD-YYYY", true);
        if (!dateFrom.isValid()) {
          // invalid date format
          return false;
        }

        dateFrom = dateFrom.toDate();
        if (today > dateFrom) {
          // past date
          return false;
        }

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          if (minDate > dateFrom) {
            // date is greater than mindate
            return false;
          }
        }

        let nights = 1;
        if (!!this.$route.query.nights && Number.isInteger(+this.$route.query.nights)) {
          nights = parseInt(this.$route.query.nights);
        }

        let maxDate = this.$dayjs(this.options.maxdate, "MM/DD/YYYY")

        if (maxDate.isValid()) {
          maxDate = maxDate.toDate();
          if (dateFrom > maxDate) {
            // date is greater than maxdate
            return false;
          }
        }
      }

      // validate adults. integer, >=1
      if (!!this.$route.query.adults) {
        if (!Number.isInteger(+this.$route.query.adults)) {
          return false;
        }

        if (parseInt(this.$route.query.adults) < 1) {
          return false;
        }
      }

      // validate children. integer, >=0
      if (!!this.$route.query.children) {
        if (!Number.isInteger(+this.$route.query.children)) {
          return false;
        }

        if (parseInt(this.$route.query.children) < 0) {
          return false;
        }
      }

      const guests = parseInt(this.$route.query.adults) + parseInt(this.$route.query.children);
      if (guests > this.maxGuests) {
        // guests exceeded
        return false;
      }

      // validate nights. integer, >=1 && <=14
      if (!!this.$route.query.nights) {
        if (!Number.isInteger(+this.$route.query.nights)) {
          return false;
        }

        const nights = parseInt(this.$route.query.nights);
        if (nights < 1 || nights > 14) {
          return false;
        }
      }

      return this.validateDestination();
    },
    validateDestination() {
      let hotelCodes = Object.keys(this.$store.state.property.hotels);

      return !!hotelCodes.find((item) => item === this.destination);
    },
    /*
    async loadRateplans() {
      await this.$store.dispatch("property/fetchHotelsRatePlans", {
        property_code: PROPERTY_CODE,
        rateplan: this.rateCode,
      });
    },
    */
    urlNeedsNormalization() {
      return (
        typeof this.$route.query.dateFrom === "undefined" ||
        typeof this.$route.query.adults === "undefined" ||
        typeof this.$route.query.children === "undefined" ||
        typeof this.$route.query.nights === "undefined" ||
        typeof this.$route.query.destination === "undefined"
      );
    },
    /*
     * Set params defaults and redirect to normalized URL
     */
    normalizeUrl() {
      this.urlQueryParams["adults"] = !!this.$route.query.adults ? this.$route.query.adults : 1;
      this.urlQueryParams["children"] = !!this.$route.query.children ? this.$route.query.children : 0;
      this.urlQueryParams["destination"] = this.destination;

      if (!this.$route.query.dateFrom) {
        this.urlQueryParams["dateFrom"] = this.$dayjs(this.allowedRange.min).format('MM-DD-YYYY');
        this.urlQueryParams["nights"] = this.$route.query.nights;
        this.urlQueryParams["rateCode"] = this.ratePlan?.code
      } else {
        this.urlQueryParams["dateFrom"] = this.$route.query.dateFrom;
        this.urlQueryParams["nights"] = this.$route.query.nights;
        if (!!this.$route.query.rateCode) {
          this.urlQueryParams["rateCode"] = this.$route.query.rateCode;
        }
      }

      if (!this.$route.query.nights) {
        this.urlQueryParams["nights"] = this.ratePlan?.minstay || 1;
      }

      return this.$router.push({
        name: "hotelPage",
        params: this.destination,
        query: this.urlQueryParams,
      });
    },
    /*
    ratePlansValid() {
      return typeof this.ratePlans[this.rateCode] !== "undefined";
    },
    */
    async loadHotelAvailability() {
      const dateFrom = this.$dayjs(this.dateString2Date(this.$route.query.dateFrom)).format("MM/DD/YYYY");

      await this.$store
        .dispatch("property/fetchAvailableHotels", {
          date: dateFrom,
          limit: 1,
          rate_code: this.searchParams.rateCode,
          is_group: this.searchParams.rateType === 'group',
          nights: this.searchParams.nights,
        })
        .then((r) => {
          const availability = this.$store.state.property.availableHotels[dateFrom][this.destination];
          if (!availability.min) {
            let modalAlertMessage = availability.message;
            if (!modalAlertMessage) {
              if (availability.status === 'CLOSED') {
                modalAlertMessage = `Unfortunately, ${this.hotel?.title} is closed for the selected dates`;
              } else {
                modalAlertMessage = `Unfortunately, ${this.hotel?.title} has no availability for the selected dates`;
              }
            }

            this.setModal("HotelRateCalendar", {
              hotel_id: this.destination,
              rates: this.$store.state.property.availableHotels[dateFrom][this.destination].rates,
              currentRatePlan: this.ratePlan,
              alert: true,
              alertMessage: modalAlertMessage,
            });
            let query = {
              dateFrom: this.$route.query.dateFrom,
              adults: this.$route.query.adults,
              children: this.$route.query.children,
              nights: this.$route.query.nights,
              destination: "ALL",
            };
            if (!!this.$route.query.rateCode) {
              query["rateCode"] = this.$route.query.rateCode;
              query.rateType = this.$route.query.rateType
            }

            if (!this.isModifyReservationMode) {
              return this.$router.push({
                name: "hotels",
                query: query,
              });
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async loadHotel() {
      this.hotel = await apiHotel(this.destination);

      let groups = this.hotel.groups;
      delete groups["DEFAULT"];
      if (Object.keys(groups).length > 1) this.$eventHub.$emit("set-room-groups", groups);
    },
    async loadRooms() {
      this.rooms = await apiHotelRooms(this.destination);
    },
    async loadRoomsAvailability() {
      this.availabilityData.nights = this.searchParams.nights;
      this.availabilityData.date = this.$dayjs(this.dateString2Date(this.$route.query.dateFrom)).format("MM/DD/YYYY");
      this.availabilityData.rate_code = this.searchParams.rateCode;
      this.availabilityData.is_group = this.searchParams.rateType === 'group'

      if (this.isModifyReservationMode) {
        this.availabilityData.cart_token = this.cartToken
      }

      const availability = await apiAvailabilityRooms(this.destination, this.availabilityData);
      this.roomsAvailabilityResponse = availability;
      this.roomsAvailability = Object.values(availability.availability[Object.keys(availability.availability)[0]].rooms);
      this.noRoomsOnModifySearch = false;

      if (this.isModifyReservationMode && !this.roomsAvailability.length) {
        this.noRoomsOnModifySearch = true;

        return;
        // no rooms available. redirect back to manage reservation
        //this.allowRouteLeave = true;
        //this.$msg('There are no available rooms for selected date')
        //return this.returnModifyReservation();
      }
      const rateCode = !!this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : "INTERNET";
      this.rates = availability.rates;
      this.activeRates = {}      
      for (const rateKey in this.rates) {
        if (
          availability.rates[rateKey].minstay <= this.$route.query.nights &&
          (!!availability.availability[this.availabilityData.date].rates[rateKey] || rateKey === rateCode)
        )
          this.$set(this.activeRates, rateKey, availability.rates[rateKey]);
      }
      this.minRatePrices = availability.availability[this.availabilityData.date].rates;
      let rateOneDate = Object.values(this.activeRates)[0]

      for (const key in this.activeRates) {
        if (
          this.activeRates[key].category === "PROMOREQ" || this.activeRates[key].category === "PROMOSHOW"
        ) {
          rateOneDate = this.activeRates[key]
        }
      }
      console.log('availability',availability);
      console.log('rateCode',rateCode);
      console.log('rateOneDate', rateOneDate);

      this.checkedButton =
        Object.keys(availability.rates).find((key) => key === rateCode) && availability.rates[rateCode].minstay <= +this.$route.query.nights && !!!this.noRateMessage
          ? rateCode
          : rateOneDate.code; 
      
      // const onlyAvailRooms = availability.availability[this.availabilityData.date].rooms.filter((item) => item.availability > 0)

      // console.log('OnlyAvailRooms',onlyAvailRooms);
      console.log('availability.availability[this.availabilityData.date].rooms',availability.availability[this.availabilityData.date].rooms);
      for ( let rateCode in this.minRatePrices) {
        let min = Number.POSITIVE_INFINITY
        let itemToArr={}
        availability.availability[this.availabilityData.date].rooms.forEach((item)=>{
          if (rateCode === item.rateCode && item.available && item.max >= +this.$route.query.adults + +this.$route.query.children ) {
            if (item.price < min){
              min = item.price;
              itemToArr = item
            }
          }
        })
        if (itemToArr.rateCode) {
          this.minRooms[itemToArr.rateCode]=itemToArr
        }
      }
    },
    async loadHotelAlerts() {
      // we should use INTERNET alerts any rates
      this.hotelAlerts = await apiHotelAlerts(this.searchParams.destination, 'INTERNET');
      // let currentDate = this.$dayjs(this.$route.query.dateFrom).toDate().getTime();
      // let code = this.ratePlan?.code
      // if (!!Object.keys(this.activeRates).length) {
      //   apiHotelAlerts(this.destination, code).then((alerts) => {
      //     if (!!alerts) {
      //       this.alertMessages = {};
      //       for (let key in alerts) {
      //         if (this.alertMessages[this.activeRates[code].code] === undefined) {
      //           this.$set(this.alertMessages, this.activeRates[code].code, []);
      //         }
      //         let dateStart = this.$dayjs(alerts[key].start).toDate().getTime()
      //         let dateEnd = this.$dayjs(alerts[key].end).toDate().getTime()

      //         if (dateStart <= currentDate && currentDate <= dateEnd) {
      //           this.alertMessages[this.activeRates[code].code].push({
      //             key: key,
      //             message: alerts[key].message,
      //             start: alerts[key].start,
      //             end: alerts[key].end,
      //           });
      //         }
      //       }
      //     }
      //   });
    },
    async loadCart() {
      if (!this.cartToken) {
        await this.$store.dispatch("property/fetchCartToken")
      }

      await this.$store.dispatch('property/fetchCart')
    },
    handleButtonClick(code) {
      this.minPrice = 0;
      this.maxPrice = 0;
      this.code = code
      this.priceFilterReady = false;

      if (code !== this.checkedButton) {
        this.$eventHub.$emit("hotel-page-room-tab");
      }

      this.checkedButton = code;
    },
    handleSliderButtonClick(type) {
      if (type === "left" && this.transformValue < 0)
        if (this.transformValue + this.tabTransformWidth <= 0) this.transformValue += this.tabTransformWidth;
        else this.transformValue += -this.transformValue;
      if (type === "right" && -this.transformValue < this.initialTransformValue)
        if (this.initialTransformValue - -this.transformValue >= this.tabTransformWidth) this.transformValue -= this.tabTransformWidth;
        else this.transformValue -= this.initialTransformValue - -this.transformValue;
    },
    scheduleScrollToCurrentRoom(delay) {
      setTimeout(this.scrollToCurrentRoom, delay);
    },
    scrollToCurrentRoom() {
      if (this.currentRoomCode) {
        const el = document.getElementById(this.currentRoomCode);
        el && el.scrollIntoView({ block: "start" });
      }
    },
    // scheduleOpenAddRoomModal(delay) {
    //   setTimeout(this.openAddRoomModal, delay);
    // },
    // openAddRoomModal() {
    //   const roomAvailability = this.currentRoomAvailability;
    //   const roomData = this.currentRoomData;

    //   if (roomAvailability && roomData) {
    //     const data = {
    //       roomAvailability: roomAvailability,
    //       roomData: roomData,
    //       rate: this.rates[roomAvailability.rateCode],
    //       modalContainerClass: "add-room-modal-container",
    //     };
    //     this.setModal("AddRoom", data);
    //   }
    // },
    onViewCalendar(data) {
      console.log('onViewCalendar', data)
      this.setModal("HotelRateCalendar", data);
    },
    trackData() {
      // const data = {
      //   ecommerce: {
      //     currencyCode: "USD",
      //     detail: [
      //       {
      //         actionField: { list: "Search" },
      //       },
      //       {
      //         products: [
      //           {
      //             name: this.destination,
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   page: {
      //     type: "category",
      //     environment: "production",
      //   },
      // };

      const data = {
        'content-name': '/booking/lodging-select',
        'content-view-name': 'Choose Room',
        event: 'view_item',
        ecommerce: {
          currency: 'USD',
          // value: , // @TODO: sum of all items
          items: [{
            item_id: this.hotel.code,
            item_name: this.hotel.title,
            affiliation: this.isAgency ? 'TA' : 'Consumer',
            index: 0,
            item_brand: this.options.title,
            item_category: 'Hotel',
            item_category2: this.$dayjs(this.searchParams.dateFrom, 'MM/DD/YYYY').format('YYYYMMDD'),
            item_category3: this.searchParams.nights,
            // item_category4: '', // @TODO: number of rooms
            item_category5: this.searchParams.adults + this.searchParams.children,
            item_category6: this.searchParams.adults,
            item_category7: this.searchParams.children,
            // item_variant: '', // @TODO: room type
            coupon: this.searchParams.rateCode,
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            // price: null, // @TODO: price
            // quantity: null // @TODO: number of rooms
          }]
        }
      }

      gtmTrack({ ecommerce: null });
      gtmTrack(data);
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore =  this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        if (typeof this.$refs.text !== "undefined") {
          this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
          this.isViewingMore = isViewingMore;  
        }
      });
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },    
    onSkipLodging() {
      return this.$router.push({
        name: "activitiesPage",
        query: this.$route.query
      })
    }
  },
  computed: {
    ...optionsComputed,
    ...appValuesComputed,
    ...mapGetters({
      cartToken: 'property/cartToken',
      ratePlans: "property/hotelsRatePlans",
      addToReservation: 'app/addToReservation',
      isModifyReservationMode: 'app/isModifyReservationMode',
      isAgency: 'auth/isAgency',
    }),
    windowWidth() {
      return this.windowSize.width.value;
    },
    searchParams() {
      return {
        destination: this.$route.query.destination,
        dateFrom: this.$route.query.dateFrom,
        nights: parseInt(this.$route.query.nights) || 1,
        adults: parseInt(this.$route.query.adults) || 0,
        children: parseInt(this.$route.query.children) || 0,
        rateCode: this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : null,
        rateType: this.$route.query.rateType
      };
    },

    allowedRange() {
      let start = this.bookingDatesWindow.min
      let end = this.bookingDatesWindow.max

      if (this.ratePlan?.category === 'GROUP') {
        if (this.ratePlan.start) {
          start = this.$dayjs.max([
            start,
            this.$dayjs(this.ratePlan.start)
          ]).format('MM/DD/YYYY')
        }

        if (this.ratePlan.end) {
          end = this.$dayjs.min([
            end,
            this.$dayjs(this.ratePlan.end).subtract(1, 'day')
          ]).format('MM/DD/YYYY')
        }
      }

      return { start, end }
    },

    searchParamsIsValid() {
      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.nights ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // date
      const dateFrom = this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY')

      if (
        !dateFrom.isValid() ||
        this.searchParams.dateFrom !== dateFrom.format('MM-DD-YYYY')
      ) {
        console.log(`Param dateFrom (${this.searchParams.dateFrom}) invalid`)
        return false
      }

      if (!this.$dayjs(dateFrom).isBetween(this.allowedRange.start, this.allowedRange.end, 'day', '[]')) {
        console.log(`Param dateFrom (${this.searchParams.dateFrom}) is not within allowedRange`)
        return false
      }

      // nights
      if (this.searchParams.nights < 0 || this.searchParams.nights > 14) {
        console.log(`Param nights (${this.searchParams.nights}) invalid`)
        return false
      }

      // guests
      if (this.searchParams.adults < 1) {
        console.log(`Param adults (${this.searchParams.adults}) invalid`)
        return false
      }

      if (this.searchParams.children < 0) {
        console.log(`Param children (${this.searchParams.children}) invalid`)
        return false
      }

      if (this.searchParams.adults + this.searchParams.children > parseInt(this.options.resformMaxOccupancy)) {
        console.log(`Params adults + children (${this.searchParams.adults} + ${this.searchParams.children}) invalid`)
        return false
      }

      // destination
      if (!this.validateDestination()) {
        console.log('Destination is not valid')
        return false;
      }

      return true
    },
    rateForSend() {
      return !!this.noRateMessage ? 'INTERNET' : this.code || this.$route.query.rateCode || 'INTERNET'
    },
    destination() {
      return this.$route.query.destination;
    },
    dateHotelAlerts() {
      return _.chain(this.hotelAlerts)
        .filter((i) => this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').isBetween(
          this.$dayjs(i.start, 'MM/DD/YYYY'),
          this.$dayjs(i.end, 'MM/DD/YYYY'),
          'day', '[]',
        ))
        .value()
    },
    noRateMessage() {
      return !!this.$route.query.rateCode &&
        !!Object.keys(this.activeRates).length &&
        !Object.keys(this.activeRates).find((rate) => {
          return rate === this.$route.query.rateCode.toUpperCase();
        })
        ? "We're sorry. This rate is not available on your selected dates. However, other rates are available at the following rooms."
        : false;
    },
    filters() {
      return this.$store.state.app.roomFilter;
    },
    order() {
      return this.$store.state.app.roomOrder;
    },
    numberOfAvailableNonAdaRooms() {
      let items = this.roomsRate;

      return items.filter((room) => {
        return room.type !== "ada" && room.type !== "ada_rv" && room.type !== "aba_tent" && room.available;
      }).length;
    },
    numberOfUnavailableNonAdaRooms() {
      let items = this.roomsRate;

      return items.filter((room) => {
        return room.type !== "ada" && room.type !== "ada_rv" && room.type !== "ada_tent" && !room.available;
      }).length;
    },
    numberOfAvailableAdaRooms() {
      let items = this.roomsRate;

      return items.filter((room) => {
        return (room.type === "ada" || room.type === "ada_rv" || room.type === "ada_tent") && room.available;
      }).length;
    },
    numberOfUnavailableAdaRooms() {
      let items = this.roomsRate;

      return items.filter((room) => {
        return (room.type === "ada" || room.type === "ada_rv" || room.type === "ada_tent") && !room.available;
      }).length;
    },
    enableAdaOnLoad() {
      // there are non-ADA rooms
      if (this.numberOfAvailableNonAdaRooms > 0) {
        return false;
      }

      // there is no non-ADA rooms
      // but there are ADA rooms
      if (this.numberOfAvailableAdaRooms > 0) {
        return true;
      }

      // there is no non-ADA rooms
      // there is no ADA rooms
      // but there are unavailable non-ADA rooms
      if (this.numberOfUnavailableNonAdaRooms > 0) {
        return false;
      }

      // there is no non-ADA rooms
      // there is no ADA rooms
      // there is no unavailable non-ADA rooms
      // but there are unavailable ADA rooms
      if (this.numberOfUnavailableAdaRooms > 0) {
        return true;
      }

      // there is no any rooms
      return false;
    },
    roomsRate() {
      return this.roomsAvailability && this.roomsAvailability.filter((el) => el.rateCode === this.checkedButton);
    },
    // roomsRateFiltered() {
    //   let items = this.roomsRate;

    //   let exclude_ada = true;
    //   let excluded_room_groups = [];

    //   // apply all filters, but price
    //   this.filters.forEach((filter) => {
    //     switch (filter.field) {
    //       case "ada":
    //         exclude_ada = false;
    //         break;
    //       case "excluded_room_groups":
    //         excluded_room_groups = filter.value;
    //         break;
    //     }
    //   });

    //   if (exclude_ada) {
    //     items = items.filter((room) => {
    //       return room.type !== "ada" && room.type !== "aba_rv" && room.type !== "aba_tent";
    //     });
    //   } else {
    //     items = items.filter((room) => {
    //       return room.type === "ada" || room.type === "aba_rv" || room.type === "aba_tent";
    //     });
    //   }
    //   if (!!excluded_room_groups.length) {
    //     items = items.filter((room) => {
    //       let tempGroups = room.group;
    //       excluded_room_groups.forEach((excludedGroup) => {
    //         tempGroups = tempGroups.filter((roomGroup) => {
    //           return roomGroup !== "DEFAULT" && roomGroup !== excludedGroup;
    //         });
    //       });
    //       return tempGroups.length;
    //     });
    //   }

    //   this.minPrice = 0;
    //   this.maxPrice = 0;

    //   items.forEach((room) => {
    //     if (room.available > 0) {
    //       if (this.minPrice === 0 || room.price < this.minPrice) {
    //         this.minPrice = room.price;
    //       }
    //       if (room.price > this.maxPrice) {
    //         this.maxPrice = room.price;
    //       }
    //     }
    //   });
    //   /*
    //   this.minPrice = this.minPrice;
    //   this.maxPrice = this.maxPrice;
    //   */
    //   this.$store.dispatch("app/setRoomMinPrice", this.minPrice);
    //   this.$store.dispatch("app/setRoomMaxPrice", this.maxPrice);

    //   if (!this.priceFilterReady) {
    //     this.$eventHub.$emit("reset-price-filter");
    //     this.priceFilterReady = true;
    //   }

    //   this.filters.forEach((filter) => {
    //     switch (filter.field) {
    //       case "price_range":
    //         items = items.filter((room) => {
    //           return room.available > 0 && room.price >= filter.value[0] && room.price <= filter.value[1];
    //         });

    //         break;
    //     }
    //   });

    //   let groupedItems = [[], [], [], []],
    //     sortedItems = [];
    //   items.forEach((item) => {
    //     if (!!item.available && item.max >= +this.$route.query.adults + +this.$route.query.children) {
    //       groupedItems[0].push(item);
    //     } else if (!!item.available && item.max < +this.$route.query.adults + +this.$route.query.children) {
    //       groupedItems[1].push(item);
    //     } else if (!!!item.available && item.max >= +this.$route.query.adults + +this.$route.query.children) {
    //       groupedItems[2].push(item);
    //     } else if (!!!item.available && item.max < +this.$route.query.adults + +this.$route.query.children) {
    //       groupedItems[3].push(item);
    //     }
    //   });

    //   if (this.order === "desc") {
    //     groupedItems.forEach((group) => {
    //       group.sort((a, b) => b.price - a.price);
    //       group.forEach((item) => sortedItems.push(item));
    //     });
    //   } else {
    //     groupedItems.forEach((group) => {
    //       group.sort((a, b) => a.price - b.price);
    //       group.forEach((item) => sortedItems.push(item));
    //     });
    //   }

    //   return sortedItems;
    // },
    isSliderBtnDisabledLeft() {
      return !(this.transformValue < 0);
    },
    isSliderBtnDisabledRight() {
      return !(-this.transformValue < this.initialTransformValue);
    },
    maxGuests() {
      return this.$store.state.app.options.resformMaxOccupancy;
    },
    ada_only: {
      get: function() {
        const filters = this.$store.state.app.roomFilter;
        let flag = false;

        filters.forEach((filter) => {
          switch (filter.field) {
            case "ada":
              flag = true;

              break;
          }
        });

        return flag;
      },
      set: function(value) {
        this.priceFilterReady = false;
        this.$eventHub.$emit("toggle-room-ada-filter", value);
      },
    },
    rateDesc() {
      return (
        !!this.checkedButton &&
        !!this.activeRates &&
        !!this.activeRates[this.checkedButton] &&
        (this.activeRates[this.checkedButton].description.split("|").length <= 1
          ? this.activeRates[this.checkedButton].description
          : this.activeRates[this.checkedButton].description.split("|")[1])
      );
    },
    rateAlert() {
      return (
        !!this.checkedButton && !!this.activeRates && !!this.activeRates[this.checkedButton] && this.activeRates[this.checkedButton].description.split("|")[2]
      );
    },
    currentRoomCode() {
      return this.$route.hash ? this.$route.hash.slice(1) : "";
    },
    // currentRoomAvailability() {
    //   if (this.currentRoomCode) {
    //     const room = this.roomsRateFiltered.filter((room) => room.roomCode === this.currentRoomCode);
    //     return room.length ? room[0] : "";
    //   }

    //   return "";
    // },
    currentRoomData() {
      if (this.currentRoomCode) {
        return this.rooms[this.currentRoomCode] ? this.rooms[this.currentRoomCode] : "";
      }

      return "";
    },
    getImageUrl() {
      if (typeof this.hotel.images !== "undefined" && this.hotel.images.length > 0) {
        return this.getCdnResourceUrl(this.hotel.images[0].image);
      }

      return "";
    },
    getImageTitle() {
      if (typeof this.hotel.images !== "undefined" && this.hotel.images.length > 0) {
        return this.hotel.images[0].title;
      }

      return "";
    },
    adaFilterLabel() {
      return this.hotel.type === "campground" ? "Show ABA Sites Only" : "Show ADA Rooms Only";
    },
    adaDescription() {
      return this.hotel.type === "campground"
        ? "Accessible site required for individuals with mobility impairments. If an accessible site is not needed and other site types are available, please do not reserve this site to allow for our guests requiring these accessible features."
        : "Accessible room required for individuals with mobility impairments. If an accessible room is not needed and other room types are available, please do not reserve this room to allow for our guests requiring these accessible features.";
    },
    shouldViewLess() {
      return this.shouldViewMore && this.isViewingMore;
    },
    rateCode() {
      return this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : "INTERNET";
    },
    ratePlan() {
      if (this.searchParams.rateCode) {
        return this.ratePlans[this.searchParams.rateCode]
      }
      return null
    },
    /*
    minDateFromRate() {
      const minDate = this.$dayjs(this.ratePlan["start"], "MM/DD/YYYY").toDate();
      const minDateOption = this.$dayjs(this.options.mindate).toDate();

      return minDateOption > minDate ? this.$dayjs(this.options.mindate).format("MM-DD-YYYY") : this.$dayjs(minDate).format("MM-DD-YYYY");
    },
    */
    /*
    nightsFromRate() {
      return this.ratePlan.minstay;
    },
    */
    /*
    rateCodeFromRate() {
      return typeof this.rateplans[this.rateCode] !== "undefined" ? this.rateCode : "INTERNET";
    },
    */
    canSkipLodging() {
      return this.toursInlineEnabled;
    },
    dateKey () {
      return this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').format('MM/DD/YYYY');
    },
    ratesRooms() {
      const guests = this.searchParams.guests;
      const adults = this.searchParams.adults;
      const nights = this.searchParams.nights;
      const dateKey = this.dateKey;
      const roomsAvailability = this.roomsAvailabilityResponse.availability?.[dateKey];
      const rates = roomsAvailability?.rates ?? {};
      const rooms = roomsAvailability?.rooms ?? [];

      return mapValues(rates, (__, rateCode) => {
        const items = rooms
          .filter((roomAvailability) => roomAvailability.rateCode === rateCode)
          .map((roomAvailability) => {
            const roomAvailabilityPerOneGuest = roomAvailability.perGuest[1];
            const roomAvailabilityPerGuest = roomAvailability.perGuest[adults];
            const roomAvailabilityPerGuestRegular = roomAvailability.perGuestRegular[adults];

            const prices = getPrices({
              base: roomAvailabilityPerGuest?.p ?? 0,
              fees: roomAvailabilityPerGuest?.f ?? 0,
              taxes: roomAvailabilityPerGuest?.t ?? 0,
              nights,
            })

            const oldPrices = getPrices({
              base: roomAvailabilityPerGuestRegular?.p ?? 0,
              fees: roomAvailabilityPerGuestRegular?.f ?? 0,
              taxes: roomAvailabilityPerGuestRegular?.t ?? 0,
              nights,
            })

            const pricesPerOneGuest = getPrices({
              base: roomAvailabilityPerOneGuest?.p ?? 0,
              fees: roomAvailabilityPerOneGuest?.f ?? 0,
              taxes: roomAvailabilityPerOneGuest?.t ?? 0,
              nights,
            })

            let availabilityStatus;
            if ((roomAvailability.max < guests || !prices.avgPrice.subTotal) && pricesPerOneGuest.avgPrice.subTotal) {
              availabilityStatus = 'accupancyExceeded';
            } else if (roomAvailability.available && prices.avgPrice.subTotal) {
              availabilityStatus = 'available';
            } else {
              availabilityStatus = 'soldOut';
            }

            return {
              roomAvailability,
              availabilityStatus,
              prices,
              oldPrices,
              pricesPerOneGuest,
            }
          });

        const sortedItems = orderBy(items, [
          (item) => item.prices.avgPrice.subTotal ? 1 : 0,
          (item) => item.pricesPerOneGuest.avgPrice.subTotal ? 1 : 0,
          (item) => item.prices.avgPrice.subTotal,
        ], ['desc', 'desc', this.order]);

        return sortedItems;
      });
    },

    rateRooms() {
      const adaOnly = !!this.filters.find(i => i.field === 'ada')?.value;
      const excludeGroups = this.filters.find(i => i.field === 'excluded_room_groups')?.value ?? [];

      const rooms = this.ratesRooms[this.checkedButton] ?? [];
      const adaTypes = ['ada', 'ada_rv', 'ada_tent'];

      return rooms.filter((i) => {
        if (adaOnly && !adaTypes.includes(i.roomAvailability.type)) {
          return false;
        } else if (!adaOnly && adaTypes.includes(i.roomAvailability.type)) {
          return false;
        }

        if (_.intersection(i.roomAvailability.group, excludeGroups).length) {
          return false;
        }

        return true;
      });
    }
  },
  watch: {
    searchParams: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.searchParamsIsValid) {
          this.$store.dispatch('property/setLodgeSearchParams', {
            ...this.searchParams,
            dateFrom: this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').format('MM-DD-YYYY')
          })
        }

        // init
        this.isLoadingRooms = true;
        await this.init();
        this.isLoadingRooms = false;
      }
    },
  },
  rooms: {
    immediate: true,
    deep: true,
    handler() {
      this.roomsAvailabilityResponse = {}
      this.roomsAvailability = []
    }
  },
  // What about to create bookingMixin and use it in all activity and lodges booking pages?
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', from.name, to.name)
    if (this.allowRouteLeave) {
      return next();
    }

    if (!this.isModifyReservationMode && !this.addToReservation) {
      return next();
    }

    if (['ReviewPage', 'hotels', 'tours', 'tourPage', 'hotelPage', 'activitiesPage'].includes(to.name)) {
      return next();
    }

    this.setConfirmationModal({
      content: "Are you sure you want to cancel reservation editing?",
    })
      .then(() => {
        if (this.addToReservation) {
          this.$store.dispatch("app/endAddToReservation")
        }

        if (this.isModifyReservationMode) {
          this.$store.dispatch("app/endModifyReservation")
        }

        next();
      })
      .catch(function() {
        next(false);
      });
  },
  mounted() {
    // window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      // this.onResize();
    });
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
  }  
};
