import logo from "@/assets/logo-xanterra.png";
import partner1 from "@/assets/partner1.png";
import partner2 from "@/assets/partner2.png";
import partner3 from "@/assets/partner3.png";
import partner4 from "@/assets/partner4.png";
import partner5 from "@/assets/partner5.png";
import partner6 from "@/assets/partner6.png";
import partner7 from "@/assets/partner7.png";
import partner8 from "@/assets/partner8.png";
import partner9 from "@/assets/partner9.png";
import partner10 from "@/assets/partner10.png";
import partner11 from "@/assets/partner11.png";
import partner12 from "@/assets/partner12.png";
import partner13 from "@/assets/partner13.png";
import partner14 from "@/assets/partner14.png";

export default {
  data: () => ({
    partners: [
      { to: 'https://www.windstarcruises.com', src: partner1 },
      { to: 'https://www.vbt.com', src: partner2 },
      { to: 'https://www.countrywalkers.com', src: partner3 },
      { to: 'https://www.holidayvacations.com', src: partner4 },
      { to: 'https://www.oasisatdeathvalley.com', src: partner5 },
      { to: 'https://www.thetrain.com', src: partner6 },
      { to: 'https://www.grandcanyongrandhotel.com', src: partner7 },
      { to: 'https://www.glaciernationalparklodges.com/lodging/cedar-creek-lodge/', src: partner8 },
      { to: 'https://www.yellowstonenationalparklodges.com', src: partner9 },
      { to: 'https://www.grandcanyonlodges.com', src: partner10 },
      { to: 'https://www.glaciernationalparklodges.com', src: partner11 },
      { to: 'https://www.zionlodge.com', src: partner12 },
      { to: 'https://www.mtrushmorenationalmemorial.com', src: partner13 },
      { to: 'https://www.trailridgegiftstore.com', src: partner14 },
    ]
  }),
  mounted() {
    const resources = document.getElementById('resources').getElementsByTagName('a');
    for (let i=0, child; child=resources[i]; i++) {
      child.setAttribute('target', '_blank');
    }
  },
  computed: {
    data() {
      return this.$store.state.app.options
    },
    getFooterLogo() {
      return logo
      // return this.getAppCdnResourceUrl(logo)
    },
    resources() {
      return this.data.resources.split("<p>").join("").split("</p>").join("");
    }
  }
}
