import { PROPERTY_CODE, AVAILABILITY_STATUS_OPTIONS } from "@/constants";
import { optionsComputed, propertyComputed } from "@/store/helper";
import { apiAddTourToCart } from "@/api/cart";
import VBFormInputButtons from '@/components/inputs/VBFormInputButtons.vue'
import VBFormInputQuantity from '@/components/inputs/VBFormInputQuantity.vue'
import VBFormSelectDropdown from '@/components/inputs/VBFormSelectDropdown.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'
import HotelItemAlerts from '@/components/hotels/HotelItemAlerts.vue'
import HotelItemGallery from '@/components/hotels/HotelItemGallery.vue'
import TruncatedText from '@/components/TruncatedText/index.vue'
import {gtmTrack} from '@/lib/gtm';
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  components: {
    VBFormInputButtons,
    VBFormInputQuantity,
    VBFormSelectDropdown,
    HotelItemImages,
    HotelItemAlerts,
    HotelItemGallery,
    TruncatedText,
  },

  props: {
    tour: Object,
    toursAvailability: Object,
    preselectedDate: {
      type: [String, Boolean],
      default: false,
    },
    preselectedOptionPickupCode: {
      type: [String, Boolean],
      default: false,
    },
    defaultActivityTime: String,
    prices: Object,
    onPreviousWeek: Function,
    onNextWeek: Function,
    loading: Boolean,
    allowPreviousWeek: Boolean,
    allowNextWeek: Boolean,
  },
  data: () => ({
    selectedDate: null,
    selectedOptionCode: null,
    selectedPickupName: null,

    /* OLD */
    adultQty: 1,
    childrenQty: 0,
    infantQty: 0,
    animalQty: 0,
    guestsQty: 1,
    answers: {},
    addInCartInProgress: false,
    errorMessage: "",
  }),
  computed: {
    ...optionsComputed,
    ...propertyComputed,

    ...mapGetters({
      cartToken: 'property/cartToken',
      tourCart: 'property/tourCart',
      options: 'app/options',
      tourCartByDate: 'property/tourCartByDate',
      addToReservationToursByDate: 'app/addToReservationToursByDate',
      isAgency: 'auth/isAgency',
    }),

    tourImage() {
      return _.get(this.tour, 'images.0')
    },

    tourOptionOptions() {
      return _.chain(this.tour)
        .get('options')
        .map((v, k) => ({
          value: k,
          text: v.title,
          disabled: this.getIsDisabledOptionByCode(k)
        }))
        .value()
    },

    availableTourOptionOptions() {
      return _.filter(this.tourOptionOptions, i => !i.disabled)
    },

    tourPickupOptions() {
      return _.chain(this.tour)
        .get('options')
        .map('pickup')
        .flatMap(i => _.map(i))
        .uniq()
        .map(i => ({
          value: i,
          text: this.getTextPickupByName(i),
          disabled: this.getIsDisabledPickupByName(i),
        }))
        .value()
    },

    availableTourPickupOptions() {
      return _.filter(this.tourPickupOptions, i => !i.disabled)
    },

    selectedOptionPickupCode() {
      return _.chain(this.tour)
        .get(`options.${this.selectedOptionCode}.pickup`)
        .findKey(i => i === this.selectedPickupName)
        .value()
    },

    selectedOption() {
      return _.get(this.tour, `options.${this.selectedOptionCode}`)
    },

    selectedDateAvailability() {
      return _.get(this.toursAvailability, this.selectedDate)
    },

    selectedTourAvailability() {
      return _.get(this.selectedDateAvailability, this.tour.code)
    },

    selectedTourAvailabilityAdultMinPrice() {
      const base = this.selectedTourAvailability?.prices.adult.min ?? 0;
      const fees = this.selectedTourAvailability?.prices.adult.fee ?? 0;
      const taxes = this.selectedTourAvailability?.prices.adult.tax ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    selectedTourAvailabilityChildMinPrice() {
      const base = this.selectedTourAvailability?.prices.child.min ?? 0;
      const fees = this.selectedTourAvailability?.prices.child.fee ?? 0;
      const taxes = this.selectedTourAvailability?.prices.child.tax ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    selectedOptionAvailability() {
      return _.get(this.selectedTourAvailability, `options.${this.selectedOptionCode}`)
    },

    selectedOptionAvailabilityStatus() {
      if (!this.selectedOptionAvailability) {
        return null
      }

      let value = 'available'
      const isOpened = ['OPEN', 'SOLDOUT'].includes(this.selectedOptionAvailability.status)
      const isAvailable = !!this.selectedOptionAvailability.available

      if (!isOpened) {
        value = 'closed'
      } else if (!isAvailable) {
        value = 'soldOut'
      } else if (this.selectedOptionAvailability.available < (this.guestsWithOthers)) {
        value = 'guestsExceededAvailability'
      }

      return _.find(AVAILABILITY_STATUS_OPTIONS, { value })
    },

    selectedOptionPickupAvailability() {
      return _.get(this.selectedOptionAvailability, `pickup.${this.selectedOptionPickupCode}`)
    },

    selectedOptionPickupAvailabilityAdultPrice() {
      const base = this.selectedOptionPickupAvailability?.prices.adult ?? 0;
      const fees = this.selectedOptionPickupAvailability?.fees.adult ?? 0;
      const taxes = this.selectedOptionPickupAvailability?.taxes.adult ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    selectedOptionPickupAvailabilityChildPrice() {
      const base = this.selectedOptionPickupAvailability?.prices.child ?? 0;
      const fees = this.selectedOptionPickupAvailability?.fees.child ?? 0;
      const taxes = this.selectedOptionPickupAvailability?.taxes.child ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    placesLeft() {
      return _.get(this.selectedOptionAvailability, 'available', 0)
    },

    // placesAvailable() {
    //   if (this.selectedOptionAvailability) {
    //     return Math.min(this.tour.lowAvailability, this.placesLeft);
    //   }
    //   return +this.tour.lowAvailability;
    // },

    anyage() {
      return _.get(this.selectedTourAvailability, 'anyage', this.tour.anyage)
    },

    guests() {
      if (this.anyage) {
        return this.guestsQty;
      }

      return this.adultQty + this.childrenQty;
    },

    guestsWithOthers() {
      return this.guests + this.infantQty + this.animalQty;
    },

    dynamicMaxAdults() {
      if (this.anyage) {
        return this.tour.maxAdults - this.guestsWithOthers + this.guestsQty;
      }
      return this.tour.maxAdults - this.guestsWithOthers + this.adultQty;
    },
    dynamicMaxChildren() {
      if (this.anyge) {
        return 0;
      }
      return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.childrenQty, this.tour.maxChildren);
    },
    dynamicMaxInfants() {
      if (this.anyage) {
        return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.infantQty, this.guestsQty);
      }
      return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.infantQty, this.adultQty);
    },
    dynamicMaxAnimals() {
      if (this.anyage) {
        return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.animalQty, this.guestsQty);
      }
      return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.animalQty, this.adultQty);
    },

    allowSelect() {
      return this.selectedOptionPickupAvailability && this.placesLeft >= this.guestsWithOthers;
    },

    /* OLD */
    /*
     * anyage flag allows to add guests without asking age
     */
    getImageUrl() {
      if (typeof this.tour.images !== "undefined" && this.tour.images.length > 0) {
        return this.getCdnResourceUrl(this.tour.images[0].image);
      }
      return "";
    },
    getImageTitle() {
      if (typeof this.tour.images !== "undefined" && this.tour.images.length > 0) {
        return this.tour.images[0].title;
      }
      return "";
    },
    adultsYears() {
      if (this.tour.descAdults) {
        return this.tour.descAdults;
      }
      return this.oPropertyAdultsYears;
    },
    childrenYears() {
      if (this.tour.descChildren) {
        return this.tour.descChildren;
      }
      return this.oPropertyChildrenYears;
    },
    selectedChildrenAllowed() {
      if (this.selectedDate) {
        return this.getChildrenAllowed(this.selectedDate);
      } else {
        return !!this.tour.maxChildren;
      }
    },
    cartItem() {
      return _.find(this.tourCart, i => (
        i.date === this.selectedDate &&
        i.optionCode === this.selectedOptionPickupCode
      ))
    },

    itemInCart() {
      return !!this.cartItem
    },

    cancelBeforeDate() {
      const settings = this.options.cancelBeforeActivities?.find(i => (
        this.$dayjs(this.selectedDate, 'MM/DD/YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(this.selectedDate, 'MM/DD/YYYY')
        .subtract(value, 'day')
        .toDate();
      
      return cancelBeforeDate;
    },
    cancelationAvailable() {
      const today = new Date();
      return this.cancelBeforeDate && this.cancelBeforeDate > today;
    },
    cancelationMessage() {
      return this.cancelationAvailable ? (
        `free cancelation before ${this.$dayjs(this.cancelBeforeDate).format('ddd, MMM D')}`
      ) : 'cancelation fee applies';
    }, 
  },

  async created() {
    //console.log("CREATED", this.toursAvailability, this.selectedTour, this.defaultActivityTime, this, this.addInCartInProgress);
    this.adultQty = parseInt(this.$route.query.adults);
    this.childrenQty = parseInt(this.$route.query.children);
    this.infantQty = +this.$route.query.infants || 0;
    this.animalQty = +this.$route.query.animals || 0;
    this.guestsQty = this.adultQty + this.childrenQty;
    this.init();
    
    this.onSelectDate(this.preselectedDate)

    // @TODO we need to preselect
    /*
    if (this.preselectedOption) {
      this.selectedOption = this.preselectedOption;
    } else {
      this.selectedOption = this.availableOptions[0]["key"];
    }
    */
  },
  methods: {
    init() {
      this.selectedDate = false;
      // @TODO we need to preselect
      /*
      this.selectedOption = this.availableOptions[0]["key"];
      */
    },

    getIsDisabledOptionByCode(code) {
      let disabled = true

      if (this.selectedTourAvailability) {
        // disabled = _.get(this.selectedTourAvailability, `options.${code}.available`) === 0
        disabled = (
          !_.get(this.selectedTourAvailability, `options.${code}`) ||
          _.get(this.selectedTourAvailability, `options.${code}.status`) === 'CLOSED'
        )

      } else if (!this.selectedDateAvailability) {
        disabled = !_.chain(this.toursAvailability)
          .map(this.tour.code)
          .find(i => !!_.get(i, `options.${code}.available`))
          .value()
      }

      return disabled
    },

    getIsDisabledPickupByName(name) {
      let disabled = true

      if (this.selectedOptionAvailability) {
        disabled = (
          this.selectedOptionAvailability.available === 0 ||
          !_.find(this.selectedOptionAvailability.pickup, i => i.name === name)
        )

      } else if (!this.selectedDateAvailability) {
        disabled = false
      }

      return disabled
    },

    getTextPickupByName(name) {
      if (this.selectedOptionAvailability) {
        const pickupAvailability = _.find(this.selectedOptionAvailability.pickup, i => i.name === name)

        if (pickupAvailability && pickupAvailability.startTime) {
          return pickupAvailability.endTime ? (
            `${name} ${pickupAvailability.startTime} - ${pickupAvailability.endTime}`
          ) : `${name} ${pickupAvailability.startTime}`
        }
      }

      return name
    },

    /*
    setQty(type, key) {
      if (type === "plus" && this.guests < this.resformMaxOccupancy) {
        if (key === "adult") {
          this.adultQty += 1;
        }
        if (key === "children") {
          this.childrenQty += 1;
        }
        if (key === "guest") {
          this.guestsQty += 1;
        }
      }
      if (type === "minus" && this.guests > 1) {
        if (key === "adult" && this.adultQty > 1) {
          this.adultQty -= 1;
        }
        if (key === "children" && this.childrenQty > 0) {
          this.childrenQty -= 1;
        }
        if (key === "guest") {
          this.guestsQty -= 1;
        }
      }
    },
    */
    /*
    getTourAvailability(dateString) {
      if (
        typeof this.toursAvailability[dateString] !== "undefined" &&
        typeof this.toursAvailability[dateString][this.tour.code] !== "undefined"
      ) {
        return this.toursAvailability[dateString][this.tour.code];
      }

      return false;
    },
    */
    getSelectedTourOptionAvailability() {
      return this.getTourOptionAvailability(this.selectedDate);
    },
    getTourOptionAvailability(dateString) {
      if (!dateString) {
        return false;
      }

      if (
        typeof this.toursAvailability[dateString] !== "undefined" &&
        typeof this.toursAvailability[dateString][this.tour.code] !== "undefined" &&
        typeof this.toursAvailability[dateString][this.tour.code]["options"][this.selectedOption] !== "undefined"
      ) {
        return this.toursAvailability[dateString][this.tour.code]["options"][this.selectedOption];
      }

      return false;
    },
    /*
    getClasses(dateString) {
      //console.log("getClasses", dateString, this.selectedDate);
      let classes = [];
      //const date = this.$dayjs(dateString, "MM/DD/YYYY").toDate();
      const disabled = this.getDayIsDisabled(dateString);
      const available = this.getDayIsAvailable(dateString);

      if (disabled) {
        classes.push("disabled");
      } else if (!available) {
        classes.push("unavailable");
      } else {
        classes.push("available");
      }

      if (this.selectedDate) {
        const selectedDate = this.$dayjs(this.selectedDate, "MM/DD/YYYY").format("MM/DD/YYYY");
        if (selectedDate === dateString) {
          classes.push("selected");
        }
      }

      return classes.join(" ");
    },
    */
   /*
    getDayIsDisabled(dateString) {
      return this.toursAvailability[dateString] === false;
    },
    */
    getDayIsAvailable(dateString) {
      return !!_.get(this.toursAvailability, `${dateString}.${this.tour.code}.available`)
    },

    getDayStatus(dateString) {
      const availability = this.toursAvailability[dateString]?.[this.tour.code]
      const isDisabled = !!availability
      const isOpened = !!_.values(availability?.options).find(i => ['OPEN', 'SOLDOUT'].includes(i.status))
      const isAvailable = !!availability?.available
      const enoughTime = this.$dayjs(this.tour.mindate, 'MM/DD/YYYY').toDate() <= this.$dayjs(dateString, 'MM/DD/YYYY').toDate()

      if (!isDisabled) {
        return 'disabled'

      } else if (!isOpened) {
        return 'closed'

      } else if (!enoughTime) {
        return 'notEnoughTime'

      } else if (!isAvailable) {
        return 'soldOut'
      }

      return 'available'
    },
    getDayStatusTitle(dateString) {
      const titles = {
        disabled: 'NOT IN RAGE',
        notEnoughTime: 'NOT AVAILABLE',
        closed: 'CLOSED',
        soldOut: 'SOLD OUT'
      }

      const status = this.getDayStatus(dateString)
      return titles[status]
    },
    /*
    getAvailable(dateString) {
      const option = this.getTourOptionAvailability(dateString);

      if (option) {
        return parseInt(option["available"]);
      }

      return 0;
    },
    */
   /*
    getAnyAge(dateString) {
      const option = this.getTourOptionAvailability(dateString);

      if (option) {
        return !!option["anyage"];
      }

      return false;
    },
    */
    getChildrenAllowed(dateString) {
      const option = this.getTourOptionAvailability(dateString);

      if (option) {
        return !!option["children"];
      }

      return false;
    },
    getPrices(dateString) {
      const option = this.getTourOptionAvailability(dateString);

      if (option) {
        return option["prices"];
      }

      return false;
    },
    onSelectDate(dateString) {
      if (!this.getDayIsAvailable(dateString)) {
        return;
      }

      if (this.selectedDate) {
        if (this.selectedDate === dateString) {
          this.selectedDate = false;
        } else {
          this.selectedDate = dateString;
        }
      } else {
        this.selectedDate = dateString;
      }
      // @TODO Preselect first option and first pickup
      /*
      this.selectedOption = this.availableOptions[0]["key"];
      */

      this.tryApplyCartData();
    },
    tryApplyCartData() {
      this.answers = {};

      if (!this.cartItem) {
        return;
      }

      this.adultQty = this.cartItem.adults;
      this.childrenQty = this.cartItem.children;
      this.infantQty = this.cartItem.infants;
      this.animalQty = this.cartItem.animals;
      this.answers = this.cartItem.answers ? this.cartItem.answers : {};
    },
    onAddedToCart() {
      this.setConfirmationModal({
        content: "Do you want to add more tours?",
        header: "",
        decline: "No, Review Reservation",
        confirm: "Yes",
        confirmOnClose: true,
      })
        .then(() => {
          this.setModal()
        })
        .catch(() => {
          this.$router.push({
            name: "ReviewPage",
          });
        });
    },
    onSelectClick(datesOverlapConfirmed) {
      const date = this.$dayjs(this.selectedDate, "MM-DD-YYYY").format("MM/DD/YYYY");
      const dateStr = this.$dayjs(this.selectedDate, "MM-DD-YYYY").format('MMM D');

      this.trackData();

      if (
        !datesOverlapConfirmed &&
        !this.itemInCart &&
        (this.tourCartByDate[date] || this.addToReservationToursByDate[date])
      ) {
        this.$bvModal.msgBoxConfirm(`You already have a tour on ${dateStr}, are you sure you want to add another tour on ${dateStr}?`, {
          title: 'Confirm',
          headerBgVariant: 'secondary',
          titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
          contentClass: 'bg-gamma',
          bodyClass: 'font-size-13 line-height-11 font-weight-500 py-4',
          footerClass: 'border-0',
          okTitle: 'Yes',
          okVariant: 'primary',
          hideHeaderClose: true,
          noCloseOnBackdrop: true,
          cancelVariant: 'outline-primary',
          cancelTitle: 'Cancel',
          buttonSize: 'lg',
          centered: true
        })
          .then((value) => {
            if (value) {
              this.onSelectClick(true);
            }
          })
        return;
      }

      if (this.$_.isEmpty(this.tour.questions)) {
        // no tour questions - go ahead adding to cart
        return this.addToCart();
      } else {
        const data = {
          adults: !this.anyage ? this.adultQty : this.guestsQty,
          children: !this.anyage ? this.childrenQty : 0,
          infants: this.infantQty,
          animals: this.animalQty,
          questions: this.tour.questions,
          answers: this.answers,
          tourCode: this.tour.code,
          tourOption: this.selectedOptionPickupCode,
          anyAge: this.anyage,
          date: this.$dayjs(this.selectedDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
          modalContainerClass: "activity-questionnaire",
          onAddedToCart: this.onAddedToCart,
        };

        return this.setModal("ActivityQuestions", data);
      }
    },
    async addToCart() {
      this.errorMessage = "";
      const data = {
        property_code: PROPERTY_CODE,
        tour_code: this.tour.code,
        tour_option: this.selectedOptionPickupCode,
        date: this.$dayjs(this.selectedDate, "MM/DD/YYYY").format("MM/DD/YYYY"),
        adults: !this.anyage ? this.adultQty : this.guestsQty,
        children: !this.anyage ? this.childrenQty : 0,
        infants: this.infantQty,
        animals: this.animalQty,
        answers: {},
      };
      console.log('data', data)

      this.addInCartInProgress = true;

      if (!this.cartToken) {
        await this.$store.dispatch('property/fetchCartToken')
      }

      apiAddTourToCart(this.cartToken, data)
        .then(() => {
          this.$store.dispatch("property/fetchTourCart");
          this.addInCartInProgress = false;
          this.onAddedToCart();
        })
        .catch((err) => {
          this.errorMessage = err.response.data.detail;
          //console.error(err.response.data);
          this.addInCartInProgress = false;
        });
    },

    trackData() {
      const value = this.tour.anyage ? (
        this.guests * this.selectedOptionPickupAvailabilityAdultPrice.total
      ) : (
        this.adultQty * this.selectedOptionPickupAvailabilityAdultPrice.total +
        this.childrenQty * this.selectedOptionPickupAvailabilityChildPrice.total
      );

      const quantity = this.guests;

      const data = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: _.round(value, 2),
          items: [{
            item_id: this.tour.code,
            item_name: this.tour.title,
            affiliation: this.isAgency ? 'TA' : 'Consumer',
            index: 0,
            item_brand: this.options.title,
            item_category: 'Activity',
            item_category2: this.$dayjs(this.selectedDate, 'MM/DD/YYYY').format('YYYYMMDD'),
            item_category5: this.guests,
            item_category6: this.tour.anyage ? this.guests : this.adultQty,
            item_category7: this.tour.anyage ? 0 : this.childrenQty,
            item_variant: this.selectedOption.title,
            coupon: null,
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            price: _.round(value / quantity, 2),
            quantity,
          }]
        }
      }

      gtmTrack({ ecommerce: null });
      gtmTrack(data);
    },
  },
  watch: {
    'tour.code': function(value, oldValue) {
      if (value === oldValue) {
        return;
      }

      this.init();
    },
    selectedOptionPickupCode: function(value, oldValue) {
      if (value === oldValue) {
        return;
      }

      this.tryApplyCartData();
    },

    selectedOptionAvailability: {
      immediate: true,
      deep: true,

      handler() {
        if (
          !_.find(this.availableTourOptionOptions, { value: this.selectedOptionCode })
          // _.get(this.selectedOptionAvailability, 'available') === 0
        ) {
          const newSelectedOptionsCode = _.get(this.availableTourOptionOptions, '0.value', null)

          this.$nextTick(() => {
            if (newSelectedOptionsCode !== this.selectedOptionCode) {
              this.selectedOptionCode = newSelectedOptionsCode
            }
          })
        }
      }
    },

    selectedOptionPickupAvailability: {
      immediate: true,
      deep: true,

      handler() {
        if (
          !_.find(this.availableTourPickupOptions, { value: this.selectedPickupName })
          // _.get(this.selectedOptionAvailability, 'available') === 0
        ) {
          const newSelectedPickupName = _.get(this.availableTourPickupOptions, '0.value', null)

          this.$nextTick(() => {
            if (newSelectedPickupName !== this.selectedPickupName) {
              this.selectedPickupName = newSelectedPickupName
            }
          })
        }
      }
    },

    adultQty: {
      immediate: true,
      handler() {
        if (this.infantQty > this.adultQty) {
          this.infantQty = this.adultQty;
        }

        if (this.animalQty > this.adultQty) {
          this.animalQty = this.adultQty;
        }
      }
    },

    guestsQty: {
      immediate: true,
      handler() {
        if (this.infantQty > this.guestsQty) {
          this.infantQty = this.guestsQty;
        }

        if (this.animalQty > this.guestsQty) {
          this.animalQty = this.guestsQty;
        }
      }
    }
  }
};
