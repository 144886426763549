import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    reservation: Object,
  },
  data: () => ({}),

  computed: {
    ...mapGetters({
      options: 'app/options'
    }),

    buttons() {
      let modifyButtons = _.cloneDeep(this.$store.state.app.options.todoButtons)
      //console.log(this.$store.state.app.options.todoButtons);
      modifyButtons.forEach((item) => {
        if(item.action === 'activity' && this.$store.state.app.options.toursMode === "off") {
          item.enabled = ''
        }
      })
      return modifyButtons;
    }
  },

  methods: {
    onAction(action) {
      /*
      let query = JSON.parse(localStorage.getItem('queryForActivities'))
      */
      switch (action) {
        case "stay":
          this.$emit("addStay");
          break;

        case "activity":
          this.$emit("addActivity");
          break;  
          /*
          if (this.$store.state.app.options.toursMode === 'full' ||
          this.$store.state.app.options.toursMode === 'standalone'
          ) {
            query ? localStorage.setItem('dateForActivity', query.dateFrom ) : localStorage.setItem('dateForActivity', JSON.parse(localStorage.getItem('toursSearchParams')).dateFromString)
            this.$router.push({name: "book_activity"} )
          } else if (this.$store.state.app.options.toursMode === 'inline') {
            this.$router.push({
              name: "activitiesPage",
              query: query
            })
          }
          */
        default:
          console.log(`Bad Times at the El Royale 2: ${action}`);
          break;
      }
      /*
      localStorage.removeItem('queryForActivities')
      */
    },
    getImageUrl(url) {
      return this.getCdnResourceUrl(url);
    }
  },
};
