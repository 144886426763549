<template>
  <b-dropdown
    ref="trigger"
    :id="computedId"
    block
    variant="outline-secondary"
    :toggle-class="computedToggleClass"
    no-caret
    no-flip
    :disabled="disabled"
    :right="right"
    :menu-class="{
      'p-2': true,
      'm-0': true,
      'min-w-100': true,
      'bg-gamma': true,
      'd-none': $mq === 'sm',
      'border-top-2': true
    }"
    @shown="open"
  >
    <template #button-content>
      <slot name="label">
        <div class="text-truncate mw-100 font-size-7 font-family-1">
          {{ labelTop }}
        </div>

        <div class="text-truncate mw-100 font-weight-bold">
          {{ label }}
        </div>
      </slot>
    </template>

    <slot
      name="dropdown"
      :open="open"
      :close="close"
      :toggle="toggle"
    />

    <b-modal
      v-if="$mq === 'sm'"
      v-model="isOpened"
      ref="modal"
      dialog-class="modal-down-sm-fullscreen"
      content-class="rounded-0 border-0"
      centered
      header-class="rounded-0 bg-beta p-0 border-0 height-50px bg-beta"
      title-class="text-dark text-uppercase px-2 py-4 font-size-12 text-center font-family-2 w-100"
      body-class="p-0"
      hide-footer
      hide-header-close
      scrollable
      size="lg"
      no-close-on-backdrop
      :data-for-property="property.code"
    >
      <template #modal-title>
        {{ modalTitle }}
      </template>

      <div class="d-flex flex-column h-100 overflow-hidden">
        <div class="p-4 overflow-auto h-100">
          <slot
            name="dropdown"
            :open="open"
            :close="close"
            :toggle="toggle"
          />
        </div>

        <div class="p-4 bg-beta">
          <b-row>
            <b-col>
              <b-button
                class="text-uppercase"
                size="'lg'"
                block
                variant="outline-primary"
                @click="isOpened = false"
              >
                Cancel
              </b-button>
            </b-col>

            <b-col>
              <b-button
                class="text-uppercase"
                size="'lg'"
                block
                variant="primary"
                @click="isOpened = false"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </b-dropdown>
</template>

<script>
export default {
  name: 'DropdownSelect',

  props: {
    labelTop: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    modalTitle: {
      type: String,
      default: null
    },

    right: {
      type: Boolean,
      default: false
    },

    property: {
      type: Object,
      required: true
    },

    toggleClass: {
      type: String,
      default: null
    },

    id: {
      type: String,
      default: null,
    },

    parentId: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: null,
    }
  },

  data() {
    return {
      isOpened: false,
      isDropdownVisible: false,
    }
  },

  computed: {
    computedToggleClass() {
      let computedToggleClass = 'h-100 d-flex flex-column align-items-start px-3 py-1 bg-white'

      if (this.toggleClass) {
        computedToggleClass = `${computedToggleClass} ${this.toggleClass}`
      }

      return computedToggleClass
    },

    computedId() {
      const id = this.id || 'dropdown-select'

      if (this.parentId) {
        return `${id}--nested-for--${this.parentId}`;
      }

      return id;
    }
  },

  watch: {
    isOpened: {
      immediate: true,
      handler(newValue) {
        if (this.$refs.trigger) {
          if (newValue) {
            this.$refs.trigger.show()
            this.$emit('shown')
            this.addModalStyleVars()
          } else {
            this.$refs.trigger.hide()
            this.$emit('hidden')
          }
        }
      }
    }
  },

  methods: {
    open() {
      this.isOpened = true
    },

    close() {
      this.isOpened = false
    },

    toggle() {
      this.isOpened = !this.isOpened
    },

    addModalStyleVars() {
      if (this.$refs.modal) {
        this.$nextTick(() => {
          let modalWrapper = document.createElement('div')
          modalWrapper.classList = 'xanterra-body'
          document.body.appendChild(modalWrapper)
          modalWrapper.appendChild(this.$refs.modal.$el)
        })
      }
    }
  }
}
</script>

<style lang="sass">
.b-dropdown-menu
  ::v-deep .dropdown-menu
    border: 4px solid #000
</style>