import _ from 'lodash'
import {useVuelidate} from "@vuelidate/core";
// @TODO replace this mixin by separate functions to use in somethis like composition api
export default {
  setup: () => ({v$: useVuelidate()}),
  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isFetching: false,
      isSubmitting: false,
      newItem: null,
      input: null,
      inputChangedAt: null,
      inputSavedAt: null
    }
  },

  computed: {
    initialInput() {
      return this.getInput()
    },

    inputIsDirty() {
      return !_.isEqual(this.input, this.initialInput)
    },

    newItemItemIdentifier() {
      return _.get(this.newItem, this.idKey || 'id')
    },

    isCreated() {
      return !!this.newItemItemIdentifier
    },

    isFetched() {
      return this.isCreated && _.keys(this.newItem).length > 1
    },

    upsertActionName() {
      return this.isCreated ? 'Update' : 'Create'
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,

      handler() {
        if (!_.isEqual(this.item, this.newItem)) {
          this.newItem = _.cloneDeep(this.item)

          if (this.prefetch) {
            this.refresh()
          }
        }
      }
    },
  
    newItem: {
      immediate: true,
      deep: true,

      handler() {
        if (this.newItem && !_.isEqual(this.newItem, this.item)) {
          this.$emit('update:item', _.cloneDeep(this.newItem))
        }

        if (this.newItem) {
          this.setInput()
        }
      }
    },

    input: {
      immediate: true,
      deep: true,
      handler() {
        this.inputChangedAt = +new Date()

        if (this.autosave) {
          this.$nextTick(() => {
            if (this.inputIsDirty) {
              this.debounceSubmit()
            }
          })
        }
      }
    }
  },

  created() {
    this.debounceSubmit = _.debounce(async function() {
      if (this.inputIsDirty) {
        this.inputSavedAt = +new Date()
        await this.submit()
      }
    }, this.debounceSubmitTime || 2000)
  },

  validations: () => ({}),

  methods: {
    getInput() {
      return null
    },

    setInput() {
      this.v$.$reset?.()
      this.input = _.cloneDeep(this.initialInput)
    },
  
    getFieldState(vuelidateParam) {
      return vuelidateParam && vuelidateParam.$dirty ? !vuelidateParam?.$invalid : null
    },

    prepareInput() {
      return this.input 
    },

    getSubmitSuccessMessage(newItem, oldItem) {
      const action = this.isCreated ? 'updated' : 'created'
      return `Item has been ${action}`
    },
  
    getFieldInvalidFeedback(vuelidateParam) {
      let invalidFeedback = 'This field is invalid'

      if (vuelidateParam) {
        if (vuelidateParam.required === false) {
          invalidFeedback = 'This value is required.'

        } else if (vuelidateParam.minLength === false) {
          invalidFeedback = `Minimum ${vuelidateParam.$params.minLength.min} length.`

        } else if (vuelidateParam.maxLength === false) {
          invalidFeedback = `Maximum ${vuelidateParam.$params.maxLength.max} length.`

        } else if (vuelidateParam.between === false) {
          if (vuelidateParam.$params.between.min === vuelidateParam.$params.between.max) {
            invalidFeedback = `Value must be equal to ${vuelidateParam.$params.between.min}.`
          } else {
            invalidFeedback = `Value max be between ${vuelidateParam.$params.between.min} and ${vuelidateParam.$params.between.max}.`
          }

        } else if (vuelidateParam.email === false) {
          invalidFeedback = `Invalid email address.`

        } else if (vuelidateParam.sameAs === false) {
          invalidFeedback = `Two inputs don't match.`
        }
      }
      return invalidFeedback
    },
  
    async submit(submitEventName) {
      if (this.beforeSubmit) {
        await this.beforeSubmit()
      }

      this.v$.$touch?.()

      if (this.v$.$error) {
        return
      }

      const oldItem = _.cloneDeep(this.newItem)
      const newItem = this.isCreated ? await this.updateItem() : await this.createItem()

      // Show success message
      if (!this.hideSuccessMessage) {
        console.log(this.getSubmitSuccessMessage(newItem, oldItem))
      }

      // Emit submit event
      if (submitEventName) {
        this.$emit(submitEventName, this.newItem)
      }
    },
  
    fetchItem() {
      const endpoint = this.fetchEndpoint || `${this.baseEndpoint}/${this.newItemItemIdentifier}`
      this.isFetching = true

      return this.$axios
        .get(endpoint)
        .then(({ data }) => {
          this.isFetching = false
          this.newItem = data
        })
        .catch((e) => {
          this.isFetching = false
          throw e
        })
    },
  
    updateItem() {
      const endpoint = this.updateEndpoint || `${this.baseEndpoint}/${this.newItemItemIdentifier}`
      const input = this.prepareInput()
      this.isSubmitting = true
      
      return this.$axios
        .patch(endpoint, input)
  
        .then(async ({ data }) => {
          if (this.afterSubmit) {
            const newData = await this.afterSubmit(data)
            data = newData || data
          }

          this.isSubmitting = false
          this.newItem = data

          return data
        })
  
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },
  
    createItem() {
      const endpoint = this.createEndpoint || this.baseEndpoint
      const input = this.prepareInput()
      this.isSubmitting = true

      return this.$axios
        .post(endpoint, input)

        .then(async ({ data }) => {
          if (this.afterSubmit) {
            const newData = await this.afterSubmit(data)
            data = newData || data
          }

          this.isSubmitting = false
          this.newItem = data

          return data
        })

        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    refresh() {
      if (this.isCreated) {
        return this.fetchItem()
      }
    }
  }
}