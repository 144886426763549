import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import minMax from "dayjs/plugin/minMax"
import isBetween from "dayjs/plugin/isBetween"
import weekOfYear from "dayjs/plugin/weekOfYear"

dayjs.extend(customParseFormat)
dayjs.extend(minMax)
dayjs.extend(isBetween)
dayjs.extend(weekOfYear)

export default dayjs

