import { apiAddToCart, apiGetCart } from "@/api/cart";
import vClickOutside from "v-click-outside";
import { PROPERTY_CODE } from "@/constants";
import { mapGetters } from 'vuex'
import { appValuesComputed, authComputed, optionsComputed } from "@/store/helper";
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'
import _ from "lodash";
import {useVuelidate} from "@vuelidate/core";
import { useWindowSize } from 'vue-window-size';
import { gtmTrack } from "@/lib/gtm";
import {getPrices} from "@/utils/taxes-and-fees";

export default {
  setup:() => ({
    v$: useVuelidate(),
    windowSize: useWindowSize(),
  }),

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    HotelItemImages,
  },

  data: () => ({
    roomAvailability: {},
    roomData: {},
    minAgeAdult: '',
    minAgeChild: '',
    //arrival: new Date(),
    departure: new Date(),
    //nightsAmount: null,
    currentOpenSelect: "",
    //value: "",
    roomsAmount: 1,
    adults: 1,
    children: 0,
    specRequests: {},
    addData: {
      property_code: "",
      hotel_code: "GCBA",
      rateplan_code: "INTERNET",
      room_code: "CQ1",
      date: "tomorrow",
      nights: 1,
      adults: 1,
      children: 0,
      quantity: 1,
      spec_requests: {},
    },
    errorMessage: "",
    displayedRooms: null,
    confirmLoading: false,
    cartItem: {},
    rate: "",
    rateTitle: "",
    rvType: "",
    rvAnswers: {},
    rvTerms: false,
    rvCollapsed: false,
    rvTermsShow: false,
    loading: false,
    availableRoomsAmount: 0,
    shouldViewMore: true,
    isViewingMore: false
  }),
  async created() {
    this.availableRoomsAmount = +localStorage.getItem("availableRoomsAmount")
    this.loading = true

    if (!this.cartToken) {
      await this.$store.dispatch("property/fetchCartToken")
    }

    // try {
    //   const cart = await apiGetCart(this.cartToken);
    // } catch (err) { }
    this.roomAvailability = this.$store.state.app.modalProps.roomAvailability;
    if (this.isEdit || !!this.quantity) {
      this.displayedRooms =
        this.roomAvailability.available < this.$store.state.property.availableRoomsAmount
          ? this.roomAvailability.available
          : parseInt(this.numberOfItemsInCart) + parseInt(this.$store.state.property.availableRoomsAmount);
      //console.log("CREATED::1");
    } else {
      this.displayedRooms =
        this.roomAvailability.available < this.$store.state.property.availableRoomsAmount
          ? this.roomAvailability.available
          : this.$store.state.property.availableRoomsAmount;
      //console.log("CREATED::2");
    }

    this.roomData = this.$store.state.app.modalProps.roomData;
    this.minAgeAdult = this.$store.state.app.modalProps.minAgeAdult || this.$store.state.app.options.resformAgeAdult;
    this.minAgeChild = this.$store.state.app.modalProps.minAgeChild || this.$store.state.app.options.resformAgeChild;
    this.cartItem = this.$store.state.app.modalProps.cartItem;
    this.rate = this.$store.state.app.modalProps.rate ? this.$store.state.app.modalProps.rate : "";
    this.rateTitle = this.$store.state.app.modalProps.rateTitle;

    //console.log('this.roomAvailability', this.roomAvailability, this)

    //this.nightsAmount = this.$route.query.nights;
    //this.arrival = this.dateString2Date(this.$route.query.dateFrom);
    this.roomsAmount = this.quantity ? this.quantity : this.roomsAmount;
    this.adults = this.cartAdults ? this.cartAdults : +this.$route.query.adults;
    this.children = this.cartChildren ? this.cartChildren : +this.$route.query.children;
    this.specRequests = this.cartSpecRequests;

    // init RV questions
    if (this.isRvOrTent) {
      this.initRvQuestionsFromCartItem();
    }
    this.$nextTick(function () {
      this.onResize();
    });
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      cartToken: 'property/cartToken',
      hotels: 'property/hotels',
      isAgency: 'auth/isAgency',
    }),
    ...authComputed,
    ...appValuesComputed,
    ...optionsComputed,
    windowWidth() {
      return this.windowSize.width.value;
    },
    isEdit() {
      return this.$route.name === "ReviewPage";
    },
    terms() {
      return this.isAgency ? this.$store.state.app.options.termsTa : this.$store.state.app.options.terms;
    },
    nightsAmount() {
      return this.isEdit ? (this.cartItem.nights ? this.cartItem.nights : null) : this.$route.query.nights;
    },
    arrival() {
      return this.isEdit
        ? this.cartItem.date
          ? this.dateString2Date(this.cartItem.date)
          : new Date()
        : this.dateString2Date(this.$route.query.dateFrom);
    },
    /*adults() {
        return this.isEdit ? (this.cartItem.adults ? this.cartItem.adults : 1) : parseInt(this.$route.query.adults)
      },
      children() {
        return this.isEdit ? (this.cartItem.children ? this.cartItem.children : 0) : parseInt(this.$route.query.children)
      },*/
    dateValue() {
      return (
        this.$dayjs(this.arrival).format("ddd, MMM D") +
        " - " +
        this.$dayjs(this.arrival)
          .add(this.nightsAmount, "d")
          .format("ddd, MMM D, YYYY") +
        ", " +
        this.nightsAmount +
        " " +
        (this.nightsAmount !== 1 ? "Nights" : "Night")
      );
    },
    roomTitle() {
      return this.$store.state.app.modalProps.roomData.title.replace("amp;", "");
    },
    itemInCart() {
      const hotelCode = this.roomAvailability.hotelCode;
      const rvCode = this.roomAvailability.hotelCode + ":RV";
      const rateCode = this.roomAvailability.rateCode;
      const roomCode = this.roomAvailability.roomCode;
      let item = false;

      if (
        this.$store.state.property.addedRooms &&
        this.$store.state.property.addedRooms[hotelCode] &&
        this.$store.state.property.addedRooms[hotelCode][rateCode] &&
        this.$store.state.property.addedRooms[hotelCode][rateCode][roomCode]
      ) {
        item = this.$store.state.property.addedRooms[hotelCode][rateCode][roomCode];
      } else if (
        this.$store.state.property.addedRooms &&
        this.$store.state.property.addedRooms[rvCode] &&
        this.$store.state.property.addedRooms[rvCode][rateCode] &&
        this.$store.state.property.addedRooms[rvCode][rateCode][roomCode]
      ) {
        item = this.$store.state.property.addedRooms[rvCode][rateCode][roomCode];
      }

      //console.log("itemInCart", this.$store.state.property.addedRooms, item, roomCode);

      return item;
    },
    quantity() {
      if (this.isModifyReservationMode) {
        return _.min([this.displayedRooms, parseInt(this.modifyReservation.rooms)]);
      } else {
        if (this.itemInCart !== false && this.itemInCart.date === this.$dayjs(this.arrival).format("MM/DD/YYYY")) {
          return parseInt(this.itemInCart.quantity);
          // console.log('this.displayedRooms',this.displayedRooms, this.itemInCart);
          // return parseInt(this.displayedRooms);
        } else {
          return 0;
        }
      }
    },
    cartAdults() {
      if (this.itemInCart !== false) {
        return parseInt(this.itemInCart.adults);
      } else {
        return 0;
      }
    },
    cartChildren() {
      if (this.itemInCart !== false) {
        return parseInt(this.itemInCart.children);
      } else {
        return 0;
      }
    },
    cartSpecRequests() {
      if (this.itemInCart !== false) {
        // filter only spec requestsion for ows
        return _.chain(this.itemInCart.specreqs)
          .keys()
          .filter(i => this.roomData.owsRequests?.[i])
          .keyBy()
          .mapValues(i => this.roomData.owsRequests[i])
          .value();
      } else {
        return {};
      }
    },
    rateTitleLabel() {
      return this.rate ? this.rate.title : this.rateTitle;
    },
    isReviewPage() {
      return this.$route.name === "ReviewPage";
    },
    isRv() {
      return this.roomData.type === "rv" || this.roomData.type === "aba_rv";
    },
    isTent() {
      return this.roomData.type === "tent" || this.roomData.type === "aba_tent";
    },
    isRvOrTent() {
      return this.isRv || this.isTent;
    },
    modalTitle() {
      if (this.isReviewPage) {
        return this.isRvOrTent ? "Update Sites" : "Update Rooms";
      } else {
        return this.isRvOrTent ? "Add Your Sites" : "Add Your Rooms";
      }
    },
    roomsSelectLabel() {
      return this.isRvOrTent ? "Number of Sites" : "Number of Rooms";
    },
    roomSelectValue() {
      return this.getRoomsSelectValueLabel(this.roomsAmount);
    },
    addRoomButtonLabel() {
      let roomLabel;
      if (this.isRvOrTent) {
        roomLabel = this.roomsAmount > 1 ? "Sites" : "Site";
      } else {
        roomLabel = this.roomsAmount > 1 ? "Rooms" : "Room";
      }
      const actionLabel = this.quantity >= 1 || this.isReviewPage ? "Update" : "Add";

      const label = `${actionLabel} ${roomLabel}`;

      return label;
    },
    rvTypes() {
      return this.roomData.questions;
    },
    rvMaxSize() {
      if (typeof this.roomData.sizeMax !== "undefined") {
        return parseInt(this.roomData.sizeMax);
      }

      return 0;
    },
    rvTypeLabel() {
      return typeof this.rvTypes[this.rvType] !== "undefined" ? this.rvTypes[this.rvType].title : "Please choose equipment type";
    },
    rvTypeDescription() {
      return typeof this.rvTypes[this.rvType] !== "undefined" ? this.rvTypes[this.rvType].description : "";
    },
    rvQuestionsByType() {
      return typeof this.rvTypes[this.rvType] !== "undefined" ? this.rvTypes[this.rvType].options : [];
    },
    rvTypeValid() {
      return !!this.rvType;
    },
    rvQuestionsAnswered() {
      if (!this.rvTypeValid) {
        return false;
      }

      for (var i in this.rvQuestionsByType) {
        if (typeof this.rvAnswers[i] === "undefined") {
          return false;
        }
      }

      return true;
    },
    rvSize() {
      let size = 0;

      for (var i in this.rvQuestionsByType) {
        if (this.rvQuestionsByType[i].isLength && typeof this.rvAnswers[i] !== "undefined") {
          size += parseInt(this.rvAnswers[i]);
        }
      }

      return size;
    },
    rvSizeValid() {
      return this.rvSize > 0 && this.rvSize <= this.rvMaxSize;
    },
    showSelectEquipmentMessage() {
      return this.rvQuestionsAnswered && this.rvSize <= 0;
    },
    showErrorRvSizeMessage() {
      return !this.showSelectEquipmentMessage && this.rvTypeValid && this.rvQuestionsAnswered && !this.rvSizeValid;
    },
    rvAllowAdd() {
      let flag = false;
      if (this.isModifyReservationMode) {
        // check if any param has changed
        const modifyItem = this.modifyReservation.item;
        const roomCode = this.roomAvailability.roomCode;
        const rateCode = this.roomAvailability.rateCode;
        const date = this.$dayjs(this.arrival).format("MM/DD/YYYY");

        if (
          modifyItem.roomCode !== roomCode ||
          modifyItem.rateCode !== rateCode ||
          modifyItem.date !== date ||
          parseInt(modifyItem.adults) !== parseInt(this.adults) ||
          parseInt(modifyItem.children) !== parseInt(this.children) ||
          parseInt(modifyItem.quantity) !== parseInt(this.roomsAmount) ||
          parseInt(modifyItem.nights) !== parseInt(this.nightsAmount) ||
          this.rvEquipmentDetails !== this.cartItem?.specreqs?.EQUIPMENT_DETAILS ||
          this.rvSize !== this.cartItem.specreqs?.EQUIPMENT_SIZE
        ) {
          flag = true;
        }

        if (this.rvTypeValid && this.rvQuestionsAnswered && this.rvSizeValid && this.rvTerms) {
          return flag;
        } else {
          return false;
        }
      } else {
        flag = this.rvTypeValid && this.rvQuestionsAnswered && this.rvSizeValid;

        if (!this.isReviewPage) {
          flag = flag && this.rvTerms;
        }

        if (this.quantity > 0) {
          flag = flag && (
            this.quantity !== this.roomsAmount ||
            this.cartAdults !== this.adults ||
            this.cartChildren !== this.children ||
            this.rvEquipmentDetails !== this.cartItem?.specreqs?.EQUIPMENT_DETAILS ||
            this.rvSize !== this.cartItem?.specreqs?.EQUIPMENT_SIZE
          );
        }

        return flag;
      }
    },
    rvAllowUpdate() {
      if (!this.rvAllowAdd) {
        return false;
      }
    },
    rvEquipmentDetails() {
      const type = this.rvTypeLabel;
      let answers = [];

      for (var i in this.rvQuestionsByType) {
        if (typeof this.rvAnswers[i] !== "undefined") {
          answers.push(this.rvQuestionsByType[i].question + ": " + this.rvAnswers[i]);
        }
      }

      answers = answers.join(", ");

      let line = `${type} | ${answers}`;

      return line;
    },
    numberOfGuestsLabel() {
      return this.isRvOrTent ? "Number of Guests" : "Number of Guests Per Room";
    },
    roomsLeft() {
      const left =
        this.isEdit || !!this.quantity
          ? this.numberOfItemsInCart + this.$store.state.property.availableRoomsAmount
          : this.$store.state.property.availableRoomsAmount;

      //console.log("roomsLeft", this.$store.state.property.availableRoomsAmount, this.itemInCart.quantity, this.numberOfItemsInCart, left, this.displayedRooms);

      return left;
    },
    /*
     * Number of rooms/camps in the cart
     */
    numberOfItemsInCart() {
      const hotelCode = this.roomAvailability.hotelCode;
      let number = 0;

      if (this.$store.state.property.addedRooms && this.$store.state.property.addedRooms[hotelCode]) {
        const perRate = this.$store.state.property.addedRooms[hotelCode];
        for (var rate in perRate) {
          const perCode = perRate[rate];
          for (var code in perCode) {
            const room = perCode[code];

            number += parseInt(room.quantity);
          }
        }
      }

      return number;
    },
    roomAllowAdd() {
      if (this.isModifyReservationMode) {
        // check if any param has changed
        const modifyItem = this.modifyReservation.item;
        const roomCode = this.roomAvailability.roomCode;
        const rateCode = this.roomAvailability.rateCode;
        const date = this.$dayjs(this.arrival).format("MM/DD/YYYY");
        let flag = false;

        if (
          modifyItem.roomCode !== roomCode ||
          modifyItem.rateCode !== rateCode ||
          modifyItem.date !== date ||
          parseInt(modifyItem.adults) !== parseInt(this.adults) ||
          parseInt(modifyItem.children) !== parseInt(this.children) ||
          parseInt(modifyItem.quantity) !== parseInt(this.roomsAmount) ||
          parseInt(modifyItem.nights) !== parseInt(this.nightsAmount)
        ) {
          flag = true;
        }

        return flag;
      } else {
        const isSameItemInCart = this.$store.state.property.addedRooms[this.$store.state.app.modalProps.roomAvailability.hotelCode] &&
          this.$store.state.property.addedRooms[this.$store.state.app.modalProps.roomAvailability.hotelCode][this.$store.state.app.modalProps.roomAvailability.rateCode] ?
          this.$store.state.property.addedRooms[this.$store.state.app.modalProps.roomAvailability.hotelCode][this.$store.state.app.modalProps.roomAvailability.rateCode][this.$store.state.app.modalProps.roomData.code] :
          null
        const date = this.$dayjs(this.arrival).format("MM/DD/YYYY")
        const flag =
          this.quantity > 0 &&
          this.quantity === this.roomsAmount &&
          parseInt(this.cartAdults) === parseInt(this.adults) &&
          parseInt(this.cartChildren) === parseInt(this.children) &&
          isSameItemInCart && isSameItemInCart.date === date;
        return !flag;
      }
    },
    guestsAdults() {
      return this.adults;
    },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    },
    hotel() {
      return this.hotels[this.roomAvailability.hotelCode]
    },
    prices() {
      const adults = this.adults;
      const nights = +this.nightsAmount;
      const roomAvailabilityPerGuest = this.roomAvailability.perGuest[adults];

      const prices = getPrices({
        base: roomAvailabilityPerGuest?.p ?? 0,
        fees: roomAvailabilityPerGuest?.f ?? 0,
        taxes: roomAvailabilityPerGuest?.t ?? 0,
        nights,
      })

      return prices;
    }
  },
  methods: {
    selectRoomsAmount(amount) {
      this.roomsAmount = amount;
      this.value = (amount !== 1 ? amount + " Rooms " : amount + " Room ") + "($" + amount * Math.ceil(this.roomAvailability.price) + ")";
      this.currentOpenSelect = "";
    },
    setCurrentOpenSelect(value) {
      this.currentOpenSelect = this.currentOpenSelect === value ? "" : value;
    },
    onClickOutside() {
      this.currentOpenSelect = "";
    },
    setAdults(value) {
      this.adults = value;
    },
    setChildren(value) {
      this.children = value;
    },
    async addRoom() {
      this.trackData();
      console.log('this.addData', this.addData);
      // const isSameItemInCart = this.$store.state.property.addedRooms[this.$store.state.app.modalProps.roomAvailability.hotelCode] ? this.$store.state.property.addedRooms[this.$store.state.app.modalProps.roomAvailability.hotelCode][this.$store.state.app.modalProps.roomAvailability.rateCode][this.$store.state.app.modalProps.roomData.code] : null
      // const date = this.$dayjs(this.arrival).format("MM/DD/YYYY")
      this.confirmLoading = true;
      if (!this.cartToken) await this.$store.dispatch("property/fetchCartToken");
      this.addData.hotel_code = this.$store.state.app.modalProps.hotelCode || this.$route.query.destination;

      if (_.isEmpty(this.cartItem)) {
        this.addData.rateplan_code = this.roomAvailability.rateCode;
        this.addData.is_group = this.rate.category === 'GROUP'
      } else {
        this.addData.rateplan_code = this.cartItem.rateCode;
        this.addData.is_group = this.cartItem.isGroup
      }

      this.addData.room_code = this.roomAvailability.roomCode;
      this.addData.property_code = PROPERTY_CODE;
      this.addData.date = this.$dayjs(this.arrival).format("MM/DD/YYYY");
      this.addData.nights = this.nightsAmount;
      this.addData.adults = this.adults;
      this.addData.children = this.children;
      this.addData.quantity = this.roomsAmount;
      this.addData.spec_requests = _.cloneDeep(this.specRequests) ?? {};

      if (this.isRvOrTent) {
        this.addData.spec_requests["EQUIPMENT_SIZE"] = this.rvSize;
        this.addData.spec_requests["EQUIPMENT_DETAILS"] = this.rvEquipmentDetails;
      }

      try {
        const cart = await apiGetCart(this.cartToken);
      } catch (err) { }
      // console.log('FFFFFFFFF', isSameItemInCart, date);
      //   if (isSameItemInCart && isSameItemInCart.date !== date ) {
      //     // this.cart_loading = true;

      //     const remove = await apiRemoveRoom(this.$store.state.property.cart_token, isSameItemInCart.key);
      //     console.log("remove: ", remove);
      //     await this.$store.dispatch("property/fetchCart");
      //     // this.cart_loading = false;
      // }
      apiAddToCart(this.cartToken, this.addData)
        .then((resolve) => {
          this.$store.dispatch("property/fetchCart");
          this.confirmLoading = false;
          localStorage.setItem("availableRoomsAmount", this.roomsAmount);
          this.setModal();
          if (!this.isEdit) {
            if (this.isModifyReservationMode || !this.toursInlineEnabled) {
              this.$router.push({ name: "ReviewPage" });

            } else {
              localStorage.removeItem('queryForActivities')
              localStorage.setItem('queryForActivities', JSON.stringify(this.$route.query))
              return this.$router.push({
                name: "activitiesPage",
                query: this.$route.query
              })
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.errorMessage = err.response.data.detail;
          this.confirmLoading = false;
        });

    },
    trackData() {
      const value = this.prices.fullPrice.subTotal * +this.roomsAmount;
      const quantity = this.roomsAmount * this.nightsAmount;
      const data = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: _.round(value, 2),
          items: [{
            item_id: this.hotel.code,
            item_name: this.hotel.title,
            affiliation: this.isAgency ? 'TA' : 'Consumer',
            index: 0,
            item_brand: this.options.title,
            item_category: 'Hotel',
            item_category2: this.$dayjs(this.arrival).format('YYYYMMDD'),
            item_category3: +this.nightsAmount,
            item_category4: this.roomsAmount,
            item_category5: this.adults + this.children,
            item_category6: this.adults,
            item_category7: this.children,
            item_variant: this.roomTitle,
            coupon: this.roomAvailability.rateCode,
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            price: _.round(value / quantity, 2),
            quantity,
          }]
        }
      }

      gtmTrack({ ecommerce: null });
      gtmTrack(data);
    },
    
    getRoomsSelectValueLabel(room) {
      let roomLabel;
      if (this.isRvOrTent) {
        roomLabel = room > 1 ? "Sites" : "Site";
      } else {
        roomLabel = room > 1 ? "Rooms" : "Room";
      }

      const priceLabel = this.$formatCurrency(Math.ceil(this.prices.avgPrice.subTotal * room));
      return `${room} ${roomLabel} (${priceLabel})`;
    },
    onSetRvType(key) {
      this.currentOpenSelect = "";

      if (this.rvType === key) {
        return;
      }
      this.rvType = key;

      // for (var i in this.rvAnswers) {
      //   this.$delete(this.rvAnswers, i);
      // }
      this.rvAnswers = {}
    },
    getRvValueLabel(question_index) {
      if (typeof this.rvAnswers !== "undefined" && typeof this.rvAnswers[question_index] !== "undefined") {
        const value = this.rvAnswers[question_index];
        if (value === "0" || value === 0) {
          return "None";
        } else if (value === "") {
          return "None";
        } else if (this.rvQuestionsByType[question_index].isLength) {
          return value + " ft";
        } else {
          return value;
        }
      } else {
        return "Please make a selection";
      }
    },
    getRvAnswerLabel(question_index, value) {
      if (value === "0" || value === 0) {
        return "None";
      } else if (value === "") {
        return "None";
      } else if (this.rvQuestionsByType[question_index].isLength) {
        return value + " ft";
      } else {
        return value;
      }
    },
    onSelectAnswer(question_index, answer) {
      this.$set(this.rvAnswers, question_index, answer);

      //console.log("onSelectAnswer", this.rvAnswers);

      this.currentOpenSelect = "";
    },
    onRvCollapseToggle() {
      this.rvCollapsed = !this.rvCollapsed;
    },
    initRvQuestionsFromCartItem() {
      // try cartItem
      let cart = this.cartItem;

      if (!cart) {
        cart = this.itemInCart;
      }
      if (
        typeof cart !== "undefined" &&
        typeof cart.specreqs !== "undefined" &&
        typeof cart.specreqs.EQUIPMENT_DETAILS !== "undefined" &&
        typeof cart.specreqs.EQUIPMENT_SIZE !== "undefined"
      ) {
        let equpmentDetails = cart.specreqs.EQUIPMENT_DETAILS.split("|");
        //console.log("initRvQuestions::2", equpmentDetails);

        if (equpmentDetails.length !== 2) {
          return;
        }

        let type = equpmentDetails[0].trim();
        //console.log("initRvQuestions::3", type);

        // figuring out type
        for (var i in this.rvTypes) {
          if (type === this.rvTypes[i].title.trim()) {
            this.rvType = i;
          }
        }
        //console.log("initRvQuestions::4", this.rvType);

        let answers = equpmentDetails[1].trim().split(",");

        //console.log("initRvQuestions::5", answers, this.rvQuestionsByType);

        if (answers.length !== this.rvQuestionsByType.length) {
          return;
        }
        //console.log("initRvQuestions::6", answers);

        for (var i in answers) {
          let answer = answers[i].split(":");

          if (answer.length !== 2) {
            return;
          }

          this.$set(this.rvAnswers, i, answer[1].trim());
        }

        //console.log("initRvQuestions", this.rvAnswers);
      }
    },
    onTnCView() {
      this.rvTermsShow = true;
    },
    onTnCHide() {
      this.rvTermsShow = false;
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore = this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function () {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.$nextTick(function () {
      this.onResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
