<template>
  <DropdownSelect
    modal-title="How long do you want to stay?"
    label-top="Length"
    :label="label"
    :property="property"
    :id="id || 'nights-select-dropdown'"
    :parent-id="parentId"
  >
    <template #dropdown="{ close }">
      <div class="min-w-100px pl-3">
        <div
          v-for="nights in max"
          :key="nights"
          class="py-2 font-size-9 font-family-2 cursor-pointer text-secondary text-hover-primary"
          @click="newValue = nights; close()"
        >
          ~ {{ nights }} {{ nights === 1  ? 'Night' : 'Nights' }}
        </div>
      </div>
    </template>
  </DropdownSelect>
</template>

<script>
import DropdownSelect from './DropdownSelect.vue'
import FormMixin from '@shared/mixins/form'
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'NightsSelect',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    FormMixin
  ],

  components: {
    DropdownSelect
  },

  props: {
    property: {
      type: Object,
      required: true
    },

    max: {
      type: Number,
      default: 14
    },

    id: {
      type: String,
      default: null
    },

    parentId: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      autosave: true
    }
  },

  computed: {
    label() {      
      if (!this.newValue) {
        return null

      } else if (this.newValue === 1) {
        return '1 Night'
      }

      return `${this.newValue} Nights`
    }
  }
}
</script>