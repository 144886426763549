import { propertyMethods } from "@/store/helper";
import { mapGetters } from 'vuex'

export default {
  props: {
    hotelWithAvailability: Object,
    searchParams: Object,
    availabilityData: Object,
  },
  data: () => ({
  }),
  methods: {
    ...propertyMethods,
    openHotelPage() {
      this.$router.push({ name: 'hotelPage', query: { dateFrom: this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').format('MM-DD-YYYY'), nights: this.searchParams.nights, destination: this.hotel.code, adults: this.searchParams.adults, children: this.searchParams.children, rateCode: this.searchParams.rateCode}})
    },
    excerpt(text) {
      return text.substr(0, 150);
    },
    onViewCalendar(data) {
      this.setModal("HotelRateCalendar", data);
    },
  },
  computed: {
    ...mapGetters({
      ratePlans: 'property/hotelsRatePlans',
      options: 'app/options',
    }),
    hotel() {
      return this.hotelWithAvailability.hotel;
    },
    availability() {
      return this.hotelWithAvailability.availability;
    },
    available() {
      return this.hotelWithAvailability.available;
    },
    availabilityStatus() {
      return this.hotelWithAvailability.availabilityStatus;
    },
    prices() {
      return this.hotelWithAvailability.prices;
    },
    oldPrices() {
      return this.hotelWithAvailability.oldPrices;
    },
    pricesPerOneAdult() {
      return this.hotelWithAvailability.pricesPerOneAdult;
    },
    rateCode() {
      return this.$route.query.rateCode ? this.$route.query.rateCode : "";
    },
    ratePlan() {
      return this.ratePlans[this.rateCode]
    },
    getImageUrl() {
      if (typeof this.hotel.images !== 'undefined' && this.hotel.images.length > 0) {
        return this.getCdnResourceUrl(this.hotel.images[0].image)
      }

      return ""
    },
    getImageTitle() {
      if (typeof this.hotel.images !== 'undefined' && this.hotel.images.length > 0) {
        return this.hotel.images[0].title
      }

      return ""
    },
    dateFrom() {
      return this.$route.query.dateFrom ? this.dateString2Date(this.$route.query.dateFrom) : new Date();
    },
    cancelBeforeDate() {
      const settings = this.options.cancelBeforeHotels?.find(i => (
        this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY')
        .subtract(value, 'day')
        .toDate();
      
      return cancelBeforeDate;
    },
    cancelationAvailable() {
      const today = new Date();
      return this.cancelBeforeDate && this.cancelBeforeDate > today;
    },
    cancelationMessage() {
      return this.cancelationAvailable ? (
        `free cancelation before ${this.$dayjs(this.cancelBeforeDate).format('ddd, MMM D')}`
      ) : 'cancelation fee applies';
    },
  }
};
