import { mapGetters } from "vuex";
import RangePicker from "@/components/RangePicker/index.vue";
import { propertyMethods, propertyComputed, optionsComputed } from "@/store/helper";
import _ from "lodash";
import vClickOutside from "v-click-outside";
import bookingMixin from '@/mixin/booking'
import { useWindowSize } from 'vue-window-size';
import {getPrices} from '@/utils/taxes-and-fees'

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),
  mixins: [
    bookingMixin
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { RangePicker },
  data: () => ({
    noMobile: true,
    hotel: {},
    currentOpenSelect: "",
    // allowed global date range
    /*
    minDate: null,
    maxDate: null,
    */
    // dynamic allowed date range. computed
    //allowMinDate: null,
    //allowMaxDate: null,
    // current displayed date (month)
    currentDate: null,
    // selected date range
    startDate: null,
    endDate: null,
    // data should be initialized
    // nights from search parameters
    searchNights: 1,
    // rate code from search parameters
    searchRatePlan: "",
    // selected rate code
    rateCode: "",
    // ratePlan: null,
    hotelCode: "",
    guests: 1,
    // loading flag
    loading: false,
    // start date per night data
    startDatePerNightData: {},
    selectPreviewDate: null,
    date: null,
    shouldViewMore: false,
    isViewingMore: false
  }),
  async created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      availableHotelsMonthly: 'property/availableHotelsMonthly'
    }),
    ...optionsComputed,
    ...propertyComputed,
    windowWidth() {
      return this.windowSize.width.value;
    },
    availabilityData() {
      if (this.currentDate) {
        const monthString = this.$dayjs(this.currentDate).format('MM/YYYY')
        const key = `${monthString}-${this.rateCode}`
        return this.availableHotelsMonthly?.[key] || {}
      }
      return {}
    },
    prevMonthLastDate() {
      if (this.currentDate) {
        return this.$dayjs(this.currentDate).date(1).subtract(1, 'day').toDate()
      }
      return null
    },
    prevMonthLastDateAvailability() {
      if (!this.prevMonthLastDate) {
        return null
      }
      const monthString = this.$dayjs(this.prevMonthLastDate).format('MM/YYYY')
      const dateString = this.$dayjs(this.prevMonthLastDate).format('MM/DD/YYYY')
      const key = `${monthString}-${this.rateCode}`
      const dateAvailabilityData = this.availableHotelsMonthly?.[key]?.[dateString][this.hotelCode]
      return dateAvailabilityData
    },
    prevMonthLastDateDisabled() {
      if (this.checkOutMode) {
        return this.startDate >= this.currentDate ? true : false
      }

      if (!this.prevMonthLastDateAvailability) {
        return null
      }

      return !+this.prevMonthLastDateAvailability?.rates2[this.rateCode]?.available[this.guests]
    },
    startDateAvailability() {
      if (!this.startDate) {
        return null
      }
      const monthString = this.$dayjs(this.startDate).format('MM/YYYY')
      const startDateString = this.$dayjs(this.startDate).format('MM/DD/YYYY')
      const key = `${monthString}-${this.rateCode}`
      const startDateAvailability = this.availableHotelsMonthly?.[key]?.[startDateString]?.[this.hotelCode]
      return startDateAvailability
    },
    checkOutMode() {
      return this.startDate && !this.endDate
    },
    computedEndDate() {
      return this.endDate || this.selectPreviewDate
    },
    computedSelectedNights() {
      if (this.computedEndDate) {
        return this.$dayjs(this.computedEndDate).diff(this.$dayjs(this.startDate), 'day')
      }
      return null
    },
    minDate() {
      let minDate = this.$dayjs(this.bookingDatesWindow.min).toDate()

      if (this.ratePlan?.start) {
        minDate = this.$dayjs.max(
          this.$dayjs(minDate),
          this.$dayjs(this.ratePlan.start, 'MM/DD/YYYY')
        ).toDate()
      }

      return minDate
    },
    maxDate() {
      let maxDate = this.$dayjs(this.bookingDatesWindow.max).toDate()

      if (this.ratePlan?.end) {
        maxDate = this.$dayjs.min(
          this.$dayjs(maxDate),
          this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY')
        ).toDate()
      }

      return maxDate
    },
    maxStayDate() {
      let maxStayDate = this.$dayjs(this.bookingDatesWindow.maxStay).toDate()

      if (this.ratePlan?.end) {
        maxStayDate = this.$dayjs.min(
          this.$dayjs(maxStayDate),
          this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY').subtract(1, 'day')
        ).toDate()
      }

      return maxStayDate
    },
    // disabledDays() {
    //   let dates = [];
    //   const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();

    //   let currentDateCloned = this.$dayjs(this.currentDate).toDate();

    //   for (var i = 1; i <= numberOfDays; i++) {
    //     currentDateCloned.setDate(i);

    //     //console.log("disabledDays", this.getPriceLabel(date), date);
    //     if (this.getPriceLabel(currentDateCloned) === "") {
    //       //if (!this.getAvailable(date)) {
    //       dates.push(currentDateCloned.getDate());
    //     }
    //   }

    //   //console.log("disabledDays", dates);

    //   return dates;
    // },
    allDates() {
      const length = this.$dayjs(this.currentDate).daysInMonth()
      return Array.from({ length })
        .map((_, i) => this.$dayjs(this.currentDate).set('date', i + 1).toDate())
    },
    checkOutAvailableDates() {
      if (this.checkOutMode) {
        const availabilityPerNight = this.startDateAvailability?.rates[this.rateCode]?.perNight
        const availabilityPerNightEntries = Object.entries(availabilityPerNight || {})
        return availabilityPerNightEntries
          .filter(([k, v]) => v.available[this.guests])
          .map(([k, v]) => this.$dayjs(this.startDate).add(+k - 1, 'day').toDate())
          .sort()
      }
    },
    availableDays() {
      if (this.checkOutMode) {
        return this.allDates
          .filter(date => {
            return this.checkOutAvailableDates.find(i => this.$dayjs(date).isSame(i))
          })
          .map(date => this.$dayjs(date).date())
      }

      return this.allDates
        .filter(date => {
          const dateKey = this.$dayjs(date).format("MM/DD/YYYY")
          const rate2Availability = this.availabilityData[dateKey]?.[this.hotelCode]?.rates2[this.rateCode]
          const isAvailable = (
            !!rate2Availability?.available[this.guests] &&
            !!rate2Availability?.mins[this.guests]?.p
          );
          return isAvailable;
        })
        .map(date => this.$dayjs(date).date())
    },
    customClasses() {
      let classes = {};

      if (this.minstay === 1 || !this.currentDate) {
        return classes;
      }

      // apply classes for check out mode only
      if (this.startDate && !this.endDate) {
        //const dateKey = this.$dayjs(this.startDate).format("MM/DD/YYYY");
        //const numberOfDays = this.$dayjs(this.allowMaxDate).diff(this.$dayjs(this.allowMinDate), "day");

        let currentDateCloned = this.$dayjs(this.allowMinDate).toDate();
        for (var i = 1; i < this.minstay; i++) {
          currentDateCloned.setDate(currentDateCloned.getDate() + 1);
          const dateKey2 = this.$dayjs(currentDateCloned).format("MM/DD/YYYY");

          classes[dateKey2] = "disallow-for-checkout";
        }
      } else if (!this.startDate || (this.startDate && this.endDate)) {
        let currentDateCloned = new Date(this.currentDate.getTime());
        currentDateCloned.setDate(1);
        const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();

        //console.log("customClasses", this.availabilityData, this.hotelCode, this.rateCode, this.minstay);

        for (var i = 0; i < numberOfDays; i++) {
          const dateKey = this.$dayjs(currentDateCloned).format("MM/DD/YYYY");

          // validate
          if (typeof this.availabilityData[dateKey] === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof this.availabilityData[dateKey][this.hotelCode] === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof this.availabilityData[dateKey][this.hotelCode].rates === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof this.availabilityData[dateKey][this.hotelCode].rates[this.rateCode] === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof this.availabilityData[dateKey][this.hotelCode].rates[this.rateCode]["perNight"] === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (typeof this.availabilityData[dateKey][this.hotelCode].rates[this.rateCode]["perNight"][this.minstay] === "undefined") {
            currentDateCloned.setDate(currentDateCloned.getDate() + 1);
            continue;
          }

          if (
            typeof this.availabilityData[dateKey][this.hotelCode].rates[this.rateCode]["perNight"][this.minstay]["available"][
              this.guests
            ] === "undefined" ||
            !this.availabilityData[dateKey][this.hotelCode].rates[this.rateCode]["perNight"][this.minstay]["available"][this.guests]
          ) {
            classes[dateKey] = "disallow-for-checkin";
          }

          currentDateCloned.setDate(currentDateCloned.getDate() + 1);
        }
      }

      //console.log("customClasses", classes);

      return classes;
    },
    availability() {
      let availability = {};
      //let minstayFactorPerRate = {};
      //let lastPricePerRate = {};
      for (const dateKey in this.availabilityData) {
        availability[dateKey] = {};

        for (const rateKey in this.availabilityData[dateKey][this.hotelCode].rates) {
          const rates = this.availabilityData[dateKey][this.hotelCode].rates;
          /*if (typeof minstayFactorPerRate[rateKey] === "undefined") {
            minstayFactorPerRate[rateKey] = 1;
            lastPricePerRate[rateKey] = 0;
          }*/

          let flag = false;
          //console.log(rates, dateKey, rateKey, this.minstay);
          if (
            typeof rates[rateKey]["perNight"][this.minstay] !== "undefined" &&
            typeof rates[rateKey]["perNight"][this.minstay].mins !== "undefined" &&
            typeof rates[rateKey]["perNight"][this.minstay].mins[this.guests] !== "undefined" &&
            +rates[rateKey]["perNight"][this.minstay].mins[this.guests] > 0
          ) {
            flag = true;
            //minstayFactorPerRate[rateKey] = 1;
            //lastPricePerRate[rateKey] = price;
          }

          this.$set(availability[dateKey], rateKey, flag);
        }
      }

      //console.log("availability", availability);

      return availability;
    },
    rates() {
      let rates = {};

      // keep rate code from search
      if (this.searchRatePlan) {
        rates[this.searchRatePlan.code] = this.searchRatePlan
      }
  
      const hotelsAvalability = Object.entries(this.availabilityData)[0]?.[1]?.[this.hotelCode]
      rates = {
        ...rates,
        ...hotelsAvalability?.rates
      }

      /*
      if (Object.keys(this.availabilityData).length > 0) {
        //console.log('KAKA from rates computed entered.')
        const hotelsAvalability = this.availabilityData[Object.keys(this.availabilityData)[0]][this.hotelCode];
        rates = _.defaultsDeep(rates, hotelsAvalability.rates);
      }
      */

      //console.log("rates", rates, this.searchRateCode, this.availabilityData);
      return rates;
    },
    ratePlan() {
      return this.rates[this.rateCode]
    },
    minstay() {
      return this.rates[this.rateCode]?.minstay || 1
    },
    allowAction() {
      return true;
      //return !!this.startDate;
    },
    checkInOutLabel() {
      if (this.startDate && this.endDate) {
        return "Check In - Check Out";
      } else if (this.startDate) {
        return "Check In";
      } else {
        return "Please select check in date";
      }
    },
    startEndDatesLabel() {
      if (this.startDate && this.endDate) {
        return (
          this.$dayjs(this.startDate).format("ddd, MMM D") +
          " - " +
          this.$dayjs(this.endDate).format("ddd, MMM D, YYYY") +
          ", " +
          this.nightsLabel
        );
      } else if (this.startDate) {
        return this.$dayjs(this.startDate).format("ddd, MMM D, YYYY");
      } else {
        return "";
      }
    },
    cancelButtonLabel() {
      if (this.startDate) {
        return "Cancel";
      } else {
        return "Close";
      }
    },
    cancelButtonTitle() {
      if (this.startDate) {
        return "Cancel Date Selection";
      } else {
        return "Close Calendar";
      }
    },
    selectionPrices() {
      if (this.startDate && this.endDate) {
        const startDateRateAvailability = this.startDateAvailability?.rates[this.rateCode]
        const nightsAvailability = startDateRateAvailability?.perNight?.[this.nights]
        const nightsIsAvailable = +nightsAvailability?.available[this.guests] > 0;
        if (nightsIsAvailable)  {
          return getPrices({
            base: nightsAvailability.mins[this.guests]?.p ?? 0,
            fees: nightsAvailability.mins[this.guests]?.f ?? 0,
            taxes: nightsAvailability.mins[this.guests]?.t ?? 0,
            nights: this.nights,
          })
        }
      }
    },
    nights() {
      if (this.startDate && this.endDate) {
        return this.$dayjs(this.endDate).diff(this.$dayjs(this.startDate), "day");
      } else {
        return 0;
      }
    },
    nightsLabel() {
      return this.nights + (this.nights > 1 ? " nights" : " night");
    },
    minStayValid() {
      return this.nights >= this.minstay;
    },
    minStayMessage() {
      return `Please select at least ${this.minstay} ${this.minstay > 1 ? " nights" : " night"}  to proceed`;
    },
    cart() {
      return this.$store.state.property.cart && Object.keys(this.$store.state.property.cart).length;
    },
    getImageUrl() {
      if (typeof this.hotel.images !== "undefined" && this.hotel.images.length > 0) {
        return this.getCdnResourceUrl(this.hotel.images[0].image);
      }

      return "";
    },
    getImageTitle() {
      if (typeof this.hotel.images !== "undefined" && this.hotel.images.length > 0) {
        return this.hotel.images[0].title;
      }

      return "";
    },
    // dynamic min date based on selected check in date
    allowMinDate() {
      if (this.startDate && !this.endDate) {
        return this.$dayjs(this.startDate).toDate();
      } else {
        return this.minDate;
      }
    },
    // dynamic max date based on selected check in date
    allowMaxDate() {
      if (this.startDate && !this.endDate) {
        const days = this.maxNightsFromStart;
        return this.$dayjs(this.startDate)
          .add(days, "day")
          .toDate();
      } else {
        return this.maxDate;
      }
    },
    // available amount of nights starting from start date
    maxNightsFromStart() {
      if (!this.startDate) {
        return 1;
      }

      const perNight = this.startDatePerNightData;

      if (!perNight) {
        return 1;
      }

      for (var i = this.minstay; i < 14; i++) {
        if (
          typeof perNight[i] === "undefined" ||
          typeof perNight[i]["available"] === "undefined" ||
          typeof perNight[i]["available"][this.guests] === "undefined" ||
          !perNight[i]["available"][this.guests]
        ) {
          return i - 1;
        }
      }

      return 14;
    },
    // alertMessage() {
    //   // console.log('=======================>GGG', this.$store.state.property);
    //   // console.log('FFFFTTTTDDDAAA',this.$store.state.app.modalProps.alert, this.$store.state.app.modalProps, this.hotel, this.availabilityData);
    //   let message = ''
    //   for(const dateKey in this.$store.state.property.availableHotels) {
    //     // console.log('DataKEEEEEyy', dateKey);
    //     if(this.$store.state.property.availableHotels[dateKey][this.$store.state.app.modalProps.hotel_id].status === "CLOSED") {
    //       message = this.$store.state.property.availableHotels[dateKey][this.$store.state.app.modalProps.hotel_id].message
    //       break
    //     }
    //   }
    //   return !!this.$store.state.app.modalProps.alert && !!this.$store.state.app.modalProps.alertMessage
    //     ? this.$store.state.app.modalProps.alertMessage
    //     : !!message ? message
    //     : `Unfortunately, ${this.hotel.title} has no availability for the selected dates.`;
    // },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    }
  },
  methods: {
    ...propertyMethods,
    async init() {
      this.clearHotelsAvailabilityMonthlyCache();
      const hotels = this.$store.state.property.hotels;
      this.hotel = hotels[this.$store.state.app.modalProps.hotel_id];

      // this.rateCode = this.$store.state.app.modalProps.currentRate ? this.$store.state.app.modalProps.currentRate : "INTERNET";
      this.searchRatePlan = this.$store.state.app.modalProps.currentRatePlan
      this.searchNights = this.$store.state.app.modalProps.nights ? parseInt(this.$store.state.app.modalProps.nights) : 1;

      this.rateCode = this.$store.state.app.modalProps.selectedRateCode || this.searchRatePlan?.code || 'INTERNET'
      this.guests = +this.$route.query.adults + +this.$route.query.children;
      this.hotelCode = this.$store.state.app.modalProps.hotel_id;

      /*
      const maxDateOption = this.$dayjs(this.$store.state.app.options.maxdate).toDate()


      if (this.addToReservationArrival) {
        const arrival = this.dateString2Date(this.addToReservationArrival);

        let minDate = new Date();
        let today = new Date();
        minDate.setTime(arrival.getTime() - 14 * 24 * 3600 * 1000);
        minDate.setHours(0, 0, 0, 0);

        this.minDate = minDate >= today ? minDate : today;
      } else if (this.isModifyReservationMode) {
        const arrival = this.dateString2Date(this.modifyReservation.item.date);

        let minDate = new Date();
        let today = new Date();
        minDate.setTime(arrival.getTime() - 14 * 24 * 3600 * 1000);
        minDate.setHours(0, 0, 0, 0);

        this.minDate = minDate >= today ? minDate : today;
      } else if (this.options.mindate) {
        this.minDate = this.dateString2Date(this.options.mindate);
      } else {
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        this.minDate = date;
      }

      if (this.addToReservationDeparture) {
        const departure = this.dateString2Date(this.addToReservationDeparture);

        let maxDate = new Date();
        maxDate.setTime(departure.getTime() + 14 * 24 * 3600 * 1000);
        if (maxDate > maxDateOption) maxDate = maxDateOption
        maxDate.setHours(0, 0, 0, 0);

        this.maxDate = maxDate;
      } else if (this.isModifyReservationMode) {
        const arrival = this.dateString2Date(this.modifyReservation.item.date);
        const nights = this.modifyReservation.item.nights;
        const departure = this.$dayjs(arrival)
          .add(nights, "day")
          .toDate();

        let maxDate = new Date();
        maxDate.setTime(departure.getTime() + 14 * 24 * 3600 * 1000);
        if (maxDate > maxDateOption) maxDate = maxDateOption
        maxDate.setHours(0, 0, 0, 0);

        this.maxDate = maxDate;
      } else if (this.options.maxdate) {
        this.maxDate = this.dateString2Date(this.options.maxdate);
      } else {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        date = this.$dayjs(date)
          .add(12, "month")
          .toDate();

        this.maxDate = date;
      }
      */

      let date = this.$route.query.dateFrom
      this.date = this.dateString2Date(date);
      console.log("INIT::10", date);
    },
    onDayClick(day) {
      if (day.disabled) {
        return;
      }

      if (day.customClasses === "disallow-for-checkout") {
        return;
      }

      if (day.customClasses === "disallow-for-checkin") {
        return;
      }

      let date = day.dayjs.toDate();
      let checkOutDay = day.nextAfterLastEnabledDay;
      let startDate = this.startDate;
      let endDate = this.endDate;
      this.selectPreviewDate = null;

      //console.log("checkOutDay", checkOutDay, this.minNights, date, startDate);

      if (startDate && endDate) {
        if (checkOutDay) {
          return;
        }
        this.startDate = date;
        this.endDate = null;

        // remember per night data of selected start date in order to calculate available nights from start date
        this.startDatePerNightData = this.getPerNightData(date);
      } else if (startDate) {
        if (date < startDate) {
          //this.startDate = date;
          //this.endDate = startDate;
        } else if (+date === +startDate) {
          this.startDate = null;
          this.endDate = null;
        } else {
          this.endDate = date;
        }
      } else if (endDate) {
        // should not happen
      } else {
        if (checkOutDay) {
          return;
        }
        this.startDate = date;

        // remember per night data of selected start date in order to calculate available nights from start date
        this.startDatePerNightData = this.getPerNightData(date);
      }

      /*this.$emit(
        "onDateRangeSelect",
        this.selectedStartDate,
        this.selectedEndDate
      );*/
    },
    onCurrentDateSelect(date) {
      console.log('onCurrentDateSelect', date)
      this.currentDate = date;
      this.loadData();
    },
    async loadData() {
      let key = this.$dayjs(this.currentDate).format("MM/YYYY");
      if (this.rateCode) {
        key = key + "-" + this.rateCode;
      }

      const ratePlan = this.rates[this.rateCode]

      const data = {
        key: key,
        date: this.$dayjs(this.currentDate).format("MM/DD/YYYY"),
        rate_code: ratePlan?.code,
        is_group: ratePlan?.category === 'GROUP',
        hotel_code: this.hotelCode,
        nights: 1,
        //nights: this.minstay,
      };
      console.log('data', data)

      //console.log("loadData", this.minstay);

      this.loading = true;
      //console.log('data: ', data)
      await this.getHotelsAvailabilityMonthly(data);
      this.loading = false;
    },

    getPricesForDate(date) {
      const dateKey = this.$dayjs(date).format("MM/DD/YYYY");

      const isSelected = (
        this.startDate &&
        this.computedEndDate &&
        this.$dayjs(date).isBetween(this.startDate, this.computedEndDate, 'day', '[)')
      )

      if (isSelected && this.computedSelectedNights) {
        const startDateRateAvailability = this.startDateAvailability?.rates[this.rateCode]
        const nightsAvailability = startDateRateAvailability?.perNight?.[this.computedSelectedNights]
        const nightsIsAvailable = (
          !!nightsAvailability?.available[this.guests] &&
          !!nightsAvailability?.mins[this.guests]?.p
        );
        if (nightsIsAvailable)  {
          return getPrices({
            base: nightsAvailability.mins[this.guests]?.p ?? 0,
            fees: nightsAvailability.mins[this.guests]?.f ?? 0,
            taxes: nightsAvailability.mins[this.guests]?.t ?? 0,
            nights: this.computedSelectedNights,
          })
        }

      } else {
        const startDateRate2Availability = this.availabilityData[dateKey]?.[this.hotelCode]?.rates2[this.rateCode]
        const isAvailable = (
          !!startDateRate2Availability?.available[this.guests] &&
          !!startDateRate2Availability?.mins[this.guests]?.p
        );
        if (isAvailable)  {
          return getPrices({
            base: startDateRate2Availability.mins[this.guests]?.p ?? 0,
            fees: startDateRate2Availability.mins[this.guests]?.f ?? 0,
            taxes: startDateRate2Availability.mins[this.guests]?.t ?? 0,
            nights: 1,
          })
        }
      }
    },

    getPriceLabel(date) {
      const prices = this.getPricesForDate(date);

      if (prices) {
        return this.$formatCurrency(Math.ceil(prices.avgPrice.subTotal));
      }
    },
    getSelectedDatesPriceValue() {
      if (
        this.startDatePerNightData &&
        typeof this.startDatePerNightData[this.nights] !== "undefined" &&
        typeof this.startDatePerNightData[this.nights]["mins"] !== "undefined" &&
        typeof this.startDatePerNightData[this.nights]["mins"][this.guests] !== "undefined"
      ) {
        return this.startDatePerNightData[this.nights]["mins"][this.guests];
      } else {
        return 0;
      }
    },
    getAvailable(date) {
      if (this.availability[date] && this.availability[date][this.rateCode]) {
        return this.availability[date][this.rateCode];
      } else {
        return false;
      }
    },
    getPerNightData(date) {
      const dateKey = this.$dayjs(date).format("MM/DD/YYYY");
      const hotelCode = this.hotelCode;
      const rateCode = this.rateCode;
      let perNight = false;

      if (typeof this.availabilityData[dateKey] !== "undefined") {
        if (typeof this.availabilityData[dateKey][hotelCode] !== "undefined") {
          if (typeof this.availabilityData[dateKey][hotelCode]["rates"] !== "undefined") {
            if (typeof this.availabilityData[dateKey][hotelCode]["rates"][rateCode] !== "undefined") {
              if (typeof this.availabilityData[dateKey][hotelCode]["rates"][rateCode]["perNight"] !== "undefined") {
                perNight = this.availabilityData[dateKey][hotelCode]["rates"][rateCode]["perNight"];
              }
            }
          }
        }
      }

      return perNight;
    },
    setCurrentOpenSelect(value) {
      this.currentOpenSelect = this.currentOpenSelect === value ? "" : value;
    },
    selectRate(rateName) {
      this.startDate = null;
      this.endDate = null;
      this.rateCode = rateName;
      this.currentOpenSelect = "";
      this.loadData();
    },
    onSelectionPreview(date) {
      //console.log("onSelectionPreview", date);
      this.selectPreviewDate = date;
    },
    openHotelPage() {
      if (!this.minStayValid) {
        return;
      }

      this.setModal();

      if (
        this.cartNotEmpty &&
        (this.$route.query.dateFrom !== this.$dayjs(this.startDate).format("MM-DD-YYYY") ||
          parseInt(this.$route.query.nights) !== this.nights)
      ) {
        this.setModal("ConfirmationDialog", {
          content: "Are you sure you want to empty your cart and choose another lodging date?",
          onConfirm: this.clearCart,
        });
      } else {
        // localStorage.removeItem("hotel_id");
        // localStorage.setItem("hotel_id", this.hotel.code);
        this.$router.push({
          name: "hotelPage",
          query: {
            dateFrom: this.$dayjs(this.startDate).format("MM-DD-YYYY"),
            nights: this.nights,
            destination: this.hotel.code,
            adults: this.$route.query.adults,
            children: this.$route.query.children,
            rateCode: this.ratePlan?.code,
            rateType: this.ratePlan?.category === 'GROUP' ? 'group' : 'promo'
          },
        });
      }
    },
    async clearCart() {
      await this.$store
        .dispatch("property/startOver")
        .then((r) => {
          this.setModal();
          localStorage.removeItem("hotel_id");
          localStorage.setItem("hotel_id", this.hotel.code);
          this.$router.push({
            name: "hotelPage",
            query: {
              dateFrom: this.$dayjs(this.startDate).format("MM-DD-YYYY"),
              nights: this.nights,
              destination: this.hotel.code,
              adults: this.$route.query.adults,
              children: this.$route.query.children,
              rateCode: this.$route.query.rateCode,
            },
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    onCancel() {
      if (this.startDate) {
        this.startDate = null;
        this.endDate = null;
      } else {
        this.setModal();
      }
    },
    rateSelectClose() {
      this.currentOpenSelect = "";
    },
    onClickOutside() {
      this.currentOpenSelect = "";
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore =  this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
    getDayAvailabilityStatus(dateString) {
      if (
        this.ratePlan?.category === 'GROUP' &&
        !this.$dayjs(dateString, 'MM/DD/YYYY').isBetween(
          this.$dayjs(this.ratePlan.start, 'MM/DD/YYYY'),
          this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY'),
          'day',
          '[]'
        )
      ) {
        return {
          value: 'notInGroupDates',
          text: 'Outside of Group Dates',
        }
      }
      // @TODO implelent other statues
      return {
        value: 'available',
        text: 'Available',
      }
    },
  },
  updated() {
    // try to make the whole calendar visible
    this.$nextTick(function() {
      if (this.$refs.action.offsetHeight) {
        this.$refs.content.style["padding-bottom"] = this.$refs.action.offsetHeight + 10 + "px";
        //this.$refs.contentWrapper.scrollTop = 1000;
      }
    });
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      this.onResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
