import { apiTourList, apiReservationList, apiHotelList, apiReservation } from "@/api/property";
import {
  apiAvailabilityTours,
  apiAvailabilityHotels,
  apiAvailabilityHotelsMonthly,
  apiAvailabilityRoomsMonthly,
  apiAvailabilityRateplan,
  apiAvailabilityGroupPlan
} from "@/api/availability";
import { apiCartToken, apiGetCart, apiClearCart, apiModifyCartToken, apiGetTourCart } from "@/api/cart";
import _ from 'lodash';
import { getStateFromLocalStorage, setStateToLocalStorage } from '@/store/utils'
import dayjs from '@/lib/dayjs'
import { PROPERTY_CODE } from "@/constants";
import { getRatePlanFromResponse } from '@shared/utils/rateplan'

export default {
  namespaced: true,

  state: {
    cartToken: getStateFromLocalStorage('property.cartToken'),
    lastReservationKeys: getStateFromLocalStorage('property.lastReservationKeys'),
    cart: null,
    tourCart: null,
    addedRooms: null,
    addedTours: null,
    tours: null,
    hotels: null,
    reservationList: getStateFromLocalStorage('property.reservationList'),
    toursAvailability: {},
    availableHotels: null,
    availableHotelsMonthly: {},
    availableRoomsMonthly: {},
    cart_loading: false,
    availableRoomsAmount: 0,
    hotelsRatePlans: {},

    activitySearchParams: {
      type: null,
      date: null,
      destination: null,
      adults: null,
      children: null,
      ..._.pick(getStateFromLocalStorage('property.activitySearchParams'), [
        'type',
        'date',
        'destination',
        'adults',
        'children'
      ])
    },

    lodgeSearchParams: {
      type: null,
      dateFrom: null,
      destination: null,
      adults: null,
      children: null,
      rateCode: null,
      rateType: null,
      ..._.pick(getStateFromLocalStorage('property.lodgeSearchParams'), [
        'type',
        'dateFrom',
        'destination',
        'adults',
        'children',
        'rateCode',
        'rateType'
      ])
    }
  },
  mutations: {
    setTours(state, value = {}) {
      state.tours = value;
    },
    setHotels(state, value = {}) {
      state.hotels = value;
    },
    setReservationList(state, value = {}) {
      state.reservationList = value;
      setStateToLocalStorage('property.reservationList', state.reservationList)
    },
    setToursAvailability(state, value = {}) {
      state.toursAvailability = value;
    },
    setAvailableHotels(state, value = {}) {
      state.availableHotels = value;
    },
    setAvailableHotelsMonthly(state, value = {}) {
      state.availableHotelsMonthly = {
        ...state.availableHotelsMonthly,
        [value.key]: value.data
      }
      // state.availableHotelsMonthly[value.key] = value.data;
    },
    clearAvailableHotelsMonthly(state) {
      state.availableHotelsMonthly = {};
    },
    setAvailableRoomsMonthly(state, value = {}) {
      state.availableRoomsMonthly = {
        ...state.availableRoomsMonthly,
        [value.key]: value.data
      }
      // state.availableRoomsMonthly[value.key] = value.data;
    },
    clearAvailableRoomsMonthly(state) {
      state.availableRoomsMonthly = {};
    },
    setCartToken(state, value = "") {
      state.cartToken = value
      setStateToLocalStorage('property.cartToken', state.cartToken)
    },
    setLastReservationKeys(state, value) {
      state.lastReservationKeys = value
      setStateToLocalStorage('property.lastReservationKeys', state.lastReservationKeys)
    },
    setCart(state, value = {}) {
      state.cart = value;
    },
    setTourCart(state, value = {}) {
      state.tourCart = value;
    },
    setAddedRooms(state, value = {}) {
      state.addedRooms = value;
    },
    setAddedTours(state, value = {}) {
      state.addedTours = value;
    },
    setCartLoading(state, value = {}) {
      state.cart_loading = value;
    },
    setHotelsRatePlans(state, value = {}) {
      state.hotelsRatePlans = value;
    },
    setAvailableRoomsAmount(state, value) {
      state.availableRoomsAmount = value;
    },
    setActivitySearchParams(state, value) {
      state.activitySearchParams = value
      setStateToLocalStorage('property.activitySearchParams', value)
    },
    setLodgeSearchParams(state, value) {
      state.lodgeSearchParams = value
      setStateToLocalStorage('property.lodgeSearchParams', state.lodgeSearchParams)
    }
  },

  actions: {
    async initCart({ commit, dispatch }) {
      try {
        await dispatch("fetchCart");
        await dispatch("fetchTourCart");
      } catch (err) {
        commit("setInitError");
        console.error("Error occurred while init app");
        console.error(err);
      }
    },
    async fetchToursList({ commit }) {
      try {
        const res = await apiTourList();
        commit("setTours", res);
      } catch (err) {
        console.error("Error occurred while fetching tours");
        console.error(err);
      }
    },
    async fetchAvailableTours({ commit, state }, value) {
      let toursAvailability = {};
      try {
        const resp = await apiAvailabilityTours({ date: value.date, limit: value.limit });

        const toursAvailability = _.cloneDeep(_.merge(state.toursAvailability, resp.availability));
        commit("setToursAvailability", toursAvailability);
      } catch (err) {
        console.error("Error occurred while fetching Available Tours");
        console.error(err);
      }
    },
    async fetchHotelsList({ commit }) {
      try {
        const res = await apiHotelList();
        commit("setHotels", res);
      } catch (err) {
        console.error("Error occurred while fetching hotels");
        console.error(err);
      }
    },
    async fetchAvailableHotels({ commit, dispatch, rootState }, value) {
      let availableHotels = {};
      try {
        let avail;
        if (value.cart_token) {
          avail = await apiAvailabilityHotels({
            date: value.date,
            limit: value.limit,
            rate_code: value.rate_code,
            is_group: value.is_group,
            nights: value.nights,
            cart_token: value.cart_token,
          });
        } else {
          avail = await apiAvailabilityHotels({
            date: value.date,
            limit: value.limit,
            rate_code: value.rate_code,
            is_group: value.is_group,
            nights: value.nights
          });
        }
        availableHotels = avail[Object.keys(avail)[0]];
        commit("setAvailableHotels", availableHotels);
      } catch (err) {
        console.error("Error occurred while fetching Available Hotels");
        if (typeof rootState.app.errors.rate_code !== "undefined" && !!rootState.app.errors.rate_code.stringLengthTooShort) {
          let data = {};
          for (const valueKey in value) {
            data[valueKey] = value[valueKey];
          }
          data["rate_code"] = "INTERNET";
          await dispatch("fetchAvailableHotels", data);
        }
        console.log(err.response);
      }
    },
    /*
     * cached or remote hotels availability monthly data getter
     */
    async getHotelsAvailabilityMonthly({ commit, state }, value) {
      //console.log('getHotelsAvailabilityMonthly', value)
      if (typeof state.availableHotelsMonthly[value.key] === "undefined") {
        try {
          const avail = await apiAvailabilityHotelsMonthly(value.hotel_code, {
            date: value.date,
            rate_code: value.rate_code,
            is_group: value.is_group,
            nights: value.nights,
          });
          let availableHotels = avail.availability;

          commit("setAvailableHotelsMonthly", {
            data: availableHotels,
            key: value.key,
          });

          return availableHotels;
        } catch (err) {
          console.error("Error occurred while fetching Available Hotels");
          console.error(err);
        }
      } else {
        return state.availableHotelsMonthly[value.key];
      }
    },
    clearHotelsAvailabilityMonthlyCache({ commit }) {
      commit("clearAvailableHotelsMonthly");
    },
    /*
     * cached or remote hotels availability monthly data getter
     */
    async getAvailabilityRoomsMonthly({ commit, state }, value) {
      if (typeof state.availableRoomsMonthly[value.key] === "undefined") {
        try {
          let avail;

          if (value.cart_token) {
            avail = await apiAvailabilityRoomsMonthly(value.hotelCode, {
              room_code: value.room_code,
              date: value.date,
              rate_code: value.rate_code,
              is_group: value.is_group,
              cart_token: value.cart_token,
            });
          } else {
            avail = await apiAvailabilityRoomsMonthly(value.hotelCode, {
              room_code: value.room_code,
              date: value.date,
              rate_code: value.rate_code,
              is_group: value.is_group,
            });
          }
          // let data = avail.availability;
          let data = avail;

          commit("setAvailableRoomsMonthly", {
            data: data,
            key: value.key,
          });

          return data;
        } catch (err) {
          console.error("Error occurred while fetching Available Rooms");
          console.error(err);
        }
      } else {
        return state.availableRoomsMonthly[value.key];
      }
    },
    clearAvailabilityRoomsMonthlyCache({ commit }) {
      commit("clearAvailableRoomsMonthly");
    },
    async fetchReservationsList({ commit }) {
      try {
        const res = await apiReservationList();
        commit("setReservationList", res);
      } catch (err) {
        console.error("Error occurred while fetching reservations");
        console.error(err);
      }
    },
    async clearReservationsList({ commit }) {
      commit("setReservationList", null);
    },
    async populateReservationList({ commit, state }, value) {
      await commit('setReservationList', {
        ...state.reservationList,
        [value.uniqueId]: _.pick(value, [
          'uniqueId',
          'token',
          'adults',
          'arrival',
          'children',
          'departure',
          'images',
          'modifyAllowed',
          'status',
          'subtitles',
          'title',
          'images'
        ])
      })
    },
    removeReservationFromList({ commit, state }, reservation) {
      const reservationListEntries = Object.entries(state.reservationList || {})
        .filter(([id, item]) => item.uniqueId !== reservation?.uniqueId)

      const newReservationList = Object.fromEntries(reservationListEntries)
      commit('setReservationList', newReservationList)
    },
    clearReservationListWithExpiredToken({ commit, state }) {
      const reservationListEntries = Object.entries(state.reservationList || {})
        .filter(([id, item]) => {
          let isExpired = false
          try {
            const { exp } = JSON.parse(atob(item.token.split('.')[1]))
            isExpired = exp * 1000 <= Date.now()
          } catch (error) {
            console.log('clearReservationListWithExpiredToken', error)
          }
          if (isExpired) {
            console.log('Reservation with token is expired', item.token)
          }
          return !isExpired
        })

      const newReservationList = Object.fromEntries(reservationListEntries)
      commit('setReservationList', newReservationList)
    },
    async fetchReservation({ commit, state }, value) {
      try {
        let reservation = await apiReservation(state.reservationList[value].token);
        commit("setReservations", reservation);
      } catch (err) {
        console.error("Error occurred while fetching reservations");
        console.error(err);
      }
    },
    async fetchCartToken({ commit }) {
      try {
        let cart = await apiCartToken();
        await commit("setCartToken", cart.cart_token)
      } catch (err) {
        console.error("Error occurred while fetching Cart Token");
        console.error(err);
      }
    },
    async fetchModifyCartToken({ commit }, reservation_token) {
      try {
        let cart = await apiModifyCartToken(reservation_token)
        await commit("setCartToken", cart.cart_token)

      } catch (err) {
        console.error("Error occurred while fetching Modify Cart Token");
        console.error(err);
        throw err;
      }
    },
    async fetchCart({ commit, state, rootState, dispatch }) {
      let rooms = {};
      await commit("setCartLoading", true);
      try {
        if (!state.cartToken) await dispatch("fetchCartToken");
        const cart = await apiGetCart(state.cartToken);
      
        //console.log(cart)
        await commit("setCart", cart);
        for (let cartKey in cart) {
          if (!rooms[cart[cartKey].hotelCode]) {
            rooms[cart[cartKey].hotelCode] = {};
            rooms[cart[cartKey].hotelCode][cart[cartKey].rateCode] = {};
            rooms[cart[cartKey].hotelCode][cart[cartKey].rateCode][cart[cartKey].roomCode] = cart[cartKey];
          } else if (!rooms[cart[cartKey].hotelCode][cart[cartKey].rateCode]) {
            rooms[cart[cartKey].hotelCode][cart[cartKey].rateCode] = {};
            rooms[cart[cartKey].hotelCode][cart[cartKey].rateCode][cart[cartKey].roomCode] = cart[cartKey];
          } else {
            rooms[cart[cartKey].hotelCode][cart[cartKey].rateCode][cart[cartKey].roomCode] = cart[cartKey];
          }
        }
        //console.log("fetchCart", 'rooms: ', rooms)
        await commit("setAddedRooms", rooms);

        let roomsLeft = 0;
        if (!rootState.app.mReservation) {
          let cartRooms = 0;
          Object.values(cart).forEach((room) => {
            cartRooms += room.quantity;
          });
          roomsLeft = rootState.app.options.resformMaxRooms - cartRooms;
        } else {
          roomsLeft = rootState.app.options.resformMaxRooms;
        }
        await commit("setAvailableRoomsAmount", roomsLeft);
        //console.log("fetchCart", 'rooms: ', roomsLeft)
      } catch (e) {
        console.error("Error occurred while fetching Cart");
        console.error(e);
        if (
          e?.response?.status === 422 &&
          e?.response?.data?.validation_messages?.cart_token
        ) {
          await dispatch("fetchCartToken");
        }
      }
      await commit("setCartLoading", false);
    },
    async fetchTourCart({ commit, state, dispatch }) {
      let tours = {};
      try {
        commit("setCartLoading", true);
        if (!state.cartToken) await dispatch("fetchCartToken");
        const cart = await apiGetTourCart(state.cartToken);
        commit("setCartLoading", false);
        //console.log(cart);
        commit("setTourCart", cart);

        for (let cartKey in cart) {
          const date = cart[cartKey].date;
          const tourCode = cart[cartKey].tourCode;
          const rateCode = cart[cartKey].rateCode;
          const optionCode = cart[cartKey].optionCode;

          if (typeof tours[date] === "undefined") {
            tours[date] = {};
          }
          if (typeof tours[date][tourCode] === "undefined") {
            tours[date][tourCode] = {};
          }
          if (typeof tours[date][tourCode][rateCode] === "undefined") {
            tours[date][tourCode][rateCode] = {};
          }
          if (typeof tours[date][tourCode][rateCode][optionCode] === "undefined") {
            tours[date][tourCode][rateCode][optionCode] = {};
          }

          tours[date][tourCode][rateCode][optionCode] = cart[cartKey];
        }
        //console.log("fetchTourCart", "tours: ", tours);
        commit("setAddedTours", tours);
      } catch (err) {
        console.error("Error occurred while fetching Cart");
        console.error(err);
      }
    },
    async clearCart({ dispatch, getters }) {
      apiClearCart(getters.cartToken)
      await dispatch('clearCartLocally')
    },

    async startOver({ commit, state, dispatch }) {
      try {
        await apiClearCart(state.cartToken);
      } finally {
        dispatch("fetchCart");
        commit("setCartToken", null);
        commit("setCart", null);
        commit("setTourCart", null);
        localStorage.removeItem("hotelsSearchParams");
        localStorage.removeItem("hotel_id");
        localStorage.removeItem("step5");
        localStorage.removeItem("step4");
        localStorage.removeItem("step3");
        localStorage.removeItem("step2");
        /*
        localStorage.removeItem("cart_token");
        */
      }
    },
    // for using after order is placed in order not to call fetchCart and save time. cart should be clear on server side
    clearCartLocally({ commit }) {
      commit("setCart", []);
      commit("setTourCart", null);
    },
    clearSearchParams() {
      localStorage.removeItem("hotelsSearchParams");
    },
    async setCartToken({ commit }, value) {
      await commit("setCartToken", value)
    },
    async fetchHotelsRatePlans({ commit, state }, value) {
      if (typeof state.hotelsRatePlans[value.rateplan] !== "undefined") {
        return;
      }

      return new Promise(async (resolve) => {
        await apiAvailabilityRateplan({
          property_code: value.property_code,
          rateplan: value.rateplan,
        }).then(
          (response) => {
            //console.log(response);
            let ratePlans = state.hotelsRatePlans;
            ratePlans[value.rateplan] = response.details;
            commit("setHotelsRatePlans", ratePlans);
            resolve(response);
          },
          (error) => {
            console.log("fetchHotelsRatePlans::ERROR", error);
            if (value.rateplan !== "INTERNET" && typeof state.hotelsRatePlans["INTERNET"] === "undefined") {
              return new Promise(async (anotherResolve) => {
                await apiAvailabilityRateplan({
                  property_code: value.property_code,
                  rateplan: "INTERNET",
                }).then(
                  (response) => {
                    //console.log(response);
                    let ratePlans = state.hotelsRatePlans;
                    ratePlans["INTERNET"] = response.details;
                    commit("setHotelsRatePlans", ratePlans);
                    anotherResolve(resolve(response));
                  },
                  (anotherError) => {
                    console.log("fetchHotelsRatePlans::ERROR", anotherError);
                    anotherResolve(resolve(false));
                  }
                );
              });
            } else {
              resolve(false);
            }
          }
        );
      });
    },
    async fetchHotelsRatePlan({ state, commit, dispatch }, { rateCode, rateType }) {
      if (rateType === 'group') {
         // if rate type is group
        return apiAvailabilityGroupPlan({
          property_code: PROPERTY_CODE,
          groupcode: rateCode
        })
          .then(async data => {
            const hotelsRatePlan = getRatePlanFromResponse(data.details, rateType)
            await dispatch('setHotelsRatePlan', hotelsRatePlan)
            return state.hotelsRatePlans[rateCode]
          })
          .catch(async error => {
            // if response code 422 - clear code from store
            if (error?.response?.code === 422) {
              await dispatch('clearHotelsRatePlan', rateCode)
            }
            return state.hotelsRatePlans[rateCode]
          })
      }

      return apiAvailabilityRateplan({
        property_code: PROPERTY_CODE,
        rateplan: rateCode
      })
        .then(async data => {
          const hotelsRatePlan = getRatePlanFromResponse(data.details, rateType)
          await dispatch('setHotelsRatePlan', hotelsRatePlan)
          return state.hotelsRatePlans[rateCode]
        })
        .catch(async error => {
          // if response code 422 - clear code from store
          if (error?.response?.code === 422) {
            await dispatch('clearHotelsRatePlan', rateCode)
          }
          return state.hotelsRatePlans[rateCode]
        })
    },
    async setHotelsRatePlan({ commit, state }, hotelsRatePlan) {
      const hotelsRatePlans = {
        ...state.hotelsRatePlans,
        [hotelsRatePlan.code]: hotelsRatePlan
      }

      await commit('setHotelsRatePlans', hotelsRatePlans)
    },
    async clearHotelsRatePlan({ commit, state }, rateCode) {
      const { [rateCode]: _, ...hotelsRatePlans } = state.hotelsRatePlans
      await commit('setHotelsRatePlans', hotelsRatePlans)
    },
    setActivitySearchParams({ commit, state }, value) {
      const activitySearchParams = { ...state.activitySearchParams, ...value }
      commit("setActivitySearchParams", activitySearchParams)
    },
    setLodgeSearchParams({ commit, state }, value) {
      const lodgeSearchParams = { ...state.lodgeSearchParams, ...value }
      commit("setLodgeSearchParams", lodgeSearchParams)
    },
    setLastReservationKeys({ commit }, value) {
      commit('setLastReservationKeys', value)
    }
  },
  getters: {
    hotels: state => state.hotels,
    tours: state => state.tours,
    toursBySeasons: state => _.map(state.tours).reduce((result, item) => {
      const season = item.code.split('-')[1][0];
        return {
          ...result,
          [season]: {
            ...result[season],
            [item.code]: item,
          }
        }
      }, {}),
    availableHotelsMonthly: state => state.availableHotelsMonthly,
    availableRoomsMonthly: state => state.availableRoomsMonthly,

    // Tours mindate
    toursMindate: (state) => _.chain(state.tours)
      .sortBy(i => dayjs(i.mindate, 'MM/DD/YYYY').toDate())
      .first()
      .get('mindate')
      .value(),
    
    toursInfantsAllowed: (state) => !!_.find(state.tours, i => i.infantsAllowed),
    toursAnimalsAllowed: (state) => !!_.find(state.tours, i => i.animalsAllowed),
    toursMaxAdults: (state) => _.chain(state.tours)
      .map( i => i.maxAdults)
      .max()
      .value(),
    toursMaxChildren: (state) => _.chain(state.tours)
      .map( i => i.maxChildren)
      .max()
      .value(),

    // hotels maxstaydate
    hotelsMaxDate: (state) => _.chain(state.hotels)
      .sortBy(i => dayjs(i.lastcheckin, 'MM/DD/YYYY').toDate())
      .last()
      .get('lastcheckin')
      .value(),

    hotelsMaxStayDate: (state) => (
        dayjs(
          _.chain(state.hotels)
            .sortBy(i => dayjs(i.lastcheckout, 'MM/DD/YYYY').toDate())
            .last()
            .get('lastcheckout')
            .value(),
          'MM/DD/YYYY'
        ).subtract(1, 'day').format('MM/DD/YYYY')
    ),

    hotelsTitle: (state) => {
      const list =
        state.hotels &&
        Object.values(state.hotels)
          .reduce((acc, curr) => acc.concat({ type: curr.type, title: curr.title, code: curr.code }), [])
          .reduce(
            (acc, curr) => {
              curr.type === "hotel"
                ? acc.hotels.push({ title: curr.title, code: curr.code })
                : acc.lodging.push({ title: curr.title, code: curr.code });
              return acc;
            },
            { hotels: [], lodging: [] }
          );
      list && Object.keys(list).forEach((key) => list[key].length === 0 && delete list[key]);
      return list;
    },

    hotelsRatePlans: state => state.hotelsRatePlans,
    // Availability
    toursAvailability: state => state.toursAvailability,
    hotelsAvailability: state => state.availableHotels,
    // Cart
    cartToken: state => state.cartToken,
    hotelCart: state => state.cart,
    groupCart: state => _.filter(state.cart, (v, k) => v.isGroup),
    nonGroupCart: state => _.filter(state.cart, (v, k) => !v.isGroup),
    tourCart: state => state.tourCart,
    tourCartByDate: state => _.reduce(state.tourCart, (result, value, key) => {
      result[value.date] = result[value.date] ?? {};
      result[value.date][key] = value;
      return result;
    }, {}),
    hotelCartIsEmpty: state => _.isEmpty(state.cart),
    tourCartIsEmpty: state => _.isEmpty(state.tourCart),
    groupCartIsEmpty: state => !_.find(state.cart, (v, k) => v.isGroup),
    nonGroupCartIsEmpty: state => !_.find(state.cart, (v, k) => !v.isGroup),
    cartIsEmpty: state => _.isEmpty(state.cart) && _.isEmpty(state.tourCart),
    lastReservationKeys: state => state.lastReservationKeys,
    // Search Params
    activitySearchParams: state => state.activitySearchParams,
    lodgeSearchParams: state => state.lodgeSearchParams,
    // Profile
    reservationList: state => state.reservationList,
  },
};
