<template>
  <section class="profile__section">
    <h2 class="profile__section-title">AGENCY INFORMATION</h2>
    <div class="ant-list ant-list-split">
      <ul class="ant-list-items">
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                IATA Number
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.iata }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                Daytime Phone
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.phone }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                Email
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.email }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                Street Address
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.address1 }} {{ form.address2 }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                City / Town
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.city }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                Country
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.country }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                State / Province
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.state }}
              </div>
            </div>
          </div>
        </li>
        <li class="ant-list-item">
          <div class="ant-list-item-meta">
            <div class="ant-list-item-meta-content">
              <h4 class="ant-list-item-meta-title">
                Zip / Postal Code
              </h4>
              <div class="ant-list-item-meta-description">
                {{ form.zip }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { required } from "@vuelidate/validators";
import { apiUpdateProfile } from "@/api/profile";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),

  data: () => ({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    state: "",
    zip: "",
    address1: "",
    address2: "",
    loading: false,
    errors: {},
  }),
  props: ["form"],
  validations: () => {
    return {
      firstName: { required },
      lastName: { required },
      city: { required },
      country: { required },
      state: { required },
      zip: { required },
      address1: { required },
      address2: {},
    }
  },
  computed: {
    countryList() {
      return this.$store.state.app.options.countries;
    },
    stateList() {
      return this.$store.state.app.options.states[this.country];
    },
  },
  watch: {
    form: {
      immediate: true,
      handler() {
        this.setFormValues();
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCountryOptionSelect(value) {
      this.country = value;
      this.state = null;
    },
    handleStateOptionSelect(value) {
      this.state = value;
    },
    setFormValues() {
      console.log(this.form);
      //this.firstName = this.form.firstName.replace("amp;", "");
      //this.lastName = this.form.lastName.replace("amp;", "");
      this.address1 = this.form.address1.replace("amp;", "");
      this.address2 = this.form.address2.replace("amp;", "");
      this.city = this.form.city.replace("amp;", "");
      this.country = this.form.country;
      this.state = this.form.state;
      this.zip = this.form.zip;
    },
    async updateUserAddress() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        city: this.city,
        country: this.country,
        state: this.state,
        zip: this.zip,
        address1: this.address1,
        address2: this.address2,
      };
      try {
        this.loading = true;
        const res = await apiUpdateProfile["address"](data);
        if (res && res.status === "SUCCESS") {
          this.$msg("Contacts data changes successfully!", 1);
          this.$store.dispatch("auth/getUserInfo");
        }
      } catch (error) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors };
        this.$store.dispatch("app/setErrors");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
