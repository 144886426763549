import _ from 'lodash';

export const calculateTaxesAndFeesValues = ({
  taxesAndFees,
  basePrice,
}) => {
  return _.orderBy(
    (taxesAndFees ?? []),
    [
      ({type}) => type === 'fee' ? 0 : 1,
      ({taxed}) => taxed ? 0 : 1,
    ]
  )
    .reduce((acc, item) => {
      const base = item.type === 'tax' ? (
        basePrice + _.sumBy(acc.filter(({item}) => item.taxed), 'value')
      ) : basePrice;
      
      let value = 0;
      if (item.flat) {
        value += item.flat;
      }

      if (item.percentage) {
        value += base * item.percentage;
      }

      return [...acc, {
        item,
        value: _.round(value, 2),
      }]
    }, []);
}

export const getPrices = ({
  base = 0,
  fees = 0,
  taxes = 0,
  nights = 1,
}) => {
  const subTotal = base + fees;
  const total = base + fees + taxes;

  const fullPrice = {
    base: _.round(base, 2),
    fees: _.round(fees, 2),
    taxes: _.round(taxes, 2),
    subTotal: _.round(subTotal, 2),
    total: _.round(total, 2),
  }

  const avgPrice = {
    base: _.round(base / nights, 2),
    fees: _.round(fees / nights, 2),
    taxes: _.round(taxes / nights, 2),
    subTotal: _.round(subTotal / nights, 2),
    total: _.round(total / nights, 2),
  };

  return {
    fullPrice,
    avgPrice,
  }
}

export const getPrice = ({
  base = 0,
  fees = 0,
  taxes = 0,
}) => {
  const subTotal = base + fees;
  const total = base + fees + taxes;

  return {
    base: _.round(base, 2),
    fees: _.round(fees, 2),
    taxes: _.round(taxes, 2),
    subTotal: _.round(subTotal, 2),
    total: _.round(total, 2),
  }
}