import vClickOutside from "v-click-outside";
import { appMethodsComputed, propertyMethods } from "@/store/helper";
import { apiAvailabilityHotelsMonthly } from "@/api/availability";
import { mapGetters } from 'vuex'
import { apiModifyCartToken } from "@/api/cart";
import _ from 'lodash';
import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data: () => ({
    roomAvailability: {},
    hotelAvailability: null,
    reservation: {},
    item: null,
    currentOpenSelect: "",
    // displayedRooms: 0,
    loading: false,
    isLoading: false,
    days: 14,
    searchParameters: {
      date: null,
      nights: null,
      rooms: null,
      adults: null,
      children: null,
    },
    modifyCartToken: null,
    errors: {},
    minAgeChild: '',
    minAgeAdult: ''
  }),
  created() {
    this.minAgeAdult = this.$store.state.app.modalProps.minAgeAdult || this.$store.state.app.options.resformAgeAdult
    this.minAgeChild = this.$store.state.app.modalProps.minAgeChild || this.$store.state.app.options.resformAgeChild
    this.reservation = this.$store.state.app.modalProps.reservation;
    this.item = this.$store.state.app.modalProps.item;
    // this.displayedRooms = parseInt(this.$store.state.app.options.resformMaxRooms);
    // init search params
    this.searchParameters.hotelCode = this.hotelCode;
    this.searchParameters.date = this.startDate;
    this.searchParameters.nights = this.nights;
    this.searchParameters.rooms = this.roomQuantity;
    this.searchParameters.adults = this.adults;
    this.searchParameters.children = this.children;
  },
  computed: {
    ...mapGetters({
      cartToken: 'property/cartToken',
      ratePlans: 'property/hotelsRatePlans',
      options: 'app/options',
      hotels: 'property/hotels',
    }),
    windowWidth() {
      return this.windowSize.width.value;
    },
    specRequests() {
      return _.filter(
        this.item?.specreqs,
        (v, k) => !['EQUIPMENT_DETAILS', 'EQUIPMENT_SIZE'].includes(k)
      )
    },
    equipmentSize() {
        return this.item.specreqs?.EQUIPMENT_SIZE
    },
    equipmentDetails() {
        return this.item.specreqs?.EQUIPMENT_DETAILS
    },
    equipmentType() {
        return this.item.specreqs?.EQUIPMENT_DETAILS?.split(' | ')?.[0]
    },
    equipmentSizes() {
        return this.item.specreqs?.EQUIPMENT_DETAILS?.split(' | ')?.[1]
    },
    hotelCode() {
      return this.item ? this.item.hotelCode : "ALL";
    },
    hotel() {
      return this.hotels[this.hotelCode];
    },
    roomType() {
      return this.item ? this.item.roomDetails.type : "";
    },
    rateCode() {
      return this.item ? this.item.rateCode : null;
    },
    ratePlan() {
      const ratePlan = this.ratePlans[this.item.rateCode]

      if ((ratePlan?.category === 'GROUP') !== this.item.isGroup) {
        return null
      }

      return ratePlan
    },
    roomCost() {
      return this.item ? parseFloat(this.item.total) / parseInt(this.item.quantity) / this.nights : 0;
    },
    roomCostWithTax() {
      return this.item ? (parseFloat(this.item.total) + parseFloat(this.item.tax)) / parseInt(this.item.quantity) : 0;
    },
    roomSelectValue() {
      // const price = this.roomCost ? "($" + this.searchParameters.rooms * Math.ceil(this.roomCost) + ")" : "";
      let label;

      if (this.isRvOrTent) {
        label = this.searchParameters.rooms > 1 ? "Sites" : "Site";
      } else {
        label = this.searchParameters.rooms > 1 ? "Rooms" : "Room";
      }

      return `${this.searchParameters.rooms} ${label}`;
    },
    // datepicker selected date range
    startDate() {
      return this.item && this.item.date ? this.dateString2Date(this.item.date) : new Date();
    },
    // stay description date labels
    arrive() {
      return this.$dayjs(this.startDate).format("ddd, MMM D");
    },
    depart() {
      return this.$dayjs(this.startDate)
        .add(this.nights, "day")
        .format("ddd, MMM D, YYYY");
    },
    nights() {
      return this.item && this.item.nights ? parseInt(this.item.nights) : 0;
    },
    adults() {
      return this.item && this.item.adults ? parseInt(this.item.adults) : 0;
    },
    children() {
      return this.item && this.item.children ? parseInt(this.item.children) : 0;
    },
    room() {
      return this.item && this.item.roomDetails ? this.item.roomDetails : false;
    },
    roomTitle() {
      return this.room ? this.room.title : "";
    },
    rateTitle() {
      return this.item ? this.item.rateTitle : "";
    },
    roomQuantity() {
      return this.item ? parseInt(this.item.quantity) : 0;
    },
    dailyRates() {
      return this.item ? this.item.dailyRates : {};
    },
    roomTax() {
      return this.item ? parseFloat(this.item.tax) : 0;
    },
    roomTotal() {
      return this.item ? Math.round((+this.item.total + +this.item.tax + Number.EPSILON) * 100) / 100 : 0;
    },
    getImageUrl() {
      if (this.room && typeof this.room.images !== "undefined" && this.room.images.length > 0) {
        return this.getCdnResourceUrl(this.room.images[0].image);
      }

      return "";
    },
    // datepicker allowed date range
    minDate() {
      /*
      let today = new Date();
      let minDate = this.$dayjs(this.startDate)
        .subtract(this.days, "day")
        .toDate();

      today.setHours(0, 0, 0, 0);
      minDate.setHours(0, 0, 0, 0);

      return minDate >= today ? minDate : today;
      */
      let minDay = this.$dayjs.max([
        this.$dayjs(this.reservation.arrival).subtract(14, 'day'),
        this.$dayjs(this.reservation.departure).subtract(27, 'day'),
        ...this.options.mindate ? [this.$dayjs(this.options.mindate)] : []
      ])

      minDay = this.$dayjs.min([this.$dayjs(this.reservation.arrival), minDay])

      return minDay.toDate()
    },
    maxDate() {
      /*
      const maxDate = this.$dayjs(this.$store.state.app.options.maxdate, 'MM/DD/YYYY')
        .toDate()

      const lastStayDate = this.$dayjs(this.startDate)
        .add(this.days + this.nights - 1, "day")
        .toDate()

      return lastStayDate > maxDate ? maxDate : lastStayDate 
      */
      const maxDay = this.$dayjs.min([
        this.$dayjs(this.reservation.arrival).add(27, 'day'),
        this.$dayjs(this.reservation.departure).add(13, 'day'),
        this.$dayjs(this.hotel.lastcheckin),
        this.$dayjs(this.options.maxdate)
      ]).toDate()

      return maxDay
    },
    maxStayDate() {
      /*
      const maxStayDate = this.$dayjs(this.$store.state.app.options.maxdate, 'MM/DD/YYYY')
        .add(14, 'day')
        .toDate()

      const lastStayDate = this.$dayjs(this.startDate)
        .add(this.days + this.nights, "day")
        .toDate()

      return lastStayDate > maxStayDate ? lastStayDate : maxStayDate 
      */
      const maxStayDay = this.$dayjs.min([
        this.$dayjs(this.reservation.arrival).add(27, 'day'),
        this.$dayjs(this.reservation.departure).add(13, 'day'),
        this.$dayjs(this.hotel.lastcheckout).subtract(1, 'day')
      ]).toDate()

      return maxStayDay
    },

    computedMaxStayDate() {
      let maxStayDate = this.maxStayDate || this.$dayjs(this.maxDate).add(14, 'day').toDate()

      if (this.ratePlan?.end) {
        if (maxStayDate) {
          maxStayDate = this.$dayjs.min(
            this.$dayjs(maxStayDate),
            this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY').subtract(1, 'day')
          ).toDate()

        } else {
          maxStayDate = this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY')
            .subtract(1, 'day')
            .toDate()
        }
      }

      return maxStayDate
    },

    allowedRange() {
      let start = this.minDate
      let end = this.maxDate

      if (this.ratePlan) {
        if (this.ratePlan.start) {
          start = this.$dayjs.max(
            this.$dayjs(start),
            this.$dayjs(this.ratePlan.start, 'MM/DD/YYYY')
          ).toDate()
        }

        if (this.ratePlan.end) {
          end = this.$dayjs.min(
            this.$dayjs(end),
            this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY').subtract(1, 'day')
          ).toDate()
        }
      }

      return { start, end }
    },

    nightsAvailability() {
      const dateKey = this.$dayjs(this.searchParameters.date).format('MM/DD/YYYY')
      const dayAvailability = this.hotelAvailability?.[dateKey][this.item.hotelCode]
      const rateAvailability = dayAvailability?.rates[this.item.rateCode]
      const nightsAvailability = rateAvailability?.perNight[this.searchParameters.nights]
      return nightsAvailability
    },

    maxRoomsAvailable() {
      const guests = this.searchParameters.adults + this.searchParameters.children
      return this.nightsAvailability?.available[guests]
    },

    maxRooms() {
      return this.maxRoomsAvailable ?? +this.options.resformMaxRooms
    },

    itemTitle() {
      return this.isRvOrTent ? "Site" : "Room";
    },
    itemQtyTitle() {
      return this.isRvOrTent ? "Number of Sites" : "Number of Rooms";
    },
    isRvOrTent() {
      return (
        this.item &&
        this.item.roomDetails &&
        (this.item.roomDetails.type === "rv" ||
          this.item.roomDetails.type === "tent" ||
          this.item.roomDetails.type === "aba_rv" ||
          this.item.roomDetails.type === "aba_tent")
      );
    },
    roomsSelectLabel() {
      return this.isRvOrTent ? "Number of Sites" : "Number of Rooms";
    },
    numberOfGuestsLabel() {
      return this.isRvOrTent ? "Number of Guests" : "Number of Guests Per Room";
    },
    dateLabel() {
      return "Check In - Check Out";
    },

    dateType() {
      return "";
    },
    dataChanged() {
      if (+this.searchParameters.date !== +this.startDate) {
        return true;
      }
      if (this.searchParameters.nights !== this.nights) {
        return true;
      }
      if (this.searchParameters.rooms !== this.roomQuantity) {
        return true;
      }
      if (this.searchParameters.adults !== this.adults) {
        return true;
      }
      if (this.searchParameters.children !== this.children) {
        return true;
      }

      return false;
    },
    addRoomButtonLabel() {
      return this.dataChanged ? "Check Availability" : "No Change";
    },
    maxOccupancy() {
      return this.item && this.item.roomDetails && this.item.roomDetails.occupancyMax ? parseInt(this.item.roomDetails.occupancyMax) : 6;
    }
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.item) {
          this.isLoading = true
          // Fetch rate code
          if (this.item.rateCode) {
            await this.$store.dispatch('property/fetchHotelsRatePlan', {
              rateCode: this.item.rateCode,
              rateType: this.item.isGroup ? 'group' : 'promo'
            })
          }

          // Fetch cart token
          if (!this.modifyCartToken) {
            const respCartToken = await apiModifyCartToken(this.item.itemToken)
            this.modifyCartToken = respCartToken?.cart_token
          }

          // Fetch availability
          const respHotelAvailability = await apiAvailabilityHotelsMonthly(this.item.hotelCode, {
            date: this.allowedRange.start,
            limit: this.$dayjs(this.allowedRange.end, 'MM/DD/YYYY').diff(this.$dayjs(this.allowedRange.start, 'MM/DD/YYYY'), 'day'),
            rate_code: this.ratePlan?.code,
            is_group: this.ratePlan?.category === 'GROUP',
            cart_token: this.modifyCartToken,
            singletype: true
          })
          this.hotelAvailability = respHotelAvailability.availability

          this.isLoading = false
        }
      }
    }
  },
  methods: {
    ...propertyMethods,
    ...appMethodsComputed,
    getRoomsSelectValueLabel(room) {
      let roomLabel;
      if (this.isRvOrTent) {
        roomLabel = room > 1 ? "Sites" : "Site";
      } else {
        roomLabel = room > 1 ? "Rooms" : "Room";
      }
      // const guests = this.searchParameters.adults + this.searchParameters.children
      // const price = this.nightsAvailability?.mins[guests]
      // const priceStr = price ? "($" + room * Math.ceil(price) + ")" : "";

      return `${room} ${roomLabel}`;
    },
    setDate(data) {
      this.searchParameters.date = data.date;
      this.searchParameters.nights = data.limit;
    },
    onClickOutside() {
      this.currentOpenSelect = "";
    },
    selectRoomsAmount(amount) {
      this.searchParameters.rooms = amount;
      this.currentOpenSelect = "";
    },
    setCurrentOpenSelect(value) {
      this.currentOpenSelect = this.currentOpenSelect === value ? "" : value;
    },
    setAdults(value) {
      this.searchParameters.adults = value;
    },
    setChildren(value) {
      this.searchParameters.children = value;
    },
    async onSubmit() {
      if (this.isModifyReservationMode && !this.roomsAvailability.length) {
        // no rooms available. redirect back to manage reservation
        this.allowRouteLeave = true;
        this.$msg('There are no available rooms for selected date')
        return this.returnModifyReservation();
      }

      let query = {
        dateFrom: this.$dayjs(this.searchParameters.date).format("MM-DD-YYYY"),
        adults: this.searchParameters.adults,
        children: this.searchParameters.children,
        nights: this.searchParameters.nights,
        destination: this.searchParameters.hotelCode,
      };

      if (this.ratePlan) {
        query.rateCode = this.ratePlan.code;
        query.rateType = this.ratePlan.category === 'GROUP' ? 'group' : 'promo'
      }

      const data = {
        item: this.item,
        reservation: this.reservation,
        rooms: this.searchParameters.rooms
      };

      try {
        // pre-flight
        this.loading = true;
        await this.startModifyReservation(data);
        this.$router.push({ name: "hotelPage", query });
        this.loading = false;
        this.setModal();

        /*
        localStorage.setItem('availableRoomsAmount', this.displayedRooms);

        let availabilityData = {};
  
        availabilityData.nights = this.searchParameters.nights;
        availabilityData.date = this.$dayjs(this.searchParameters.date).format("MM/DD/YYYY");
        if (this.rateCode) {
          availabilityData.rate_code = this.rateCode;
        }

        availabilityData.cart_token = this.cartToken
    
        const availability = await apiAvailabilityRooms(this.hotelCode, availabilityData);
        const rooms = Object.values(availability.availability[Object.keys(availability.availability)[0]].rooms);

        if (!rooms.length) {
          this.loading = false;
          this.$msg('There are no available rooms for selected date')
          return;
        } else {
          this.$router.push({
            name: "hotelPage",
            query,
          });
  
          this.setModal();  
        }
        */
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors, detail: err.detail };
      } finally {
        this.loading = false;
        this.$store.dispatch("app/setErrors");
      }
    },
    onCancel() {
      this.setModal();
    },
  },
};
