import { mapGetters } from "vuex";
import { apiRemoveRoom, apiCreateModify, apiRemoveTour, apiValidateCart } from "@/api/cart";
import { gtmTrack } from "@/lib/gtm";
import PaymentForm from "./PaymentForm/index.vue";
import { optionsComputed, propertyMethods } from "@/store/helper";
import _ from "lodash";
import {getRoomEventItem} from "../../utils/gtm";
import { apiAvailabilityRooms } from "@/api/availability";
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  components: {
    PaymentForm,
  },
  data: () => ({
    form: {},
    unwatch: null,
    totalPrice: null,
    specialRequests: "",
    specials: false,
    cart_loading: false,
    placeOrderProgressFlag: false,
    cartValidated: false,
    cartValid: false,
    invalidCartMessage: "",
    roomsAvailabilities: [],
  }),
  async created() {
    if (this.$store.getters["app/previousRoute"].params.hotel_id) {
      localStorage.setItem("hotel_id", this.$store.getters["app/previousRoute"].query.destination);
    }

    localStorage.removeItem("step5");
    localStorage.setItem("step5", this.$route.fullPath);
    this.form = { ...this.userInfo };
    this.unwatch = this.$store.watch(
      (state, getters) => getters["auth/userInfo"],
      () => (this.form = { ...this.userInfo })
    );

    await this.$store.dispatch("property/fetchCart")

    if (this.options.toursEnabled) {
      await this.$store.dispatch("property/fetchTourCart")
    }

    this.cart_loading = true;

    for (let key in this.cartItems) {
      const cartItem = this.cartItems[key];
      await this.fetchRoomAvailability(cartItem.hotelCode, {
        date: cartItem.date,
        nights: cartItem.nights,
        rate_code: cartItem.rateCode,
        is_group: cartItem.isGroup,
      });
    }

    if (this.isModifyReservationMode && this.currentStayItem) {
      await this.fetchRoomAvailability(this.currentStayItem.hotelCode, {
        date: this.currentStayItem.date,
        nights: this.currentStayItem.nights,
        rate_code: this.currentStayItem.rateCode,
        is_group: this.currentStayItem.isGroup,
      });
    }

    if (this.cartTourItems.length) {
      const minTourDate = this.$dayjs.min(
        this.cartTourItems.map((i) => this.$dayjs(i.date, "MM/DD/YYYY"))
      ).format("MM/DD/YYYY");
  
      await this.$store.dispatch("property/fetchAvailableTours", {
        date: minTourDate,
        limit: 31,
      });
    }

    this.cart_loading = false;


    this.$on("onSeLoading", this.onSeLoading);

    console.log("CREATED", this.itemsNumber);

    if (this.itemsNumber === 0) {
      return this.$router.push({
        name: "book_stay",
      });
    }

    this.validateCart();

    this.trackData();
  },
  computed: {
    ...optionsComputed,
    ...mapGetters({
      options: 'app/options',
      cartToken: 'property/cartToken',
      userInfo: 'auth/userInfo',
      addToReservation: 'app/addToReservation',
      isModifyReservationMode: 'app/isModifyReservationMode',
      tourCartByDate: 'property/tourCartByDate',
      hotels: 'property/hotels',
      isAgency: 'auth/isAgency',
    }),
    tourCountByDates() {
      return _.chain(this.tourCartByDate)
        .map((item, date) => ({
          date,
          count: _.size(item),
          bookedCount: _.size(this.addToReservation?.sections?.Tours?.items),
        }))
        .filter(i => i.count > 1 || i.bookedCount > 0)
        .keyBy('date')
        .value()
    },
    deposit() {
      const cart = this.$store.state.property.cart;
      console.log("deposit", cart);
      let total = 0;
      for (let key in cart) {
        let date = Object.keys(cart[key].dailyRates)[0];
        total += +cart[key].dailyRates[date] + +cart[key].dailyTaxes[date];
      }

      for (let key in this.cartTourItems) {
        total += +this.cartTourItems[key].total + +this.cartTourItems[key].tax;
      }

      return Number(Math.round((total + Number.EPSILON) * 100) / 100).toFixed(2);
    },
    depositDue() {
      return Number(Math.round((+this.totalPrice - +this.deposit + Number.EPSILON) * 100) / 100).toFixed(2);
    },
    cartItems() {
      this.calcTotalPrice();
      return this.$store.state.property.cart && Object.values(this.$store.state.property.cart);
    },
    cartTourItems() {
      if (this.toursEnabled) {
        return this.$store.state.property.tourCart && Object.values(this.$store.state.property.tourCart);
      } else {
        return [];
      }
    },
    stayItemsNumber() {
      return this.cartItems ? this.cartItems.length : 0;
    },
    activityItemsNumber() {
      return this.cartTourItems ? this.cartTourItems.length : 0;
    },
    itemsNumber() {
      return this.stayItemsNumber + this.activityItemsNumber;
    },
    currentStayItem() {
      return this.modifyReservation.item;
    },
    modifyReservationId() {
      return this.modifyReservation.reservation.uniqueId;
    },
    /*
     * current reservation total (modify mode)
     */
    modifyCurrentStayTotal() {
      return parseFloat(this.modifyReservation.item.total) + parseFloat(this.modifyReservation.item.tax);
    },
    /*
     * new reservation total (modify mode)
     */
    modifyNewStayTotal() {
      return parseFloat(this.totalPrice);
    },
    /*
     * reservations total diff (modify mode)
     */
    modifyTotalDiff() {
      return this.modifyNewStayTotal - this.modifyCurrentStayTotal;
    },
    /*
     * current reservation deposit (modify mode)
     */
    modifyCurrentStayDeposit() {
      return parseFloat(this.modifyReservation.item.deposit);
    },
    /*
     * new stay estimated deposit amount - first day price (modify mode)
     */
    modifyNewStayDeposit() {
      let rate = 0;
      let tax = 0;

      if (this.cartItems && this.cartItems.length > 0) {
        const cartItem = this.cartItems[0];
        const dailyRates = Object.values(cartItem.dailyRates);
        const dailyTaxes = Object.values(cartItem.dailyTaxes);
        rate = dailyRates[0] ? parseFloat(dailyRates[0]) : 0;
        tax = dailyTaxes[0] ? parseFloat(dailyTaxes[0]) : 0;
      }

      return parseFloat(rate + tax);
    },
    /*
     * due amount (modify mode)
     */
    modifyAmountDue() {
      return this.modifyNewStayDeposit > this.modifyCurrentStayDeposit ? this.modifyNewStayDeposit - this.modifyCurrentStayDeposit : 0;
    },
    /*
     * deposit diff (modify mode)
     */
    modifyDepositDiff() {
      return this.modifyNewStayDeposit - this.modifyCurrentStayDeposit;
    },
    cartTotalTax() {
      const cart = this.$store.state.property.cart;

      let total = 0;
      for (let key in cart) {
        total += parseInt(cart[key].tax);
      }

      return total;
    },
    taxDefined() {
      return this.cartTotalTax > 0;
    },
    plusTaxesLabel() {
      return this.taxDefined ? "" : "+tax";
    },
    allowModify() {
      let flag = false;
      if (this.isModifyReservationMode && this.cartItems && this.cartItems.length > 0) {
        const modifyItem = this.modifyReservation.item;
        const cartItem = this.cartItems[0];

        if (
          modifyItem.roomCode !== cartItem.roomCode ||
          modifyItem.rateCode !== cartItem.rateCode ||
          modifyItem.date !== cartItem.date ||
          parseInt(modifyItem.adults) !== parseInt(cartItem.adults) ||
          parseInt(modifyItem.children) !== parseInt(cartItem.children) ||
          parseInt(modifyItem.quantity) !== parseInt(cartItem.quantity) ||
          parseInt(modifyItem.nights) !== parseInt(cartItem.nights)
        ) {
          flag = true;
        }
      }

      return flag;
    },
    addTourUrl() {
      return `/booking/activities-select/?dateFrom=${this.predictiveDateFrom}&nights=${this.predictiveNightsAmount}&destination=${this.predictiveHotelCode}&adults=${this.predictiveAdults}&children=${this.predictiveChildren}&rateCode=${this.predictiveRateCode}`;
    },
    /*
     * try to predict nights amount
     */
    predictiveNightsAmount() {
      // using first stay item in cart
      if (this.cartItems.length > 0) {
        return this.cartItems[0].nights;
      }

      // using last search data
      if (localStorage.getItem("hotelsSearchParams")) {
        const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));

        if (params.nights) {
          return parseInt(params.nights);
        }
      }

      return 1;
    },
    /*
     * try to predict hotel code
     */
    predictiveHotelCode() {
      console.log("predictiveHotelCode", this.cartItems);
      // using first stay item in cart
      if (this.cartItems.length > 0) {
        return this.cartItems[0].hotelCode;
      }

      // using last search data
      if (localStorage.getItem("hotelsSearchParams")) {
        const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));

        if (params.destination) {
          return params.destination;
        }
      }

      return "ALL";
    },
    /*
     * try to predict date from
     */
    predictiveDateFrom() {
      // using first stay item in cart
      if (this.cartItems.length > 0) {
        return this.$dayjs(this.cartItems[0].date, "MM/DD/YYYY").format("MM-DD-YYYY");
      }

      // using last search data
      if (localStorage.getItem("hotelsSearchParams")) {
        const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));

        if (params.dateFromString) {
          return params.dateFromString;
        }
      }

      // using first tour item in cart
      if (this.cartTourItems.length > 0) {
        return this.$dayjs(this.cartTourItems[0].date, "MM/DD/YYYY").format("MM-DD-YYYY");
      }

      return this.$dayjs().format("MM-DD-YYYY");
    },
    /*
     * try to predict adults
     */
    predictiveAdults() {
      // using first stay item in cart
      if (this.cartItems.length > 0) {
        return parseInt(this.cartItems[0].adults);
      }

      // using last search data
      if (localStorage.getItem("hotelsSearchParams")) {
        const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));

        if (params.adults) {
          return parseInt(params.adults);
        }
      }

      // using first tour item in cart
      if (this.cartTourItems.length > 0) {
        return parseInt(this.cartTourItems[0].adults);
      }

      return 1;
    },
    /*
     * try to predict children
     */
    predictiveChildren() {
      // using first stay item in cart
      if (this.cartItems.length > 0) {
        return parseInt(this.cartItems[0].children);
      }

      // using last search data
      if (localStorage.getItem("hotelsSearchParams")) {
        const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));

        if (params.children) {
          return parseInt(params.children);
        }
      }

      // using first tour item in cart
      if (this.cartTourItems.length > 0) {
        return parseInt(this.cartTourItems[0].children);
      }

      return 0;
    },
    /*
     * try to predict rate code
     */
    predictiveRateCode() {
      // using first stay item in cart
      if (this.cartItems.length > 0) {
        return this.cartItems[0].rateCode;
      }

      // using last search data
      if (localStorage.getItem("hotelsSearchParams")) {
        const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));

        if (params.rateCode) {
          return params.rateCode;
        }
      }

      return "";
    },
    allowPlaceOrder() {
      return this.cartValidated && this.cartValid;
    },
    stayInCart() {
      return this.stayItemsNumber > 0;
    },
  },
  methods: {
    ...propertyMethods,
    onSeLoading(flag) {
      this.placeOrderProgressFlag = flag;
    },
    discardAllChanges() {
      this.form = { ...this.userInfo };
    },
    async calcTotalPrice() {
      const cart = this.$store.state.property.cart;
      let total = 0;
      for (let key in cart) {
        total += +cart[key].total + +cart[key].tax;
      }

      for (let key in this.cartTourItems) {
        total += +this.cartTourItems[key].total + +this.cartTourItems[key].tax;
      }

      this.totalPrice = Number(Math.round((total + Number.EPSILON) * 100) / 100).toFixed(2);
    },
    onRemoveRoom(key) {
      this.setModal("ConfirmationDialog", {
        content: "Are you sure you want to remove this room?",
        onConfirm: this.removeRoom,
        params: { key: key },
      });
    },
    onRemoveTour(key) {
      this.setModal("ConfirmationDialog", {
        content: "Are you sure you want to remove this activity?",
        onConfirm: this.removeTour,
        params: { key: key },
      });
    },
    async removeRoom(params) {
      this.cart_loading = true;
      const remove = await apiRemoveRoom(this.cartToken, params.key);
      console.log("remove: ", remove);
      await this.$store.dispatch("property/fetchCart");
      await this.validateCart();
      this.cart_loading = false;
    },
    async removeTour(params) {
      this.cart_loading = true;
      const remove = await apiRemoveTour(this.cartToken, params.key);
      console.log("remove: ", remove);
      await this.$store.dispatch("property/fetchTourCart");
      await this.validateCart();
      this.cart_loading = false;
    },
    onClearCart() {
      this.setModal("ConfirmationDialog", {
        content: "Are you sure you want to clear itinerary and start over?",
        onConfirm: this.clearCart,
      });
    },
    async clearCart() {
      await this.$store.dispatch("app/setLoading", true);
      await this.$store.dispatch("property/startOver");
      await this.$store.dispatch("app/setLoading", false);
      this.$router.push({ name: "home" });
    },
    onContinueShopping() {
      this.$router.push({ name: "home" });
    },
    trackData() {
      const data = {
        ecommerce: {
          checkout: {
            actionField: { step: "1" },
          },
        },
        page: {
          type: "checkout",
          environment: "production",
        },
      };

      gtmTrack(data);
    },

    trackModifyRefund() {
      try {
        const modifyItem = this.modifyReservation.item;

        const data = {
          'content-name': '/booking/lodging-confirmation',
          'content-view-name': 'Confirmation',
          event: 'refund',
          ecommerce: {
            currency: 'USD',
            transaction_id: this.modifyReservationId,
            tax: _.round(modifyItem.tax, 2),
            value: _.round(modifyItem.total, 2),
            items: [modifyItem].map((item, index) => ({
              ...getRoomEventItem({
                item,
                hotels: this.hotels,
                isAgency: this.isAgency,
                propertyTitle: this.options.title,
              }),
              index,
            })),
          },
        }
        gtmTrack({ ecommerce: null });
        console.log('data', data);
        gtmTrack(data)
      } catch (error) {
        console.error('trackModifyRefund error', error)
      }
    },

    async onModifySubmit() {
      try {
        this.onSeLoading(true);
        const res = await apiCreateModify(this.cartToken)
        //console.log(res);

        if (res.reservation_token) {
          this.trackModifyRefund();
          this.$store.dispatch("app/endModifyReservation")
          // this.$store.dispatch("property/clearReservationsList")
          this.$store.dispatch("property/clearCartLocally")
          this.$store.dispatch('property/setLastReservationKeys', {
            uniqueId: res.reservation_unique_id,
            token: res.reservation_token
          })

          this.$router.push({
            name: "ConfirmationPage",
            query: {
              reservation: res.reservation_unique_id,
              legs: res.leg_number,
              mode: 'modify',
            }
          })
        }
      } catch (error) {
        console.log('error', error);
        this.errors = { ...this.errors, ...this.$store.state.app.errors };
      } finally {
        this.onSeLoading(false);
      }
    },
    onReturnToReservationReview() {
      const reservation_id = this.modifyReservationId;

      this.$router.push({
        name: "ReservationPage",
        params: { reservation_id: reservation_id },
      });
    },
    onEditActivityItem(cartItem) {
      console.log("onEditActivityItem", cartItem);

      if (this.stayInCart) {
        const params = {
          dateFrom: this.$dayjs(cartItem.date, "MM/DD/YYYY").format("MM-DD-YYYY"),
          nights: this.predictiveNightsAmount,
          destination: this.predictiveHotelCode,
          adults: this.predictiveAdults,
          children: this.predictiveChildren,
          rateCode: this.predictiveRateCode,
        };

        //console.log("onEditActivityItem::2", params);

        this.$router.push({
          name: "activitiesPage",
          params: { edit: cartItem },
          query: params,
        });
      } else {
        const params = {
          date: this.$dayjs(cartItem.date, "MM/DD/YYYY").format("MM-DD-YYYY"),
          destination: cartItem.tourCode,
          adults: cartItem.adults,
          children: cartItem.children,
        };

        //console.log("onEditActivityItem::2", params);

        this.$router.push({
          name: "tourPage",
          params: { edit: cartItem },
          query: params,
        });
      }
    },
    async validateCart() {
      try {
        //console.log("validateCart::2", response);
        const response = await apiValidateCart(this.cartToken)
        this.cartValid = typeof response.status !== "undefined" && response.status === "VALID";
        if (this.cartValid) {
          this.invalidCartMessage = "";
        } else {
          this.invalidCartMessage = response.message ? response.message : "";
        }
      } catch (err) {
        //console.log("validateCart::3", err);
        this.cartValid = false;

        //this.invalidCartMessage = err.response.data.validation_messages.cart_token.stay_length;
      } finally {
        this.cartValidated = true;
      }
    },

    async fetchRoomAvailability(hotelCode, params) {
      const resp = await apiAvailabilityRooms(hotelCode, params);

      this.roomsAvailabilities = [
        ...this.roomsAvailabilities,
        {
          hotelCode,
          params,
          resp,
        }
      ];
    },

    getCartItemAvailability(cartItem) {
      return this.roomsAvailabilities.find((item) => (
        item.hotelCode === cartItem.hotelCode &&
        item.params.date === cartItem.date &&
        item.params.nights === cartItem.nights &&
        item.params.rate_code === cartItem.rateCode &&
        item.params.is_group === cartItem.isGroup
      ));
    },
  },
  beforeDestroy() {
    typeof this.unwatch === "function" && this.unwatch();
  },

  // What about to create bookingMixin and use it in all activity and lodges booking pages?
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', from.name, to.name)
    if (this.allowRouteLeave) {
      return next();
    }

    if (!this.isModifyReservationMode && !this.addToReservation) {
      return next();
    }

    if (['ReviewPage', 'hotels', 'tours', 'tourPage', 'hotelPage', 'activitiesPage'].includes(to.name)) {
      return next();
    }

    this.setConfirmationModal({
      content: "Are you sure you want to cancel reservation editing?",
    })
      .then(() => {
        if (this.addToReservation) {
          this.$store.dispatch("app/endAddToReservation")
        }

        if (this.isModifyReservationMode) {
          this.$store.dispatch("app/endModifyReservation")
        }

        next();
      })
      .catch(function() {
        next(false);
      });
  }
};
