<template>
  <b-row
    v-if="item.gallery && item.gallery.length"
  >
    <b-col
      class="mb-3"
      sm="4"
    >
      <HotelItemImages
        class="w-100 h-300px"
        :item="item"
        images-key="gallery"
        :active-index.sync="activeIndex"
      />
    </b-col>
 
    <b-col>
      <div
        class="text mb-5 typography"
        v-html="item.gallery[activeIndex].title"
      />
    </b-col>
  </b-row>
</template>

<script>
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'

export default {
  name: 'HotelItemGallery',

  components: {
    HotelItemImages
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      activeIndex: 0
    }
  }
}
</script>