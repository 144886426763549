import {apiHotelRooms} from "@/api/property";
import _ from 'lodash';

export default {
    props: {
        reservation: Object,
        item: Object,
        index: Number,
        minAgeAdult: Number,
        minAgeChild: Number,
        isConfirmationPage: Boolean
    },
    data: () => ({
        room: {},
        toursAmount: null,
    }),
    created() {
        this.toursAmount = this.$get(this.reservation, 'sections.Tours.items.length') || null;
        this.fetchRooms();
    },
    computed: {
        isHistory() {
            return this.$dayjs().startOf('day') > this.$dayjs(this.reservation.arrival)
        },
        totalItemsAmount : function() {
            return this.reservation.sections.Lodging.items.length + this.toursAmount;
        },
        arrive() {
            const date = this.dateString2Date(this.item.date)
            return this.$dayjs(date).format('ddd, MMM D')
        },
        depart() {
            let date = this.dateString2Date(this.item.date)
            date.setTime(date.getTime() + this.item.nights * 24 * 3600 * 1000)
            return this.$dayjs(date).format('ddd, MMM D, YYYY')
        },
        getImageUrl() {
            if (
              typeof this.room.images !== "undefined" &&
              this.room.images.length > 0
            ) {
              return this.getCdnResourceUrl(this.room.images[0].image);
            }
      
            return "";
        },
        itemTitle() {
            return this.isRvOrTent ? "Site" : "Room";
          },
        itemQtyTitle() {
            return this.isRvOrTent ? "Number of Sites" : "Number of Rooms";
        },
        isRvOrTent() {
            console.log("isRvOrTent", this.item)
            return (
                this.item.roomDetails.type === "rv" ||
                this.item.roomDetails.type === "tent" ||
                this.item.roomDetails.type === "aba_rv" ||
                this.item.roomDetails.type === "aba_tent"
            );
        },
        specRequests() {
            return _.filter(
                this.item.specreqs,
                (v, k) => !['EQUIPMENT_DETAILS', 'EQUIPMENT_SIZE'].includes(k)
            )
        },
        equipmentSize() {
            return this.item.specreqs?.EQUIPMENT_SIZE
        },
        equipmentDetails() {
            return this.item.specreqs?.EQUIPMENT_DETAILS
        },
        equipmentType() {
            return this.item.specreqs?.EQUIPMENT_DETAILS?.split(' | ')?.[0]
        },
        equipmentSizes() {
            return this.item.specreqs?.EQUIPMENT_DETAILS?.split(' | ')?.[1]
        }
    },
    methods: {
        async fetchRooms () {
            try {
                const rooms = await apiHotelRooms(this.item.hotelCode);
                this.room = rooms[this.item.roomCode];
            }
            catch (err) {
                console.error('Error occurred while fetching rooms');
                console.error(err)
            }
        },
        handleCancel() {
            this.$emit('cancelItemReservation', { token: this.item.itemToken, type: 'Room', itemIndex: this.index } )

            /*
            new Promise(()=> {
                this.$emit('cancelItemReservation', { token: this.item.itemToken, type: 'Room', itemIndex: this.index} )
            })
            */
        }
    }
}
