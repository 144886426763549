import { helpers } from "@vuelidate/validators";
import Luhn from 'luhn-js';
import cardValidator from 'card-validator';

export const password = helpers.regex(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/)
// export const creditcard = helpers.regex(/^(?:3[47][0-9]{13})$/)
// export const creditcard = helpers.regex(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/)
export const creditcard = (value) => {
  const minDigits = 14;
  const maxDigits = 19;
  const valueLength = `${value}`.length;
  return Luhn.isValid(value) && valueLength >= minDigits && valueLength <= maxDigits;
};
export const notCreditCard = (value) => {
  const numberValidation = cardValidator.number(value);
  return !numberValidation.isValid;
}
export const zip = helpers.regex(/^[0-9\- a-z A-Z]{2,15}$/)
export const phone = helpers.regex(/^[0-9\-&#;]{2,20}$/)
export const general = helpers.regex(/^[0-9\'\- &:#;?,-.|a-z_\/]*$/i)
export const address = helpers.regex(/^[0-9\'\- &:#;?,-.|a-z_\/]{2,40}$/i)
//export const name = helpers.regex(/([^0-9.\- &#;a-z_])*/)
export const name = helpers.regex(/^[0-9.\- &#;a-z_]{2,30}$/i)
export const promoCode = helpers.regex(/^[0-9\'\- &:#;?,-.|a-z_\/]{3,40}$/i)
