import { apiGetProfile, apiUpdateProfile, apiGetAgencyProfile } from '@/api/profile'

export default {
  namespaced: true,

  state: {
    status: !!localStorage.getItem('access_token'),
    login_type: localStorage.getItem('login_type'),
    user: {},
    errors: {},
    availabilityStatusMessage: '',
    profile_loading: false,
  },
  getters: {
    userInfo: state => JSON.parse(JSON.stringify(state.user)),
    status: state => state.status,
    loginType: state => state.login_type,
    isUser: state => state.status && state.login_type !== "a" && state.login_type !== "r",
    isAgency: state => state.status && state.login_type === "a",
    isUserOrAgency: state => state.status && state.login_type !== "r",
    isReservation: (state) => state.status && state.login_type === "r",
    isUserOrReservation: (state) => state.status && state.login_type !== "a"
  },
  mutations: {
    setUserInfo(state, value) {
      state.user = value
    },
    clearUserInfo(state, value = {}) {
      state.user = value
    },
    setErrors(state, value) {
      state.errors = value
    },
    setAuthStatus(state, value) {
      state.status = value
      state.login_type = value ? localStorage.getItem('login_type') : ''
    },
    setAvailabilityStatusMessage(state, value) {
      state.availabilityStatusMessage = value
    },
    setProfileLoading(state, value= {}) {
      state.profile_loading = value
    }
  },

  actions: {
    async getUserInfo({ state, commit }) {
      try {
        if (state.login_type === 'a') {
          commit('setProfileLoading', true);
          const res = await apiGetAgencyProfile()
          commit('setUserInfo', this._vm.$isObject(res) ? res : {})
          commit('setProfileLoading', false);
        } else if (state.login_type !== 'r') {
          commit('setProfileLoading', true);
          const res = await apiGetProfile()
          commit('setUserInfo', this._vm.$isObject(res) ? res : {})
          commit('setProfileLoading', false);
        }
      } catch (err) {
        commit('setErrors', this._vm.$isObject(err) ? err : {})
      }
    },
    /*async setAvailabilityStatusMessage({ commit }, message) {
      commit('setAvailabilityStatusMessage', message);
    },*/
    async setAvailabilityStatusMessage({ commit }, message) {
      try {
        commit('setAvailabilityStatusMessage', message);
      } catch (err) {
        commit('setErrors', this._vm.$isObject(err) ? err : {})
      }
    },
    async setAuthStatus({ commit, dispatch }, value) {
      commit('setAuthStatus', value);
      value ? dispatch('getUserInfo') : commit('clearUserInfo')
    },
    async getAuthStatus({ commit, dispatch }, value) {
      value ? dispatch('getUserInfo') : commit('clearUserInfo')
    },
    async updateUserInfo({ commit, dispatch }, { type, payload }) {
      try {
        const res = await apiUpdateProfile[type](payload);
        if (res.status === 'SUCCESS') dispatch('getUserInfo')
      } catch (err) {
        commit('setErrors', this._vm.$isObject(err) ? err : {})
      }
    },
    async changePassword(_, value) {
      try {
        const res = await apiUpdateProfile['password'](value);
        console.log(res);
        if (res && res.status === 'SUCCESS') {
          this.$auth.logout();
          this.$msg('Password changed, log in with new credentials')
        }
      } catch (error) {
        console.error(error)
      }
    },
    async changeQuestion(_, value) {
      try {
        const res = await apiUpdateProfile['question'](value);
        if (res && res.status === 'SUCCESS') {
          this.$msg('Secret question changed!')
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
