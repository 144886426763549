import _ from 'lodash'

export default {
  name: 'MonthCarousel',

  props: {
    value: {
      type: String,

      default: function() {
        return this.$dayjs().format('YYYY/MM')
      }
    },

    format: {
      type: String,
      default: 'MMMM YYYY'
    },

    min: {
      type: String,

      default: function() {
        return this.$dayjs().format('YYYY/MM')
      }
    },

    max: {
      type: String,

      default: function() {
        return this.$dayjs().add(1, 'year').format('YYYY/MM')
      }
    }
  },

  computed: {
    prevValue() {
      const prev = this.$dayjs(this.value, 'YYYY/MM').subtract(1, 'month')
      return prev >= this.$dayjs(this.min, 'YYYY/MM') ? prev.format('YYYY/MM') : null
    },

    nextValue() {
      const next = this.$dayjs(this.value, 'YYYY/MM').add(1, 'month')
      return next <= this.$dayjs(this.max, 'YYYY/MM') ? next.format('YYYY/MM') : null
    }
  }
}