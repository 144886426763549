<template>
  <vc-date-picker
    ref="datePicker"
    v-model="newValue"
    class="border-0 rounded-0 bg-transparent"
    is-expanded
    is-range
    color="red"
    locale="en-us"
    transition="none"
    :min-date="dynamicMinDate"
    :max-date="dynamicMaxDate"
    :disabled-dates="[disabledDates]"
    @dayclick="onDayClick"
  />
</template>

<script>
export default {
  name: 'DateAndNightsSelect',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    minDate: {
      type: Date,
      required: true
    },

    maxDate: {
      type: Date,
      required: true
    },

    // Can be grater than maxDate
    maxStayDate: {
      type: Date,
      required: false
    }
  },

  data() {
    return {
      lastClickedDay: null
    }
  },

  computed: {
    newValue: {
      get() {
        return {
          start: this.value.date,
          end: this.$dayjs(this.value.date).add(this.value.nights || 1, 'day').toDate()
        }
      },

      set(value) {
        this.$emit('input', {
          date: this.$dayjs.min(this.$dayjs(value.start), this.$dayjs(this.maxDate)).toDate(),
          nights: Math.max(this.$dayjs(value.end).diff(value.start, 'day'), 1)
        })
      }
    },

    computedMaxStayDate() {
      return this.maxStayDate || this.$dayjs(this.maxDate).add(14, 'day').toDate()
    },

    dynamicMinDate() {
      let dynamicMinDate = this.minDate

      if (this.lastClickedDay) {
        dynamicMinDate = this.$dayjs.max(
          this.$dayjs(this.lastClickedDay).subtract(14, 'day'),
          this.$dayjs(dynamicMinDate)
        ).toDate()
      }

      return dynamicMinDate
    },

    dynamicMaxDate() {
      let dynamicMaxDate = this.$dayjs.max(
        this.$dayjs(this.maxDate),
        this.$dayjs(this.newValue.end)
      ).toDate()

      if (this.lastClickedDay) {
        dynamicMaxDate = this.$dayjs(this.computedMaxStayDate).add(1, 'day').toDate()

        dynamicMaxDate = this.$dayjs.min(
          this.$dayjs(this.lastClickedDay).add(14, 'day'),
          this.$dayjs(dynamicMaxDate)
        ).toDate()
      }

      return dynamicMaxDate
    },

    // Disable dates between (maxDate or the day after end date) and maxStayDate
    disabledDates() {
      if (!this.lastClickedDay && this.computedMaxStayDate) {
        // Max value of maxDate and newValue.end
        const start = this.$dayjs.max(
          this.$dayjs(this.maxDate).add(1, 'day'),
          this.$dayjs(this.newValue.end).add(1, 'day')
        ).toDate()

        const end = this.$dayjs(this.computedMaxStayDate).add(1, 'day').toDate()

        if (start < end) {
          return { start, end }
        }
      }

      return null
    }
  },

  watch: {
    newValue: {
      immediate: true,
      deep: true,

      handler() {
        this.lastClickedDay = null
      }
    }
  },

  methods: {
    onDayClick({ date }) {
      
      this.$nextTick(() => {
        if (this.$refs.datePicker.dragValue) {
          // Handle start date choose
          if (!this.lastClickedDay) {
            if (date >= this.minDate && date <= this.maxDate) {
              // Set last clicked if click inside range
              this.lastClickedDay = date
            } else {
              // Unset dragValue if clicked date not inside range
              this.$refs.datePicker.dragValue = null
            }
          }

        } else {
          // Handle end date choose
          this.lastClickedDay = null
        }
      })
    }
  }
}
</script>