import http from '@/lib/axios'
import { PROPERTY_CODE } from '@/constants'

export const apiSignIn = (email, password, config) => http.post('user/auth/login', { username: email, password, property_code: PROPERTY_CODE }, config)

export const apiLogout = (token) => http.post('user/auth/logout', { refresh_token: token })

export const apiRefreshToken = (token) => http.post('user/auth/refresh', { refresh_token: token })

export const apiSignUp = (params) => http.post('user/auth/register', { ...params, property_code: PROPERTY_CODE })

export const apiAuthQuestion = (email) => http.post('user/auth/question', { username: email, property_code: PROPERTY_CODE })

export const apiAuthAnswer = (params) => http.post('user/auth/answer', { ...params, property_code: PROPERTY_CODE })

export const apiAuthReset = (params) => http.post('user/auth/reset', { ...params, property_code: PROPERTY_CODE })

export const apiAuthUsername = (params) => http.post('user/auth/username', { ...params, property_code: PROPERTY_CODE })

export const apiAgencySignIn = (username, iata, password) => http.post('user/auth/agency/login', { username, iata, password, property_code: PROPERTY_CODE })

export const apiReservationSignIn = (reservation_number, first_name, last_name, date, get_token) => http.post('user/auth/reservation', { reservation_number, first_name, last_name, date, get_token, property_code: PROPERTY_CODE })
