import { apiAvailabilityTours } from "@/api/availability";
import { AVAILABILITY_STATUS_OPTIONS } from "@/constants";
import RangePicker from "@/components/RangePicker/index.vue";
import { optionsComputed } from "@/store/helper";
import bookingMixin from '@/mixin/booking'
import _ from "lodash";
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  mixins: [
    bookingMixin
  ],
  components: { RangePicker },
  data: () => ({
    /*availableDates: {
      start: new Date().setHours(0, 0, 0, 0),
      end: null,
    },*/
    //monthTourAvailability: {},
    //tourAvailability: {},

    tour: {},
    adults: 1,
    children: 0,
    infants: 0,
    animals: 0,
    loading: false,
    availabilityData: {},
    initialDate: new Date(),
    selectedDate: null,
    // allowed global date range
    /*
    minDate: null,
    maxDate: null,
    */
    // current displayed date (month)
    currentDate: null,
    shouldViewMore: false,
    isViewingMore: false
  }),
  async created() {
    this.tour = this.$store.state.app.modalProps.tour_data;
    //this.tourAvailability = this.$store.state.app.modalProps.tour_availability;
    this.adults = +this.$store.state.app.modalProps.adults || 1;
    this.children = +this.$store.state.app.modalProps.children || 0;
    this.infants = +this.$store.state.app.modalProps.infants || 0;
    this.animals = +this.$store.state.app.modalProps.animals || 0;

    this.initialDate = this.$dayjs(this.$store.state.app.modalProps.date, "MM-DD-YYYY").toDate();
    /*
    this.minDate = this.dateString2Date(this.options.mindate);

    const isActivities = ['tours', 'tourPage'].includes(this.$route.name)
    this.maxDate = this.$dayjs(isActivities ? this.options.toursMaxdate : this.options.maxdate).toDate()
    */
    //console.log("CREATED", this.$store.state.app.modalProps, this);
  },
  computed: {
    ...optionsComputed,
    /*minPrice() {
      return this.date &&
        this.monthTourAvailability &&
        this.monthTourAvailability[this.$dayjs(this.date).format("MM/DD/YYYY")] &&
        this.monthTourAvailability[this.$dayjs(this.date).format("MM/DD/YYYY")][this.tour.code].prices.adult
        ? this.monthTourAvailability[this.$dayjs(this.date).format("MM/DD/YYYY")][this.tour.code].prices.adult.min
        : 0;
    },*/
    minDate() {
      return this.$dayjs(this.bookingDatesWindow.min).toDate()
    },
    maxDate() {
      return this.$dayjs(this.bookingDatesWindow.max).toDate()
    },
    guests() {
      return this.adults + this.children + this.infants + this.animals;
    },
    imageUrl() {
      if (typeof this.tour.images !== "undefined" && this.tour.images.length > 0) {
        return this.getCdnResourceUrl(this.tour.images[0].image);
      }

      return "";
    },
    imageTitle() {
      if (typeof this.tour.images !== "undefined" && this.tour.images.length > 0) {
        return this.tour.images[0].title;
      }

      return "";
    },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    },
    disabledDays() {
      /*
      let dates = [];
      const numberOfDays = this.$dayjs(this.currentDate).daysInMonth();

      let currentDateCloned = this.$dayjs(this.currentDate).toDate();

      for (var i = 1; i <= numberOfDays; i++) {
        currentDateCloned.setDate(i);

        if (!this.getMinPrice(currentDateCloned)) {
          dates.push(currentDateCloned.getDate());
        }
      }
      */

      const dates = _.chain(1)
        .range(this.$dayjs(this.currentDate).daysInMonth() + 1)
        .filter(i => {
          const dateString = this.$dayjs(this.currentDate).date(i).format('MM/DD/YYYY')
          const availabiliytStatus = this.getDayAvailabilityStatus(dateString)
          return availabiliytStatus.value !== 'available'
        })
        .value()

      return dates;
    },
    isDateSelected() {
      return !!this.selectedDate;
    },
    actionLabel() {
      return this.isDateSelected ? "Activity Date" : "Select Activity Date";
    },
    dateLabel() {
      return this.$dayjs(this.selectedDate).format("ddd, MMM D, YYYY");
    },
    selectedPrice() {
      const dateKey = this.$dayjs(this.selectedDate).format("MM/DD/YYYY");
      const cheapestOption = this.getDayCheapestOption(dateKey);
      if (cheapestOption) {
        const base = (
          cheapestOption.cheapestPickup.prices.adult * this.adults +
          cheapestOption.cheapestPickup.prices.child * this.children
        )

        const fees = (
          cheapestOption.cheapestPickup.fees.adult * this.adults +
          cheapestOption.cheapestPickup.fees.child * this.children
        )

        const taxes = (
          cheapestOption.cheapestPickup.taxes.adult * this.adults +
          cheapestOption.cheapestPickup.taxes.child * this.children
        )

        return getPrice({
          base,
          fees,
          taxes,
        })
      }
    },
    allowSave() {
      return this.isDateSelected;
    },
  },
  methods: {
    async loadData() {
      this.availabilityData = {};
      let key = this.$dayjs(this.currentDate).format("MM/YYYY");

      const data = {
        key: key,
        date: this.$dayjs(this.currentDate).format("MM/DD/YYYY"),
        limit: 31,
        tour_code: this.tour.code,
      };

      this.loading = true;

      this.availabilityData = await apiAvailabilityTours(data);

      this.loading = false;
    },
    setDate(value) {
      this.selectedDate = new Date(value);
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore = this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
    onDayClick(day) {
      if (day.disabled) {
        return
      }

      this.selectedDate = !day.dayjs.isSame(this.selectedDate) ? day.dayjs.toDate() : null
    },
    onCurrentDateSelect(date) {
      this.currentDate = date;
      this.loadData();
    },

    getMinPriceLabel(date) {
      const price = this.getMinPrice(date);

      if (price) {
        return this.$formatCurrency(price);
      }

      return "";
    },
    getMinPrice(date) {
      const dailyData = this.getDailyData(date);

      if (!dailyData) {
        return 0;
      }

      if (!dailyData["available"]) {
        return 0;
      }

      if (dailyData["available"] < this.guests) {
        return 0;
      }

      if (typeof dailyData["prices"] === "undefined") {
        return 0;
      }

      if (typeof dailyData["prices"]["adult"] === "undefined") {
        return 0;
      }

      if (typeof dailyData["prices"]["adult"]["min"] === "undefined") {
        return 0;
      }

      return parseFloat(dailyData["prices"]["adult"]["min"]);
    },
    getDailyData(date) {
      const dateKey = this.$dayjs(date).format("MM/DD/YYYY");

      if (!this.availabilityData) {
        return false;
      }

      if (typeof this.availabilityData["availability"] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["availability"][dateKey] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["availability"][dateKey][this.tour.code] === "undefined") {
        return false;
      }

      return this.availabilityData["availability"][dateKey][this.tour.code];
    },
    onSave() {
      if (!this.allowSave) {
        return;
      }

      this.setModal();

      this.$router.push({
        name: "tourPage",
        query: {
          date: this.$dayjs(this.selectedDate).format("MM-DD-YYYY"),
          destination: this.tour.code,
          adults: this.adults,
          children: this.children,
          infants: this.infants,
          animals: this.animals,
        },
      });
    },
    onCancel() {
      this.setModal();
    },

    getDayAvailabilityStatus(dateString) {
      const availability = this.availabilityData.availability?.[dateString]?.[this.tour.code]
      const isOpened = !!_.values(availability?.options).find(i => ['OPEN', 'SOLDOUT'].includes(i.status))
      const enoughTime = this.$dayjs(this.tour.mindate, 'MM/DD/YYYY').toDate() <= this.$dayjs(dateString, 'MM/DD/YYYY').toDate()

      let value = 'available'

      if (!availability) {
        value = 'notInRange'
      } else if (!isOpened) {
        value = 'closed'
      } else if (!enoughTime) {
        value = 'notEnoughTime'
      } else if (!availability.available) {
        value = 'soldOut'
      } else if (availability.available < this.guests) {
        value = 'guestsExceededAvailability'
      }

      return _.find(AVAILABILITY_STATUS_OPTIONS, { value })
    },

    getDayCheapestOption(dateString) {
      const availability = this.availabilityData.availability?.[dateString]?.[this.tour.code]

      const cheapestOption = _.chain(availability?.options)
        .filter(i => i.available >= this.guests)
        // Add cheapest pickup for each options
        .map(i => ({
          ...i,
          cheapestPickup: _.chain(i.pickup)
            // Add total price to each pickup
            .map(i => ({
              ...i,
              totalPrice: (
                i.prices.adult * this.adults +
                i.fees.adult * this.adults +
                i.taxes.adult * this.adults +
                i.prices.child * this.children +
                i.fees.child * this.children +
                i.taxes.child * this.children
              )
            }))
            .sortBy(i => i.totalPrice)
            .first()
            .value()
        }))
        // Get cheapest option
        .sortBy(i => i.cheapestPickup.totalPrice)
        .first()
        .value()

      return cheapestOption
    },

    getPriceForDate(dateString) {
      const cheapestOption = this.getDayCheapestOption(dateString);
      const base = cheapestOption?.cheapestPickup.prices.adult ?? 0;
      const fees = cheapestOption?.cheapestPickup.fees.adult ?? 0;
      const taxes = cheapestOption?.cheapestPickup.taxes.adult ?? 0;

      const price = getPrice({
        base,
        fees,
        taxes,
      })

      return price;
    },

    getDayMinPriceLabel(dateString) {
      const price = this.getPriceForDate(dateString);
      if (price.total) {
        return this.$formatCurrency(Math.ceil(price.total));
      }
    }
  },
  updated() {
    // try to make the whole calendar visible
    this.$nextTick(function() {
      if (this.$refs.action.offsetHeight) {
        this.$refs.content.style["padding-bottom"] = this.$refs.action.offsetHeight + 10 + "px";
        //this.$refs.contentWrapper.scrollTop = 1000;
      }
    });
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      this.onResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
