import { apiReservation } from "@/api/property";
import { optionsComputed, propertyMethods, propertyComputed } from "@/store/helper";
import { mapGetters } from 'vuex'
import bookingMixin from '@/mixin/booking'
import vClickOutside from "v-click-outside";
import ReservationItemFindButton from '@/components/reservations/buttons/ReservationItemFindButton.vue'
import _ from "lodash";
import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),

  mixins: [
    bookingMixin
  ],

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    ReservationItemFindButton
  },

  data: () => ({
    today: new Date().setHours(12, 0, 0, 0),
    /*
    upcomingReservationsMessage: "",
    reservationsHistoryMessage: "",
    */
    reservationSelectOpened: false,
    selectedReservation: null,
    formValidated: false,
    days: 14,
    dateLabel: "Check In - Check Out",
    exactDate: true,
    loading: false,
    isFetchingReservations: false,
    isFetchingReservation: false,
    /*
    upcReservation: []
    */
  }),
  async created() {
    /*
    if (!this.$store.state.property.reservations) {
      await this.fetchReservations();
    }

    this.$eventHub.$on("add-to-reservation", this.onAddToReservation);

    // add to reservation feature via hash
    if (this.addToReservation) {
      const items = this.reservationOrdered.filter((item) => item.uniqueId === this.$route.params.reservation_id);

      if (items.length === 1) {
        this.onAddToReservation(items[0]);
      }
    }
    */
   this.$store.dispatch('property/clearReservationListWithExpiredToken')
  },
  watch: {
    '$route.fullPath': {
      immediate: true,

      handler() {
        if (this.isReservationListPage && this.isUser) {
          this.fetchReservations()
        }
      }
    },

    selectedReservationId: {
      immediate: true,

      handler() {
        if (
          this.selectedReservationId &&
          this.selectedReservationId !== this.selectedReservation?.uniqueId &&
          this.reservationList[this.selectedReservationId]
        ) {
          this.fetchSelectedReservation()
        }
      }
    },

    reservationList: {
      immediate: true,
      deep: true,

      handler() {
        if (
          this.selectedReservationId &&
          this.selectedReservationId !== this.selectedReservation?.uniqueId &&
          this.reservationList[this.selectedReservationId]
        ) {
          this.fetchSelectedReservation()
        }
      }
    },

    addNewReservationAllowed: {
      immediate: true,

      handler() {
        if (!this.addNewReservationAllowed && this.currentTabIndex === 1) {
          // @TODO move all options to bootstrap settings
          this.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and choose another lodging date?', {
            title: 'Confirm',
            headerBgVariant: 'secondary',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Yes',
            okVariant: 'primary',
            hideHeaderClose: true,
            noCloseOnBackdrop: true,
            cancelVariant: 'outline-primary',
            cancelTitle: 'No, return to cart',
            buttonSize: 'lg',
            centered: true
          })
            .then((value) => {
              if (value) {
                this.$store.dispatch('property/clearCart')

              } else {
                this.$router.push({ name: 'ReviewPage' })
              }
            })
        }
      }
    }
  },
  computed: {
    ...optionsComputed,
    ...propertyComputed,

    ...mapGetters({
      cartIsEmpty: 'property/cartIsEmpty',
      tours: 'property/tours',
      hotels: 'property/hotels',
      isUser: 'auth/isUser',
      isAgency: 'auth/isAgency',
      reservationList: 'property/reservationList',
      hotelsMaxDate: 'property/hotelsMaxDate',
      hotelsMaxStayDate: 'property/hotelsMaxStayDate',
    }),

    windowWidth() {
      return this.windowSize.width.value;
    },

    tabs() {
      const tabs = [
        { id: 'upcoming', title: 'Upcoming reservations', isVisible: true },
        { id: 'new', title: 'Make a new reservation', isVisible: true },
        { id: 'history', title: 'Past and canceled reservations', isVisible: true }
      ]

      return _.filter(tabs, { isVisible: true })
    },

    currentTabIndex: {
      get() {
        const index = _.findIndex(this.tabs, { id: this.$route.query.tab })
        return index >= 0 ? index : 0
      },

      set(index) {
        const tab = _.get(this.tabs, `${index}.id`) || 'upcoming'
        this.$router.push({ name: 'ReservationListPage', query: { tab } })
      }
    },

    tabActiveKey() {
      return `${this.currentTabIndex + 1}`
    },

    selectedReservationId: {
      get() {
        return parseInt(_.get(this.$route.query, 'reservation')) || null
      },

      set(value) {
        if (parseInt(this.$route.query.reservation) !== value) {
          this.$router.push({ name: 'ReservationListPage', query: { tab: 'new', reservation: value } })
        }
      }
    },

    reservationSelectEnabled: {
      get() {
        return !_.isNil(this.$route.query.reservation)
      },

      set(value) {
        console.log('reservationSelectEnabled', value)
        if (value) {
          this.$router.push({ name: 'ReservationListPage', query: { tab: 'new', reservation: '' } })

        } else {
          this.$router.push({ name: 'ReservationListPage', query: { tab: 'new', reservation: undefined } })
        }
      }
    },

    addNewReservationAllowed() {
      return this.cartIsEmpty || this.selectedReservationId
    },

    make_reservation_loading() {
      return (this.isFetchingReservations || this.isFetchingReservation) && this.reservationSelectEnabled;
    },
    formInvalid() {
      return this.formValidated && this.reservationSelectEnabled && !this.selectedReservationId;
    },
    isReservationPage() {
      return !!this.$route.params.reservation_id
    },
    isReservationListPage() {
      return !this.isReservationPage
    },
    reservationOrdered() {
      let reservations = this.reservationList ? Object.values(this.reservationList) : [];

            const items = reservations.sort((a, b) => {
        if (a.arrival && b.arrival) {
          const arrival_a = this.dateString2Date(a.arrival).getTime();
          const arrival_b = this.dateString2Date(b.arrival).getTime();

          return arrival_a - arrival_b;
        }

        return 0;
      });

      //console.log("reservationOrdered", reservations, items)

      return items;
    },
    /*
    reservationHistory() {
      const items = this.reservationOrdered.filter((item) => {
        let arrival = item.arrival;

        return this.$dayjs(this.today).diff(this.$dayjs(arrival), "day", true) >= 1 || item.status === "CANCELLED";
      });

      //console.log("reservationHistory", items)

      return items;
    },
    */
    /*
    upcomingReservations() {
      const items = this.reservationOrdered.filter((item) => {
        let arrival = item.arrival;

        return this.$dayjs(this.today).diff(this.$dayjs(arrival), "day", true) < 1 && item.status === "OK";
      });

      //console.log("upcomingReservations", items)
      this.upcReservation = items
      return items;
    },
    */
    reservationUpcomingList() {
      // @TODO move reservationUpcomingList to vuex getters
      return _.filter(
        this.reservationOrdered,
        i => i.status !== 'CANCELLED' && this.$dayjs(this.$dayjs().format('MM/DD/YYYY')) <= this.$dayjs(i.arrival)
      )
    },
    reservationHistoryList() {
      // @TODO move reservationHistoryList to vuex getters
      return _.filter(
        this.reservationOrdered,
        i => i.status === 'CANCELLED' || this.$dayjs(this.$dayjs().format('MM/DD/YYYY')) > this.$dayjs(i.arrival)
      )
    },
    upcomingReservationsMessage() {
      let message = "Here is the list of your upcoming reservations."

      if (!this.reservationUpcomingList.length) {
        message =  "There are currently no reservations in our records that match your search."
      }

      return message
    },
    historyReservationsMessage() {
      let message = "Here is the list of your reservations history."

      if (!this.reservationHistoryList.length) {
        message =  "There are currently no reservations in our records that match your search."
      }

      return message
    },
    canAddToReservation() {
      return this.reservationUpcomingList.length > 0;
    },
    selectedReservationLabel() {
      const selectedReservation =  this.reservationList[this.selectedReservationId];
      return selectedReservation ? this.getReservationTitleForSelect(selectedReservation) : "Please Select";
    },
    selectedReservationHotels() {
      return _.get(this.selectedReservation, 'sections.Lodging.items', [])
    },
    selectedReservationHotel() {
      return _.get(this.selectedReservationHotels, '0', null)
    },
    selectedReservationTours() {
      return _.get(this.selectedReservation, 'sections.Tours.items', [])
    },
    selectedReservationTour() {
      return _.get(this.selectedReservationTours, '0', null)
    },
    startDate() {
      let startDate = null

      if (this.selectedReservationHotel) {
        startDate = this.$dayjs(this.selectedReservationHotel.date).toDate()

      } else if (this.selectedReservationTour) {
        startDate = this.$dayjs(this.selectedReservationTour.date).toDate()
      }

      return startDate
    },
    nights() {
      let nights = 1;

      if (this.selectedReservationHotel) {
        nights = this.selectedReservationHotel.nights

      } else if (this.selectedReservationTour) {
        nights = 1
      }

      /*
      if (this.selectedReservation) {
        const start = this.selectedReservation.arrival;
        const end = this.selectedReservation.departure;

        nights = this.$dayjs(end).diff(this.$dayjs(start), "day") || 1;
      }
      */

      return nights;
    },
    minDate() {
      if (this.selectedReservation) {
        /*
        let minDate = new Date();
        let today = new Date();
        minDate.setTime(arrival.getTime() - this.days * 24 * 3600 * 1000);
        minDate.setHours(0, 0, 0, 0);
        return minDate >= today ? minDate : today;
        */
        let minDay = this.$dayjs.max([
          this.$dayjs(this.selectedReservation.arrival).subtract(14, 'day'),
          this.$dayjs(this.selectedReservation.departure).subtract(27, 'day'),
          ...this.options.mindate ? [this.$dayjs(this.options.mindate)] : []
        ])

        minDay = this.$dayjs.min([this.$dayjs(this.selectedReservation.arrival), minDay])

        return minDay.toDate()
      } else {
        return this.$dayjs(this.bookingDatesWindow.min).toDate()
        /*
        let value;

        if (this.options.mindate) {
          value = this.dateString2Date(this.options.mindate);
        } else {
          let date = new Date();
          date.setHours(0, 0, 0, 0);

          value = date;
        }

        return value;
        */
      }
    },
    maxDate() {
      let maxDay = this.$dayjs(this.hotelsMaxDate, 'MM/DD/YYYY').toDate();

      if (this.selectedReservation) {
        maxDay = this.$dayjs.min([
          this.$dayjs(this.selectedReservation.arrival).add(27, 'day'),
          this.$dayjs(this.selectedReservation.departure).add(13, 'day'),
          this.$dayjs(maxDay),
        ]).toDate()
      }
  
      return maxDay;
    },
    maxStayDate() {
      let maxStayDay = this.$dayjs(this.bookingDatesWindow.maxStay, 'MM/DD/YYYY').toDate() ;

      if (this.selectedReservation) {
        maxStayDay = this.$dayjs.min([
          this.$dayjs(this.selectedReservation.arrival).add(27, 'day'),
          this.$dayjs(this.selectedReservation.departure).add(14, 'day'),
          this.$dayjs(maxStayDay),
        ]).toDate()
      }

      return maxStayDay;
    },
    adults() {
      //return 2
      return this.selectedReservation ? parseInt(this.selectedReservation.adults) : 2;
    },
    children() {
      //return null
      return this.selectedReservation ? parseInt(this.selectedReservation.children) : null;
    },
    promoCodes() {
      return this.options.displaycodes;
    },
    addToReservation() {
      return this.$route.hash === "#bookStay";
    },
  },
  methods: {
    ...propertyMethods,
    /*
    async child(data) {
      await this.fetchReservations();
      this.upcReservation = this.upcomingReservations.filter((i) => i.token !== data)
    },
    */
    closeSelected() {
      console.log('closeSelected')
    },
    async onSubmit(data, isConfirmedClearCart) {
      //console.log("onSubmit", data);

      if (this.selectedReservation) {
        if (!this.cartIsEmpty && !isConfirmedClearCart) {
          // @TODO move all options to bootstrap settings
          this.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and start editing your reservation?', {
            title: 'Confirm',
            headerBgVariant: 'secondary',
            headerClass: 'close-button-black',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Yes',
            okVariant: 'primary',
            hideHeaderClose: false,
            cancelVariant: 'outline-primary',
            buttonSize: 'lg',
            centered: true
          })
            .then(async (value) => {
              if (value) {
                this.onSubmit(data, true)
              }
            })
          return

        } else {
          this.isFetchingReservations = true
          await this.$store.dispatch("app/startAddToReservation", this.selectedReservation);
          this.isFetchingReservations = false
        }

      } else {
        this.$store.dispatch("app/endAddToReservation");
      }

      const availabilityData = {
        date: this.$dayjs(new Date(data.dateFrom)).format("MM/DD/YYYY"),
        nights: data.nights,
        rate_code: data.rateCode,
        is_group: data.rateType === 'group',
        limit: 1,
      };

      await this.$store.dispatch("property/fetchAvailableHotels", availabilityData);

      let name;
      let query = {
        dateFrom: this.$dayjs(data.dateFrom).format("MM-DD-YYYY"),
        adults: data.adults,
        children: data.children,
      };

      if (data.rateCode) {
        query["rateCode"] = data.rateCode ? data.rateCode : "";
        query["rateType"] = data.rateType
      }
      if (!data.exactDate) {
        name = "hotelsFlex";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      } else if (data.destination === "ALL") {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = "ALL";
      } else if (data.destination === "ALL-CAMPGROUNDS" || data.destination === "ALL-HOTELS") {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      } else if (!this.$store.state.property.availableHotels[availabilityData.date][data.destination].min) {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = "ALL";
        // this.setModal("HotelRateCalendar", {
        //   hotel_id: data.destination,
        //   alert: true,
        // });
      } else {
        name = "hotelPage";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      }

      this.$router.push({
        name: name,
        query: query,
      });
    },
    async fetchReservations() {
      if (this.isUser) {
        this.isFetchingReservations = true;
        return this.$store
          .dispatch("property/fetchReservationsList")
          .then(() => {
            // this.reservations = this.$store.state.property.reservationList;
          })
          .finally(() => {
            //console.log('FINALLY')
            this.isFetchingReservations = false;
          });
      }
    },
    onCanceledReservation(reservation) {
      if (this.isUser) {
        this.fetchReservations()
      } else if (this.isAgency) {
        this.$store.dispatch('property/populateReservationList', {
          ...reservation,
          status: 'CANCELLED',
          modifyAllowed: false
        })
      }
    },
    async fetchSelectedReservation() {
      this.isFetchingReservation = true
      return apiReservation(this.reservationList[this.selectedReservationId].token)
        .then((data) => {
          this.isFetchingReservation = false
          this.selectedReservation = data
        })
        .catch(e => {
          this.isFetchingReservation = false
          console.log(e)
        })
    },
    /*
    async cancelReservation(data) {
      try {
        let cancel = await apiDeleteReservation(data.token, data.reason);
        this.isFetchingReservations = true;
        await this.$store.dispatch("property/fetchReservationsList");
        this.isFetchingReservations = false;
        this.reservations = this.$store.state.property.reservationList;
      } catch (err) {
        console.error(err);
      }
    },
    */
    async clearCart() {
      await this.$store
        .dispatch("property/startOver")
        .then(() => {
          this.setModal();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    callback(key) {
      if (key === '2' && this.cartNotEmpty) {
        this.setModal("ConfirmationDialog", {
          content: "Are you sure you want to empty your cart and choose another lodging date?",
          onConfirm: async () => {
            await this.clearCart()
            this.currentTabIndex = parseInt(key) - 1
          },
        })

      } else {
        this.currentTabIndex = parseInt(key) - 1
      }
    },
    onReservationSelect(reservation) {
      // this.selectedReservation = reservation;
      if (this.selectedReservationId !== +reservation.uniqueId) {
        this.selectedReservation = null;
        this.selectedReservationId = +reservation.uniqueId;
      }

      this.reservationSelectOpened = false;
    },
    onAddToReservation(reservation) {
      if (!reservation.uniqueId) {
        return;
      }
      this.currentTabIndex = 1;

      this.reservationSelectEnabled = true;
      this.selectedReservation = null;
      this.selectedReservationId = reservation.uniqueId;
      // this.selectedReservation = reservation;
    },
    onAddToReservationToggle() {
      if (!this.reservationSelectEnabled) {
        this.selectedReservationId = null;
        this.selectedReservation = null;
      }
    },
    validate() {
      this.formValidated = true;
      return !this.formInvalid;
    },
    getReservationTitleForSelect(reservation) {
      let titles = [];

      for (let key in reservation.subtitles) {
        titles.push(reservation.subtitles[key]);
      }

      return "#" + reservation.uniqueId + ", " + reservation.arrival + ", " + titles.join(", ");
    },
    async onFoundReservation({ reservation_token }) {
      this.isFetchingReservations = true
      return apiReservation(reservation_token)
        .then(async (data) => {
          this.isFetchingReservations = false

          const images = []
          // Hotel image
          const firstHotelCode = data.sections.Lodging?.items[0]?.hotelCode
          if (firstHotelCode) {
            const firstHotelImage = this.hotels[firstHotelCode]?.images[0]
            if (firstHotelImage) {
              images.push(firstHotelImage)
            }
          }
          // Activity image
          const firstTourCode = data.sections.Tours?.items[0]?.tourCode
          if (firstTourCode) {
            const firstTourImage = this.tours[firstTourCode]?.images[0]
            if (firstTourImage) {
              images.push(firstTourImage)
            }
          }

          await this.$store.dispatch('property/populateReservationList', { ...data, images })

          this.$router.push({
            name: 'ReservationPage',
            params: {
              reservation_id: data.uniqueId
            }
          })
        })
        .catch(e => {
          this.isFetchingReservations = false
          console.log(e)
        })
    }
  },
};
