<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      dialog-class="modal-down-sm-fullscreen"
      content-class="rounded-0 border-0"
      centered
      header-class="rounded-0 bg-beta p-0 border-0 height-50px close-button-psi"
      title-class="text-dark text-uppercase pl-4 pr-0 font-size-18 font-family-2"
      body-class="p-0"
      hide-footer
      scrollable
      size="lg"
      no-close-on-backdrop
    >
      <template #modal-title>
        {{ title }}
      </template>

      <CartTourItemUpdate
        class="h-100 overflow-hidden"
        :item="item"
        :prefetch="prefetch"
        @update:item="$emit('update:item', $event)"
        @submitted="$emit('submitted', $event); popupIsShown = false"
        @cancel="$emit('cancel'); popupIsShown = false"
      />
    </b-modal>
  </component>
</template>

<script>
import CartTourItemUpdate from '../CartTourItemUpdate/index.vue'

export default {
  name: 'CartTourItemUpdateButton',

  components: {
    CartTourItemUpdate
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false
    }
  },

  computed: {
    title() {
      return 'Update activity'
    }
  }
}
</script>

