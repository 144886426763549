<template>
  <b-modal
    :visible="!!error429RetryAfter"
    title="Too many requests"
    header-bg-variant="secondary"
    title-class="text-white text-uppercase font-family-2 line-heigt-10"
    content-class="bg-gamma"
    body-class="font-size-14 line-height-11 font-weight-500 py-4"
    footer-class="border-0"
    :ok-title="isRefreshing ? 'Loading...' : 'Try again'"
    ok-variant="primary"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    ok-only
    :ok-disabled="isRefreshing || retryIn > 0"
    centered
    button-size="lg"
    @ok="$event.preventDefault(); refresh()"
  >
    <template v-if="retryIn > 0">
      Please, try again in {{ retryIn }} sec.
    </template>

    <template v-else>
      Please, try again.
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorModal429',

  data() {
    return {
      now: new Date,
      interval: null,
      isRefreshing: false
    }
  },

  computed: {
    ...mapGetters({
      error429RetryAfter: 'app/error429RetryAfter'
    }),

    retryIn() {
      return this.error429RetryAfter - this.$dayjs(this.now).unix()
    }
  },

  created() {
    this.interval = setInterval(this.updateNow, 1000)
  },

  methods: {
    refresh() {
      this.isRefreshing = true
      document.location.reload()
    },

    updateNow() {
      this.now = new Date
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>