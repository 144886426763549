<template>
  <b-button-group
    v-bind="{ ...defaultButtonGroupProps, ...buttonGroupProps }"
  >
    <b-button
      v-bind="arrowProps"
      :disabled="(value || 0) <= min"
      @click="$emit('input', (value || 0) - 1)"
    >
      -
    </b-button>

    <b-button
      v-bind="numberProps"
    >
      {{ value || 0 }}
    </b-button>

    <b-button
      v-bind="arrowProps"
      :disabled="(max || max === 0) && (value || 0) >= max"
      @click="$emit('input', (value || 0) + 1)"
    >
      +
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'VBFormInputQuantity',

  props: {
    value: {
      type: Number,
      default: null
    },

    min: {
      type: Number,
      default: 0
    },

    max: {
      type: Number,
      default: null
    },

    buttonGroupProps: {
      type: Object,
      default: null
    },

    numberProps: {
      type: Object,

      default: () => ({
        variant: 'link',
        class: 'text-decoration-none text-dark',
      })
    },

    arrowProps: {
      type: Object,

      default: () => ({})
    }
  },

  computed: {
    defaultButtonGroupProps() {
      return {}
    }
  }
}
</script>