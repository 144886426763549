import { optionsComputed, authComputed, propertyMethods } from "@/store/helper";
import pageBackground from "@/assets/book-stay.png";
import bookingMixin from '@/mixin/booking'
import { mapGetters } from "vuex";

export default {
  mixins: [
    bookingMixin
  ],
  meta: {
    title: "Book a stay",
  },
  data: () => ({
    // searchParams: {
    //   dateFrom: null,
    //   nights: 1,
    //   destination: "ALL",
    //   adults: 1,
    //   children: 0,
    //   rateCode: "",
    //   exactDate: true,
    // },
    dateLabel: "Check In - Check Out",
    confirmLoading: false,
  }),
  async created() {
    // clear save search if were in Add to reservation mode
    if (this.addToReservation) {
      this.$store.dispatch("property/clearSearchParams");
      this.$store.dispatch("app/endAddToReservation");
    }

    if (this.isPartnersPage && this.isLoggedInAsNonAgency) {
      await this.$auth.logout(true);
    }

    if (this.isPartnersPage && !this.isLoggedInAsAgency) {
      this.setModal("LoginAgency", {
        callback: this.afterLoggedInAsAgency,
        partnersPage: true,
      });
    } else if (this.isPartnersPage && this.isLoggedInAsAgency) {
      this.afterLoggedInAsAgency();
    }
  },
  computed: {
    ...authComputed,
    ...optionsComputed,
    ...mapGetters({
      addToReservation: 'app/addToReservation',
      cartIsEmpty: 'property/cartIsEmpty',
      lodgeSearchParams: 'property/lodgeSearchParams'
    }),
    searchParams() {
      return {
        dateFrom: this.lodgeSearchParams.dateFrom ? this.$dayjs(this.lodgeSearchParams.dateFrom, "MM-DD-YYYY").toDate() : null,
        nights: this.lodgeSearchParams.nights,
        destination: this.lodgeSearchParams.destination,
        adults: this.lodgeSearchParams.adults || 1,
        children: this.lodgeSearchParams.children,
        rateCode: this.lodgeSearchParams.rateCode,
        rateType: this.lodgeSearchParams.rateType,
        type: this.lodgeSearchParams.type
      };
    },
    isPartnersPage() {
      return this.$route.name === "Partners";
    },
    isLoggedInAsAgency() {
      return this.isAgency;
    },
    isLoggedInAsNonAgency() {
      return this.isUser || this.isReservation;
    },
    hotelsList() {
      return this.$store.getters["property/hotelsTitle"];
    },
    /*
    promoCode() {
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return params.rateCode;
    },
    */
    /*
    promoType() {
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return params.rateType;
    },
    */
   /*
    hotelCode() {
      let hotel = null;
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
        const hotels = Object.values(this.hotelsList).flat();
        hotels.forEach((item) => {
          if (item.code === params.destination) {
            hotel = item.code;
          }
        });
      }

      return hotel;
    },
    */
   /*
    adults() {
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.adults);
    },
    */
   /*
    children() {
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.children);
    },
    */
    /*
    startDate() {
      let value = null;
      let params;

      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
        const today = new Date();
        if (params.dateFromString) {
          const savedDate = this.dateString2Date(params.dateFromString);
          //console.log("startDate", savedDate);

          if (savedDate >= today) {
            value = savedDate;
          }
        }
      } else {
        params = this.searchParams;
        value = params.dateFrom;
      }

      return value;
    },
    */
   /*
    exactDate() {
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return !!params.exactDate;
    },
    */
   /*
    nights() {
      let params;
      if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.nights);
    },
    */
    minDate() {
      return this.$dayjs(this.bookingDatesWindow.min).toDate()
    },
    maxDate() {
      return this.$dayjs(this.bookingDatesWindow.max).toDate()
    },
    /*
    minDate() {
      let value;

      if (this.options.mindate) {
        value = this.dateString2Date(this.options.mindate);
      } else {
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        value = date;
      }

      return value;
    },
    maxDate() {
      let value;

      if (this.options.maxdate) {
        value = this.dateString2Date(this.options.maxdate);
      } else {
        date = new Date();
        date.setHours(0, 0, 0, 0);
        date = this.$dayjs(date)
          .add(12, "month")
          .toDate();

        value = date;
      }

      return value;
    },
    */
    pageBackground() {
      const stayItem = this.$store.state.app.options.images.find((item, i) => {
        if (item.key === "bg-book-stay") return true;
      });

      if (!!stayItem) {
        return this.getCdnResourceUrl(stayItem.image);
      }
      return pageBackground
      // return this.getAppCdnResourceUrl(pageBackground);
    },
    promoCodes() {
      return this.options.displaycodes;
    },
  },

  watch: {
    cartIsEmpty: {
      immediate: true,

      handler() {
        if (!this.cartIsEmpty) {
          // @TODO move all options to bootstrap settings
          this.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and choose another lodging date?', {
            title: 'Confirm',
            headerBgVariant: 'secondary',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Yes',
            okVariant: 'primary',
            hideHeaderClose: true,
            noCloseOnBackdrop: true,
            cancelVariant: 'outline-primary',
            cancelTitle: 'No, return to cart',
            buttonSize: 'lg',
            centered: true
          })
            .then(async (value) => {
              if (value) {
                await this.$store.dispatch('property/clearCart')

              } else {
                this.$router.push({ name: 'ReviewPage' })
              }
            })
        }
      }
    }
  },

  methods: {
    ...propertyMethods,
    async onSubmit(params) {
      let name = null
      if (params.exactDate) {
        name = ['ALL', 'ALL-HOTELS', 'ALL-CAMPGROUNDS'].includes(params.destination)  ? 'hotels' : 'hotelPage'
      } else {
        name = 'hotelsFlex'
      }

      const query = {
        destination: params.destination,
        adults: params.adults,
        children: params.children,
        rateCode: params.rateCode,
        rateType: params.rateType,
        dateFrom: this.$dayjs(params.dateFrom).format('MM-DD-YYYY'),
        nights: params.nights
      }

      this.$router.push({ name, query })

      //console.log("onSubmit", data);
      /*
      this.confirmLoading = true;
      localStorage.removeItem("hotelsSearchParams");
      localStorage.setItem("hotelsSearchParams", JSON.stringify(data));
      const date =
        this.$dayjs(new Date()).format("YYYY/MM") === this.$dayjs(data.dateFrom).format("YYYY/MM")
          ? this.$dayjs(new Date()).format("MM/DD/YYYY")
          : this.$dayjs(data.dateFrom).format("MM/DD/YYYY");
      const availabilityData = {
        date: !data.exactDate ? date : this.$dayjs(data.dateFrom).format("MM/DD/YYYY"),
        nights: data.nights,
        rate_code: data.rateCode,
        limit: data.nights,
      };
      //console.log("onSubmit", availabilityData);

      await this.$store.dispatch("property/fetchAvailableHotels", availabilityData);

      let name;
      let query = {
        dateFrom: this.$dayjs(data.dateFrom).format("MM-DD-YYYY"),
        adults: data.adults,
        children: data.children,
      };
      let params = {};

      if (data.rateCode) {
        query["rateCode"] = data.rateCode ? data.rateCode : "";
      }

      if (!data.exactDate) {
        name = "hotelsFlex";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      } else if (data.destination === "ALL") {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = "ALL";
      } else if (data.destination === "ALL-CAMPGROUNDS" || data.destination === "ALL-HOTELS") {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      } else if (!this.$store.state.property.availableHotels[availabilityData.date][data.destination].min) {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = "ALL";
        this.setModal("HotelRateCalendar", {
          hotel_id: data.destination,
          alert: true,
          alertMessage: this.$store.state.property.availableHotels[availabilityData.date][data.destination].message,
        });
      } else {
        name = "hotelPage";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      }

      this.$router.push({
        name: name,
        params: params,
        query: query,
      });
      */
    },
    afterLoggedInAsAgency() {
      this.$router.push({
        name: "book_stay",
        params: { skipCartCheck: true }
      });
    },
  },
};
