import _ from 'lodash'
import crudItemMixin from '@/mixin/crud-item'
import { mapGetters } from 'vuex'
import vClickOutside from 'v-click-outside'
import VBFormInputQuantity from '@/components/inputs/VBFormInputQuantity.vue'
import { apiAddTourToCart } from "@/api/cart"
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'
import {useVuelidate} from "@vuelidate/core";
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  name: 'CartTourItemUpdate',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    crudItemMixin
  ],

  directives: {
    clickOutside: vClickOutside.directive
  },

  components: {
    VBFormInputQuantity,
    HotelItemImages
  },

  data() {
    return {
      currentOpenSelect: null
    }
  },

  computed: {
    ...mapGetters({
      options: 'app/options',
      tours: 'property/tours',
      toursAvailability: 'property/toursAvailability',
      cartToken: 'property/cartToken'
    }),

    tour() {
      return _.get(this.tours, this.newItem.tourCode)
    },

    tourImage() {
      return _.get(this.tour, 'images.0')
    },

    selectedOptionCode() {
      return _.chain(this.tour)
        .get(`options`)
        .findKey(i => _.find(i.pickup, (v, k) => k === this.newItem.optionCode))
        .value()
    },

    selectedOption() {
      return _.get(this.tour, `options.${this.selectedOptionCode}`)
    },

    selectedOptionPickup() {
      return _.get(this.selectedOption, `pickup.${this.newItem.optionCode}`)
    },

    selectedDateAvailability() {
      return _.get(this.toursAvailability, this.newItem.date)
    },

    selectedTourAvailability() {
      return _.get(this.selectedDateAvailability, this.tour.code)
    },

    selectedOptionAvailability() {
      return _.get(this.selectedTourAvailability, `options.${this.selectedOptionCode}`)
    },

    selectedOptionPickupAvailability() {
      return _.get(this.selectedOptionAvailability, `pickup.${this.newItem.optionCode}`)
    },

    selectedOptionPickupAvailabilityAdultPrice() {
      const base = this.selectedOptionPickupAvailability?.prices.adult ?? 0;
      const fees = this.selectedOptionPickupAvailability?.fees.adult ?? 0;
      const taxes = this.selectedOptionPickupAvailability?.taxes.adult ?? 0;
      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    selectedOptionPickupAvailabilityChildPrice() {
      const base = this.selectedOptionPickupAvailability?.prices.child ?? 0;
      const fees = this.selectedOptionPickupAvailability?.fees.child ?? 0;
      const taxes = this.selectedOptionPickupAvailability?.taxes.child ?? 0;
      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    placesLeft() {
      return _.get(this.selectedOptionAvailability, 'available', 0)
    },

    // placesAvailable() {
    //   if (this.selectedOptionAvailability) {
    //     return Math.min(this.tour.lowAvailability, this.placesLeft);
    //   }
    //   return +this.tour.lowAvailability;
    // },

    anyage() {
      return _.get(this.selectedTourAvailability, 'anyage', this.tour.anyage)
    },

    descAdults() {
      return _.get(this.tour, 'descAdults') || this.options.resformAgeAdult
    },

    descChildren() {
      return _.get(this.tour, 'descChildren') || this.options.resformAgeChild
    },

    inputGuests() {
      if (this.anyage) {
        return this.input.adults;
      }

      return this.input.adults + this.input.children;
    },

    dynamicMaxAdults() {
      return this.tour.maxAdults;
    },
    dynamicMaxChildren() {
      return this.tour.maxChildren - this.input.infants;
    },
    dynamicMaxInfants() {
      return Math.min(this.tour.maxChildren - this.input.children, this.input.adults);
    },
    dynamicMaxAnimals() {
      return this.input.adults;
    },

    inputGuestsWithOthers() {
      return this.inputGuests + this.input.infants + this.input.animals;
    },
  },

  methods: {
    getInput() {
      return {
        adults: this.newItem.adults,
        children: this.newItem.children,
        infants: this.newItem.infants,
        animals: this.newItem.animals,
        answers: this.newItem.answers
      }
    },

    addToCart() {
      this.isSubmitting = true

      const input = {
        ...this.input,
        property_code: this.newItem.propertyCode,
        tour_code: this.newItem.tourCode,
        tour_option: this.newItem.optionCode,
        date: this.newItem.date
      }

      apiAddTourToCart(this.cartToken, input)
        .then(() => {
          this.isSubmitting = false
          this.$store.dispatch("property/fetchTourCart")
          this.$emit('cancel')
        })
        .catch((error) => {
          this.isSubmitting = false
          console.log('error', error)
        });
    },

    submit() {
      if (_.isEmpty(this.tour.questions)) {
        this.addToCart()

      } else {
        const data = {
          adults: this.input.adults,
          children: this.input.children,
          infants: this.input.infants,
          animals: this.input.animals,
          answers: this.input.answers,

          tourCode: this.newItem.tourCode,
          tourOption: this.newItem.optionCode,

          date: this.newItem.date,
          anyAge: this.anyage,
          questions: this.tour.questions,
          onAddedToCart: () => this.setModal(),
          modalContainerClass: "activity-questionnaire"
        }
      
        this.setModal('ActivityQuestions', data)
        this.$emit('cancel')
      }
    }
  },

  watch: {
    'newItem.date': {
      immediate: true,
      deep: true,

      async handler() {
        const data = { date: this.newItem.date, limit: 1 }
        await this.$store.dispatch('property/fetchAvailableTours', data)
      }
    },

    'input.adults': {
      immediate: true,
      handler() {
        if (this.input.infants > this.input.adults) {
          this.input.infants = this.input.adults;
        }

        if (this.input.animals > this.input.adults) {
          this.input.animals = this.input.adults;
        }
      }
    },
  }
}