import ErrorModal429 from '@/components/errors/ErrorModal429.vue'

export default {
  components: {
    ErrorModal429
  },
  data: () => ({
    headerHeight: 50,
    alertsHeight: 0,
    isRefreshing: false
  }),
  computed: {
    fixedHeaderHeight() {
      return this.headerHeight + this.alertsHeight;
    },
  },
  methods: {
    onAlertsRendered(height) {
      this.alertsHeight = height;
    },
    onAlertsDestroyed() {
      this.alertsHeight = 0;
    },
  },
  created() {
    this.$eventHub.$on("alerts-rendered", this.onAlertsRendered);
    this.$eventHub.$on("alerts-destroyed", this.onAlertsDestroyed);
  },
};
