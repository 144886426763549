import { apiHotelRooms } from "@/api/property";
import _ from 'lodash';
import {getPrices} from '@/utils/taxes-and-fees';

export default {
  props: {
    cartItem: Object,
    amount: Number,
    index: Number,
    modifyMode: Boolean,
    currentItem: Boolean,
    roomAvailabilityResponse: Object,
  },
  data: () => ({
    room: {},
    availabilityData: { date: new Date(), nights: 1 },
    // step3: "",
  }),
  async created() {
    //console.log(this.hotelCode);
    /*
    this.step3 =
        `/booking/lodging-select/?dateFrom=${
      this.$dayjs(new Date(this.cartItem.date)).format('MM-DD-YYYY')
    }&nights=${
      this.cartItem.nights
    }&destination=${this.hotelCode}&adults=${this.cartItem.adults}&children=${
      this.cartItem.children
    }&rateCode=${this.cartItem.rateCode || ""}`;
    //console.log(this.step3)
    */
    this.fetchRooms();
    this.availabilityData.nights = +this.cartItem.nights;
    this.availabilityData.date = this.$dayjs(this.cartItem.date).format(
      "MM/DD/YYYY"
    );
    this.availabilityData.rate_code = this.cartItem.rateCode;
    this.availabilityData.is_group = this.cartItem.isGroup;
  },
  computed: {
    hotelCode() {
      let hotelCode = this.cartItem.hotelCode;

      if (typeof this.cartItem.roomDetails !== "undefined") {
        const roomType = this.cartItem.roomDetails.type;

        const rvTypes = [
          "rv",
          "tent",
          "aba_rv",
          "aba_tent",
        ];

        if (rvTypes.indexOf(roomType) !== -1 && hotelCode.indexOf("RV") === -1) {
          hotelCode = hotelCode + ":RV";
        }
      }

      return hotelCode;
    },
    hotelTitle() {
      return this.$store.state.property.hotels[this.hotelCode].title;
    },
    roomTitle() {
      return this.room.title ? this.room.title.replace("amp;", "") : "";
    },
    getImageUrl() {
      if (
        typeof this.room.images !== "undefined" &&
        this.room.images.length > 0
      ) {
        return this.getCdnResourceUrl(this.room.images[0].image);
      }

      return "";
    },
    itemTitle() {
      return this.isRvOrTent ? "Site" : "Room";
    },
    itemQtyTitle() {
      return this.isRvOrTent ? "Number of Sites" : "Number of Rooms";
    },
    addAnotherItemLabel() {
      return this.isRvOrTent ? "Add Another Site" : "Add Another Room";
    },
    isRvOrTent() {
      return (
        this.room.type === "rv" ||
        this.room.type === "tent" ||
        this.room.type === "aba_rv" ||
        this.room.type === "aba_tent"
      );
    },
    specRequests() {
      return _.filter(
        this.cartItem.specreqs,
        (v, k) => !['EQUIPMENT_DETAILS', 'EQUIPMENT_SIZE'].includes(k)
      )
    },
    equipmentSize() {
        return this.cartItem.specreqs?.EQUIPMENT_SIZE
    },
    equipmentDetails() {
        return this.cartItem.specreqs?.EQUIPMENT_DETAILS
    },
    equipmentType() {
        return this.cartItem.specreqs?.EQUIPMENT_DETAILS?.split(' | ')?.[0]
    },
    equipmentSizes() {
        return this.cartItem.specreqs?.EQUIPMENT_DETAILS?.split(' | ')?.[1]
    },
    tax() {
      return this.cartItem.tax > 0 ? this.$formatCurrency(this.cartItem.tax) + " USD" : " + tax";
    },
    addAnotherRoute() {
      const name = 'hotelPage'

      const query = {
        dateFrom: this.$dayjs(new Date(this.cartItem.date)).format('MM-DD-YYYY'),
        nights: this.cartItem.nights,
        destination: this.cartItem.hotelCode,
        adults: this.cartItem.adults,
        children: this.cartItem.children
      }

      if (this.cartItem.rateCode) {
        query.rateCode = this.cartItem.rateCode
        query.rateType = this.cartItem.isGroup ? 'group' : 'promo'
      }

      return { name, query }
    },
    roomAvailability() {
      return this.roomAvailabilityResponse?.availability[
        Object.keys(this.roomAvailabilityResponse.availability)[0]
      ].rooms.find(
        (el) =>
          el.rateCode === this.cartItem.rateCode &&
          el.roomCode === this.cartItem.roomCode
      );
    },

    roomAvailabilityAvgBasePrice() {
      const additionalGuests = Math.max(0, this.cartItem.adults - (this.roomAvailability?.base ?? 0));
      const additional = this.roomAvailability?.additional ?? 0;
      const available = this.roomAvailability?.available ?? 0;
      const price = this.roomAvailability?.price ?? 0;

      return available && price ? (
        price + (additionalGuests * additional)
      ) : 0;
    },

    prices() {
      const adults = this.cartItem.adults;
      const nights = +this.nights;
      const quantity = this.cartItem.quantity;
      const roomAvailabilityPerGuest = this.roomAvailability?.perGuest[adults];

      const prices = getPrices({
        base: (roomAvailabilityPerGuest?.price ?? 0) * quantity,
        fees: (roomAvailabilityPerGuest?.fees ?? 0)  * quantity,
        taxes: (roomAvailabilityPerGuest?.taxes ?? 0) * quantity,
        nights,
      })
      
      return prices;
    },
  },
  methods: {
    async fetchRooms() {
      try {
        const rooms = await apiHotelRooms(this.cartItem.hotelCode);
        this.room = rooms[this.cartItem.roomCode];
        console.log(this.room);
      } catch (err) {
        console.error("Error occurred while fetching rooms");
        console.error(err);
      }
    },
  },
};
