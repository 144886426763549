export default {
  data: () => ({
    confirmLoading: false,
  }),
  computed: {
    header_label() {
      return this.$store.state.app.modalProps.header ? this.$store.state.app.modalProps.header : "Confirm";
    },
    text() {
      return this.$store.state.app.modalProps.content ? this.$store.state.app.modalProps.content : "Please confirm action";
    },
    confirm_label() {
      return this.$store.state.app.modalProps.confirm ? this.$store.state.app.modalProps.confirm : "Yes";
    },
    decline_label() {
      return this.$store.state.app.modalProps.decline ? this.$store.state.app.modalProps.decline : "Cancel";
    },
    disable_decline() {
      return typeof this.$store.state.app.modalProps.disableDecline !== "undefined"
        ? !!this.$store.state.app.modalProps.disableDecline
        : false;
    },
  },
  methods: {
    async on_confirm() {
      if (this.$store.state.app.modalProps.onConfirm) {
        this.confirmLoading = true;
        await this.$store.state.app.modalProps.onConfirm(this.$store.state.app.modalProps.params);
        this.confirmLoading = false;
      }
      if (this.$store.state.app.modalProps.resolve) {
        this.$store.state.app.modalProps.resolve();
      }
      this.setModal();
    },
    on_decline() {
      if (this.$store.state.app.modalProps.onDecline) {
        this.$store.state.app.modalProps.onDecline();
      }
      if (this.$store.state.app.modalProps.reject) {
        this.$store.state.app.modalProps.reject();
      }
      this.setModal();
    },
    onClose() {
      if (this.$store.state.app.modalProps.confirmOnClose) {
        if (this.$store.state.app.modalProps.resolve) {
          this.$store.state.app.modalProps.resolve();
        }
      }

      return this.setModal();
    },
  },
  created() {
    // add extra class to modal
    this.$store.dispatch("app/setModalContainerClass", "confirmation-dialog");
  },
  destroyed() {
    // clear extra class from store
    this.$store.dispatch("app/setModalContainerClass", null);
  },
};
