import _ from 'lodash';
import { mapGetters } from 'vuex';
import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),

  props: {
    open: Boolean,
    type: String,
    // initial code value
    selectedCode: String,
    isEdit: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // value: "",
    windowParams: null,
    // code: "",
    // label: "",
    // hideOptionAll: false,
    activityGroupNames: {
      'W': 'Winter Activities',
      'S': 'Summer Activities',
    },
    activeActivityGroup: 'S',
  }),
  methods: {
    selectHotel(code) {
      //console.log("selectHotel", hotel, code, this.type);
      // this.label = hotel;
      // this.code = code;
      // this.value = hotel;
      this.$emit("getLocation", { location: code});
      this.$emit("open-select", "");
    },
    toggleActivityGroup(groupCode) {
      this.activeActivityGroup = groupCode !== this.activeActivityGroup ? groupCode : null
    },
    // init() {
    //   //console.log("init", this.selectedCode, this.type);

    //   const code = this.selectedCode;
    //   let list;

    //   if (this.type === "hotels") {
    //     list = Object.values(this.hotelsList).flat();
    //   } else {
    //     list = Object.values(this.toursList).flat();
    //   }

    //   if (typeof code !== "undefined" && code !== null) {
    //     this.code = code;
    //     let value;
    //     let label;
    //     list.forEach((item) => {
    //       if (item.code === code) {
    //         label = item.title;
    //         value = label
    //       }
    //     });
    //     if (!!!value) {
    //       label = `All ${this.locationName} ${code === 'ALL-HOTELS' ? 'Hotels' : 'Campgrounds'}`;
    //       value = label
    //     }
    //     this.value = value;
    //     this.label= label;
    //   } else if (this.type === "hotels") {
    //     if (!!this.isEdit) {
    //       this.code = this.$route.query.destination;
    //       if (this.$route.query.destination === 'ALL')
    //         this.value = `All ${this.locationName} Lodging`;
    //       else {
    //         this.value = `All ${this.locationName} ${this.$route.query.destination === 'ALL-HOTELS' ? 'Hotels' : 'Campgrounds'}`;
    //       }
    //     }
    //     else if (localStorage.getItem("hotelsSearchParams")) {
    //       const params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
    //       this.code = params.destination;
    //       if (params.destination === 'ALL')
    //         this.value = `All ${this.locationName} Lodging`;
    //       else {
    //         this.value = `All ${this.locationName} ${params.destination === 'ALL-HOTELS' ? 'Hotels' : 'Campgrounds'}`;
    //       }
    //     } else {
    //       this.code = "ALL";
    //       this.value = `All ${this.locationName} Lodging`;
    //     }
    //   } else {
    //     this.code = "ALL";
    //     this.value = `All ${this.locationName} Activities`;
    //   }

    //   if (list.length === 1) {
    //     this.value = list[0].title;
    //     this.code = list[0].code;
    //     this.hideOptionAll = true;
    //   }
    //   console.log(this.value);
    //   this.$emit("getLocation", { location: this.code });
    // },
  },
  // watch: {
  //   selectedCode: {
  //     immediate: true,
  //     handler() {
  //       this.init();
  //     }
  //   }
  // },
  computed: {
    ...mapGetters({
      lodges: 'property/hotels',
      tours: 'property/tours',
      groupPropertyActivities: 'app/groupPropertyActivities',
      toursBySeasons: 'property/toursBySeasons',
      property: 'app/options',
    }),
    windowWidth() {
      return this.windowSize.width.value;
    },
    hotels() {
      return _.filter(this.lodges, i => i.type === 'hotel');
    },

    campgrounds() {
      return _.filter(this.lodges, i => i.type === 'campground');
    },

    activities() {
      return _.map(this.tours);
    },

    locationLabel() {
      return this.type === "hotels" ? "Where do you want to go?" : "What do you want to do?";
    },

    hideOptionAll() {
      if (this.type === 'tours') {
        return _.size(this.activities) === 1
      }
      return _.size(this.lodges) === 1
    },

    value() {
      if (this.type === 'tours') {
        const toursLabels = {
          'ALL': `All ${this.property.title} Activities`
        }

        return _.get(toursLabels, this.selectedCode) || _.chain(this.activities)
          .find(i => i.code === this.selectedCode)
          .get('title')
          .value()
      }

      const hotelsLabels = {
        'ALL': `All ${this.property.title} Lodges`,
        'ALL-HOTELS': `All ${this.property.title} Hotels`,
        'ALL-CAMPGROUNDS': `All ${this.property.title} Campgrounds`
      }
      
      return _.get(hotelsLabels, this.selectedCode) || _.chain(this.lodges)
        .find(i => i.code === this.selectedCode)
        .get('title')
        .value()
    }
  },
};
