import Vue from "vue";
// import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import minMax from "dayjs/plugin/minMax";
// import isBetween from "dayjs/plugin/isBetween";
// import weekOfYear from "dayjs/plugin/weekOfYear";
import dayjs from '@/lib/dayjs'
import { objectToFormData } from "object-to-formdata";
import { apiLogout } from "@/api/auth";
import store from "@/store";
import router from "@/lib/router";
import axios from "@/lib/axios";
import { SIZES } from "@/constants";
import numeral from "numeral";
import _ from "lodash";
import { app } from '@/main.js'

const keepLocalStorageParam = ["alerts", "storage"];

// dayjs.extend(customParseFormat);
// dayjs.extend(minMax);
// dayjs.extend(isBetween);
// dayjs.extend(weekOfYear);
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$numeral = numeral;
Vue.prototype.$http = axios;
Vue.prototype.$apiUrl = import.meta.env.VITE_API_URL;
Vue.prototype.$isObject = (v) => !!v && typeof v === "object" && !Array.isArray(v);
Vue.prototype.$deepClone = (v) => JSON.parse(JSON.stringify(v));
Vue.prototype.$objectToFormData = (v) => objectToFormData(v, { indices: true });
Vue.prototype.$scrollTop = (v) => window.scrollTo({ top: v || 0, behavior: "smooth" });
Vue.prototype.SIZES = SIZES || {};
Vue.prototype.$_ = _;

/**
 * Get an item from an array or object using "dot" notation.
 *
 * @param {object} object
 * @param {string} path
 * @param {*} value
 * @return {*}
 */
Vue.prototype.$get = (object, path, value = undefined) =>
  path.split(".").reduce((prevObj, key) => prevObj && prevObj[key], object) || value;

/**
 * Get user data or user field
 *
 * @param {string|null} field
 */
Vue.prototype.$user = (field = null) => {
  const u = store.state.auth.data;
  if (typeof u !== "object") return false;
  return field ? u[field] : u;
};

/**
 * Get user login status
 *
 *
 */

Vue.prototype.$userStatus = () => {
  return !!localStorage.getItem("access_token");
};

/**
 * Get login type
 */
Vue.prototype.$loginType = () => {
  return localStorage.getItem("login_type") ? localStorage.getItem("login_type") : "";
};

/**
 * Get last reservation token
 */
Vue.prototype.$lastReservationKeys = () => {
  return store.getters['property/lastReservationKeys']
  /*
  return localStorage.getItem("last_reservation_token") ? localStorage.getItem("last_reservation_token") : "";
  */
};

/**
 * Validate user role
 *
 * @param {string|array} roles - role name or array of names for checking
 * @param {string|null} val - compare with role
 */
Vue.prototype.$isRole = (roles, val = null) => {
  const key = parseInt(val || Vue.prototype.$user("role"));
  const list = {
    1: "Admin",
    2: "Moderator",
    3: "Member",
  };
  return roles.indexOf(list[key]) > -1;
};

/**
 * Get app options
 *
 * @param {string|null} field
 */
Vue.prototype.$appOptions = () => {
  return store.state.app.options;
};

/**
 * Get if tours are enabled
 */
Vue.prototype.$toursEnabled = () => {
  let flag = typeof store.state.app.options.toursEnabled !== "undefined" ? store.state.app.options.toursEnabled : true;
  return flag;
};

/**
 * Simple notification
 *
 * @param {string|null} message
 * @param {int} type - 0 error | 1 success
 */
Vue.prototype.$msg = (message = null, type = 0) => { 
  if (!message || typeof message !== "string") {
    message = "Something went wrong.";
  }

  // Vue.prototype.$message[type !== 1 ? "error" : "success"](message, 5);
  const title = type !== 1 ? 'Error' : 'Success'
  const variant = type !== 1 ? 'danger' : 'success'
  app.$bvToast.toast(message, { title, variant, solid: true })
};

/** Auth system */
Vue.prototype.$auth = {
  /*
   * loginType:
   * empty - regular
   * a - agency
   */

  async login(accessToken, refreshToken, expiresIn, tokenType, loginType = "", reservationToken = "") {
    const expiresInMs = typeof expiresIn === "string" ? parseInt(expiresIn) * 1000 : expiresIn * 1000;
    window.localStorage.setItem("access_token", accessToken);
    window.localStorage.setItem("refresh_token", refreshToken);
    window.localStorage.setItem("expires_in", Date.now() + expiresInMs);
    window.localStorage.setItem("token_type", tokenType);

    if (loginType !== "") {
      window.localStorage.setItem("login_type", loginType);
    }
    if (reservationToken !== "") {
      window.localStorage.setItem("reservation_token", reservationToken);
    }

    axios.defaults.headers.Authorization = `${tokenType} ${accessToken}`;
    await store.dispatch("auth/setAuthStatus", true);
  },
  logout(silent) {
    const refreshToken = localStorage.getItem("refresh_token");
    refreshToken && apiLogout(refreshToken);
    delete axios.defaults.headers.Authorization;
    store.dispatch("auth/setAuthStatus", false);
    store.dispatch("property/clearCartLocally");

    let params = {};
    for (var i in keepLocalStorageParam) {
      const paramName = keepLocalStorageParam[i];
      params[paramName] = localStorage.getItem(paramName) ? localStorage.getItem(paramName) : null;
    }

    window.localStorage.clear();

    for (var j in params) {
      const paramValue = params[j];
      if (paramValue) {
        window.localStorage.setItem(j, paramValue);
      }
    }

    if (!silent && router.currentRoute.name !== "home") router.push({ name: "home" }).catch(() => {});
  },
  logoutFromReservation() {
    delete axios.defaults.headers.Authorization;
    store.dispatch("auth/setAuthStatus", false);
    let alerts = localStorage.getItem("alerts") ? localStorage.getItem("alerts") : null;
    window.localStorage.clear();
    if (!!alerts) window.localStorage.setItem("alerts", alerts);
    if (router.currentRoute.name !== "home") router.push({ name: "home" }).catch(() => {});
  },
  silentLogout() {
    delete axios.defaults.headers.Authorization;
    store.dispatch("auth/setAuthStatus", false);
    let alerts = localStorage.getItem("alerts") ? localStorage.getItem("alerts") : null;
    window.localStorage.clear();
    if (!!alerts) window.localStorage.setItem("alerts", alerts);
  },
};

// Set errors to store
Vue.prototype.$setErrors = (data) => {
  store.dispatch("app/setErrors", data);
};

Vue.prototype.$debug = (data) => {
  store.dispatch("app/setDebug", data);
};

/**
 * Check if string is a correct stringified JSON
 *
 * @param {string|null} value
 */
Vue.prototype.$isJson = (value = null) => {
  if (!value) {
    return false;
  }

  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

Vue.prototype.$formatCurrency = (value = 0, format = "$0,0[.]00") => {
  return numeral(value).format(format);
};
