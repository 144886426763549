export default {
  mounted() {
    const promoItems = document.getElementsByClassName('promo__item-text');
    for (let i = 0; i < promoItems.length; i++) {
      const item = promoItems[i].getElementsByTagName('a');
      for (let j=0, child; child=item[j]; j++) {
        child.setAttribute('target', '_blank');
      }
    }
  },
  computed: {
    benefits() {
      return this.$store.state.app.options?.promoItems
    },
    title() {
      return this.$store.state.app.options?.promoTitle
    }
  }
}
