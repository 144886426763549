export default {
  data: () => ({
    orderSelectedId: "asc",
    orderSelectOpened: false,
    typeHotelSelected: true,
    selectedMinPrice: 0,
    selectedMaxPrice: 0,
    sliderMinPrice: 0,
    sliderMaxPrice: 0,
    typeCampgroundSelected: true,
    //roomType: ["ada", "single", "double", "suite", "canyon"],
    ada: false,
    ada_info: false,
    roomGroups: {},
  }),
  props: {
    open: Boolean,
    filterData: Object,
  },
  created() {
    this.$eventHub.$on("hotel-page-room-tab", this.resetPriceFilter);
    this.$eventHub.$on("reset-price-filter", this.resetPriceFilter);
    this.$eventHub.$on("toggle-room-ada-filter", this.onToggleRoomAdaFilter);
    this.$eventHub.$on(
      "toggle-hotel-stay-type-filter",
      this.onToggleHotelStayTypeFilter
    );
    this.$eventHub.$on("set-room-groups", this.onSetRoomGroups);
  },
  computed: {
    orderSelectedValue() {
      return this.orderSelectedId === "asc"
        ? "Price - Low to High"
        : "Price - High to Low";
    },
    orderOptions() {
      return [
        {
          id: "asc",
          name: "Price - Low to High",
        },
        {
          id: "desc",
          name: "Price - High to Low",
        },
      ];
    },
    selectedPriceRangeLabel() {
      return `$${Math.ceil(this.priceSelected[0])} - $${Math.ceil(
        this.priceSelected[1]
      )}`;
    },
    roomTypeOptions() {
      return [
        {
          value: "ada",
          label: "Show ADA Accessible Rooms",
        },
        {
          value: "single",
          label: "Rooms with 1 Bed",
        },
        {
          value: "double",
          label: "Rooms with 2+ Beds",
        },
        {
          value: "suite",
          label: "Suites",
        },
        {
          value: "canyon",
          label: "Canyon View",
        },
      ];
    },
    hotelFilter() {
      return this.$route.name === "hotels";
    },
    min_price() {
      if (this.hotelFilter) {
        return this.$store.state.app.hotelMinPrice
          ? this.$store.state.app.hotelMinPrice
          : 0;
      } else {
        return this.$store.state.app.roomMinPrice
          ? this.$store.state.app.roomMinPrice
          : 0;
      }
    },
    max_price() {
      if (this.hotelFilter) {
        return this.$store.state.app.hotelMaxPrice
          ? this.$store.state.app.hotelMaxPrice
          : 0;
      } else {
        return this.$store.state.app.roomMaxPrice
          ? this.$store.state.app.roomMaxPrice
          : 0;
      }
    },
    priceSelected: {
      get() {
        return [this.selectedMinPrice, this.selectedMaxPrice];
        //return [this.min_price, this.max_price]
      },
      set(value) {
        //console.log(value);
        this.selectedMinPrice = value[0];
        this.selectedMaxPrice = value[1];
      },
    },
    adaFilterLabel() {
      if (
        this.filterData &&
        this.filterData.page &&
        this.filterData.page === "hotelPage"
      ) {
        if (
          this.filterData.hotel &&
          this.filterData.hotel.type &&
          this.filterData.hotel.type === "campground"
        ) {
          return "Show ABA Sites";
        } else {
          return "Show ADA Accessible Rooms";
        }
      } else {
        return "Show ADA Accessible Rooms";
      }
    },
    adaDescription() {
      if (
        this.filterData &&
        this.filterData.page &&
        this.filterData.page === "hotelPage"
      ) {
        if (
          this.filterData.hotel &&
          this.filterData.hotel.type &&
          this.filterData.hotel.type === "campground"
        ) {
          return "Accessible site required for individuals with mobility impairments. If an accessible room is not needed and other room types are available, please do not reserve this room to allow for our guests requiring these accessible features.";
        } else {
          return "Accessible room required for individuals with mobility impairments. If an accessible room is not needed and other room types are available, please do not reserve this room to allow for our guests requiring these accessible features.";
        }
      } else {
        return "Accessible room required for individuals with mobility impairments. If an accessible room is not needed and other room types are available, please do not reserve this room to allow for our guests requiring these accessible features.";
      }
    },
  },
  watch: {
    "$store.state.app.hotelMinPrice": {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.hotelFilter) {
          this.sliderMinPrice = value;
          this.selectedMinPrice = this.sliderMinPrice;

          if (
            this.selectedMinPrice === 0 ||
            this.selectedMinPrice < this.sliderMinPrice
          ) {
            this.selectedMinPrice = this.sliderMinPrice;
          }
        }

        this.applyFilter();
      },
    },
    "$store.state.app.hotelMaxPrice": {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.hotelFilter) {
          this.sliderMaxPrice = value;
          this.selectedMaxPrice = this.sliderMaxPrice;

          if (
            this.selectedMaxPrice === 0 ||
            this.selectedMaxPrice > this.sliderMaxPrice
          ) {
            this.selectedMaxPrice = this.sliderMaxPrice;
          }
        }

        this.applyFilter();
      },
    },
    "$store.state.app.roomMinPrice": {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.hotelFilter) {
          this.sliderMinPrice = value;
          this.selectedMinPrice = this.sliderMinPrice;

          if (
            this.selectedMinPrice === 0 ||
            this.selectedMinPrice < this.sliderMinPrice
          ) {
            this.selectedMinPrice = this.sliderMinPrice;
          }
        }

        this.applyFilter();
      },
    },
    "$store.state.app.roomMaxPrice": {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.hotelFilter) {
          this.sliderMaxPrice = value;
          this.selectedMaxPrice = this.sliderMaxPrice;

          if (
            this.selectedMaxPrice === 0 ||
            this.selectedMaxPrice > this.sliderMaxPrice
          ) {
            this.selectedMaxPrice = this.sliderMaxPrice;
          }
        }

        this.applyFilter();
      },
    },
  },
  methods: {
    /*initMinPrice() {
      if (this.hotelFilter) {
        this.min_price = this.$store.state.app.hotelMinPrice;
      } else {
        this.min_price = this.$store.state.app.roomMinPrice;
      }

      this.priceSelected[0] = this.min_price;
    },
    initMaxPrice() {
      if (this.hotelFilter) {
        this.max_price = this.$store.state.app.hotelMaxPrice;
      } else {
        this.max_price = this.$store.state.app.roomMaxPrice;
      }

      this.priceSelected[1] = this.max_price;
    },*/
    closeFilterSection() {
      this.$eventHub.$emit("close-filter-section");
    },
    onOrderSelect(value) {
      this.orderSelectedId = value;
      this.orderSelectOpened = false;

      this.applyFilter();
    },
    onTypeHotelToggle() {
      this.typeHotelSelected = !this.typeHotelSelected;

      this.applyFilter();
    },
    onTypeCampgroundToggle() {
      this.typeCampgroundSelected = !this.typeCampgroundSelected;

      this.applyFilter();
    },
    onAdaToggle() {
      this.resetPriceFilter();
      this.applyFilter();
    },
    onFilterCancel() {
      this.orderSelectedId = "asc";
      this.priceSelected = [this.min_price, this.max_price];
      this.typeHotelSelected = true;
      this.typeCampgroundSelected = true;
      this.roomType = ["ada", "single", "double", "suite", "canyon"];
      this.ada = false;
      if (!this.hotelFilter) {
        for (const groupKey in this.roomGroups) {
          this.$set(this.roomGroups[groupKey], "selected", true);
        }
      }

      this.applyFilter();

      //this.closeFilterSection()
    },
    onFilterSave() {
      this.closeFilterSection();
    },
    onPriceChange() {
      this.applyFilter();
    },
    resetPriceFilter() {
      this.priceSelected = [this.min_price, this.max_price];
    },
    applyFilter() {
      if (this.hotelFilter) {
        this.$store.dispatch("app/setHotelFilter", this.getHotelFilter());
        this.$store.dispatch("app/setHotelOrder", this.getHotelOrder());
      } else {
        this.$store.dispatch("app/setRoomFilter", this.getRoomFilter());
        this.$store.dispatch("app/setRoomOrder", this.getRoomOrder());
      }
    },
    getHotelFilter() {
      let filter = [];

      // if (
      //   this.min_price !== this.priceSelected[0] ||
      //   this.max_price !== this.priceSelected[1]
      // ) {
        filter.push({
          field: "price_range",
          value: this.priceSelected,
        });
      // } 

      if (this.typeHotelSelected === false) {
        filter.push({
          field: "exclude_type_hotel",
          value: true,
        });
      }

      if (this.typeCampgroundSelected === false) {
        filter.push({
          field: "exclude_type_campground",
          value: true,
        });
      }


      //console.log(filter)

      return filter;
    },
    getHotelOrder() {
      return this.orderSelectedId;
    },
    getRoomFilter() {
      let filter = [];

      /*filter.push({
        field: "type",
        value: this.roomType,
      });*/

      if (
        this.min_price !== this.priceSelected[0] ||
        this.max_price !== this.priceSelected[1]
      ) {
        filter.push({
          field: "price_range",
          value: this.priceSelected,
        });
      }

      if (this.ada) {
        filter.push({
          field: "ada",
          value: true,
        });
      }

      const filteredGroups = Object.values(this.roomGroups).filter(
        (group) => group.selected === false
      );
      if (!!filteredGroups.length) {
        filter.push({
          field: "excluded_room_groups",
          value: Object.keys(this.roomGroups).filter(
            (groupKey) => !this.roomGroups[groupKey].selected
          ),
        });
      }
      //console.log(filter);
      //console.log(filter, this.min_price, this.max_price)

      return filter;
    },
    getRoomOrder() {
      return this.orderSelectedId;
    },
    onRoomTypeFilter() {
      this.applyFilter();
    },
    onAdaInfo() {
      this.ada_info = !this.ada_info;
    },
    onToggleRoomAdaFilter(value) {
      this.ada = !!value;
      this.applyFilter();
    },
    onToggleHotelStayTypeFilter(value) {
      //console.log(value);
      this.onFilterCancel();
      if (value === "ALL-CAMPGROUNDS") {
        this.typeHotelSelected = false;
        this.typeCampgroundSelected = true;
      } else if (value === "ALL-HOTELS"){
        this.typeHotelSelected = true;
        this.typeCampgroundSelected = false;
      } else {
        this.typeHotelSelected = true;
        this.typeCampgroundSelected = true;
      }

      this.applyFilter();
    },
    onRoomGroupTypeToggle(groupKey) {
      this.roomGroups[groupKey].selected = !this.roomGroups[groupKey].selected;
      this.applyFilter();
    },
    onSetRoomGroups(groups) {
      for (const groupKey in groups) {
        if (groupKey !== "DEFAULT") {
          this.$set(this.roomGroups, groupKey, groups[groupKey]);
          this.$set(this.roomGroups[groupKey], "selected", true);
        }
      }
      //console.log(this.roomGroups);
      this.applyFilter();
    },
  },
};
