<template>
  <section class="profile__section">
    <h2 class="profile__section-title">
      COMMUNICATION PREFERENCES - Email & Phone
    </h2>
    <form>
      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-email">Email Address</label>
          <a-input
            placeholder="Email Address"
            id="profile-email"
            name="email"
            class="input input--auth"
            :class="{ 'input--error': v$.email.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.email.$model"
            :maxLength="60"
          />
          <div
            class="form__item-error"
            v-if="v$.email.required?.$invalid && v$.email.$error"
          >
            Field is required
          </div>
          <div
            class="form__item-error"
            v-if="v$.email.email?.$invalid && v$.email.$error"
          >
            Please enter a valid email address
          </div>
          <div class="form__item-error" v-if="errors.email">
            <span v-for="err in errors.email" :key="err">
              {{ err }}
            </span>
          </div>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-phone"> Phone Number </label>
          <a-input
            placeholder="Phone Number"
            id="profile-phone"
            class="input input--auth"
            name="phone"
            :class="{ 'input--error': v$.phone.$error }"
            v-model.trim="v$.phone.$model"
            @focus="resetFormFieldErrors"
            :maxLength="20"
          />
          <div
            class="form__item-error"
            v-if="v$.phone.required?.$invalid && v$.phone.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.phone.phone?.$invalid && v$.phone.$error"
          >
            Please enter 2-20 chars. Digits and &#;- chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.phone.notCreditCard?.$invalid && v$.phone.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.phone">
            <li v-for="err in errors.phone" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
      </div>
    </form>
    <div class="profile__buttons">
      <a-button class="button" @click="setFormValues">
        Discard all changes
      </a-button>
      <a-button
        class="button button-primary"
        :disabled="loading"
        @click="updateUserContacts"
      >
        Save changes
      </a-button>
    </div>
  </section>
</template>

<script>
import { required, email } from '@vuelidate/validators'
import { apiUpdateProfile } from '@/api/profile'
import {
  phone,
  notCreditCard
} from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    email: '',
    phone: '',
    loading: false,
    errors: {}
  }),
  props: ['form'],
  validations: () => {
    return {
      email: {
        required, email
      },
      phone: {
        required, phone, notCreditCard,
      }
    }
  },
  watch: {
    form: {
      immediate: true,
      handler() {
        this.setFormValues()
      }
    }
  },
  methods: {
    setFormValues() {
      this.email = this.form.email;
      this.phone = this.form.phone;
    },
    async updateUserContacts() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      try {
        this.loading = true
        const res = await apiUpdateProfile['communication']({ email: this.email, phone: this.phone })
        if (res && res.status === 'SUCCESS') {
          this.$msg('Contacts data changes successfully!', 1);
          this.$store.dispatch('auth/getUserInfo');
        }
      } catch (error) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors };
        this.$store.dispatch('app/setErrors')
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>
