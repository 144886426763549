import dayjs from 'dayjs'
import _ from 'lodash';

export function getRatePlanFromResponse(details, type) {
  if (type === 'group') {
    return {
      details,
      category: 'GROUP',
      code: _.chain(details)
        .flatMap()
        .get('0.code')
        .value(),
      minstay: _.chain(details)
        .flatMap()
        .map('minstay')
        .min()
        .value(),
      // title: _.chain(details)
      //   .flatMap()
      //   .get('0.title')
      //   .value(),
      // description: _.chain(details)
      //   .flatMap()
      //   .get('0.description')
      //   .value(),
      start: _.chain(details)
        .flatMap()
        .map('start')
        .orderBy(i => dayjs(i, 'MM/DD/YYYY').toDate())
        .first()
        .value(),
      end: _.chain(details)
        .flatMap()
        .map('end')
        .orderBy(i => dayjs(i, 'MM/DD/YYYY').toDate())
        .last()
        .value(),
      expires: _.chain(details)
        .flatMap()
        .map('expires')
        .orderBy(i => dayjs(i, 'MM/DD/YYYY').toDate())
        .last()
        .value(),
      hotels: details,
    }
  }

  return {
    details,
    category: _.chain(details)
      .map()
      .get('0.category')
      .value(),
    code: _.chain(details)
      .map()
      .get('0.code')
      .value(),
    minstay: _.chain(details)
      .map('minstay')
      .min()
      .value(),
    title: _.chain(details)
      .map()
      .get('0.title')
      .value(),
    description: _.chain(details)
      .map()
      .get('0.description')
      .value(),
    start: _.chain(details)
      .map('start').orderBy(i => dayjs(i, 'MM/DD/YYYY').toDate())
      .first()
      .value(),
    end: _.chain(details)
      .map('end').orderBy(i => dayjs(i, 'MM/DD/YYYY').toDate())
      .first()
      .value(),
    expires: _.chain(details)
      .map('expires').orderBy(i => dayjs(i, 'MM/DD/YYYY').toDate())
      .last()
      .value()
  }
}