import http from '@/lib/axios'
import { PROPERTY_CODE } from '@/constants'

export const apiCartToken = () => http.post(`cart/token`, { property_code: PROPERTY_CODE });

export const apiModifyCartToken = (reservation_token) => http.post(`cart/token/modify`, { property_code: PROPERTY_CODE, reservation_token: reservation_token });

export const apiAddToCart = (cart_token, room) => http.post(`cart/rooms/${cart_token}`, { ...room });

export const apiGetCart = (cart_token) => http.get(`cart/rooms/${cart_token}`);

export const apiClearCart = (cart_token) => http.get(`cart/clear/${cart_token}`);

export const apiRemoveRoom = (cart_token, room_key) => http.delete(`cart/rooms/${cart_token}/${room_key}`);

export const apiBookingRequest = (cart_token, payerInfo) => http.post(`cart/booking/${cart_token}`, { ...payerInfo });

export const apiCreateModify = (cart_token) => http.post(`cart/modify/${cart_token}`);

export const apiAddTourToCart = (cart_token, data) => http.post(`cart/tours/${cart_token}`, { ...data });

export const apiGetTourCart = (cart_token) => http.get(`cart/tours/${cart_token}`);

export const apiRemoveTour = (cart_token, key) => http.delete(`cart/tours/${cart_token}/${key}`);

export const apiValidateCart = (cart_token) => http.get(`cart/validate/${cart_token}`);
