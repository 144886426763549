import Vue from 'vue'

export const User = () => Vue.prototype.$userStatus() && Vue.prototype.$loginType() !== 'a' && Vue.prototype.$loginType() !== 'r'

export const Agent = () => Vue.prototype.$userStatus() && Vue.prototype.$loginType() === 'a'

export const UserOrAgency = () => Vue.prototype.$userStatus() && Vue.prototype.$loginType() !== 'r'

export const Reservation = () => Vue.prototype.$userStatus() && Vue.prototype.$loginType() === 'r'

export const UserOrReservation = () => Vue.prototype.$userStatus() && Vue.prototype.$loginType() !== 'a'

export const AnyLogged = () => Vue.prototype.$userStatus()

// export const Guest = () => !Auth()

export const Role = (array, val = null) => Vue.prototype.$isRole(array, val)

export const AfterReservationPlaced = () => Vue.prototype.$userStatus() && Vue.prototype.$lastReservationKeys()

export const ToursEnabled = () => Vue.prototype.$toursEnabled()
