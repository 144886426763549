import CartTourItemUpdateButton from '@/components/cart/buttons/CartTourItemUpdateButton.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex';

export default {
  components: {
    CartTourItemUpdateButton
  },

  props: {
    cartItem: Object,
    amount: Number,
    index: Number,
    modifyMode: Boolean,
    currentItem: Boolean,
    addTourUrl: String,
    onEditItem: Function
  },
  data: () => ({
    room: {},
    availabilityData: { date: new Date(), nights: 1 },
    roomAvailability: {},
  }),
  /*async created() {
  },*/
  computed: {
    ...mapGetters({
      tourCartByDate: 'property/tourCartByDate',
      toursAvailability: 'property/toursAvailability',
    }),
    totalToursOnThisDate() {
      return _.size(this.tourCartByDate[this.cartItem.date]);
    },
    tours() {
      return this.$store.state.property.tours;
    },
    tour() {
      return this.tours[this.cartItem.tourCode]
    },
    selectedOptionCode() {
      return _.chain(this.tour)
        .get(`options`)
        .findKey(i => _.find(i.pickup, (v, k) => k === this.cartItem.optionCode))
        .value()
    },
    selectedOption() {
      return _.get(this.tour, `options.${this.selectedOptionCode}`)
    },
    selectedOptionPickup() {
      return _.get(this.selectedOption, `pickup.${this.cartItem.optionCode}`)
    },
    optionCode() {
      return this.cartItem.optionCode;
    },
    tourTitle() {
      return this.tour.title;
    },
    optionTitle() {
      return this.tour.options[this.optionCode];
    },
    date() {
      return this.$dayjs(this.cartItem.date, "MM/DD/YYYY").toDate();
    },
    rateCode() {
      return this.cartItem.rateCode;
    },
    total() {
      return parseFloat(this.cartItem.total);
    },
    subtotal() {
      return parseFloat(this.cartItem.total) + parseFloat(this.cartItem.tax);
    },
    getImageUrl() {
      if (
        typeof this.tour.images !== "undefined" &&
        this.tour.images.length > 0
      ) {
        return this.getCdnResourceUrl(this.tour.images[0].image);
      }

      return "";
    },
    itemTitle() {
      return "Activity";
    },
    tax() {
      return this.cartItem.tax > 0 ? this.$formatCurrency(this.cartItem.tax) + " USD" : " + tax";
    },
    guestsLabel() {
      const items = [];

      if (this.tour.anyage) {
        const guestsLabel = this.cartItem.adults > 1 ? "Guests" : "Guest";
        items.push(`${this.cartItem.adults} ${guestsLabel}`);
      } else {
        const adultsLabel = this.cartItem.adults > 1 ? "Adults" : "Adult";
        items.push(`${this.cartItem.adults} ${adultsLabel}`);
      }

      if (this.cartItem.children) {
        const childrenLabel = this.cartItem.children > 1 ? "Children" : "Child";
        items.push(`${this.cartItem.children} ${childrenLabel}`);
      }

      if (this.cartItem.infants) {
        const infantLabel = this.cartItem.infants > 1 ? "Infants" : "Infant";
        items.push(`${this.cartItem.infants} ${infantLabel}`);
      }

      if (this.cartItem.animals) {
        const animalLabel = this.cartItem.animals > 1 ? "Animals" : "Animal";
        items.push(`${this.cartItem.animals} ${animalLabel}`);
      }

      return items.join(', ');
    },

    dateAvailability() {
      return _.get(this.toursAvailability, this.cartItem.date)
    },

    tourAvailability() {
      return _.get(this.dateAvailability, this.tour.code)
    },
  },
  methods: {
    editItem() {
      if (typeof this.onEditItem === "function") {
        this.onEditItem(this.cartItem);
      }
    }
  },
};
