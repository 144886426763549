<template>
  <section class="profile__section">
    <h2 class="profile__section-title">BILLING ADDRESS</h2>
    <form>
      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-first-name"> First Name </label>
          <a-input
            placeholder="First Name"
            id="profile-first-name"
            class="input input--auth"
            name="firstName"
            :class="{ 'input--error': v$.firstName.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.firstName.$model"
            :maxLength="30"
          />
          <div
            class="form__item-error"
            v-if="v$.firstName.required?.$invalid && v$.firstName.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.firstName.name?.$invalid && v$.firstName.$error"
          >
            Please enter 2-30 chars. Letters, digits and .- &#;_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.firstName.notCreditCard?.$invalid && v$.firstName.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.firstName">
            <li v-for="err in errors.firstName" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-last-name"> Last Name </label>
          <a-input
            placeholder="Last Name"
            id="profile-last-name"
            class="input input--auth"
            name="lastName"
            :class="{ 'input--error': v$.lastName.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.lastName.$model"
            :maxLength="30"
          />
          <div
            class="form__item-error"
            v-if="v$.lastName.required?.$invalid && v$.lastName.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.lastName.name?.$invalid && v$.lastName.$error"
          >
            Please enter 2-30 chars. Letters, digits and .- &#;_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.lastName.notCreditCard?.$invalid && v$.lastName.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.lastName">
            <li v-for="err in errors.lastName" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
      </div>

      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-address1"> Street Address </label>
          <a-input
            placeholder="Street Address"
            id="profile-address1"
            class="input input--auth"
            name="address1"
            :class="{ 'input--error': v$.address1.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.address1.$model"
            :maxLength="40"
          />
          <div
            class="form__item-error"
            v-if="v$.address1.required?.$invalid && v$.address1.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.address1.address?.$invalid && v$.address1.$error"
          >
            Please enter 2-40 chars. Letters, digits and '- &:#;?,-.|_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.address1.notCreditCard?.$invalid && v$.address1.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.address1">
            <li v-for="err in errors.address1" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-address2">
            Apartment, Suite, Unit or Building (optional)
          </label>
          <a-input
            placeholder="Apartment, Suite, Unit or Building"
            id="profile-address2"
            class="input input--auth"
            name="address2"
            :class="{ 'input--error': v$.address2.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.address2.$model"
            :maxLength="40"
          />
          <div
              class="form__item-error"
              v-if="v$.address2.address?.$invalid && v$.address2.$error"
          >
            Please enter 2-40 chars. Letters, digits and '- &:#;?,-.|_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.address2.notCreditCard?.$invalid && v$.address2.$error">
            Value can't be credit card number
          </div>
        </div>
      </div>

      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-zip"> Zip / Postal Code </label>
          <a-input
            placeholder="Zip / Postal Code"
            id="profile-zip"
            class="input input--auth"
            name="zip"
            :class="{ 'input--error': v$.zip.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.zip.$model"
            :maxLength="15"
          />
          <div
            class="form__item-error"
            v-if="v$.zip.required?.$invalid && v$.zip.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.zip.zip?.$invalid && v$.zip.$error"
          >
            Please enter 2-15 chars. Letters, digits and - are only allowed
          </div>
          <div class="form__item-error" v-if="v$.zip.notCreditCard?.$invalid && v$.zip.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.zip">
            <li v-for="err in errors.zip" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-city"> City / Town </label>
          <a-input
            placeholder="City / Town"
            id="profile-city"
            class="input input--auth"
            name="city"
            :class="{ 'input--error': v$.city.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.city.$model"
            :maxLength="40"
          />
          <div
            class="form__item-error"
            v-if="v$.city.required?.$invalid && v$.city.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.city.general?.$invalid && v$.city.$error"
          >
            Letters, digits and '- &:#;?,-.|_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.city.notCreditCard?.$invalid && v$.city.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.city">
            <li v-for="err in errors.city" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
      </div>

      <div class="form__item-group">
        <div class="form__item form__item-row">
          <label for="profile-country"> Country </label>
          <a-select
            show-search
            :filter-option="filterOption"
            id="profile-country"
            placeholder="Country"
            name="country"
            class="input__select input--auth"
            :class="{ 'input--error': v$.country.$error }"
            dropdownClassName="input__select-options"
            @change="handleCountryOptionSelect"
            @focus="($event) => {
              resetFormFieldErrors($event)
            }"
            v-model.trim="v$.country.$model || country"
          >
            <a-select-option
              v-for="(value, key) in countryList"
              :value="key"
              :key="key"
            >
              {{ value }}
            </a-select-option>
          </a-select>

          <div
            class="form__item-error"
            v-if="v$.country.required?.$invalid && v$.country.$error"
          >
            Field is required
          </div>
          <ul class="form__item-error" v-if="errors.country">
            <li v-for="err in errors.country" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
        <div class="form__item form__item-row">
          <label for="profile-state"> State / Province </label>

          <a-select
            show-search
            :filter-option="filterOption"
            id="profile-state"
            placeholder="State / Province"
            class="input__select input--auth"
            dropdownClassName="input__select-options"
            @change="handleStateOptionSelect"
            name="state"
            :class="{ 'input--error': v$.state.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.state.$model"
            v-if="stateList"
          >
            <a-select-option
              v-for="(value, key) in stateList"
              :value="key"
              :key="key"
            >
              {{ value }}
            </a-select-option>
          </a-select>

          <a-input
            placeholder="State / Province"
            id="profile-state"
            class="input input--auth"
            name="state"
            :class="{ 'input--error': v$.state.$error }"
            @focus="resetFormFieldErrors"
            v-model.trim="v$.state.$model"
            :maxLength="40"
            v-else

          />
          <div
            class="form__item-error"
            v-if="v$.state.required?.$invalid && v$.state.$error"
          >
            Field is required
          </div>
          <div
              class="form__item-error"
              v-if="v$.state.general?.$invalid && v$.state.$error"
          >
            Letters, digits and '- &:#;?,-.|_ chars are only allowed
          </div>
          <div class="form__item-error" v-if="v$.state.notCreditCard?.$invalid && v$.state.$error">
            Value can't be credit card number
          </div>
          <ul class="form__item-error" v-if="errors.state">
            <li v-for="err in errors.state" :key="err">
              {{ err }}
            </li>
          </ul>
        </div>
      </div>
    </form>
    <div class="profile__buttons">
      <a-button class="button" :disabled="loading" @click="setFormValues">
        Discard all changes
      </a-button>
      <a-button class="button button-primary" @click="updateUserAddress">
        Save changes
      </a-button>
    </div>
  </section>
</template>

<script>
import { required } from '@vuelidate/validators'
import { apiUpdateProfile } from '@/api/profile'
import {
  zip,
  general,
  name,
  address,
  notCreditCard
} from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    firstName: '',
    lastName: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    address1: '',
    address2: '',
    loading: false,
    errors: {}
  }),
  props: ['form'],
  validations: () => {
    return {
      firstName: { required, name, notCreditCard },
      lastName: { required, name, notCreditCard },
      city: { required, general, notCreditCard },
      country: { required },
      state: { required, general, notCreditCard },
      zip: { required, zip, notCreditCard },
      address1: { required, address, notCreditCard },
      address2: { address, notCreditCard },
    }
  },
  computed: {
    countryList() { return this.$store.state.app.options.countries },
    stateList() {
      return this.$store.state.app.options.states[this.country]
    }
  },
  watch: {
    form: {
      immediate: true,
      handler() {
        this.setFormValues()
      }
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCountryOptionSelect(value) {
      this.country = value;
      this.state = null
    },
    handleStateOptionSelect(value) {
      this.state = value
    },
    setFormValues() {
      this.firstName = this.form.firstName.replace('amp;', '');
      this.lastName = this.form.lastName.replace('amp;', '');
      this.address1 = this.form.address1.replace('amp;', '');
      this.address2 = this.form.address2.replace('amp;', '');
      this.city = this.form.city.replace('amp;', '');
      this.country = this.form.country || 'US';
      this.state = this.form.state;
      this.zip = this.form.zip;

    },
    async updateUserAddress() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        city: this.city,
        country: this.country,
        state: this.state,
        zip: this.zip,
        address1: this.address1,
        address2: this.address2,
      };
      try {
        this.loading = true;
        const res = await apiUpdateProfile['address'](data);
        if (res && res.status === 'SUCCESS') {
          this.$msg('Contacts data changes successfully!', 1);
          this.$store.dispatch('auth/getUserInfo')
        }
      } catch (error) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors };
        this.$store.dispatch('app/setErrors')
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>
