import { propertyMethods } from "@/store/helper";
import TruncatedText from '@/components/TruncatedText/index.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash';
import {getPrices} from '@/utils/taxes-and-fees'

export default {
  components: {
    TruncatedText,
  },
  props: {
    hotelWithAvailability: Object,
    searchParams: Object,
  },
  data: () => ({
    shouldViewMore: false,
    isViewingMore: false
  }),
  methods: {
    ...propertyMethods,
    openHotelPage(minRate) {
      let rateCode = this.searchParams.rateCode
      let rateType = this.searchParams.rateType

      if (minRate?.code && minRate?.category) {
        rateCode = minRate?.code
        rateType = minRate?.category === 'GROUP' ? 'group' : 'promo'
      }

      this.$router.push({
        name: "hotelPage",
        query: {
          dateFrom: this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY').format('MM-DD-YYYY'),
          nights: this.searchParams.nights,
          destination: this.hotel.code,
          adults: this.searchParams.adults,
          children: this.searchParams.children,
          rateCode,
          rateType
        },
      });
    },
    onViewCalendar(data) {
      console.log("onViewCalendar", data)
      this.setModal("HotelRateCalendar", data);
    },
    onViewMore() {
      this.isViewingMore = true;
    },
    onViewLess() {
      this.isViewingMore = false;
    },
    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore =  this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore = this.$mq === 'sm' && this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    onCheapHotel() {
      this.openHotelPage(this.minRate)
    }
  },
  computed: {
    ...mapGetters({
      ratePlans: 'property/hotelsRatePlans',
      options: 'app/options',
    }),

    guests() {
      return this.searchParams.adults + this.searchParams.children;
    },

    hotel() {
      return this.hotelWithAvailability.hotel;
    },
    availability() {
      return this.hotelWithAvailability.availability;
    },
    available() {
      return this.hotelWithAvailability.available;
    },
    availabilityStatus() {
      return this.hotelWithAvailability.availabilityStatus;
    },
    prices() {
      return this.hotelWithAvailability.prices;
    },
    oldPrices() {
      return this.hotelWithAvailability.oldPrices;
    },

    availabilityPerGuests() {
      return this.availability[this.guests];
    },

    availabilityStatusOptions() {
      return {
        available: 'available',
        closed: 'Closed',
        soldOut: 'Sold out',
        occupancyExceeded: 'Max occupacy exceeded',
      }
    },

    availabilityMessage() {
      if (['closed', 'soldOut', 'occupancyExceeded'].includes(this.availabilityStatus)) {
        return this.availabilityStatusOptions[this.availabilityStatus];
      } else if (this.availabilityPerGuests?.a === 1) {
        return 'Only 1 room left';
      } else if (this.availabilityPerGuests?.a && this.availabilityPerGuests?.a < this.hotel.lowAvailability) {
        return `Only ${this.availabilityPerGuests.a} rooms left`;
      }
    },

    availableRates() {
      return _.filter(this.availability.rates, (item) => item.available[this.guests] && item.mins[this.guests]?.p > 0)
    },

    minRate() {
      return _.minBy(this.availableRates, (item) => {
        const prices = getPrices({
          base: item.mins[this.guests].p,
          fees: item.mins[this.guests].f,
          taxes: item.mins[this.guests].t,
          nights: this.searchParams.nights,
        })

        return prices.avgPrice.subTotal;
      })
    },

    minRatePrices() {
      if (this.minRate) {
        return getPrices({
          base: this.minRate.mins[this.guests].p,
          fees: this.minRate.mins[this.guests].f,
          taxes: this.minRate.mins[this.guests].t,
          nights: this.searchParams.nights,
        })
      }
    },

    // OLD
    isShowHelped () {
      if (this.minRatePrices) {
        return this.minRatePrices.avgPrice.subTotal < this.prices.avgPrice.subTotal
      }
      return false;
    },
    rateCode() {
      return this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : "";
    },
    ratePlan() {
      return this.ratePlans[this.rateCode]
    },
    // occupancyExceeded() {
    //   return this.availabilityData.occupancy_exceed;
    // },
    // minPrice() {
    //   return this.availabilityData.min_composed;
    // },
    // maxPrice() {
    //   return this.availabilityData.max;
    // },
    // available() {
    //   return this.availabilityData.available_composed
    //   // REMOVING THIS SPAGHETTI AND MOVING AVAILABILITY LOGIC TO ONE PLACE.
    //   // available_composed IS THE FIELD WHICH RESPONSIBLE FOR SOLD OUT STATUS.
    //   // const rateCode = !!this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : 'INTERNET';
    //   // return this.availabilityData.available_composed && Object.keys(this.availabilityData.rates).find(rate => rate === rateCode);
    // },
    // availableAmount() {
    //   return parseInt(this.availabilityData.available_amount_composed);
    // },
    // hotelAvailability() {
    //   return (
    //     !!this.$store.state.property.availableHotels &&
    //     this.$store.state.property.availableHotels[
    //       this.$dayjs(this.dateString2Date(this.$route.query.dateFrom)).format(
    //         "MM/DD/YYYY"
    //       )
    //     ][this.data.code]
    //   );
    // },
    // availMessage() {
    //   //console.log("available", this.data.code, this.available);
    //   let value = ""
    //   const rateCode = !!this.$route.query.rateCode ? this.$route.query.rateCode.toUpperCase() : 'INTERNET'

    //   if (this.occupancyExceeded) {
    //     value = "MAX OCCUPANCY EXCEEDED";
    //   } else if (this.isShowHelped) {
    //     value = `${rateCode} NOT AVAILABLE`
    //   } else if (!this.available) {
    //     value = "SOLD OUT";
    //   } else if (this.availableAmount == 1) {
    //     value = "ONLY 1 ROOM LEFT"
    //   } else if (this.availableAmount <= this.data.lowAvailability) {
    //     value = `ONLY ${this.availableAmount} ROOMS LEFT`
    //   }

    //   return value
    // },
    added() {
      return this.$store.state.property && this.$store.state.property.addedRooms && !!this.$store.state.property.addedRooms[this.hotel.code];
    },
    dateFrom() {
      return this.$route.query.dateFrom ? this.dateString2Date(this.$route.query.dateFrom) : new Date();
    },
    cancelBeforeDate() {
      const settings = this.options.cancelBeforeHotels?.find(i => (
        this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(this.searchParams.dateFrom, 'MM-DD-YYYY')
        .subtract(value, 'day')
        .toDate();
      
      return cancelBeforeDate;
    },
    cancelationAvailable() {
      const today = new Date();
      return this.cancelBeforeDate && this.cancelBeforeDate > today;
    },
    cancelationMessage() {
      return this.cancelationAvailable ? (
        `free cancelation before ${this.$dayjs(this.cancelBeforeDate).format('ddd, MMM D')}`
      ) : 'cancelation fee applies';
    },
    getImageUrl() {
      if (typeof this.hotel.images !== 'undefined' && this.hotel.images.length > 0) {
        return this.getCdnResourceUrl(this.hotel.images[0].image)
      }

      return ""
    },
    getImageTitle() {
      if (typeof this.hotel.images !== 'undefined' && this.hotel.images.length > 0) {
        return this.hotel.images[0].title
      }

      return ""
    },
    // displayOldPrice() {
    //   return this.minPrice < this.maxPrice && this.minPrice !== this.maxPrice;
    // },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    }
  },
  mounted() {
    // window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      // this.onResize();
    });
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
  }
};
