import {
  MglMap,
  MglMarker,
  MglPopup,
  MglNavigationControl,
  MglFullscreenControl,
  MglScaleControl,
} from "vue-mapbox";
import Mapbox from "mapbox-gl";
import HotelCardPopup from "@/components/HotelCardPopup/index.vue";

export default {
  components: {
    MglMap,
    MglMarker,
    MglPopup,
    MglNavigationControl,
    MglFullscreenControl,
    MglScaleControl,
    HotelCardPopup,
  },
  props: {
    hotelsWithAvailability: Array,
    searchParams: Object,
  },
  data: function() {
    //
    return {
      initialized: false,
      markers: [],
      accessToken: import.meta.env.VITE_MAPBOX_API_KEY,
      mapStyle: "mapbox://styles/stemme/ck9ivy8p5089g1inws2hwo6ab",
      zoom: +this.$store.state.app.options.mapZoom || 15,
      center_long: 0,
      center_lat: 0,
      status: false
    };
  },
  created() {
    this.mapbox = Mapbox;
    this.$eventHub.$on("hotel-map-show", this.onShow);
    this.$eventHub.$on("hotel-map-toggle",(viewOnMapStatus) => {
      this.status = viewOnMapStatus
      return this.mapApiPresent
    })
  },
  computed: {
    mapApiPresent: function() {
      console.log('status=======>', this.status);
      return import.meta.env.VITE_MAPBOX_API_KEY && this.status ? true : false;
    },
    center() {
      if (this.hotelsWithAvailability.length === 0) {
        return { lon: this.center_long, lat: this.center_lat };
      }

      let min_long = false;
      let max_long = false;
      let min_lat = false;
      let max_lat = false;
      let center_long = 0;
      let center_lat = 0;

      this.hotelsWithAvailability.forEach(function(item) {
        const long = parseFloat(item.hotel.long);
        const lat = parseFloat(item.hotel.lat);
        if (long && lat) {
          min_long = min_long === false || min_long > long ? long : min_long;
          max_long = max_long === false || max_long < long ? long : max_long;
          min_lat = min_lat === false || min_lat > lat ? lat : min_lat;
          max_lat = max_lat === false || max_lat < lat ? lat : max_lat;
        }
      });

      if (min_long !== false) {
        center_long = (min_long + max_long) / 2;
        center_lat = (min_lat + max_lat) / 2;
      }

      this.center_long = center_long;
      this.center_lat = center_lat;

      return { lon: center_long, lat: center_lat};
    },
    bounds() {
      let min_long = false;
      let max_long = false;
      let min_lat = false;
      let max_lat = false;

      this.hotelsWithAvailability.forEach(function(item) {
        const long = parseFloat(item.hotel.long);
        const lat = parseFloat(item.hotel.lat);
        if (long && lat) {
          min_long = min_long === false || min_long > long ? long : min_long;
          max_long = max_long === false || max_long < long ? long : max_long;
          min_lat = min_lat === false || min_lat > lat ? lat : min_lat;
          max_lat = max_lat === false || max_lat < lat ? lat : max_lat;
        }
      });

      return [
        [min_long, min_lat],
        [max_long, max_lat],
      ];
    },
  },
  watch: {},
  methods: {
    getMap() {
      return this.$refs["map"] ? this.$refs["map"].map : false;
    },
    onShow() {
      if (!this.initialized) {
        this.init();
      }

      var map = this.getMap();

      if (!map) {
        return;
      }

      var that = this;

      // map obtains incorrect canvas position. force resize in order to redraw after expanded
      window.setTimeout(function() {
        map.resize();
        that.$refs["map"].actions.fitBounds(that.bounds, {
          padding: 100,
          linear: true,
        });
      }, 100);
    },
    init() {
      this.initialized = true;
    },
    onMapLoad() {
      // map obtains incorrect canvas position. force resize in order to redraw after the map loaded
      this.getMap().resize();
    },
  },
};
