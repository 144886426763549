<template>
  <div class="wrapper">
    <b-row
      v-for="(alert) in item.alerts"
      :key="alert.id"
    >
      <b-col
        v-if="alert.image"
        class="mb-3"
        sm="4"
      >
        <b-img
          class="d-block w-100 object-fit-cover"
          :src="alert.image"
          :alt="alert.title"
        />
      </b-col>
  
      <b-col>
        <div
          class="text mb-5 typography"
          v-html="alert.title"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'HotelItemAlerts',

  props: {
    item: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>