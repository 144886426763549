import {apiReservation, apiDeleteReservation} from "@/api/property";
import { optionsComputed } from "@/store/helper"
import ReservationItemCancelButton from '@/components/reservations/buttons/ReservationItemCancelButton.vue'

export default {
  components: {
    ReservationItemCancelButton
  },

  props: {
    data: Object,
    reservationHistory: Boolean,
  },
  data: () => ({
    reason: 'not_selected',
    cancelReservationModal: false,
    confirmLoading: false,
    owsCancels: {},
    cancelName: '',
    reservationDetails: {},
    iconLoading: false,
    errorModal: false,
    cancelFormClicked: false,
    isWarning: false,
}),
   computed: {
    ...optionsComputed,
    select_reason_error() {
        return this.cancelFormClicked && this.reason === 'not_selected'
    },
    imageUrl() {
        if (typeof this.data.images !== "undefined" && this.data.images.length > 0) {
            return this.getCdnResourceUrl(typeof this.data.images[0].image !== "undefined" ? this.data.images[0].image : this.data.images[0]);
        }
    },
    getStatus() {
        return this.data.status !== "CANCELLED" || this.data.modifyAllowed
    } 
  },
  methods: {
    async openCancelReservationModal() {
        if (!this.data.modifyAllowed) {
            this.isWarning = true
        } 
            this.iconLoading = true;
            this.owsCancels = this.$store.state.app.options.owsCancels;
            this.owsCancels['not_selected'] = 'Please select cancelation reason'
            const res = await apiReservation(this.data.token);
            this.reservationDetails = res;
            this.cancelFormClicked = false;
            this.cancelReservationModal = true;
            this.iconLoading = false;
    },
    async handleCancel() {
        this.cancelFormClicked = true;
        // if (this.isWarning) {
        //     // this.isSecondWarning = true
        //     this.isWarning = false
        // }
        if (this.reason === 'not_selected' && !this.isWarning) {
            return
        }

        this.confirmLoading = true;
            try {
                await apiDeleteReservation(this.data.token, this.reason)
                this.$emit("onChild", this.data.token)
            } catch (err) {
                console.error(err);
            }

            this.cancelReservationModal = false;
            this.confirmLoading = false;

            // await new Promise(() => {
            //     this.$emit('cancelReservation', {token: this.data.token, reason: this.reason})
            // })
                // .then(() => {
                //     this.cancelReservationModal = false;
                //     this.confirmLoading = false;
                // })
                // .catch(e => {
                //     console.error(e)
                // })
        },
        handleChange(value) {
            this.reason = value;
            console.log(`selected ${value}`);
        },
    }
}
