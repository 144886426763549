import { mapGetters, mapActions } from 'vuex'
import ContactsForm from './Contacts/index.vue'
import AddressForm from './Address/index.vue'
import AgencyInfo from '@/pages/Profile/AgencyInfo/index.vue'
import { authComputed } from "@/store/helper";

export default {
  meta: {
    title: 'Home Page'
  },
  components: {
    ContactsForm,
    AddressForm,
    AgencyInfo
  },
  data: () => ({
    form: {},
    unwatch: null
  }),
  computed: {
    ...mapGetters({ userInfo: 'auth/userInfo' }),
    ...authComputed
  },
  methods: {
    ...mapActions({ update: 'auth/updateUserInfo' }),
    discardAllChanges() {
      this.form = { ...this.userInfo }
    },
    updateUserAddress() {
      const payload = {
        ...this.form
      }
      this.update({ type: 'address', payload })
    },
    updateUserContacts() {
      const payload = {
        phone: this.form.phone,
        email: this.form.email
      }
      this.update({ type: 'communication', payload })
    }
  },
  created() {
    this.form = { ...this.userInfo };
    this.unwatch = this.$store.watch((state, getters) => getters['auth/userInfo'], () => this.form = { ...this.userInfo })
  },
  beforeDestroy() {
    typeof this.unwatch === 'function' && this.unwatch()
  }
}
