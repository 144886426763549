export const ShoppingCartIcon = () =>
  `<svg height="15" viewBox="0 -31 15.00033 15" width="15" fill = "white" xmlns="http://www.w3.org/2000/svg">
  <path d="m166 300.003906h271.003906c6.710938 0 12.597656-4.4375 14.414063-10.882812l60.003906-210.003906c1.289063-4.527344.40625-9.390626-2.433594-13.152344-2.84375-3.75-7.265625-5.964844-11.984375-5.964844h-365.632812l-10.722656-48.25c-1.523438-6.871094-7.617188-11.75-14.648438-11.75h-91c-8.289062 0-15 6.710938-15 15 0 8.292969 6.710938 15 15 15h78.960938l54.167968 243.75c-15.9375 6.929688-27.128906 22.792969-27.128906 41.253906 0 24.8125 20.1875 45 45 45h271.003906c8.292969 0 15-6.707031 15-15 0-8.289062-6.707031-15-15-15h-271.003906c-8.261719 0-15-6.722656-15-15s6.738281-15 15-15zm0 0" /><path d="m151 405.003906c0 24.816406 20.1875 45 45.003906 45 24.8125 0 45-20.183594 45-45 0-24.8125-20.1875-45-45-45-24.816406 0-45.003906 20.1875-45.003906 45zm0 0" /><path d="m362.003906 405.003906c0 24.816406 20.1875 45 45 45 24.816406 0 45-20.183594 45-45 0-24.8125-20.183594-45-45-45-24.8125 0-45 20.1875-45 45zm0 0" />
  </svg>`;

export const CopyIcon = () =>
  `<?xml version="1.0" ?><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="13" rx="2" ry="2" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="13" x="9" y="9"/><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>`;

export const FilterIcon = () => `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12">
<g id="Сгруппировать_625" data-name="Сгруппировать 625" transform="translate(-1373.5 -249)">
  <line id="Линия_7" data-name="Линия 7" x2="17" transform="translate(1373.5 252.5)" fill="none" stroke="#505050" stroke-width="2"/>
  <line id="Линия_8" data-name="Линия 8" x2="17" transform="translate(1373.5 258.5)" fill="none" stroke="#505050" stroke-width="2"/>
  <circle id="Эллипс_2" data-name="Эллипс 2" cx="3" cy="3" r="3" transform="translate(1382 249)" fill="#505050"/>
  <circle id="Эллипс_3" data-name="Эллипс 3" cx="3" cy="3" r="3" transform="translate(1376 255)" fill="#505050"/>
</g>
</svg>`;

export const MapIcon = () =>
  `<svg xmlns="http://www.w3.org/2000/svg" width="10.863" height="15.518" viewBox="0 0 10.863 15.518">
  <path id="ic_place_24px" d="M10.431,2A5.427,5.427,0,0,0,5,7.431c0,4.074,5.431,10.087,5.431,10.087S15.863,11.5,15.863,7.431A5.427,5.427,0,0,0,10.431,2Zm0,7.371a1.94,1.94,0,1,1,1.94-1.94A1.94,1.94,0,0,1,10.431,9.371Z" transform="translate(-5 -2)" fill="#505050"/>
</svg>`;

export const CalendarIcon = () =>
`<svg xmlns="http://www.w3.org/2000/svg" width="13.574" height="15.082" viewBox="0 0 13.574 15.082">
  <g id="Group_1183" data-name="Group 1183" transform="translate(0)">
    <path id="ic_event_available_24px" d="M15.066,2.508h-.754V1H12.8V2.508H6.771V1H5.262V2.508H4.508a1.5,1.5,0,0,0-1.5,1.508L3,14.574a1.508,1.508,0,0,0,1.508,1.508H15.066a1.513,1.513,0,0,0,1.508-1.508V4.016A1.513,1.513,0,0,0,15.066,2.508Zm0,12.066H4.508v-8.3H15.066Z" transform="translate(-3 -1)"/>
    <path id="ic_grid_on_24px" d="M14.217,2H3.357A1.259,1.259,0,0,0,2,3.119v8.955a1.259,1.259,0,0,0,1.357,1.119H14.217a1.259,1.259,0,0,0,1.357-1.119V3.119A1.259,1.259,0,0,0,14.217,2ZM6.072,12.074H3.357V9.835H6.072Zm0-3.358H3.357V6.477H6.072Zm0-3.358H3.357V3.119H6.072Zm4.072,6.716H7.43V9.835h2.715Zm0-3.358H7.43V6.477h2.715Zm0-3.358H7.43V3.119h2.715Zm4.072,6.716H11.5V9.835h2.715Zm0-3.358H11.5V6.477h2.715Zm0-3.358H11.5V3.119h2.715Z" transform="translate(-2 1.889)"/>
  </g>
</svg>`;

export const CartIcon = () =>
`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path id="ic_shopping_cart_24px" d="M7,18a2,2,0,1,0,2,2A2,2,0,0,0,7,18ZM1,2V4H3l3.6,7.59L5.25,14.04A1.933,1.933,0,0,0,5,15a2.006,2.006,0,0,0,2,2H19V15H7.42a.248.248,0,0,1-.25-.25l.03-.12L8.1,13h7.45a1.991,1.991,0,0,0,1.75-1.03l3.58-6.49A.977.977,0,0,0,21,5a1,1,0,0,0-1-1H5.21L4.27,2H1ZM17,18a2,2,0,1,0,2,2A2,2,0,0,0,17,18Z" transform="translate(-1 -2)" fill="#fff"/>
</svg>`;
