import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  props: {
    reservation: Object,
    item: Object,
    index: Number,
    isConfirmationPage: Boolean
  },

  data: () => ({
    tours: {},
    lodgingAmount: null,
    isAddNewLoading: false
  }),

  created() {
    this.tours = this.$store.state.property.tours;
    this.lodgingAmount = this.$get(this.reservation, 'sections.Lodging.items.length') || null;
  },

  computed: {
    ...mapGetters({
      cartIsEmpty: 'property/cartIsEmpty'
    }),

    totalLodgingAmount() {
      return _.get(this.reservation.sections.Lodging, 'items', []).length
    },

    totalToursAmount() {
      return _.get(this.reservation.sections.Tours, 'items', []).length
    },

    totalItemsAmount() {
      return this.totalLodgingAmount + this.totalToursAmount
    },

    isHistory() {
      return this.$dayjs().startOf('day') > this.$dayjs(this.reservation.arrival)
    },

    totalItemsAmoun() {
      return this.lodgingAmount + this.reservation.sections.Tours.items.length
    },

    tour() {
      return this.tours[this.item.tourCode];
    },

    tourImage() {
      return this.tour.images[0]?.image
    },

    tourImageTitle() {
      return this.tour.images[0]?.title
    },

    selectedOptionCode() {
      return _.chain(this.tour)
        .get(`options`)
        .findKey(i => _.find(i.pickup, (v, k) => k === this.item.optionCode))
        .value()
    },

    selectedOption() {
      return _.get(this.tour, `options.${this.selectedOptionCode}`)
    },

    selectedOptionPickup() {
      return _.get(this.selectedOption, `pickup.${this.item.optionCode}`)
    },

    guestsLabel() {
      const items = [];

      if (this.tour.anyage) {
        const guestsLabel = this.item.adults > 1 ? "Guests" : "Guest";
        items.push(`${this.item.adults} ${guestsLabel}`);
      } else {
        const adultsLabel = this.item.adults > 1 ? "Adults" : "Adult";
        items.push(`${this.item.adults} ${adultsLabel}`);
      }

      if (this.item.children) {
        const childrenLabel = this.item.children > 1 ? "Children" : "Child";
        items.push(`${this.item.children} ${childrenLabel}`);
      }

      if (this.item.infants) {
        const infantLabel = this.item.infants > 1 ? "Infants" : "Infant";
        items.push(`${this.item.infants} ${infantLabel}`);
      }

      if (this.item.animals) {
        const animalLabel = this.item.animals > 1 ? "Animals" : "Animal";
        items.push(`${this.item.animals} ${animalLabel}`);
      }

      return items.join(', ');
    }
  },

  methods: {
    async addActivityToReservation(isConfirmedClearCart) {
      if (!this.cartIsEmpty && !isConfirmedClearCart) {
        // @TODO move all options to bootstrap settings
        this.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and start editing your reservation?', {
          title: 'Confirm',
          headerBgVariant: 'secondary',
          headerClass: 'close-button-black',
          titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
          contentClass: 'bg-gamma',
          bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
          footerClass: 'border-0',
          okTitle: 'Yes',
          okVariant: 'primary',
          hideHeaderClose: false,
          cancelVariant: 'outline-primary',
          buttonSize: 'lg',
          centered: true
        })
          .then(async (value) => {
            if (value) {
              this.addActivityToReservation(true)
            }
          })

      } else {
        this.isAddNewLoading = true
        await this.$store.dispatch('app/startAddToReservation', this.reservation)
        this.isAddNewLoading = false

        const query = {
          date: this.$dayjs(this.item.date).format('MM-DD-YYYY'),
          adults: this.item.adults,
          children: this.item.children,
          infants: this.item.infants,
          animals: this.item.animals,
          destination: 'ALL'
        }

        this.$router.push({ name: 'tours', query })
      }
    }
  }
}
