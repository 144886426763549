import { mapGetters } from 'vuex';
import { authComputed, propertyMethods, propertyComputed } from "@/store/helper";
import ReturnToCart from "@/components/ReturnToCart/index.vue";
import { useWindowSize } from 'vue-window-size';

export default {
  setup: () => ({
    windowSize: useWindowSize(),
  }),
  components: { ReturnToCart },
  meta: {
    title: "Home Page",
  },
  data: () => ({}),
  computed: {
    ...authComputed,
    ...propertyComputed,
    windowWidth() {
      return this.windowSize.width.value;
    },
    isConfirmationPage() {
      return this.$route.name === "ReservationReview";
    },
  },
  methods: {
    ...propertyMethods,
    handleLogout() {
      this.$auth.logout();
      location.reload();
    },
  },
  created() {},
};
