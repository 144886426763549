import { optionsComputed } from "@/store/helper";
import { mapGetters } from "vuex";
import pageBackground from "@/assets/book-activity.png";
import AddToReservationModal from '@/components/reservations/AddToReservationModal/index.vue'
import bookingMixin from '@/mixin/booking'

export default {
  mixins: [
    bookingMixin
  ],
  meta: {
    title: "Book a stay",
  },

  components: {
    AddToReservationModal
  },

  data: () => ({
    searchParams: {
      dateFrom: null,
      nights: 1,
      destination: "ALL",
      adults: 1,
      children: 0,
      infants: 0,
      animals: 0,
      rateCode: "",
      minDate: null,
      maxDate: null,
      exactDate: true,
    },
    dateLabel: "Activity Date",
    confirmLoading: false
  }),
  computed: {
    ...optionsComputed,
    ...mapGetters({
      addToReservation: 'app/addToReservation',
      isModifyReservationMode: 'app/isModifyReservationMode',
      cartIsEmpty: 'property/cartIsEmpty',
      toursInfantsAllowed: 'property/toursInfantsAllowed',
      toursAnimalsAllowed: 'property/toursAnimalsAllowed',
    }),
    toursList() {
      return this.$store.getters["property/tours"];
    },
    promoCode() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return params.rateCode;
    },
    promoType() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return params.rateType;
    },
    tourCode() {
      let code = null;
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
        const tours = Object.values(this.toursList).flat();
        tours.forEach((item) => {
          if (item.code === params.destination) {
            code = item.code;
          }
        });
      }

      return code;
    },
    adults() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.adults);
    },
    children() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.children);
    },
    infants() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return this.toursInfantsAllowed ? parseInt(params.infants) : 0;
    },
    animals() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return this.toursAnimalsAllowed ? parseInt(params.animals) : 0;
    },
    startDate() {
      let value = null;
      let params;

      if (this.addToReservation) {
        return this.$dayjs(this.addToReservation.arrival).toDate()
      }

      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
        const today = new Date();
        if (params.dateFromString) {
          const savedDate = this.dateString2Date(params.dateFromString);
          //console.log("startDate", savedDate);

          if (savedDate >= today) {
            value = savedDate;
          }
        }
      } else {
        params = this.searchParams;
        value = params.dateFrom;
      }

      return value;
    },
    exactDate() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return !!params.exactDate;
    },
    nights() {
      let params;
      if (localStorage.getItem("toursSearchParams")) {
        params = JSON.parse(localStorage.getItem("toursSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.nights);
    },
    minDate() {
      return this.$dayjs(this.bookingDatesWindow.min).toDate()
    },
    maxDate() {
      return this.$dayjs(this.bookingDatesWindow.max).toDate()
    },
    pageBackground() {
      const stayItem = this.$store.state.app.options.images.find((item, i) => {
        if (item.key === "bg-book-activity") return true;
      });

      if (!!stayItem) {
        return this.getCdnResourceUrl(stayItem.image);
      }
      return pageBackground
      // return this.getAppCdnResourceUrl(pageBackground);
    },
    promoCodes() {
      return this.options.displaycodes;
    },
  },

  watch: {
    cartIsEmpty: {
      immediate: true,

      handler() {
        if (!this.cartIsEmpty) {
          // @TODO move all options to bootstrap settings
          this.$bvModal.msgBoxConfirm('Are you sure you want to empty your cart and choose another activty date?', {
            title: 'Confirm',
            headerBgVariant: 'secondary',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Yes',
            okVariant: 'primary',
            hideHeaderClose: true,
            noCloseOnBackdrop: true,
            cancelVariant: 'outline-primary',
            cancelTitle: 'No, return to cart',
            buttonSize: 'lg',
            centered: true
          })
            .then((value) => {
              if (value) {
                this.$store.dispatch('property/clearCart')

              } else {
                this.$router.push({ name: 'ReviewPage' })
              }
            })
        }
      }
    }
  },

  methods: {
    async onSubmit(data) {
      console.log("onSubmit", data);
      this.confirmLoading = true;

      localStorage.removeItem("toursSearchParams");
      localStorage.setItem("toursSearchParams", JSON.stringify(data));

      let query = {
        date: this.$dayjs(data.dateFrom).format("MM-DD-YYYY"),
        destination: data.destination,
        adults: data.adults,
        children: data.children,
        infants: data.infants,
        animals: data.animals,
      };
      let name;

      if (!data.exactDate) {
        name = "toursFlexible";
      } else if (data.destination === "ALL") {
        name = "tours";
      } else {
        name = "tourPage";
      }

      this.$router.push({
        name: name,
        query: query,
      });

      this.confirmLoading = false;
    },
  }
};
