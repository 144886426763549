import { authComputed } from "@/store/helper";
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...authComputed,

    ...mapGetters({
      options: 'app/options',
      cartIsGroupMode: 'property/cartIsGroupMode',
    }),

    termType() {
      return this.$store.state.app.modalProps.termType
    },

    terms() {
      return this.isAgency ? this.$store.state.app.options.termsTa : this.$store.state.app.options.terms;
    },

    tterms() {
      return this.isAgency ? this.$store.state.app.options.ttermsTa : this.$store.state.app.options.tterms;
    },

    gterms() {
      return this.isAgency ? this.$store.state.app.options.gtermsTa : this.$store.state.app.options.gterms;
    },

    activeTerm() {
      if (this.termType === 'tour') {
        return this.tterms;
      } else if (this.termType === 'group') {
        return this.gterms;
      }
      return this. terms;
    }
  },
};
