import _ from 'lodash'
import { mapGetters } from 'vuex'
// @TODO move computed from this mixin to store getters 
export default {
  computed: {
    ...mapGetters({
      options: 'app/options',
      modifyReservation: 'app/modifyReservation',
      addToReservation: 'app/addToReservation',
      hotelCart: 'property/hotelCart',
      tourCart: 'property/tourCart',
      toursMindate: 'property/toursMindate',
      hotelsMaxStayDate: 'property/hotelsMaxStayDate',
    }),

    earliestDateInCart() {
      const earliestTourTimestamp = _.chain(this.tourCart)
        .map(i => this.$dayjs(i.date).valueOf())
        .sort()
        .first()
        .value()

      const earliestHotelTimestamp = _.chain(this.hotelCart)
        .map(i => this.$dayjs(i.date).valueOf())
        .sort()
        .first()
        .value()

      const earliestDay = earliestTourTimestamp || earliestHotelTimestamp ? this.$dayjs.min([
        ...earliestTourTimestamp ? [this.$dayjs(earliestTourTimestamp)] : [],
        ...earliestHotelTimestamp ? [this.$dayjs(earliestHotelTimestamp)] : []
      ]) : null

      return earliestDay ? earliestDay.format('MM/DD/YYYY') : null
    },

    latestDateInCart() {
      const latestTourTimestamp = _.chain(this.tourCart)
        .map(i => this.$dayjs(i.date).valueOf())
        .sort()
        .last()
        .value()

      const latestHotelTimestamp = _.chain(this.hotelCart)
        .map(i => this.$dayjs(i.date).add(i.nights, 'day').valueOf())
        .sort()
        .last()
        .value()

      const latestDay = latestTourTimestamp || latestHotelTimestamp ? this.$dayjs.max([
        ...latestTourTimestamp ? [this.$dayjs(latestTourTimestamp)] : [],
        ...latestHotelTimestamp ? [this.$dayjs(latestHotelTimestamp)] : []
      ]) : null
      return latestDay ? latestDay.format('MM/DD/YYYY') : null
    },

    existedReservation() {
      return this.addToReservation || this.modifyReservation.reservation
    },

    existedReservationArrival() {
      const arrival = _.get(this.existedReservation, 'arrival')
      return arrival ? this.$dayjs(arrival).format('MM/DD/YYYY') : null
    },

    existedReservationDeparture() {
      const departure = _.get(this.existedReservation, 'departure')
      return departure ? this.$dayjs(departure).format('MM/DD/YYYY') : null
    },

    earliestBusyDate() {
      const earliestDay = this.earliestDateInCart || this.existedReservationArrival ? this.$dayjs.min([
        ...this.earliestDateInCart ? [this.$dayjs(this.earliestDateInCart)] : [],
        ...this.existedReservationArrival ? [this.$dayjs(this.existedReservationArrival)] : []
      ]) : null

      return earliestDay ? earliestDay.format('MM/DD/YYYY') : null
    },

    latestBusyDate() {
      const latestDay = this.latestDateInCart || this.existedReservationDeparture ? this.$dayjs.max([
        ...this.latestDateInCart ? [this.$dayjs(this.latestDateInCart)] : [],
        ...this.existedReservationDeparture ? [this.$dayjs(this.existedReservationDeparture)] : []
      ]) : null

      return latestDay ? latestDay.format('MM/DD/YYYY') : null
    },

    bookingDatesWindow() {
      let minDay = this.$dayjs.max([
        ...this.earliestBusyDate ? [this.$dayjs(this.earliestBusyDate).subtract(14, 'day')] : [],
        ...this.latestBusyDate ? [this.$dayjs(this.latestBusyDate).subtract(27, 'day')] : [],
        ...this.options.mindate ? [this.$dayjs(this.options.mindate)] : []
      ])

      // If earliestBusyDate less than minDay
      if (minDay && minDay > this.$dayjs(this.earliestBusyDate)) {
        minDay = this.$dayjs.min([this.$dayjs(this.earliestBusyDate), minDay])
      }

      let maxDay = this.$dayjs.min([
        ...this.latestBusyDate ? [this.$dayjs(this.latestBusyDate).add(14, 'day')] : [],
        ...this.earliestBusyDate ? [this.$dayjs(this.earliestBusyDate).add(27, 'day')] : [],
        ...this.options.maxdate ? [this.$dayjs(this.options.maxdate)] : []
      ])

      // If latestBusyDate more than maxDay
      if (maxDay && maxDay > this.$dayjs(this.latestBusyDate)) {
        maxDay = this.$dayjs.max([this.$dayjs(this.latestBusyDate), maxDay])
      }

      // Max stay
      let maxStayDay = this.$dayjs.min([
        ...this.latestBusyDate ? [this.$dayjs(this.latestBusyDate).add(14, 'day')] : [],
        ...this.earliestBusyDate ? [this.$dayjs(this.earliestBusyDate).add(27, 'day')] : [],
        ...this.hotelsMaxStayDate ? [this.$dayjs(this.hotelsMaxStayDate)] : []
      ])

      // If latestBusyDate more than maxStayDay
      if (maxStayDay && maxStayDay > this.$dayjs(this.latestBusyDate)) {
        maxStayDay = this.$dayjs.max([this.$dayjs(this.latestBusyDate), maxStayDay])
      }

      return {
        min: minDay ? minDay.format('MM/DD/YYYY') : null,
        max: maxDay ? maxDay.format('MM/DD/YYYY') : null,
        maxStay: maxStayDay ? maxStayDay.format('MM/DD/YYYY') : null
      }
    }
  }
}