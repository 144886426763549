<template>
  <b-dropdown
    v-bind="{ ...defaultDropdownProps, ...dropdownProps }"
  >
    <template #button-content>
      <slot>
        {{ valueLabel }}
      </slot>
    </template>

    <div
      v-for="option in options"
      :key="option.value"
    >
      <slot
        name="item"
        :option="option"
      >
        <b-dropdown-item-button
          :disabled="disabledValues.includes(option.value)"
          v-bind="{ ...defaultDropdownItemProps, ...dropdownItemProps }"
          @click="toggleItem(option.value)"
        >
          {{ option.text }}
        </b-dropdown-item-button>
      </slot>
    </div>
  </b-dropdown>
</template>
 
<script>
import _ from 'lodash'

export default {
  name: 'VBFormSelectDropdown',

  props: {
    value: {
      type: [Array, Number, String, Boolean],
      default: null
    },

    options: {
      type: Array,
      default: () => ([])
    },

    disabledValues: {
      type: Array,
      default: () => ([])
    },

    multiple: {
      type: Boolean,
      default: false
    },

    dropdownProps: {
      type: Object,
      default: null
    },

    dropdownItemProps: {
      type: Object,
      default: null
    },

    variantActive: {
      type: String,
      default: 'secondary'
    }
  },

  computed: {
    defaultDropdownProps() {
      return {
        block: true,
        menuClass: 'w-100',
        toggleClass: 'd-flex justify-content-between align-items-center',
        variant: 'outline-secondary'
      }
    },

    defaultDropdownItemProps() {
      return {
        buttonClass: 'p-2'
      }
    },

    selectedOptions() {
      const values = this.multiple ? this.value : (this.value ? [this.value] : [])
      return _.filter(this.options, i => values.includes(i.value))
    },

    valueLabel() {
      return _.chain(this.selectedOptions)
        .map('text')
        .join(',')
        .value()
    }
  },

  methods: {
    toggleItem(id) {
      let newValue

      if (this.multiple) {
        newValue = _.cloneDeep(this.value) || []

        if (newValue.includes(id)) {
          newValue.splice(newValue.indexOf(id), 1)
        } else {
          newValue.push(id)
        }

      } else {
        newValue = id !== this.value ? id : null
      }

      this.$emit('input', newValue)
    },

    isActive(id) {
      if (this.multiple) {
        return this.value && this.value.includes(id)

      } else {
        return this.value === id
      }
    }
  }
}
</script>