import _ from 'lodash'
import { mapGetters } from "vuex"
import { apiAvailabilityTours } from "@/api/availability"
import { optionsComputed } from "@/store/helper"
import { gtmTrack } from "@/lib/gtm"
import VDoubleScrollBar from '@/components/VDoubleScrollBar/index.vue'
import MonthCarousel from '@/components/MonthCarousel/index.vue'
import bookingMixin from '@/mixin/booking'
import {getPrice} from '@/utils/taxes-and-fees'

export default {
  mixins: [
    bookingMixin
  ],

  components: {
    VDoubleScrollBar,
    MonthCarousel,
  },

  data() {
    return {
      isFetching: false,
      priceCalendar: {},
      selectedTour: null
    }
  },

  computed: {
    ...optionsComputed,

    ...mapGetters({
      tours: 'property/tours',
      activitySearchParams: 'property/activitySearchParams',
      toursInfantsAllowed: 'property/toursInfantsAllowed',
      toursAnimalsAllowed: 'property/toursAnimalsAllowed',
      toursMaxAdults: 'property/toursMaxAdults',
      toursMaxChildren: 'property/toursMaxChildren',
    }),

    destination() {
      return this.tours[this.params.destination];
    },

    selectedDestination() {
      return this.params.destination ? this.tours[this.params.destination] : null;
    },

    availabilityStatusOptions() {
      return {
        closed: 'Closed',
        soldOut: 'Sold out',
        notEnoughTime: 'Not Available',
        childrenNotAllowed: 'Children not allowed',
        guestsExceededAvailability: 'Qty exceeded availability'
      }
    },

    params: {
      get() {
        return {
          destination: this.$route.query.destination,
          date: this.$route.query.date,
          adults: +this.$route.query.adults || 0,
          children: +this.$route.query.children || 0,
          infants: +this.$route.query.infants || 0,
          animals: +this.$route.query.animals || 0,
          rateCode: this.$route.query.rateCode
        }
      },

      set(value) {
        this.$router.push({ name: "toursFlexible", query: value})
      }
    },

    guests() {
      return this.params.adults + this.params.children + this.params.infants + this.params.animals;
    },

    allowedRange() {
      let start = this.$dayjs(this.bookingDatesWindow.min);

      if (this.toursMindate) {
        start = this.$dayjs.max(
          start,
          this.$dayjs(this.toursMindate, 'MM/DD/YYYY')
        ).format('MM/DD/YYYY')
      }

      const end = this.bookingDatesWindow.max

      return { start, end }
    },

    activeMonth: {
      get() {
        return this.$dayjs(this.params.date, 'MM-DD-YYYY').format("YYYY/MM")
      },

      set(value) {
        const date = this.$dayjs.max(
          this.$dayjs(value, 'YYYY/MM'),
          this.$dayjs(this.allowedRange.start)
        ).format('MM-DD-YYYY')

        this.params = { ...this.params, date }
      }
    },

    currentMonth() {
      return this.priceCalendar?.[this.activeMonth]
    },

    paramsIsValid() {
      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.infants ?? 0)) ||
        _.isNaN(+(this.$route.query.animals ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // date
      const date = this.$dayjs(this.params.date, 'MM-DD-YYYY')

      if (
        !date.isValid() ||
        this.params.date !== date.format('MM-DD-YYYY')
      ) {
        console.log(`Param date (${this.params.date}) invalid`)
        return false
      }

      if (!this.$dayjs(date).isBetween(this.allowedRange.start, this.allowedRange.end, 'day', '[]')) {
        console.log(`Param date (${this.params.date}) is not within allowedRange`)
        return false
      }

      // nights
      if (this.params.nights < 0 || this.params.nights > 14) {
        console.log(`Param nights (${this.params.nights}) invalid`)
        return false
      }

      // guests
      if (this.params.adults < 1 || this.params.adults > this.toursMaxAdults) {
        return false;
      }

      if (this.params.children < 0 || this.params.children > this.toursMaxChildren) {
        return false;
      }

      if (this.params.infants < 0 || this.params.infants > this.params.adults) {
        return false;
      }

      if (this.params.animals < 0 || this.params.animals > this.params.adults) {
        return false;
      }

      const guests = this.params.adults + this.params.children + this.params.infants + this.params.animals;
      if (guests > this.toursMaxAdults) {
        return false;
      }

      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.infants ?? 0)) ||
        _.isNaN(+(this.$route.query.animals ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // destination
      if (!this.destination && this.params.destination !== 'ALL') {
        console.log('Destination is not valid')
        return false;
      }

      return true
    },

    sortedTours(){
      const dayTourAvailability = this.currentMonth?.[this.$dayjs(this.params.date, 'MM-DD-YYYY').format('MM/DD/YYYY')];
      const sortedTourCodes = _.keys(dayTourAvailability || this.tours);
      const sortedTours = [];

      for (let i = 0; i < sortedTourCodes.length; i++) {
        const tourCode = sortedTourCodes[i];
        const tour = this.tours[tourCode];
        const isClosed = dayTourAvailability && !_.find(this.currentMonth, (i) => i[tourCode].availabilityStatus !== 'closed');

        if (tour && !isClosed) {
          sortedTours.push(tour);
        }
      }

      return sortedTours
    },
  },

  watch: {
    '$route.fullPath': {
      immediate: true,
      deep: true,

      async handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          await this.init()
        }
      }
    },

    paramsIsValid: {
      immediate: true,
      
      handler() {
        if (!this.paramsIsValid) {
          this.invalidationRedirect()
        } else {
          this.$store.dispatch('property/setActivitySearchParams', { type: 'flexible', ...this.params })
        }
      }
    }
  },

  methods: {
    async init() {
      if (!this.tours) {
        // if no tours - await for dispatch
        this.isFetching = true
        await this.$store.dispatch('property/fetchToursList')
        this.isFetching = false

      } else {
        this.$store.dispatch('property/fetchToursList')
      }

      await this.fetchAvailableTours(this.activeMonth, this.$dayjs(this.params.date, 'MM-DD-YYYY').date());
      this.scrollToSelectedDestination();
      this.trackData()
    },

    invalidationRedirect() {
      this.allowRouteLeave = true;

      if (!this.cartIsEmpty) {
        this.$router.push({ name: "ReviewPage" });
        return;
      } else if (this.addToReservation) {
        this.$router.push({ name: "ReservationPage", params: { reservation_id: this.addToReservation.uniqueId } });
        return
      } else if (this.modifyReservation) {
        this.$router.push({ name: "ReservationPage", params: { reservation_id: this.modifyReservation.uniqueId } });
        return
      }

      this.$router.push({ name: 'book_activity' });
    },

    validateDestination() {
      let tourCodes = Object.keys(this.$store.state.property.tours);
      
      return !this.$route.query.destination || this.$route.query.destination === 'ALL';
    },

    async fetchAvailableTours(newMonth, date = 1) {
      this.isFetching = true

      return apiAvailabilityTours({
        date: this.$dayjs(newMonth, 'YYYY/MM').date(date).format("MM/DD/YYYY"),
        limit: 31
      })
        .then((data) => {
          this.isFetching = false
          // pick only current month
          const result = _.pickBy(data.availability, (v, k) => (
            this.$dayjs(k).format('YYYY/MM') === newMonth &&
            this.$dayjs(k).isBetween(this.allowedRange.start, this.allowedRange.end, 'day', '[]')
          ))

          // calculate availabilitySatus and totalPrice
          for (const dateString in result) {
            const dayTours = result[dateString]
            for (const tourCode in dayTours) {
              const tourAvailability = dayTours[tourCode]
              const tour = this.tours[tourCode]

              if (tour) {
                tourAvailability.availabilityStatus = this.getTourAvailabilityStatus(tour, dateString, tourAvailability)
                tourAvailability.selected = false
                tourAvailability.cancelBeforeDate = this.getCancelBeforeDateForDate(dateString);
                tourAvailability.cancelationAvailable = tourAvailability.cancelBeforeDate > new Date();

                if (tourAvailability.availabilityStatus == 'available') {
                  const adults = this.params.adults;
                  const children = this.params.children;

                  const base = (
                    adults * tourAvailability.prices.adult.min +
                    children * tourAvailability.prices.child.min
                  )

                  const fees = (
                    adults * tourAvailability.prices.adult.fee +
                    children * tourAvailability.prices.child.fee
                  )

                  const taxes = (
                    adults * tourAvailability.prices.adult.tax +
                    children * tourAvailability.prices.child.tax
                  )

                  const price = getPrice({
                    base,
                    fees,
                    taxes,
                  })

                  tourAvailability.price = price;
                } else {
                  tourAvailability.price = null;
                }
              }
            }
          }

          this.$set(this.priceCalendar, newMonth, result)
        })
        .catch(error => {
          this.isFetching = false
          console.log('error', error)
        })
    },

    trackData() {
      // track search
      const data = {
        ecommerce: {
          currencyCode: "USD",
          detail: [
            {
              actionField: { list: "Search" },
            },
            {
              products: [
                {
                  name: this.destination,
                },
              ],
            },
          ],
        },
        page: {
          type: "category",
          environment: "production",
        },
      };

      gtmTrack(data);
    },

    // tour's methods
    getTourAvailabilityStatus(tour, dateString, tourAvailability) {
      const isOpened = !!_.values(tourAvailability.options).find(i => ['OPEN', 'SOLDOUT'].includes(i.status))
      const isAvailable = !!tourAvailability.available
      const enoughTime = this.$dayjs(tour.mindate, 'MM/DD/YYYY').toDate() <= this.$dayjs(dateString, 'MM/DD/YYYY').toDate()

      if (this.params.children && !tourAvailability.children) {
        return 'childrenNotAllowed'
      } else if (!isOpened) {
        return 'closed'
      } else if (!enoughTime) {
        return 'notEnoughTime'
      } else if (!isAvailable) {
        return 'soldOut'
      } else if (tourAvailability.available < (this.guests)) {
        return 'guestsExceededAvailability'
      }

      return 'available'
    },

    getCancelBeforeDateForDate(dateString) {
      const settings = this.options.cancelBeforeActivities?.find(i => (
        this.$dayjs(dateString, 'MM/DD/YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(dateString, 'MM/DD/YYYY')
        .subtract(value, 'day')
        .toDate();

      return cancelBeforeDate;
    },

    scrollToSelectedDestination() {
      if (this.scrollToSelectedDestination) {
        const destinationElement = this.$el.querySelector('.price-calendar-col.is-active');
        destinationElement?.scrollIntoView({
          inline: 'center',
          block: 'center',
        });
      }
    },
  }
};
