<template>
  <a-spin :spinning="isFetching && (!reservationList)">
    <a-form @submit.prevent="submit('input')">
      <a-form-item>
        <select-custom
          class="reservation-select"
          label="Existing Reservations"
          :value="selectedItemLabel"
          :open="reservationSelectOpened"
          :style="{
            width: '100%'
          }"
          @open-select="reservationSelectOpened = !reservationSelectOpened"
        >
          <div class="select-inner">
            <section class="select-inner__list">
              <div
                v-for="item in items"
                :key="item.uniqueId"
                class="select-inner__title"
                @click="newValue = item.uniqueId; reservationSelectOpened = false"
              >
                {{ getItemTitleForSelect(item) }}
              </div>
            </section>
          </div>
        </select-custom>

        <!--
        <a-select
          :value="newValue || undefined"
          size="large"
          allow-clear
          @change="newValue = $event"
        >
          <template #placeholder>
            <div class="va-select-option-label">Existing Reservations</div>

            <div class="va-select-option-content">Please Select</div>
          </template>

          <a-select-option
            v-for="item in items"
            :key="item.uniqueid"
            :value="item.uniqueId"
          >
            <div class="va-select-option-label">Existing Reservations</div>

            <div class="va-select-option-content">
              <strong>#{{ item.uniqueId }}, {{ $_.chain(item.subtitles).map().join(', ').value() }}, {{ item.departure }}</strong>
            </div>
          </a-select-option>
        </a-select>
        -->
      </a-form-item>

      <b-button
        type="submit"
        block
        variant="primary"
        class="text-uppercase"
        size="lg"
        :disabled="!newValue"
      >
        {{ submitTitle }}
      </b-button>
    </a-form>
  </a-spin>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import formMixin from '@/mixin/form'
import {useVuelidate} from "@vuelidate/core";

export default {
  name: 'ReservationSelect',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    formMixin
  ],

  props: {
    filters: {
      type: [Object, Function],
      default: null
    },

    submitTitle: {
      type: String,
      default: 'Select'
    }
  },

  data() {
    return {
      isFetching: false,
      reservationSelectOpened: false
    }
  },

  computed: {
    ...mapGetters({
      reservationList: 'property/reservationList',
      isUser: 'auth/isUser'
    }),

    items() {
      return _.chain(this.reservationList)
        .filter(this.filters)
        .value()
    },

    selectedItem() {
      return _.find(this.reservationList, { uniqueId: this.newValue})
    },

    selectedItemLabel() {
      return this.selectedItem ? this.getItemTitleForSelect(this.selectedItem) : 'Please Select'
    }
  },

  async created() {
    if (this.isUser) {
      this.isFetching = true
      await this.$store.dispatch("property/fetchReservationsList")

      if (_.isEmpty(this.reservationList)) {
        this.$emit('empty')
      }

      this.isFetching = false
    }
  },

  methods: {
    getItemTitleForSelect(item) {
      return `#${item.uniqueId}, ${item.arrival}, ${_.chain(item.subtitles).map().join(', ').value()}`
    }
  }
}
</script>

<style src="./style.sass" lang="sass" scoped />