import { PROPERTY_CODE, AVAILABILITY_STATUS_OPTIONS } from "@/constants";
import { optionsComputed, propertyComputed } from "@/store/helper";
import { apiAddTourToCart } from "@/api/cart";
import { mapGetters } from 'vuex'
import VBFormInputButtons from '@/components/inputs/VBFormInputButtons.vue'
import VBFormInputQuantity from '@/components/inputs/VBFormInputQuantity.vue'
import VBFormSelectDropdown from '@/components/inputs/VBFormSelectDropdown.vue'
import AddToReservationModal from '@/components/reservations/AddToReservationModal/index.vue'
import bookingMixin from '@/mixin/booking'
import HotelItemImages from '@/components/hotels/HotelItemImages.vue'
import HotelItemAlerts from '@/components/hotels/HotelItemAlerts.vue'
import HotelItemGallery from '@/components/hotels/HotelItemGallery.vue'
import _ from "lodash";
import TruncatedText from '@/components/TruncatedText/index.vue'
import { gtmTrack } from "@/lib/gtm";
import {getPrice} from "@/utils/taxes-and-fees";

export default {
  name: 'TourPage',

  mixins: [
    bookingMixin
  ],

  components: {
    AddToReservationModal,
    VBFormInputButtons,
    VBFormInputQuantity,
    VBFormSelectDropdown,
    HotelItemImages,
    HotelItemAlerts,
    HotelItemGallery,
    TruncatedText,
  },

  data: () => ({
    selectedOptionCode: null,
    selectedPickupName: null,

    /* OLD */
    adultQty: 1,
    childrenQty: 0,
    infantQty: 0,
    animalQty: 0,
    guestsQty: 1,
    answers: {},
    addInCartInProgress: false,
    errorMessage: "",
    loading: false,
    shouldViewMore: false,
    isViewingMore: false
  }),

  computed: {
    ...optionsComputed,
    ...propertyComputed,

    ...mapGetters({
      tours: 'property/tours',
      toursAvailability: 'property/toursAvailability',
      addToReservation: 'app/addToReservation',
      cartToken: 'property/cartToken',
      isModifyReservationMode: 'app/isModifyReservationMode',
      toursMaxAdults: 'property/toursMaxAdults',
      toursMaxChildren: 'property/toursMaxChildren',
      options: 'app/options',
      tourCartByDate: 'property/tourCartByDate',
      addToReservationToursByDate: 'app/addToReservationToursByDate',
    }),
  
    params() {
      return {
        destination: this.$route.query.destination,
        date: this.$route.query.date,
        adults: +this.$route.query.adults || 0,
        children: +this.$route.query.children || 0,
        infants: +this.$route.query.infants || 0,
        animals: +this.$route.query.animals || 0,
        rateCode: this.$route.query.rateCode
      }
    },

    tour() {
      return _.get(this.tours, this.params.destination)
    },

    tourImage() {
      return _.get(this.tour, 'images.0')
    },

    tourOptionOptions() {
      return _.chain(this.tour)
        .get('options')
        .map((v, k) => ({
          value: k,
          text: v.title,
          time: this.getOptionEarliestTime(k),
          disabled: this.getIsDisabledOptionByCode(k),
          available: this.getOptionAvailableByCode(k),
        }))
        .orderBy('time')
        .value()
    },

    availableTourOptionOptions() {
      return _.filter(this.tourOptionOptions, i => !i.disabled)
    },

    tourPickupOptions() {
      return _.chain(this.tour)
        .get('options')
        .map('pickup')
        .flatMap(i => _.map(i))
        .uniq()
        .map(i => ({
          value: i,
          text: i,
          time: this.getTimePickupByName(i),
          disabled: this.getIsDisabledPickupByName(i),
        }))
        .value()
    },

    availableTourPickupOptions() {
      return _.filter(this.tourPickupOptions, i => !i.disabled)
    },

    selectedDate() {
      return this.$dayjs(this.params.date, 'MM-DD-YYYY').format('MM/DD/YYYY')
    },

    selectedOptionPickupCode() {
      return _.chain(this.tour)
      .get(`options.${this.selectedOptionCode}.pickup`)
      .findKey(i => i === this.selectedPickupName)
      .value()
    },

    selectedOption() {
      return _.get(this.tour, `options.${this.selectedOptionCode}`)
    },

    selectedDateAvailability() {
      return _.get(this.toursAvailability, this.selectedDate)
    },

    selectedTourAvailability() {
      return _.get(this.selectedDateAvailability, _.get(this.tour, 'code'))
    },

    selectedTourAvailabilityAdultMinPrice() {
      const base = this.selectedTourAvailability?.prices.adult.min ?? 0;
      const fees = this.selectedTourAvailability?.prices.adult.fee ?? 0;
      const taxes = this.selectedTourAvailability?.prices.adult.tax ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      });
    },

    selectedTourAvailabilityChildMinPrice() {
      const base = this.selectedTourAvailability?.prices.child.min ?? 0;
      const fees = this.selectedTourAvailability?.prices.child.fee ?? 0;
      const taxes = this.selectedTourAvailability?.prices.child.tax ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      });
    },

    selectedOptionAvailability() {
      return _.get(this.selectedTourAvailability, `options.${this.selectedOptionCode}`)
    },

    selectedOptionAvailabilityStatus() {
      if (!this.selectedOptionAvailability) {
        return null
      }

      let value = 'available'
      const isOpened = ['OPEN', 'SOLDOUT'].includes(this.selectedOptionAvailability.status)
      const isAvailable = !!this.selectedOptionAvailability.available

      if (!isOpened) {
        value = 'closed'
      } else if (!isAvailable) {
        value = 'soldOut'
      } else if (this.selectedOptionAvailability.available < (this.guestsWithOthers)) {
        value = 'guestsExceededAvailability'
      }

      return _.find(AVAILABILITY_STATUS_OPTIONS, { value })
    },

    selectedOptionPickupAvailability() {
      return _.get(this.selectedOptionAvailability, `pickup.${this.selectedOptionPickupCode}`)
    },

    selectedOptionPickupAvailabilityAdultPrice() {
      const base = this.selectedOptionPickupAvailability?.prices.adult ?? 0;
      const fees = this.selectedOptionPickupAvailability?.fees.adult ?? 0;
      const taxes = this.selectedOptionPickupAvailability?.taxes.adult ?? 0;

      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    selectedOptionPickupAvailabilityChildPrice() {
      const base = this.selectedOptionPickupAvailability?.prices.child ?? 0;
      const fees = this.selectedOptionPickupAvailability?.fees.child ?? 0;
      const taxes = this.selectedOptionPickupAvailability?.taxes.child ?? 0;
      
      return getPrice({
        base,
        fees,
        taxes,
      })
    },

    placesLeft() {
      return _.get(this.selectedOptionAvailability, 'available', 0)
    },

    // placesAvailable() {
    //   if (this.selectedOptionAvailability) {
    //     return Math.min(this.tour.lowAvailability, this.placesLeft);
    //   }
    //   return +this.tour.lowAvailability;
    // },

    anyage() {
      return _.get(this.selectedTourAvailability, 'anyage', this.tour.anyage)
    },

    guests() {
      if (this.anyage) {
        return this.guestsQty;
      }

      return this.adultQty + this.childrenQty;
    },

    guestsWithOthers() {
      return this.guests + this.infantQty + this.animalQty;
    },

    dynamicMaxAdults() {
      if (this.anyage) {
        return this.tour.maxAdults - this.guestsWithOthers + this.guestsQty;
      }
      return this.tour.maxAdults - this.guestsWithOthers + this.adultQty;
    },
    dynamicMaxChildren() {
      if (this.anyge) {
        return 0;
      }
      return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.childrenQty, this.tour.maxChildren);
    },
    dynamicMaxInfants() {
      if (this.anyage) {
        return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.infantQty, this.guestsQty);
      }
      return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.infantQty, this.adultQty);
    },
    dynamicMaxAnimals() {
      if (this.anyage) {
        return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.animalQty, this.guestsQty);
      }
      return Math.min(this.tour.maxAdults - this.guestsWithOthers + this.animalQty, this.adultQty);
    },

    /* OLD */ 
    getImageUrl() {
      if (typeof this.tour.images !== "undefined" && this.tour.images.length > 0) {
        return this.getCdnResourceUrl(this.tour.images[0].image);
      }

      return "";
    },

    getImageTitle() {
      if (typeof this.tour.images !== "undefined" && this.tour.images.length > 0) {
        return this.tour.images[0].title;
      }

      return "";
    },

    adultsYears() {
      if (this.tour.descAdults) {
        return this.tour.descAdults;
      }

      return this.oPropertyAdultsYears;
    },

    childrenYears() {
      if (this.tour.descChildren) {
        return this.tour.descChildren;
      }

      return this.oPropertyChildrenYears;
    },

    cancelBeforeDate() {
      const settings = this.options.cancelBeforeActivities?.find(i => (
        this.$dayjs(this.params.date, 'MM-DD-YYYY')
          .isBetween(
            this.$dayjs(i.start, 'MM/DD/YYYY'),
            this.$dayjs(i.end, 'MM/DD/YYYY'),
            'day',
            '[]',
          )
      ));

      const value = settings?.value ?? 2;

      const cancelBeforeDate = this.$dayjs(this.params.date, 'MM-DD-YYYY')
        .subtract(value, 'day')
        .toDate();
      
      return cancelBeforeDate;
    },
    cancelationAvailable() {
      const today = new Date();
      return this.cancelBeforeDate && this.cancelBeforeDate > today;
    },
    cancelationMessage() {
      return this.cancelationAvailable ? (
        `free cancelation before ${this.$dayjs(this.cancelBeforeDate).format('ddd, MMM D')}`
      ) : 'cancelation fee applies';
    },
    /*
    anyAge() {
      //console.log("anyAge", this.selectedOption);
      if (this.selectedOption) {
        return !!this.selectedOption["anyage"];
      } else {
        return !!this.availabilityData["anyage"];
      }
    },
    */
    /*
    childrenAllowed() {
      //console.log("childrenAllowed", this.selectedOption);
      if (this.selectedOption) {
        return !!this.selectedOption["children"];
      } else {
        return !!this.availabilityData["children"];
      }
    },
    */
    /*
    selectedOption() {
      if (!this.selectedOptionName) {
        return false;
      }

      if (typeof this.availabilityData["options"] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["options"][this.selectedOptionName] === "undefined") {
        return false;
      }

      return this.availabilityData["options"][this.selectedOptionName];
    },
    */
    /*
    adultsPrice() {
      //console.log("adultsPrice", this.selectedOption);
      if (this.selectedOptionPickup) {
        return parseFloat(this.selectedOptionPickup["prices"]["adult"]);
      }

      return 0;
    },
    */
    /*
    adultsFromPrice() {
      if (typeof this.availabilityData["prices"] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["prices"]["adult"] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["prices"]["adult"]["min"] === "undefined") {
        return false;
      }

      return parseFloat(this.availabilityData["prices"]["adult"]["min"]);
    },
    */

    childrenPrice() {
      //console.log("childrenPrice", this.selectedOption);
      if (this.selectedOptionPickup) {
        return parseFloat(this.selectedOptionPickup["prices"]["child"]);
      }

      return 0;
    },
    /*
    childrenFromPrice() {
      if (typeof this.availabilityData["prices"] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["prices"]["child"] === "undefined") {
        return false;
      }

      if (typeof this.availabilityData["prices"]["child"]["min"] === "undefined") {
        return false;
      }

      return parseFloat(this.availabilityData["prices"]["child"]["min"]);
    },
    */
    /*
    showPlacesLeft() {
      return this.placesLeft && this.placesLeft < this.maxGuests;
    },
    */
   /*
    placesLeft() {
      if (this.selectedOptionPickup) {
        return parseInt(this.selectedOptionPickup["available"]);
      }

      return 0;
    },
    */
    /*
    placesLeftLabel() {
      if (this.placesLeft === 1) {
        return `only ${this.placesLeft} place left`;
      } else {
        return `only ${this.placesLeft} places left`;
      }
    },
    */

    itemInCart() {
      return !!_.find(this.tourCart, i => (
        i.date === this.selectedDate &&
        i.optionCode === this.selectedOptionPickupCode
      ))
      /*
      //console.log("itemInCart", this.pTourCart);
      if (!this.selectedOptionPickup) {
        return false;
      }

      const cart = this.pTourCart;

      if (!cart) {
        return false;
      }

      const dateKey = this.$dayjs(this.date, "MM-DD-YYYY").format("MM/DD/YYYY");
      const tourCode = this.tour.code;

      if (typeof cart[dateKey] === "undefined") {
        return false;
      }

      if (typeof cart[dateKey][tourCode] === "undefined") {
        return false;
      }

      if (typeof cart[dateKey][tourCode]["TOUR"] === "undefined") {
        return false;
      }

      if (typeof cart[dateKey][tourCode]["TOUR"][this.selectedOptionCode] === "undefined") {
        return false;
      }

      return cart[dateKey][tourCode]["TOUR"][this.selectedOptionCode];
      */
    },
    itemIsAlreadyBooked() {
      return !!_.find(this.addToReservation?.sections?.Tours?.items, i => (
        i.date === this.selectedDate &&
        i.tourCode === this.tour.code
      ))
    },
    shouldViewLess() {
      return this.$mq === 'sm' && this.shouldViewMore && this.isViewingMore;
    }
  },

  mounted() {
    // window.addEventListener("resize", this.onResize);
    this.$nextTick(function() {
      // this.onResize();
    });
  },

  watch: {
    "$route.fullPath": {
      immediate: true,
      
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return
        }
        this.init()
      }
    },

    tour: {
      immediate: true,
      deep: true,

      handler() {
        if (!this.tour) {
          this.$router.push({ name: 'tours', query: { ...this.params, destination: 'ALL' }})
        }
      }
    },

    selectedOptionAvailability: {
      immediate: true,
      deep: true,

      handler() {
        if (
          !_.find(this.availableTourOptionOptions, i => i.value === this.selectedOptionCode)
          // _.get(this.selectedOptionAvailability, 'available') === 0
        ) {
          const newSelectedOptionsCode = _.chain(this.availableTourOptionOptions)
            .find(i => i.available >= this.params.adults)
            .get('value')
            .value();

          this.$nextTick(() => {
            if (newSelectedOptionsCode !== this.selectedOptionCode) {
              this.selectedOptionCode = newSelectedOptionsCode;
            }
          })
        }
      },
    },

    selectedOptionPickupAvailability: {
      immediate: true,
      deep: true,

      handler() {
        if (
          !_.find(this.availableTourPickupOptions, i => i.value === this.selectedPickupName)
          //_.get(this.selectedOptionAvailability, 'available') === 0
        ) {
          const newSelectedPickupName = _.get(this.availableTourPickupOptions, '0.value', null)

          this.$nextTick(() => {
            if (newSelectedPickupName !== this.selectedPickupName) {
              this.selectedPickupName = newSelectedPickupName
            }
          })
        }
      }
    },
    params: {
      immediate: true,
      deep: true,
      handler() {
        if (this.validateUrl()) {
          // Save search params
          this.$store.dispatch('property/setActivitySearchParams', { ...this.params })
        }
      }
    },

    adultQty: {
      immediate: true,
      handler() {
        if (this.infantQty > this.adultQty) {
          this.infantQty = this.adultQty;
        }

        if (this.animalQty > this.adultQty) {
          this.animalQty = this.adultQty;
        }
      }
    },

    guestsQty: {
      immediate: true,
      handler() {
        if (this.infantQty > this.guestsQty) {
          this.infantQty = this.guestsQty;
        }

        if (this.animalQty > this.guestsQty) {
          this.animalQty = this.guestsQty;
        }
      }
    }
  },

  methods: {
    async init() {
      this.loading = true;

      if (!this.$store.state.property.tours) {
        await this.$store.dispatch("property/fetchToursList");
      }

      if (!this.validateUrl()) {
        return this.invalidationRedirect();
      }

      if (this.urlNeedsNormalization()) {
        //console.log("urlNeedsNormalization", this.urlNeedsNormalization());
        return this.normalizeUrl();
      }

      await this.loadAvailability();

      this.adultQty = this.params.adults;
      this.childrenQty = this.params.children;
      this.infantQty = this.params.infants;
      this.animalQty = this.params.animals;
      this.guestsQty = this.adultQty + this.childrenQty;

      this.loading = false;

      this.trackViewItemData();
    },

    invalidationRedirect() {
      this.allowRouteLeave = true;

      if (!this.cartIsEmpty) {
        this.$router.push({ name: "ReviewPage" });
        return;
      } else if (this.addToReservation) {
        this.$router.push({ name: "ReservationPage", params: { reservation_id: this.addToReservation.uniqueId } });
        return
      } else if (this.modifyReservation) {
        this.$router.push({ name: "ReservationPage", params: { reservation_id: this.modifyReservation.uniqueId } });
        return
      }

      this.$router.push({ name: 'book_activity' });
    },

    getIsDisabledOptionByCode(code) {
      let disabled = true

      if (this.selectedTourAvailability) {
        // disabled = _.get(this.selectedTourAvailability, `options.${code}.available`) === 0
        disabled = (
          !_.get(this.selectedTourAvailability, `options.${code}`) ||
          _.get(this.selectedTourAvailability, `options.${code}.status`) === 'CLOSED'
        )

      } else if (!this.toursAvailability) {
        disabled = !_.chain(this.toursAvailability)
          .map(this.tour.code)
          .find(i => !!_.get(i, `options.${code}.available`))
          .value()
      }

      return disabled
    },

    getOptionAvailableByCode(code) {
      let available = null;

      if (this.selectedTourAvailability) {
        available = this.selectedTourAvailability?.options[code]?.available;
      }

      return available
    },

    getIsDisabledPickupByName(name) {
      let disabled = true

      if (this.selectedOptionAvailability) {
        disabled = (
          // this.selectedOptionAvailability.available === 0 ||
          !_.find(this.selectedOptionAvailability.pickup, i => i.name === name)
        )

      } else if (!this.selectedDateAvailability) {
        disabled = true
      }

      return disabled
    },

    getTextPickupByName(name) {
      if (this.selectedOptionAvailability) {
        const pickupAvailability = _.find(this.selectedOptionAvailability.pickup, i => i.name === name)

        if (pickupAvailability && pickupAvailability.startTime) {
          return pickupAvailability.endTime ? (
            `${name} ${pickupAvailability.startTime} - ${pickupAvailability.endTime}`
          ) : `${name} ${pickupAvailability.startTime}`
        }
      }

      return name;
    },

    getTimePickupByName(name) {
      if (this.selectedOptionAvailability) {
        const pickupAvailability = _.find(this.selectedOptionAvailability.pickup, i => i.name === name);

        if (pickupAvailability && pickupAvailability.startTime) {
          return pickupAvailability.endTime ? (
            `${pickupAvailability.startTime} - ${pickupAvailability.endTime}`
          ) : `${pickupAvailability.startTime}` || null;
        }
      }

      return name;
    },

    getOptionEarliestTime(code) {
      let time = null;

      if (this.selectedTourAvailability) {
        const earliestPickupPoint = _.chain(this.selectedTourAvailability)
          .get(`options.${code}.pickup`)
          .map((i) => this.$dayjs(i.startTime.replaceAll('.', ''), 'h:mm a').toDate())
          .orderBy()
          .first()
          .value()

        return earliestPickupPoint
      }

      return time;
    },

    validateUrl() {
      if (
        _.isNaN(+(this.$route.query.adults ?? 0)) ||
        _.isNaN(+(this.$route.query.children ?? 0)) ||
        _.isNaN(+(this.$route.query.infants ?? 0)) ||
        _.isNaN(+(this.$route.query.animals ?? 0))
      ) {
        console.log(`Invalid params: one of numeric param is NaN`)
        return false;
      }

      // check date
      if (this.$route.query.date) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let date = this.$dayjs(this.$route.query.date, "MM-DD-YYYY");
        if (!date.isValid()) {
          // invalid date format
          return false;
        }

        date = date.toDate();
        if (today > date) {
          // past date
          return false;
        }

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          if (minDate > date) {
            // date is greater than mindate
            return false;
          }
        }

        let maxDate = this.$dayjs(this.options.maxDate, "MM/DD/YYYY");
        if (maxDate.isValid()) {
          maxDate = maxDate.toDate();
          if (date > maxDate) {
            // date is greater than maxdate
            return false;
          }
        }
      }

      // // validate adults. integer, >=1
      // if (this.$route.query.adults) {
      //   if (!Number.isInteger(+this.$route.query.adults)) {
      //     return false;
      //   }

      //   if (parseInt(this.$route.query.adults) < 1) {
      //     return false;
      //   }
      // }

      // // validate children. integer, >=0
      // if (this.$route.query.children) {
      //   if (!Number.isInteger(+this.$route.query.children)) {
      //     return false;
      //   }

      //   if (parseInt(this.$route.query.children) < 0) {
      //     return false;
      //   }
      // }

      // if (this.params.infants < 0) {
      //   console.log(`Param infants (${this.params.infants}) invalid`)
      //   return false
      // }

      // if (this.params.animals < 0) {
      //   console.log(`Param animals (${this.params.animals}) invalid`)
      //   return false
      // }

      // const guests = this.params.adults + this.params.children + this.params.infants + this.params.animals;
      // if (guests > 17) {
      //   // guests exceeded
      //   return false;
      // }

      if (this.params.adults < 1 || this.params.adults > this.toursMaxAdults) {
        return false;
      }

      if (this.params.children < 0 || this.params.children > this.toursMaxChildren) {
        return false;
      }

      if (this.params.infants < 0 || this.params.infants > this.params.adults) {
        return false;
      }

      if (this.params.animals < 0 || this.params.animals > this.params.adults) {
        return false;
      }

      const guests = this.params.adults + this.params.children + this.params.infants + this.params.animals;
      if (guests > this.toursMaxAdults) {
        return false;
      }

      return this.validateDestination();
    },

    validateDestination() {
      let tourCodes = Object.keys(this.$store.state.property.tours);
      return !!tourCodes.find((item) => item === this.params.destination);
    },

    urlNeedsNormalization() {
      return (
        typeof this.$route.query.date === "undefined" ||
        typeof this.$route.query.adults === "undefined" ||
        typeof this.$route.query.children === "undefined" ||
        typeof this.$route.query.infants === "undefined" ||
        typeof this.$route.query.animals === "undefined" ||
        typeof this.$route.query.destination === "undefined"
      );
    },

    async loadAvailability() {
      const data = {
        date: this.selectedDate,
        limit: 1
      }

      await this.$store.dispatch("property/fetchAvailableTours", data)

      // If all options are disabled
      if (!_.find(this.tourOptionOptions, { disabled: false })) {
        this.showTourRateCalendar({ alert: true })
        this.$router.push({ name: 'tours', query: { ...this.params, destination: 'ALL' }})
      }
    },

    /*
     * Set params defaults and redirect to normalized URL
     */
    async normalizeUrl() {
      let urlQueryParams = {};

      if (!this.$route.query.dateFrom) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let minDate = this.$dayjs(this.options.mindate, "MM/DD/YYYY");
        if (minDate.isValid()) {
          minDate = minDate.toDate();
          urlQueryParams["date"] =
            minDate > today
              ? this.$dayjs(minDate).format("MM-DD-YYYY")
              : this.$dayjs(today).format("MM-DD-YYYY");
        } else {
          urlQueryParams["date"] = this.$dayjs(today).format("MM-DD-YYYY");
        }
      } else {
        urlQueryParams["date"] = this.$route.query.dateFrom;
      }

      urlQueryParams["adults"] = this.$route.query.adults ? this.$route.query.adults : 1;
      urlQueryParams["children"] = this.$route.query.children ? this.$route.query.children : 0;
      urlQueryParams["infants"] = this.$route.query.infants ? this.$route.query.infants : 0;
      urlQueryParams["animals"] = this.$route.query.animals ? this.$route.query.animals : 0;

      if (this.$route.query.rateCode) {
        urlQueryParams["rateCode"] = this.$route.query.rateCode;
      }

      //console.log("normalizeUrl", urlQueryParams);

      return this.$router.push({
        name: "tours",
        query: urlQueryParams,
      });
    },
    /*
    setQty(type, key) {
      if (type === "plus" && this.guests < this.maxGuests) {
        if (key === "adult") {
          this.adultQty += 1;
        }
        if (key === "children") {
          this.childrenQty += 1;
        }
        if (key === "guest") {
          this.guestsQty += 1;
        }
      }

      if (type === "minus" && this.guests > 1) {
        if (key === "adult" && this.adultQty > 1) {
          this.adultQty -= 1;
        }
        if (key === "children" && this.childrenQty > 0) {
          this.childrenQty -= 1;
        }
        if (key === "guest") {
          this.guestsQty -= 1;
        }
      }
    },
    */
    showTourRateCalendar(params) {
      const data = {
        ...params,
        tour_data: this.tour,
        date: this.params.date,
        adults: this.params.adults,
        children: this.params.children,
        infants: this.params.infants,
        animals: this.params.animals,
      }

      this.setModal("TourRateCalendar", data)
    },

    onAddedToCart() {
      this.setConfirmationModal({
        content: "Do you want to add more tours?",
        header: "",
        decline: "No, Review Reservation",
        confirm: "Yes",
        confirmOnClose: true,
      })
        .then(() => {
          this.$router.push({
            name: "tours",
            query: {
              ...this.params,
              destination: 'ALL'
            }
          });
        })
        .catch(() => {
          this.$router.push({
            name: "ReviewPage",
          });
        });
    },

    onSelectClick(datesOverlapConfirmed) {
      this.trackAddToCartData();

      const date = this.$dayjs(this.params.date, "MM-DD-YYYY").format("MM/DD/YYYY");
      const dateStr = this.$dayjs(this.params.date, "MM-DD-YYYY").format('MMM D');
      if (
        !datesOverlapConfirmed &&
        !this.itemInCart &&
        (this.tourCartByDate[date] || this.addToReservationToursByDate[date])
      ) {
        this.$bvModal.msgBoxConfirm(`You already have a tour on ${dateStr}, are you sure you want to add another tour on ${dateStr}?`, {
          title: 'Confirm',
          headerBgVariant: 'secondary',
          titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
          contentClass: 'bg-gamma',
          bodyClass: 'font-size-13 line-height-11 font-weight-500 py-4',
          footerClass: 'border-0',
          okTitle: 'Yes',
          okVariant: 'primary',
          hideHeaderClose: true,
          noCloseOnBackdrop: true,
          cancelVariant: 'outline-primary',
          cancelTitle: 'Cancel',
          buttonSize: 'lg',
          centered: true
        })
          .then((value) => {
            if (value) {
              this.onSelectClick(true);
            }
          })
        return;
      }

      if (this.$_.isEmpty(this.tour.questions)) {
        // no tour questions - go ahead adding to cart
        return this.addToCart();

      } else {
        const data = {
          adults: !this.anyage ? this.adultQty : this.guestsQty,
          children: !this.anyage ? this.childrenQty : 0,
          infants: this.infantQty,
          animals: this.animalQty,
          questions: this.tour.questions,
          answers: this.answers,
          tourCode: this.tour.code,
          tourOption: this.selectedOptionPickupCode,
          anyAge: this.anyage,
          date,
          modalContainerClass: "activity-questionnaire",
          onAddedToCart: this.onAddedToCart,
        };

        //console.log("onSelectClick", data);

        return this.setModal("ActivityQuestions", data);
      }
    },

    async addToCart() {
      this.errorMessage = "";
      const data = {
        property_code: PROPERTY_CODE,
        tour_code: this.tour.code,
        tour_option: this.selectedOptionPickupCode,
        date: this.$dayjs(this.params.date, "MM-DD-YYYY").format("MM/DD/YYYY"),
        adults: !this.anyage ? this.adultQty : this.guestsQty,
        children: !this.anyage ? this.childrenQty : 0,
        infants: this.infantQty,
        animals: this.animalQty,
        answers: {},
      };

      //console.log("addToCart", data);

      this.addInCartInProgress = true;

      if (!this.cartToken) {
        await this.$store.dispatch('property/fetchCartToken')
      }

      apiAddTourToCart(this.cartToken, data)
        .then(() => {
          this.$store.dispatch("property/fetchTourCart");
          this.addInCartInProgress = false;
          this.onAddedToCart()
        })
        .catch((err) => {
          this.errorMessage = err.response.data.detail;
          //console.error(err.response.data);
          this.addInCartInProgress = false;
        });
    },

    tryApplyCartData() {
      if (!this.selectedOptionPickup) {
        return;
      }

      this.answers = {};

      if (!this.itemInCart) {
        return;
      }

      this.adultQty = this.itemInCart.adults;
      this.childrenQty = this.itemInCart.children;
      this.infantQty = this.itemInCart.infants;
      this.animalQty = this.itemInCart.animals;
      this.answers = this.itemInCart.answers ? this.itemInCart.answers : {};
    },

    onViewMore() {
      this.isViewingMore = true;
    },

    onViewLess() {
      this.isViewingMore = false;
    },

    onResize() {
      this.shouldViewMore = this.$mq === 'sm';
      const isViewingMore = this.isViewingMore;
      this.isViewingMore = false;

      this.$nextTick(function() {
        this.shouldViewMore =
          this.$mq === 'sm' &&
          this.$refs.text.scrollHeight > this.$refs.text.clientHeight;
        this.isViewingMore = isViewingMore;
      });
    },
    trackViewItemData() {
      const data = {
        'content-name': '/booking/activity-time',
        'content-view-name': 'Choose Activity Time',
        event: 'view_item',
        ecommerce: {
          currency: 'USD',
          // value: , // @TODO: sum of all items
          items: [{
            item_id: this.tour.code,
            item_name: this.tour.title,
            affiliation: this.isAgency ? 'TA' : 'Consumer',
            index: 0,
            item_brand: this.options.title,
            item_category: 'Activity',
            item_category2: this.$dayjs(this.params.date, 'MM/DD/YYYY').format('YYYYMMDD'),
            item_category5: this.params.adults + this.params.children,
            item_category6: this.params.adults,
            item_category7: this.params.children,
            // item_variant: '', // @TODO: room type
            coupon: this.params.rateCode,
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            // price: null, // @TODO: price
            // quantity: null // @TODO: number of rooms
          }]
        }
      }

      gtmTrack({ ecommerce: null });
      gtmTrack(data);
    },

    trackAddToCartData() {
      const value = this.tour.anyage ? (
        this.guests * this.selectedOptionPickupAvailabilityAdultPrice.total
      ) : (
        this.adultQty * this.selectedOptionPickupAvailabilityAdultPrice.total +
        this.childrenQty * this.selectedOptionPickupAvailabilityChildPrice.total
      );

      const quantity = this.guests;

      const data = {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'USD',
          value: _.round(value, 2),
          items: [{
            item_id: this.tour.code,
            item_name: this.tour.title,
            affiliation: this.isAgency ? 'TA' : 'Consumer',
            index: 0,
            item_brand: this.options.title,
            item_category: 'Activity',
            item_category2: this.$dayjs(this.params.date, 'MM/DD/YYYY').format('YYYYMMDD'),
            item_category5: this.guests,
            item_category6: this.tour.anyage ? this.guests : this.adultQty,
            item_category7: this.tour.anyage ? 0 : this.childrenQty,
            item_variant: this.selectedOption.title,
            coupon: null,
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            price: _.round(value / quantity, 2),
            quantity,
          }]
        }
      }

      gtmTrack({ ecommerce: null });
      gtmTrack(data);
    },
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
  },

  // What about to create bookingMixin and use it in all activity and lodges booking pages?
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave', from.name, to.name)
    if (this.allowRouteLeave) {
      return next();
    }

    if (!this.isModifyReservationMode && !this.addToReservation) {
      return next();
    }

    if (['ReviewPage', 'hotels', 'tours', 'tourPage', 'hotelPage', 'activitiesPage'].includes(to.name)) {
      return next();
    }

    this.setConfirmationModal({
      content: "Are you sure you want to cancel reservation editing?",
    })
      .then(() => {
        if (this.addToReservation) {
          this.$store.dispatch("app/endAddToReservation")
        }

        if (this.isModifyReservationMode) {
          this.$store.dispatch("app/endModifyReservation")
        }

        next();
      })
      .catch(function() {
        next(false);
      });
  },
};
