import _ from 'lodash';

export default {
  name:'VDoubleScrollbar',

  props: {
    leftFixedWidth: {
      type: String,
      default: null,
    },

    rightFixedWidth: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    showLeftShadow: {
      type: Boolean,
      default: true,
    },

    showRightShadow: {
      type: Boolean,
      default: true,
    },
  },

  data(){
    return {
      scrollWidth: 0,
      clientWidth: 0,
      scrollLeft: 0,
    }
  },

  computed:{
    scrollBarStyle() {
      return {
        paddingTop: '1px',
        width: this.scrollWidth == null ? 'auto' : `${this.scrollWidth}px`,
      }
    },

    offsetLeft() {
      return this.scrollLeft;
    },

    offsetRight() {
      return Math.floor(this.scrollWidth - this.scrollLeft - this.clientWidth);
    },
  },

  mounted() {
    this.initScrollbar();
  },

  watch: {
    scrollLeft: {
      immediate: true,

      handler() {
        if (this.$refs.topScroll && this.$refs.topScroll.scrollLeft !== this.scrollLeft) {
          this.$refs.topScroll.scrollLeft = this.scrollLeft;
        }

        if (this.$refs.scrollArea && this.$refs.scrollArea.scrollLeft !== this.scrollLeft) {
          this.$refs.scrollArea.scrollLeft = this.scrollLeft;
        }

        this.$emit('scroll', { left: this.scrollLeft });
      }
    },
  },

  methods:{
    initScrollbar() {
      this.destroyScrollbar();
      this.setWidth();
      this.$refs.topScroll.addEventListener('scroll', this.onTopScroll);
      this.$refs.scrollArea.addEventListener('scroll', this.onAreaScroll);
    },

    destroyScrollbar() {
      window.removeEventListener('resize', this.setWidth);
      this.$refs.topScroll.removeEventListener('scroll', this.onTopScroll);
      this.$refs.scrollArea.removeEventListener('scroll', this.onAreaScroll);
    },

    onTopScroll() {
      if (this.scrollLeft !== this.$refs.topScroll?.scrollLeft) {
        this.scrollLeft = this.$refs.topScroll?.scrollLeft;
      }
    },

    onAreaScroll() {
      if (this.scrollLeft !== this.$refs.scrollArea?.scrollLeft) {
        this.scrollLeft = this.$refs.scrollArea?.scrollLeft;
      }
    },

    setWidth() {
      if (this.$refs.scrollArea) {
        this.scrollWidth = this.$refs.scrollArea.scrollWidth;
        this.clientWidth = this.$refs.scrollArea.clientWidth;
      }
    },
  },

  beforeDestroy() {
    this.destroyScrollbar();
  },
}