import http from '@/lib/axios'
import { PROPERTY_CODE } from '@/constants'
import { RATE_CODE } from '@/constants'

export const apiAppStatus = () => http.post(`property/ping`, { "property_code": PROPERTY_CODE });

export const apiAppInfo = () => http.get(`property/information/${PROPERTY_CODE}`);

export const apiHotelAlerts = (hotelCode, rateCode) => http.get(`property/alerts/${PROPERTY_CODE}/${hotelCode}/${rateCode || RATE_CODE}`);

export const apiHotelList = () => http.get(`property/hotels/${PROPERTY_CODE}`);

export const apiHotel = hotelCode => http.get(`property/hotels/${PROPERTY_CODE}/${hotelCode}`);

export const apiHotelRooms = hotelCode => http.get(`property/rooms/${PROPERTY_CODE}/${hotelCode}`);

export const apiTourList = () => http.get(`property/tours/${PROPERTY_CODE}`);

export const apiReservationToken = ({ reservation_number, first_name, last_name, date }) => http.post('user/reservation/token', { reservation_number, first_name, last_name, date, property_code: PROPERTY_CODE })
export const apiReservationList = ( ) => http.get(`user/reservations`);
export const apiReservation = reservationToken => http.get(`user/reservations/${reservationToken}`);
export const apiDeleteReservation = (reservationToken, cancel_code) => http.delete(`user/reservations/${reservationToken}`, { data: { cancel_code: cancel_code } });
