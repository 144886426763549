export const setBodyPosition = {
  fixed(setOffset) {
    const currentOffset = window.scrollY;
    setOffset(currentOffset);
    document.body.style.position = "fixed";
    document.body.style.top = `-${currentOffset}px`;
  },
  unfixed(offset) {
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, offset);
  },
};

export function unescapeString(str) {
  return str
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}

export function isJSON(value) {
  if (!value) {
    return false;
  }

  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }

  return true;
}
