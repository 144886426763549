import { apiReservationSignIn } from "@/api/auth";
import { required } from "@vuelidate/validators";
import { CopyIcon } from "@/components/Icons";
import { notCreditCard } from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    reservation_number: "",
    first_name: "",
    last_name: "",
    date: "",
    loading: false,
    errors: {},
    min_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    dp_config: {
      type: "string",
      mask: "MM/DD/YYYY",
    },
    copySuccess: false,
    copyError: false,
    copyIcon: CopyIcon(),
    username: "",
  }),
  validations: () => {
    return {
      reservation_number: {
        required,
        notCreditCard,
      },
      first_name: {
        required,
        notCreditCard,
      },
      last_name: {
        required,
        notCreditCard,
      },
      date: {
        required,
        notCreditCard,
      },
    }
  },
  methods: {
    async handleSignIn() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      try {
        this.loading = true;
        const res = await apiReservationSignIn(
          this.reservation_number,
          this.first_name,
          this.last_name,
          this.date
        );
        if (res.username) {
          this.username = res.username;
          localStorage.setItem("resetRequestUsername", this.username);
          if (res.type === 'new')
            this.setModal('ResetPassword', {type: res.type});
        } else {
          this.$msg(
            "Error has happened",
            1
          );
        }
      } catch (err) {
        this.errors = {
          ...this.errors,
          ...this.$store.state.app.errors,
          detail: err.response.data.detail,
        };
      } finally {
        this.loading = false;
        this.$store.dispatch("app/setErrors");
      }
    },
    clipboardSuccessHandler() {
      this.copySuccess = true;
      this.copyError = false;
      this.setHideMessage();
    },
    clipboardErrorHandler() {
      this.copyError = true;
      this.copySuccess = false;
      this.setHideMessage();
    },
    setHideMessage() {
      var that = this;
      window.setTimeout(function() {
        that.copySuccess = false;
        that.copyError = false;
      }, 3000);
    },
  },
};
