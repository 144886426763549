import { authComputed } from "@/store/helper";

export default {
  data: () => ({}),
  props: {
    open: Boolean,
  },
  created() {},
  computed: {
    ...authComputed,
  },
  watch: {},
  methods: {
    closeFilterSection(path = "") {
      this.$eventHub.$emit("close-filter-section");
      if (path) this.$router.push({ path: path });
    },
    handleLogout() {
      this.$auth.logout();
      location.reload();
      this.$eventHub.$emit("close-filter-section");
    },
  },
};
