import _ from 'lodash'
import vClickOutside from "v-click-outside";
import { mapGetters } from 'vuex'
import { useWindowSize } from 'vue-window-size';

export default {
  setup:() => ({
    windowSize: useWindowSize(),
  }),

  directives: {
    clickOutside: vClickOutside.directive,
  },
  data: () => ({
    today: new Date().setHours(0, 0, 0, 1),
    currentOpenSelect: "",
    searchParams: {
      dateFrom: null,
      dateFromString: null,
      nights: null,
      destination: null,
      adults: null,
      children: null,
      infants: null,
      animals: null,
      rateCode: null,
      rateType: null,
      exactDate: null,
    },
    // tempSearchParams: {},
    // changedParams: false,
    isExactDate: true,
    dataStart: null,
    nightsFromFlex: 1
  }),
  props: {
    link: String,
    validate: Function,
    reservationId: Number,
    reservation: Object,
    startDate: Date,
    nights: Number,
    exactDate: Boolean,
    minDate: Date,
    maxDate: Date,
    // Can be grater than maxDate
    maxStayDate: {
      type: Date,
      required: false
    },
    adults: Number,
    children: Number,
    infants: Number,
    animals: Number,
    promoCode: String,
    promoType: String,
    selectedCode: String,
    disableFlexible: Boolean,
    dateLabel: String,
    dateType: String,
    confirmLoading: Boolean,
    minAgeAdults: Number,
    minAgeChildren: Number,
    promoGroupEnabled: {
      type: Boolean,
      default: true,
    },
    promoCodes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isEdit: Boolean,
    disablePropertySelect: {
      type: Boolean,
      default: false,
    },
  },
  /*
  created() {
    console.log('created')
    this.searchParams["type"] = this.link;

    for (const searchParamsKey in this.searchParams) {
      this.tempSearchParams[searchParamsKey] = this.searchParams[
        searchParamsKey
      ];
    }
  },
  */
  computed: {
    ...mapGetters({
      tours: 'property/tours',
      hotels: 'property/hotels',
      ratePlans: 'property/hotelsRatePlans',
      toursMindate: 'property/toursMindate',
      toursInfantsAllowed: 'property/toursInfantsAllowed',
      toursAnimalsAllowed: 'property/toursAnimalsAllowed',
      toursMaxAdults: 'property/toursMaxAdults',
      toursMaxChildren: 'property/toursMaxChildren',
      hotelsMaxDate: 'property/hotelsMaxDate',
      hotelsMaxStayDate: 'property/hotelsMaxStayDate',
    }),
    windowWidth() {
      return this.windowSize.width.value;
    },
    initialParams() {
      const dateFrom = this.startDate || this.minDate || this.$dayjs().add(1, 'day').toDate()

      return {
        dateFrom,
        dateFromString: this.$dayjs(dateFrom).format('MM-DD-YYYY'),
        nights: this.nights || 1,
        destination: this.selectedCode || 'ALL',
        adults: this.adults || 2,
        children: this.children,
        infants: this.infants,
        animals: this.animals,
        rateCode: this.promoCode,
        rateType: this.promoType,
        exactDate: this.exactDate,
        type: this.link
      }
    },
    searchParamsIsDirty() {
      return !_.isEqual(
        _.pick(this.initialParams, [
          'dateFromString',
          'nights',
          'destination',
          'adults',
          'children',
          'infants',
          'animals',
          'rateCode',
          'rateType',
          'exactDate',
          'type'
        ]),
        _.pick(this.searchParams, [
          'dateFromString',
          'nights',
          'destination',
          'adults',
          'children',
          'infants',
          'animals',
          'rateCode',
          'rateType',
          'exactDate',
          'type'
        ])
      )
    },
    options() {
      return this.$store.state.app.options;
    },
    maxGuests() {
      if (this.link === 'hotels') {
        return +this.$store.state.app.options.resformMaxOccupancy;
      }
      return null;
    },
    maxAdults() {
      if (this.activity) {
        return this.activity.maxAdults;
      } else if (this.link === 'tours') {
        return this.toursMaxAdults;
      }
      return null;
    },
    maxChildren() {
      if (this.activity) {
        return this.activity.maxChildren;
      } else if (this.link === 'tours') {
        return this.toursMaxChildren;
      }
      return null;
    },
    minAgeAdult() {
      return this.minAgeAdults ? this.minAgeAdults : this.$store.state.app.options.resformAgeAdult
    },
    minAgeChild() {
      return this.minAgeChildren || this.minAgeChildren === 0 ? this.minAgeChildren : this.$store.state.app.options.resformAgeChild
    },
    isInitialSearch() {
      return this.$route.name === "hotels";
    },
    editMode() {
      return this.isEdit;
    },
    showNights() {
      return !this.searchParams.exactDate && this.link !== "tours";
    },
    activity() {
      return this.searchParams.type === 'tours' ? this.tours?.[this.searchParams.destination] : null 
    },
    hotel() {
      return this.searchParams.type === 'hotels' ? this.hotels?.[this.searchParams.destination] : null 
    },
    infantsAllowed() {
      return this.searchParams.type === 'tours' && (this.activity?.infantsAllowed ?? this.options.infantsAllowed);
    },
    animalsAllowed() {
      return this.searchParams.type === 'tours' && (this.activity?.animalsAllowed ?? this.options.animalsAllowed);
    },
    infantsText() {
      return this.activity?.infantsText ?? this.options.resformInfantsText;
    },
    animalsText() {
      return this.activity?.animalsText ?? this.options.resformAnimalsText;
    },
    ratePlan() {
      return this.ratePlans[this.searchParams.rateCode]
    },
    allowedRange() {
      let start = this.minDate
      let end = this.maxDate
      
      if (this.searchParams.type === 'tours') {
        if (this.activity) {
          if (this.activity.mindate) {
            start = this.$dayjs.max(
              this.$dayjs(start),
              this.$dayjs(this.activity.mindate, 'MM/DD/YYYY')
            ).toDate()
          }

        } else {
          if (this.toursMindate) {
            start = this.$dayjs.max(
              this.$dayjs(start),
              this.$dayjs(this.toursMindate, 'MM/DD/YYYY')
            ).toDate()
          }
        }
      }

      if (this.searchParams.type === 'hotels') {
        if (this.hotel) {
          end = this.$dayjs.min(
            this.$dayjs(end),
            this.$dayjs(this.hotel.lastcheckin, 'MM/DD/YYYY')
          ).toDate()
        } else {
          end = this.$dayjs.min(
            this.$dayjs(end),
            this.$dayjs(this.hotelsMaxDate, 'MM/DD/YYYY')
          ).toDate()
        }
      }

      if (this.ratePlan) {
        if (this.ratePlan.start) {
          start = this.$dayjs.max(
            this.$dayjs(start),
            this.$dayjs(this.ratePlan.start, 'MM/DD/YYYY')
          ).toDate()
        }

        if (this.ratePlan.end) {
          end = this.$dayjs.min(
            this.$dayjs(end),
            this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY').subtract(1, 'day')
          ).toDate()
        }
      }

      return { start, end }
    },

    maxNights() {
      if (this.ratePlan?.start && this.ratePlan?.end) {
        return this.$dayjs(this.ratePlan.end).diff(this.ratePlan.start, 'day')
      }

      return 14
    },

    computedMaxStayDate() {
      let maxStayDate = this.maxStayDate || this.$dayjs(this.hotelsMaxStayDate, 'MM/DD/YYYY').toDate()

      if (this.hotel) {
        maxStayDate = this.$dayjs.min(
          this.$dayjs(maxStayDate),
          this.$dayjs(this.hotel.lastcheckout, 'MM/DD/YYYY').subtract(1, 'day')
        ).toDate()
      }

      if (this.ratePlan && this.ratePlan.end) {
        if (maxStayDate) {
          maxStayDate = this.$dayjs.min(
            this.$dayjs(maxStayDate),
            this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY').subtract(1, 'day')
          ).toDate()

        } else {
          maxStayDate = this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY')
            .subtract(1, 'day')
            .toDate()
        }
      }

      return maxStayDate
    },
    disableExactDates() {
      // if (this.ratePlan?.category === 'GROUP') {
      //   return true;
      // }
      return false;
    },
    computedDisabledLocation() {
      return (
        this.disablePropertySelect ||
        this.ratePlan?.category === 'GROUP'
      )
    },
    computedDisabledDateFrom() {
      return (
        this.ratePlan?.category === 'GROUP'
      )
    },
  },
  watch: {
    initialParams: {
      immediate: true,
      deep: true,
      async handler() {
        this.searchParams = _.cloneDeep(this.initialParams)

        if (this.searchParams.rateCode) {
          // Fetch ratePlan
          await this.$store.dispatch('property/fetchHotelsRatePlan', { 
            rateCode: this.searchParams.rateCode,
            rateType: this.searchParams.rateType
          })
          // Unset rate plan if no ratePlan
          if (!this.ratePlan) {
            this.searchParams.rateCode = null
            this.searchParams.rateType = null
          }
        }
      }
    },

    searchParams: {
      immediate: true,
      deep: true,
      handler() {
        // Validate date
        if (this.searchParams.dateFrom) {
          if (
            this.searchParams.dateFrom > this.allowedRange.end ||
            this.searchParams.dateFrom < this.allowedRange.start
          ) {
            if (this.allowedRange.start <= this.allowedRange.end) {
              this.searchParams = {
                ...this.searchParams,
                dateFrom: this.allowedRange.start,
                dateFromString: this.$dayjs(this.allowedRange.start).format('MM-DD-YYYY')
              }
            }
          }
        }

        // Validate nights
        if (this.searchParams.type === 'hotels') {
          const maxNightsAllowed = Math.min(
            this.$dayjs(this.computedMaxStayDate).add(1, 'day').diff(this.searchParams.dateFrom, 'day'),
            14
          )
          if (this.searchParams.nights > maxNightsAllowed) {
            console.log('this.searchParams.nights, maxNightsAllowed', this.searchParams.nights, maxNightsAllowed)
            this.searchParams.nights = maxNightsAllowed
          }
        }
      }
    },
    ratePlan: {
      immediate: true,
      deep: true,
      handler() {
        if (this.ratePlan?.category === 'GROUP') {
          // Validate search params
          const validatedSearchParams = {
            ...this.searchParams,
            destination: 'ALL',
            exactDate: false,
            dateFrom: this.allowedRange.start,
            dateFromString: this.$dayjs(this.allowedRange.start).format('MM-DD-YYYY'),      
          }

          if (!_.isEqual(this.searchParams, validatedSearchParams)) {
            console.log(this.searchParams, validatedSearchParams);
            this.searchParams = _.cloneDeep(validatedSearchParams);
          }
        }
      }
    }
  },
  methods: {
    getExactDate(value) {
      this.isExactDate = !value
    },
    // paramsCompare() {
    //   let result = false;
    //   console.log('paramsCompare', this.searchParams, this.tempSearchParams);
    //   for (const searchParamsKey in this.searchParams) {
    //     if (searchParamsKey === "dateFrom") {
    //       console.log(
    //         this.searchParams[searchParamsKey],
    //         this.tempSearchParams[searchParamsKey]
    //       );
    //       if (
    //         this.$dayjs(this.searchParams[searchParamsKey]).format(
    //           "MM/DD/YYYY"
    //         ) !==
    //         this.$dayjs(this.tempSearchParams[searchParamsKey]).format(
    //           "MM/DD/YYYY"
    //         )
    //       ) {
    //         result = true;
    //       }
    //     } else if (
    //       this.searchParams[searchParamsKey] !==
    //       this.tempSearchParams[searchParamsKey]
    //     ) {
    //       result = true;
    //     }
    //   }
    //   return result;
    // },
    setCurrentOpenSelect(value) {
      this.currentOpenSelect = this.currentOpenSelect === value ? "" : value;
    },
    onClickOutside() {
      console.log('this.windowWidth', this.windowWidth);
      if (this.windowWidth > this.SIZES.md) {
        this.currentOpenSelect = "";
      }
    },
    // setNights(data) {
    //   this.searchParams.nights = data.night
    //   this.nightsFromFlex = data.night
    //   // this.changedParams = this.paramsCompare();
    // },
    // setDate(data) {
    //   console.log("setDate", data);
    //   this.dataStart = data.date
    //   this.nightsFromFlex = data.limit
    //   // if (this.searchParams.dateFrom === null) {
    //   //   this.tempSearchParams.dateFrom = data.date;
    //   //   this.tempSearchParams.dateFromString = this.$dayjs(data.date).format(
    //   //     "MM-DD-YYYY"
    //   //   );
    //   //   this.tempSearchParams.nights = data.limit;
    //   //   this.tempSearchParams.exactDate = data.exactDate;
    //   // }
    //   // this.searchParams.dateFrom = data.date;
    //   // this.searchParams.dateFromString = this.$dayjs(data.date).format(
    //   //   "MM-DD-YYYY"
    //   // );
    //   // this.searchParams.nights = data.limit;
    //   // this.searchParams.exactDate = data.exactDate;
    //   // this.changedParams = this.paramsCompare();
    //   // this.searchParams = {
    //   //   ...this.searchParams,
    //   //   dateFrom: data.date,
    //   //   dateFromString: this.$dayjs(data.date).format("MM-DD-YYYY"),
    //   //   nights: data.limit,
    //   //   exactDate: data.exactDate
    //   // }
    //   // console.log('this.searchParams', this.searchParams)
    //   // this.changedParams = this.paramsCompare();
    // },
    setLocation(data) {
      console.log("setLocation", data);
      // if (this.searchParams.destination === null) {
      //   console.log(data.location);
      //   this.tempSearchParams.destination = data.location;
      // }
      this.searchParams.destination = data.location;
      // this.changedParams = this.paramsCompare();
    },
    setAdults(value) {
      console.log("setAdults", value);
      // if (this.searchParams.adults === null) {
      //   this.tempSearchParams.adults = value;
      // }
      this.searchParams.adults = value;
      // this.changedParams = this.paramsCompare();
    },
    setChildren(value) {
      console.log("setChildren", value);
      // if (this.searchParams.children === null) {
      //   this.tempSearchParams.children = value;
      // }
      this.searchParams.children = value;
      // this.changedParams = this.paramsCompare();
    },
    setInfants(value) {
      console.log("setInfants", value);
      // if (this.searchParams.children === null) {
      //   this.tempSearchParams.children = value;
      // }
      this.searchParams.infants = value;
      // this.changedParams = this.paramsCompare();
    },
    setAnimals(value) {
      console.log("setAnimals", value);
      // if (this.searchParams.children === null) {
      //   this.tempSearchParams.children = value;
      // }
      this.searchParams.animals = value;
      // this.changedParams = this.paramsCompare();
    },
    setCode(data) {
      console.log("setCode", data);
      // if (this.searchParams.rateCode === null) {
      //   this.tempSearchParams.rateCode = data.rateCode;
      //   this.tempSearchParams.rateType = data.rateType;
      // }
      this.searchParams = {
        ...this.searchParams,
        rateCode: data.rateCode,
        rateType: data.rateType,
        nights: data.nights
      }
      // this.changedParams = this.paramsCompare();
    },
    submit() {
      this.$emit("submit", this.searchParams);
    },
    onGroupSearch(params) {
      const searchParams = {
        ...this.searchParams,
        ...params,
        // destination: 'ALL',
        // exactDate: false
      }

      this.$emit("submit", searchParams);
    }
  },
};
