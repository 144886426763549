<template>
  <b-form
    class="d-flex flex-column"
    @submit.prevent="submit('submitted')"
  >
    <div
      v-if="newItem"
      class="overflow-auto h-100"
    >
      <div class="my-3 text-secondary overflow-hidden">
        <b-row
          v-for="section in newItem.sections"
          :key="section.name"
          class="px-3"
        >
          <b-col>
            {{ section.name }}
          </b-col>

          <b-col
            class="text-right"
            cols="5"
          >
            {{ $formatCurrency(section.total, '$0.00') }} USD
          </b-col>
        </b-row>

        <b-row class="px-3">
          <b-col>
            Taxes & Fees
          </b-col>
          
          <b-col
            cols="5"
            class="text-right"
          >
            {{ $formatCurrency(newItem.tax, '$0.00') }} USD
          </b-col>
        </b-row>

        <b-row class="bg-psi mx-0 font-family-2 my-2 py-1">
          <b-col class="text-uppercase pl-3">
            Reservation Total
          </b-col>

          <b-col
            cols="5"
            class="text-right pr-3"
          >
            {{ $formatCurrency(+newItem.total + +newItem.tax, '$0.00') }} USD
          </b-col>
        </b-row>
      </div>

      <template v-if="newItem.sections.Lodging">
        <div
          v-for="(lodgeItem, lodgeItemIndex) in newItem.sections.Lodging.items"
          :key="`lodge-${lodgeItemIndex}`"
          class="pb-1 mb-2"
        >
          <b-row no-gutters>
            <b-col class="ml-3 mb-1 text-uppercase">
              Item {{ lodgeItemIndex + 1 }} of {{ lodgingCount + toursCount }}: Lodging
            </b-col>
          </b-row>

          <div class="mx-3 mb-1 font-family-2 text-uppercase font-size-14">
            {{ hotels[lodgeItem.hotelCode].title }}
          </div>

          <div class="mx-3 mb-1">
            {{ $dayjs(lodgeItem.date, 'MM/DD/YYYY').format('ddd, MMM D') }} - {{ $dayjs(lodgeItem.date, 'MM/DD/YYYY').add(lodgeItem.nights, 'day').format('ddd, MMM D, YYYY') }}, {{ lodgeItem.nights }} Nights
          </div>

          <b-row no-gutters>
            <b-col class="ml-3 mb-2">
              Total ${{ (lodgeItem.total + lodgeItem.tax).toFixed(2) }} USD
            </b-col>

            <b-col
              class="mr-3 text-right"
              :class="{
                'text-danger': !lodgeItem.modifyAllowed
              }"
              cols="4"
            >
              {{ lodgeItem.modifyAllowed ? 'Refundable' : 'Not refundable' }}
            </b-col>
          </b-row>
        </div>
      </template>

      <template v-if="newItem.sections.Tours">
        <div
          v-for="(tourItem, tourItemIndex) in newItem.sections.Tours.items"
          :key="`tour-${tourItemIndex}`"
          class="pb-1 mb-2"
        >
          <b-row no-gutters>
            <b-col class="ml-3 mb-1 text-uppercase">
              Item {{ tourItemIndex + 1 + lodgingCount }} of {{ lodgingCount + toursCount }}: Activity
            </b-col>
          </b-row>

          <div class="mx-3 mb-1 font-family-2 text-uppercase font-size-14">
            {{ tours[tourItem.tourCode].title }}
          </div>

          <div class="mx-3 mb-1">
            {{ $dayjs(tourItem.date, 'MM/DD/YYYY').format('ddd, MMM D, YYYY') }}
          </div>

          <b-row no-gutters>
            <b-col class="ml-3 mb-2">
              Total ${{ (tourItem.total + tourItem.tax).toFixed(2) }} USD
            </b-col>

            <b-col
              class="mr-3 text-right"
              :class="{
                'text-danger': !tourItem.modifyAllowed
              }"
              cols="4"
            >
              {{ tourItem.modifyAllowed ? 'Refundable' : 'Not refundable' }}
            </b-col>
          </b-row>
        </div>
      </template>
    </div>

    <div class="py-3">
      <b-form-group
        class="mx-3"
        :state="getFieldState(v$.input.cancel_code)"
        :invalid-feedback="getFieldInvalidFeedback(v$.input.cancel_code)"
      >
        <b-form-select
          v-model="input.cancel_code"
          size="lg"
          :state="getFieldState(v$.input.cancel_code)"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Please select cancelation reason
            </b-form-select-option>
          </template>

          <b-form-select-option
            v-for="(cancelText, cancelValue) in options.owsCancels"
            :key="cancelValue"
            :value="cancelValue"
          >
            {{ cancelText }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group class="mx-3 mb-0">
        <b-button
          class="text-uppercase"
          variant="dark"
          block
          size="lg"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner
            v-if="isSubmitting"
            class="mr-1"
            small
          />

          Cancel all
        </b-button>
      
        <b-button
          class="text-uppercase"
          variant="outline-secondary"
          block
          size="lg"
          @click="$emit('cancel')"
        >
          Go Back
        </b-button>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { apiReservation, apiDeleteReservation } from '@/api/property'
import crudItemMixin from '@/mixin/crud-item'
import { required } from '@vuelidate/validators'
import {useVuelidate} from "@vuelidate/core";
import {getRoomEventItem, getTourEventItem} from '@/utils/gtm';
import {gtmTrack} from '@/lib/gtm';
import _ from 'lodash';

export default {
  name: 'ReservationItemCancel',
  setup: () => ({v$: useVuelidate()}),

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      idKey: 'token'
    }
  },

  computed: {
    ...mapGetters({
      options: 'app/options',
      tours: 'property/tours',
      hotels: 'property/hotels'
    }),

    lodgingCount() {
      return this.newItem.sections.Lodging?.items.length || 0
    },

    toursCount() {
      return this.newItem.sections.Tours?.items.length || 0
    }
  },
  validations: () => {
    return {
      input: {
        cancel_code: { required }
      }
    }
  },

  methods: {
    getInput() {
      return {
        cancel_code: null
      }
    },

    fetchItem() {
      this.isFetching = true

      return apiReservation(this.newItemItemIdentifier)
        .then((data) => {
          this.isFetching = false
          this.newItem = data
        })
        .catch(e => {
          this.isFetching = false
          console.log(e)
        })
    },

    trackRefund() {
      const lodgingItems = this.newItem.sections.Lodging?.items ?? [];
      const activityItems = this.newItem.sections.Tours?.items ?? [];

      const products = [
        ...lodgingItems.map((item) => (
          getRoomEventItem({
            item,
            hotels: this.hotels,
            isAgency: this.isAgency,
            propertyTitle: this.options.title
          })
        )),
        ...activityItems.map((item) => (
          getTourEventItem({
            item,
            tours: this.tours,
            isAgency: this.isAgency,
            propertyTitle: this.options.title
          })
        ))
      ];

      const tax = (_.sumBy(lodgingItems, 'tax') ?? 0) + (_.sumBy(activityItems, 'tax') ?? 0);
      const value = (_.sumBy(lodgingItems, 'total') ?? 0) + (_.sumBy(activityItems, 'total') ?? 0);

      const data = {
        'content-name': '/profile/reservations',
        'content-view-name': 'Reservations',
        event: 'refund',
        ecommerce: {
          currency: 'USD',
          transaction_id: this.newItem.uniqueId,
          tax: _.round(tax, 2),
          value: _.round(value, 2),
          items: [...products.map((item, index) => ({ ...item, index }))]
        }
      }

      gtmTrack({ ecommerce: null });
      console.log('data', data);
      gtmTrack(data)
    },

    updateItem() {
      this.isSubmitting = true

      return apiDeleteReservation(this.newItem.token, this.input.cancel_code)
        .then(() => {
          this.isSubmitting = false
          this.trackRefund();
        })
        .catch(e => {
          this.isSubmitting = false
          console.log(e)
        })
    }
  }
}
</script>