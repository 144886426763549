import { apiAuthUsername } from "@/api/auth";
import { required } from "@vuelidate/validators";
import { CopyIcon } from "@/components/Icons";
import { notCreditCard } from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    reservation_number: "",
    last_name: "",
    loading: false,
    errors: {},
    copyIcon: CopyIcon(),
    username: null,
    copySuccess: false,
    copyError: false
  }),
  created() {},
  validations() {
    return {
      reservation_number: { required, notCreditCard },
      last_name: { required, notCreditCard },
    };
  },
  methods: {
    async handleRequest() {
      this.v$.$touch();
      if (this.v$.$invalid) return;

      const data = {
        reservation_number: this.reservation_number,
        last_name: this.last_name,
      };
      try {
        this.loading = true;
        const res = await apiAuthUsername(data);
        if (typeof res.username !== 'undefined') {
          this.username = res.username
        }
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors };
      } finally {
        this.loading = false;
        this.$store.dispatch("app/setErrors");
      }
    },
    clipboardSuccessHandler() {
      this.copySuccess = true;
      this.copyError = false;
      this.setHideMessage();
    },
    clipboardErrorHandler() {
      this.copyError = true;
      this.copySuccess = false;
      this.setHideMessage();
    },
    setHideMessage() {
      var that = this;
      window.setTimeout(function() {
        that.copySuccess = false;
        that.copyError = false;
        }, 3000)
    }
  },
};
