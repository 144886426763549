import { apiAuthReset } from "@/api/auth";
import {required, requiredIf } from "@vuelidate/validators";
import { password, general, notCreditCard } from "@shared/utils/validators";
import { unescapeString } from "@/utils/index";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    passwordVisible: false,
    code: "",
    password: "",
    question: '',
    answer: '',
    loading: false,
    errors: {},
    reservationType: '',
    username: localStorage.getItem('resetRequestUsername')
  }),
  created() {
    this.reservationType = this.$store.state.app.modalProps.type;
  },
  computed: {
    isPasswordVisible() {
      return this.passwordVisible ? "text" : "password";
    },
    questions() {
      return this.$store.state.app.options.accountQuestions;
    },
  },
  validations: {

    code: {
      required,
      notCreditCard,
    },
    password: { required, password },
    question: {
      required: requiredIf(function() {
        return this.$store.state.app.modalProps.type === 'new';
      }),
    },
    answer: {
      required: requiredIf(function() {
        return this.$store.state.app.modalProps.type === 'new';
      }),
      general
    },
  },
  methods: {
    unescapeString,
    async handlePasswordReset() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      let data = {
        username: localStorage.getItem("resetRequestUsername"),
        password: this.password,
        token: this.code,
      };
      if (this.reservationType === 'new') {
        data['question'] = this.question;
        data['answer'] = this.answer
      }
      try {
        this.loading = true;
        const res = await apiAuthReset(data);
        if (res && res.access_token) {
          localStorage.removeItem("resetRequest");
          localStorage.removeItem("resetRequestUsername");

          await this.$auth.login(res.access_token, res.refresh_token, res.expires_in, res.token_type, {silent: true})
          this.$eventHub.$emit('login')
          this.setModal()
          this.$msg('You have been successfully logged in', 1)
        }
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors };
      } finally {
        this.loading = false;
        this.$store.dispatch("app/setErrors");
      }
    },
    handleResendRequest() {
      localStorage.removeItem("resetRequest");
      localStorage.removeItem("resetRequestUsername");
      this.setModal("ForgotPassword");
    },
    clearAndClose() {
      localStorage.removeItem('resetRequest')
      localStorage.removeItem('resetRequestUsername')
      this.setModal();
    },
  },
};
