import {apiHotelRooms} from "@/api/property";

export default {
    props: {
        reservation: Object,
        type: String,
        token: String,
        isWarning: Boolean,
    },
    data: () => ({
        form: {
            item: {},
            date: null,
            title: ''
        },
        deposit: 0,
        room: {},
    }),
    created() {
        this.setDataForm();
    },
    watch: {
        token: function () {
            this.setDataForm();
        }
    },
    computed: {},
    methods: {
        async fetchRooms () {
            try {
                const rooms = await apiHotelRooms(this.form.item.hotelCode);
                this.form.title = rooms[this.form.item.roomCode].title;
                const currentSection = this.reservation.sections[Object.keys(this.reservation.sections)[0]].items;
                const currentRoom = currentSection.find((item) => item.roomCode === rooms[this.form.item.roomCode].code);
                this.deposit = currentRoom.deposit
            }
            catch (err) {
                console.error('Error occurred while fetching rooms');
                console.error(err)
            }
        },
        setDataForm () {
            if (this.type === 'Room') {
                this.form.date = null;
                this.form.title = '';

                let items = this.reservation.sections.Lodging.items.filter(item => item.itemToken === this.token)
                this.form.item = items[0]

                this.form.date = this.$dayjs(this.reservation.arrival).format('ddd, MMM D') +  ' - ' + this.$dayjs(this.reservation.departure).format('ddd, MMM D, YYYY') + ', ' + this.form.item.nights + ' Nights';
                this.fetchRooms();
            }
            else if (this.type === 'Activity') {
                this.form.date = null;
                this.form.title = '';

                let items = this.reservation.sections.Tours.items.filter(item => item.itemToken === this.token)
                this.form.item = items[0]

                this.form.date = this.$dayjs(this.form.item.date).format('ddd, MMM D, YYYY');
                this.form.title = this.$store.state.property.tours[this.form.item.tourCode].title;
            }
        }
    }
}

