import store from "@/store/index";
import VueGtm from "vue-gtm";

let enabled = false;
export async function gtmInit(Vue, router) {
  //console.log("GtmInit", store.state.app.options.googleTag)

  const googleTag = store.state.app.options.googleTag;
  if (!googleTag) {
    return;
  }

  Vue.use(VueGtm, {
    id: googleTag,
    defer: false,
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ['ConfirmationPage'],
    trackOnNextTick: false,
  });

  enabled = true;
}

export function gtmTrack(data) {
  if (enabled) {
    window.dataLayer.push(data);
    console.log("gtmTrack", window.dataLayer);
  } else {
    console.log("Tracking is disabled");
  }
}

export function gtmStart() {
  const data = {
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  };

  gtmTrack(data);
}

export function gtmPageView() {
  gtmStart();
}
