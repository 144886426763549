import { FilterIcon, MapIcon } from "@/components/Icons";
import { mapGetters } from 'vuex'
import bookingMixin from '@/mixin/booking';

import { propertyMethods, optionsComputed, authComputed, appMethodsComputed, propertyComputed } from "@/store/helper";

export default {
  mixins: [
    bookingMixin
  ],

  props: {
    type: String,
    spec: String,
  },
  data: () => ({
    open: false,
    links: null,
    rateCode: "",
    viewOnMapStatus: false,
    filterIcon: FilterIcon(),
    mapIcon: MapIcon(),
    minAgeAdult: null,
    minAgeChild: null,
    hotel: null,
    //hotel: null,
    searchParams: {
      dateFrom: null,
      nights: 1,
      destination: "ALL",
      adults: 1,
      children: 0,
      infants: 0,
      animals: 0,
      rateCode: "",
      rateType: '',
      minDate: null,
      maxDate: null,
      exactDate: true,
    },
    confirmLoading: false,
    editMode: false,
  }),
  async created() {
    // clear save search if were in Add to reservation mode
    if (this.addToReservationMode) {
      this.$store.dispatch("property/clearSearchParams");
      this.$store.dispatch("app/endAddToReservation");
    }

    if (this.type === "hotel") {
      if (
        !!this.$route.query.destination &&
        this.$route.query.destination !== "ALL" &&
        this.$route.query.destination !== "ALL-HOTELS" &&
        this.$route.query.destination !== "ALL-CAMPGROUNDS"
      ) {
        const hotel = this.pHotels[this.$route.query.destination];
        this.minAgeAdult = hotel.resformAgeAdult;
        this.minAgeChild = hotel.resformAgeChild;
      } else {
        this.minAgeAdult = this.$store.state.app.options.resformAgeAdult;
        this.minAgeChild = this.$store.state.app.options.resformAgeChild;
      }

      this.rateCode = this.$route.query.rateCode;
      const path = !!!this.spec ? "/booking/lodging-search" : "/booking/lodging-flex-search";
      this.links = {
        first: { link: `/booking/lodging`, title: "Search" },
        second: { link: localStorage.getItem("step2") || "", title: "Select Hotel", disabled: this.$route.path === path },
        third: {
          link: localStorage.getItem("step3") ? localStorage.getItem("step3") : "/booking/lodging-select/",
          title: "Choose Room",
          disabled: this.$route.path === path || !localStorage.getItem("step3"),
        },
      };
      if (this.toursInlineEnabled) {
        this.links = Object.assign({}, this.links, {
          fourth: {
            link: localStorage.getItem("step4") ? localStorage.getItem("step4") : "/booking/activities-select/",
            title: "Activities",
            disabled: this.$route.path === path || !localStorage.getItem("step4"),
          },
          fifth: {
            link: "/booking/lodging-review",
            title: "Review & Pay",
            disabled: !this.cartNotEmpty,
          },
        });
      } else {
        this.links = Object.assign({}, this.links, {
          fourth: {
            link: "/booking/lodging-review",
            title: "Review & Pay",
            disabled: !this.cartNotEmpty,
          },
        });
      }
      console.log(this.links);
    }
    if (this.type === "tour") {
      if (this.$route.query.destination !== "ALL") {
        const tour = this.pTours[this.$route.query.destination];
        this.minAgeAdult = this.$store.state.app.options.resformAgeAdult;
        this.minAgeChild = this.$store.state.app.options.resformAgeChild;
      } else {
        this.minAgeAdult = this.$store.state.app.options.resformAgeAdult;
        this.minAgeChild = this.$store.state.app.options.resformAgeChild;
      }

      this.links = {
        first: { link: "/booking/activities", title: "Search" },
        second: { link: localStorage.getItem("step2") || "", title: "Select Activity", disabled: this.$route.name === "tours" },
        third: { link: "/select-activity-time", title: "Choose Time" },
        fourth: { link: "/review-pay", title: "Review & Pay" },
      };
    }

    this.$eventHub.$on("close-filter-section", this.onCloseFilterSection);
  },
  computed: {
    ...authComputed,
    ...optionsComputed,
    ...propertyComputed,
    ...mapGetters({
      addToReservation: 'app/addToReservation',
      activitySearchParams: 'property/activitySearchParams',
      lodgeSearchParams: 'property/lodgeSearchParams',
      tourCartIsEmpty: 'property/tourCartIsEmpty',
      cartIsEmpty: 'property/cartIsEmpty',
      ratePlans: 'property/hotelsRatePlans',
    }),
    dateFrom() {
      if (this.ratePlan?.category === 'GROUP' && this.$route.name === 'hotelsFlex') {
        return `${this.$dayjs(this.ratePlan.start, 'MM/DD/YYYY').format("MMM D")} - ${this.$dayjs(this.ratePlan.end, 'MM/DD/YYYY').format("MMM D, YYYY")}`;
      }
      if (this.type === "hotel") {
        return this.spec
          ? this.$dayjs(this.dateString2Date(this.$route.query.dateFrom || "")).format("MMMM YYYY")
          : this.$dayjs(this.dateString2Date(this.$route.query.dateFrom || "")).format("ddd, MMM D, YYYY");
      }
      if (this.type === "tour") {
        return this.spec
          ? this.$dayjs(this.dateString2Date(this.$route.query.date || "")).format("MMMM YYYY")
          : this.$dayjs(this.dateString2Date(this.$route.query.date || "")).format("ddd, MMM D, YYYY");
      }
    },
    dateTo() {
      return this.$dayjs(this.dateString2Date(this.$route.query.dateFrom || ""))
        .add(this.$route.query.nights, "d")
        .format("ddd, MMM D, YYYY");
    },
    nightsAmount() {
      return this.$route.query.nights + (+this.$route.query.nights === 1 ? " Night" : " Nights");
    },
    adultsAmount() {
      return this.adults + (+this.adults === 1 ? " Adult" : " Adults");
    },
    childrenAmount() {
      return this.children + (+this.children === 1 ? " Child" : " Children");
    },
    infantsAmount() {
      return this.infants + (+this.infants === 1 ? " Infant" : " Infants");
    },
    animalsAmount() {
      return this.animals + (+this.animals === 1 ? " Animal" : " Animals");
    },
    locationName() {
      return this.$store.state.app.options.title;
    },
    destination() {
      const routeName = this.$route.name;
      if (routeName === "hotelPage") {
        return this.$store.state.property.hotels[this.$route.query.destination].title;

      } else if (this.type === "hotel") {
        return this.$route.query.destination === "ALL"
          ? `All ${this.locationName} Lodges`
          : this.$route.query.destination === "ALL-HOTELS"
          ? `All ${this.locationName} Hotels`
          : this.$route.query.destination === "ALL-CAMPGROUNDS"
          ? `All ${this.locationName} Campgrounds`
          : this.$store.state.property.hotels[this.$route.query.destination].title
          /*
          : !!this.spec || this.$route.name === "activitiesPage"
          ? this.$store.state.property.hotels[this.$route.query.destination].title
          : this.$store.state.property.hotels[this.$route.query.destination || this.$route.query.destination].title;
          */

      } else if (this.type === "tour") {
        return this.$route.query.destination === "ALL"
          ? `All ${this.locationName} Activities`
          : this.$store.state.property.tours[this.$route.query.destination].title;

      } else {
        return "";
      }
    },
    allowEditBar() {
      if (this.type === 'hotel') {
        return (
          this.$route.name !== "ReviewPage" &&
          !this.addToReservation &&
          this.$route.name !== "activitiesPage"
        )

      } else if (this.type === 'tour') {
        return (
          this.$route.name !== "ReviewPage"
        )
      }
      return true
    },
    hotelsList() {
      return this.$store.getters["property/hotelsTitle"];
    },
    promoCode() {
      return !!this.$route.query.rateCode ? this.$route.query.rateCode : this.searchParams.rateCode;
    },
    promoType() {
      return this.$route.query.rateType || this.searchParams.rateType
      /*
      if (!!this.$route.query.rateCode) {
        let params;
        if (localStorage.getItem("hotelsSearchParams")) {
          params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
        } else {
          params = this.searchParams;
        }
        return params.rateType;
      } else {
        return "";
      }
      */
    },
    ratePlan() {
      return this.ratePlans[this.promoCode];
    },
    destinationCode() {
      return this.$route.query.destination;
    },
    adults() {
      return +this.$route.query.adults;
    },
    children() {
      return +this.$route.query.children;
    },
    infants() {
      return +this.$route.query.infants || 0;
    },
    animals() {
      return +this.$route.query.animals || 0;
    },
    startDate() {
      let value = null;
      let params = {};

      if (!!this.$route.query.dateFrom) {
        const today = new Date();
        const savedDate = this.dateString2Date(this.$route.query.dateFrom || "");
        if (savedDate >= today) {
          value = savedDate;
        }
      } else if (!!this.$route.query.date) {
        const today = new Date();
        const savedDate = this.dateString2Date(this.$route.query.date || "");
        if (savedDate >= today) {
          value = savedDate;
        }
      } else if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
        const today = new Date();
        if (params.dateFromString) {
          const savedDate = this.dateString2Date(params.dateFromString);
          //console.log("startDate", savedDate);

          if (savedDate >= today) {
            value = savedDate;
          }
        }
      } else {
        params = this.searchParams;
        value = params.dateFrom;
      }
      return value;
    },
    exactDate() {
      return this.$route.name !== "hotelsFlex" && this.$route.name !== "toursFlexible";
    },
    nights() {
      let params = {};
      if (!!this.$route.query.nights) params["nights"] = +this.$route.query.nights;
      else if (localStorage.getItem("hotelsSearchParams")) {
        params = JSON.parse(localStorage.getItem("hotelsSearchParams"));
      } else {
        params = this.searchParams;
      }
      return parseInt(params.nights);
    },
    minDate() {
      return this.$dayjs(this.bookingDatesWindow.min).toDate()
    },
    maxDate() {
      return this.$dayjs(this.bookingDatesWindow.max).toDate()
    },
    maxStayDate() {
      return this.$dayjs(this.bookingDatesWindow.maxStay).toDate()
    },
    /*
    minDate() {
      let value;

      if (this.isModifyReservationMode) {
        const arrival = this.dateString2Date(this.modifyReservation.item.date);

        let minDate = new Date();
        let today = new Date();
        minDate.setTime(arrival.getTime() - 14 * 24 * 3600 * 1000);
        minDate.setHours(0, 0, 0, 0);

        value = minDate >= today ? minDate : today;
      } else if (this.options.mindate) {
        if (this.options.mindate) {
          value = this.dateString2Date(this.options.mindate);
        } else {
          let date = new Date();
          date.setHours(0, 0, 0, 0);

          value = date;
        }
      }

      return value;
    },
    */
    /*
    optionsMaxdate() {
      const isActivities = ['tours', 'tourPage'].includes(this.$route.name)
      return isActivities ? this.options.toursMaxdate : this.options.maxdate
    },
    maxDate() {
      let value;

      if (this.isModifyReservationMode) {
        const arrival = this.dateString2Date(this.modifyReservation.item.date);
        const nights = this.modifyReservation.item.nights;
        const departure = this.$dayjs(arrival)
          .add(nights, "day")
          .toDate();

        let maxDate = new Date();
        maxDate.setTime(departure.getTime() + 14 * 24 * 3600 * 1000);
        maxDate.setHours(0, 0, 0, 0);

        value = maxDate;
      } else {
        if (this.optionsMaxdate) {
          value = this.$dayjs(this.optionsMaxdate).toDate()
        } else {
          let date = new Date();
          date.setHours(0, 0, 0, 0);
          date = this.$dayjs(date)
            .add(12, "month")
            .toDate();

          value = date;
        }
      }

      return value;
    },
    */
    promoCodes() {
      return this.options.displaycodes;
    },
    filterData() {
      if (this.$route.name === "hotelPage") {
        return {
          page: "hotelPage",
          hotel: this.$store.state.property.hotels[this.$route.query.destination]
            ? this.$store.state.property.hotels[this.$route.query.destination]
            : {},
        };
      }
    },
    modifyReservationId() {
      return this.modifyReservation.reservation.uniqueId;
    },
    dateLabel() {
      return this.type === "tour" ? "Activity Date" : "Check In - Check Out";
    },
    dateType() {
      return this.type === "tour" ? "single" : "range";
    },
    showSteps() {
      return this.$route.name !== "ReviewPage";
    },
    editBarType() {
      return this.type === "hotel" ? "hotels" : "tours";
    }
  },
  methods: {
    ...appMethodsComputed,
    ...propertyMethods,
    onViewOnMapClick() {
      this.viewOnMapStatus = !this.viewOnMapStatus;
      this.$eventHub.$emit("hotel-map-toggle", this.viewOnMapStatus);
    },
    onCloseFilterSection() {
      this.open = false;
    },
    onReturnToReservation() {
      const reservation_id = this.addToReservation.uniqueId;
      this.$store.dispatch("app/endAddToReservation");
      this.$router.push({ name: "ReservationPage", params: { reservation_id } })
    },
    async clearCart() {
      await this.$store
        .dispatch("property/startOver")
        .then((r) => {
          this.setModal();
          this.$router.push({ path: this.links.first.link });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    toSearch() {
      if (!!this.cart) {
        this.setModal("ConfirmationDialog", {
          content: "Are you sure you want to empty your cart and choose another lodging date?",
          onConfirm: this.clearCart,
        });
      } else this.$router.push({ path: this.links.first.link });
    },
    getLinkClass(number) {
      let classes = [];

      switch (number) {
        case 1:
          break;
        case 2:
          if (this.$route.name === "hotels" || this.$route.name === "hotelsFlex" || this.$route.name === "tours") {
            classes.push("active");
          }
          break;
        case 3:
          if (this.$route.name === "hotelPage" || this.$route.name === "tourPage") {
            classes.push("active");
          }
          break;
        case 4:
          if (this.$route.name === "activitiesPage") {
            classes.push("active");
          }
          break;
        case 5:
          if (this.cartNotEmpty && this.$route.name !== "ReviewPage") {
            classes.push("not-empty");
          }
          break;
      }

      //console.log("getLinkClass", classes)

      return classes.join(" ");
    },
    async onSubmit(data) {
      console.log("onSubmit", data);

      if (data["type"] === "hotels") {
        this.submitLodgingSearch(data);
      } else if (data["type"] === "tours") {
        this.submitActivitySearch(data);
      }
    },
    async submitLodgingSearch(params) {
      let name = null
      if (params.exactDate) {
        name = ['ALL', 'ALL-HOTELS', 'ALL-CAMPGROUNDS'].includes(params.destination) ? 'hotels' : 'hotelPage'
      } else {
        name = 'hotelsFlex'
      }

      const query = {
        destination: params.destination,
        adults: params.adults,
        children: params.children,
        infants: params.infants,
        animals: params.animals,
        rateCode: params.rateCode,
        rateType: params.rateType,
        nights: params.nights,
        dateFrom: this.$dayjs(params.dateFrom).format('MM-DD-YYYY')
      }

      this.$router.push({ name, query })
      this.editMode = false

      /*
      this.confirmLoading = true;

      localStorage.removeItem("hotelsSearchParams");
      localStorage.setItem("hotelsSearchParams", JSON.stringify(data));

      const date =
        this.$dayjs(new Date()).format("YYYY/MM") === this.$dayjs(data.dateFrom).format("YYYY/MM")
          ? this.$dayjs(new Date()).format("MM/DD/YYYY")
          : this.$dayjs(data.dateFrom).format("MM/DD/YYYY");

      const availabilityData = {
        date: !data.exactDate ? date : this.$dayjs(data.dateFrom).format("MM/DD/YYYY"),
        nights: data.nights,
        rate_code: data.rateCode,
        limit: data.nights,
      };
      //console.log("onSubmit", availabilityData);

      await this.$store.dispatch("property/fetchAvailableHotels", availabilityData);

      let name;
      let query = {
        dateFrom: this.$dayjs(data.dateFrom).format("MM-DD-YYYY"),
        adults: data.adults,
        children: data.children,
      };
      let params = {};

      if (data.rateCode) {
        query["rateCode"] = data.rateCode ? data.rateCode : "";
      }

      if (!data.exactDate) {
        name = "hotelsFlex";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      } else if (data.destination === "ALL") {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = "ALL";
      } else if (data.destination === "ALL-CAMPGROUNDS" || data.destination === "ALL-HOTELS") {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      } else if (
        !this.$store.state.property.availableHotels[availabilityData.date][data.destination].min &&
        !this.isModifyReservationMode
      ) {
        name = "hotels";
        query["nights"] = data.nights;
        query["destination"] = "ALL";

        this.setModal("HotelRateCalendar", {
          hotel_id: data.destination,
          alert: true,
        });
      } else {
        name = "hotelPage";
        query["nights"] = data.nights;
        query["destination"] = data.destination;
      }

      console.log("name", name, query);
      this.$router
        .push({
          name: name,
          params: params,
          query: query,
        })
        .then((r) => {
          console.log("router-response: ", r);
        })
        .catch((err) => {
          console.log("kaka from catch");
          if (err.name === "NavigationDuplicated") console.error(err);
        })
        .finally(() => {
          this.confirmLoading = false;
          this.editMode = false;
        });
      */
    },
    submitActivitySearch(params) {
      console.log('params', params);
      let name = null
      if (params.exactDate) {
        name = ['ALL'].includes(params.destination) ? 'tours' : 'tourPage'
      } else {
        name = 'toursFlexible'
      }

      const query = {
        destination: params.destination,
        date: this.$dayjs(params.dateFrom).format('MM-DD-YYYY'),
        adults: params.adults,
        children: params.children,
        infants: params.infants,
        animals: params.animals,
      }

      console.log('name, query', name, query);

      this.$router.push({ name, query })
      this.editMode = false

      // this.confirmLoading = true;

      // localStorage.removeItem("toursSearchParams");
      // localStorage.setItem("toursSearchParams", JSON.stringify(data));

      // let query = {
      //   date: this.$dayjs(data.dateFrom).format("MM-DD-YYYY"),
      //   adults: data.adults,
      //   children: data.children,
      //   infants: data.infants,
      //   animals: data.animals,
      // };
      // let name;

      // if (!data.exactDate) {
      //   name = "toursFlexible";
      //   query["destination"] = data.destination;
      // } else if (data.destination === "ALL") {
      //   name = "tours";
      //   query["destination"] = "ALL";
      // } else {
      //   name = "tourPage";
      //   query["destination"] = data.destination;
      // }

      // console.log("name", name, query);
      // this.$router
      //   .push({
      //     name: name,
      //     query: query,
      //   })
      //   .then((r) => {
      //     console.log("router-response: ", r);
      //   })
      //   .catch((err) => {
      //     if (err.name === "NavigationDuplicated") console.error(err);
      //   })
      //   .finally(() => {
      //     this.confirmLoading = false;
      //     this.editMode = false;
      //   });
    },
    returnToModifyReservation() {
      const reservation_id = this.modifyReservationId;

      this.$router.push({
        name: "ReservationPage",
        params: { reservation_id: reservation_id },
      });
    },
  },
};
