<template>
  <div
    class="gallery position-relative"
    :style="{
      '--swiper-navigation-color': '#FFF'
    }"
  >
    <div
      ref="swiperTop"
      class="swiper gallery-top"
      :class="{
        'h-100': images && images.length === 1,
        [swiperClass]: !!swiperClass
      }"
    >
      <div class="swiper-wrapper">
        <div
          v-for="image in images"
          :key="image.url"
          class="swiper-slide"
        >
          <img
            class="w-100 h-100 object-fit-cover"
            :class="imageClass"
            :src="image.image"
            :alt="image.title"
          />
        </div>
      </div>

      <div
        class="swiper-button-next swiper-button-white d-none"
        :class="{
          'd-block': images && images.length > 1
        }"
      />

      <div
        class="swiper-button-prev swiper-button-white d-none"
        :class="{
          'd-block': images && images.length > 1
        }"
      />

      <div class="gallery-badge">
        <slot name="badge" />
      </div>
    </div>

    <div
      v-show="images && images.length > 1"
      ref="swiperThumb"
      class="swiper gallery-thumbs"
    >
      <div class="swiper-wrapper">
        <div
          v-for="image in images"
          :key="image.url"
          class="swiper-slide"
        >
          <img
            class="w-100 h-100 object-fit-cover"
            :class="thumbImageClass"
            :src="image.image"
            :alt="image.title"
          />
        </div>
      </div>
    </div>

    <HotelItemImagesButton
      v-if="showOpenButton"
      ref="swiperButton"
      class="popup-button position-absolute cursor-pointer p-2 line-height-10"
      :item="item"
      :initial-slide="popupInitialSlide"
      :images-key="imagesKey"
      :style="{
        top: 0,
        right: 0,
        zIndex: 1,
      }"
      @opened="popupInitialSlide = swiperTop.activeIndex"
    >
      <b-icon-arrows-fullscreen class="text-white position-relative" />
    </HotelItemImagesButton>
  </div>
</template>

<script>
import HotelItemImagesButton from './buttons/HotelItemImagesButton.vue'

import Swiper, {
  FreeMode,
  Navigation,
  Thumbs
} from 'swiper'

Swiper.use([
  FreeMode,
  Navigation,
  Thumbs
])

export default {
  name: 'HotelItemImages',

  components: {
    HotelItemImagesButton
  },

  props: {
    item: {
      type: Object,
      default: null
    },

    initialSlide: {
      type: Number,
      default: 0
    },

    activeIndex: {
      type: Number,
      default: 0
    },

    showOpenButton: {
      type: Boolean,
      default: true
    },

    imageClass: {
      type: String,
      default: null
    },

    thumbImageClass: {
      type: String,
      default: null
    },

    imagesKey: {
      type: String,
      default: 'images'
    },

    swiperClass: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      popupInitialSlide: 0,
      newActiveIndex: 0,
      swiperTop: null,
      swiperThumb: null 
    }
  },

  computed: {
    images() {
      return this.item[this.imagesKey]
    }
  },

  mounted() {
    this.initSwiper()
  },

  beforeDestory() {
    this.destroySwiper()
  },

  watch: {
    item: {
      immediate: true,
      deep: true,

      handler() {
        this.$nextTick(() => {
          this.updateSwiper()
        })
      }
    },

    activeIndex: {
      immediate: true,

      handler() {
        this.newActiveIndex = this.activeIndex
      }
    },

    newActiveIndex: {
      immediate: true,

      handler() {
        this.$emit('update:active-index', this.newActiveIndex) 
      }
    }
  },

  methods: {
    initSwiper() {
      this.swiperThumb = new Swiper(this.$refs.swiperThumb, {
        initialSlide: this.initialSlide,
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true
      })

      this.swiperTop = new Swiper(this.$refs.swiperTop, {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        thumbs: {
          swiper: this.swiperThumb
        },

        on: {
          realIndexChange: swiper => {
            this.newActiveIndex = swiper.activeIndex
          }
        }
      })
    },

    updateSwiper() {
      if (this.swiperThumb) {
        this.swiperThumb.update(true)
      }

      if (this.swiperTop) {
        this.swiperTop.update(true)
      }

      this.swiperTop.slideTo(this.initialSlide)
    },

    destroySwiper() {
      if (this.swiperThumb) {
        this.swiperThumb.destroy()
      }

      if (this.swiperTop) {
        this.swiperTop.destroy()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.swiper
  // width: 100%
  // height: 300px
  margin-left: auto
  margin-right: auto

  &.gallery-top
    height: 78%
    width: 100%

  &.gallery-thumbs
    height: 22%
    box-sizing: border-box
    padding-top: 10px

    .swiper-slide
      width: 25%
      height: 100%
      opacity: 0.4
      overflow: hidden
      display: flex
      justify-content: center

    .swiper-slide-thumb-active
      opacity: 1

.popup-button:before
  content: ''
  background: #222
  opacity: 0.3
  display: block
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.gallery-badge
  position: absolute
  right: 0
  bottom: 0
  z-index: 1
</style>